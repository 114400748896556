import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '0.85rem',
    '&.variant-small': {
      fontSize: '0.85rem',
    },
    '&.variant-horizontal': {
      fontSize: '0.85rem',
      float: 'left',
      marginTop: -3,
      marginRight: theme.spacing(1),
    }
  },
}), {
  name: 'UserAvatar', 
  index: 1,
});
