import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiTable-root th': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .userName': {
      display: "flex",
      alignItems: "center",
    },
    '& .userName span': {
      paddingLeft: theme.spacing(1),
    },
    '& .MuiToolbar-regular:not(.MuiTablePagination-toolbar):first-of-type': {
      display: "none",
    },
    '& .MuiTable-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
}), {
  name: 'UsersList', 
  index: 1,
});
