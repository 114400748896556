import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Visibility, 
  VisibilityOff
} from '@material-ui/icons';

import AuthForm from 'components/basic/AuthForm';
import Form from 'components/basic/Form';

import { useHistory } from 'react-router-dom';

import styles from './Login.style';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { InputAdornment, IconButton } from '@material-ui/core';

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUserMeta = useSelector(currentUserDuck.get.meta);
  const classes = styles();
  const [loggingOut, setLoggingOut] = useState(history.location.pathname === '/logout');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if(loggingOut){
      dispatch(currentUserDuck.thunk.logout());
    }
  }, [loggingOut]);  
  
  useEffect(() => {
    if(!loggingOut && currentUserMeta.loggedIn){
      history.replace('/');
    } else if(loggingOut && !currentUserMeta.loggedIn){
      setLoggingOut(false);
    }
  }, [currentUserMeta, loggingOut]);  

  const eye = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <div className={classes.root}>
      <AuthForm
        title='Zaloguj się'
        buttons={[
          { label: 'Nie pamiętasz hasła?', target: '/forgot-password'},
          // { label: 'Nie masz konta? Zarejestruj się', target: '/register'},
        ]}
      >
        <Form 
          variant='inner'
          fields={[
            { label: 'Twój e-mail', name: 'login', defaultValue: '', required: true},
            { label: 'Hasło', name: 'password', defaultValue: '', type: showPassword ? 'text' : 'password', InputProps: {endAdornment: eye}, required: true},
          ]} 
          buttons={[
            { label: 'Zaloguj', color: 'secondary', type: 'submit', disabled: currentUserMeta.loading || currentUserMeta.loggedIn},
          ]}
          onSubmit={credentials => {
            dispatch(currentUserDuck.thunk.login(credentials, history));
          }}
        />
      </AuthForm>
    </div>
  );
}
