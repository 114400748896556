import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& p': {
      marginBottom: theme.spacing(2),
    }
  },
}), {
  name: 'Text', 
  index: 1,
});
