// import Axios from 'axios';
import shortid from 'shortid';
import {RecordLibraryDuck} from 'redux/lib/Duck';
import * as  caseDetailsDucks from 'redux/ducks/caseDetails';
import backend from 'redux/lib/backend';

export const casesDuck = new RecordLibraryDuck('cases', ({id, transferPrice = 'standard'}) => {
  let query = [];
  if(transferPrice === 'free'){
    query.push('cancel=true');
  } else if(transferPrice !== 'standard'){
    query.push(`price=${transferPrice}`);
  }

  return `/cases/${id}${query.length ? '?'+query.join('&') : ''}`;
});
const duck = casesDuck;

duck.creator = (initialData = {}) => Object.assign({
  // status: 'active',

  // id: null, 
  // ownerUserId: null, 
  // ownerCompanyId: null, 
  // amountPln: '', 
  // amountForeign: '', 
  // bank: '', 
  // preparationDate: '', 
  // temporaryIncrease: null, 
  // optionalNotes: '', 
  
  // agreementNo: 'umowa-'+shortid.generate(), 
  // bankId: 3, 
  // calculationDate: '2020-01-'+Math.floor(Math.random()*28+1), 
  // currency: 'CHF', 
  // installmentAmount: 144, 
  // installmentType: 'equal', 
  // interestRate: 1, 
  // liborType: '3M', 
  // margin: 1, 
  // name: 'sygnatura-'+shortid.generate(), 
  // paymentDayNo: 4, 
  // signatureDate: '2020-01-'+Math.floor(Math.random()*28+1), 
  // person: shortid.generate()+' Kowalski', 
}, initialData); 

duck.deletedStatuses = ['erased', 'deactivated'];

duck.requiredFields = [
  'agreementNo', 
  'bankId', 
  'calculationDate',
  // 'currency', 
  'installmentAmount', 
  'installmentType', 
  'interestRate', 
  'liborType',
  'margin', 
  'paymentDayNo',
  'person', 
  'signatureDate', 
];

duck.normalize = (aCase, {users, banks}) => ({ 
  ...aCase, 
  bankName: banks.data.reduce((result, bank) => result || (bank.id === aCase.bankId && bank.name), false),
  userName: (    
      users.data
        .filter(user => user.id === aCase.ownerUserId)
        .map(user => user.firstName + ' ' + user.lastName)
        [0]
    ),
});

duck.get.byUserId = function(requestedId){ return (state) => {
  const all = this.get.all()(state);
  if(requestedId){
    return all.filter(row => row.ownerUserId == requestedId); // eslint-disable-line eqeqeq
  } else {
    return all;
  }  
}};

duck.get.simulation = requestedId => ({ caseSimulation }) => (
  caseSimulation.data
    // .filter(simulation => simulation.caseId == requestedId) // eslint-disable-line eqeqeq
    // [0]
);

duck.api.getAllReportsCount = function(args = [], onSuccess){
  return backend({
    success: onSuccess,
    method: 'get',
    url: `/cases/reports-counts`,
    axiosParams: {
      params: {
        caseIds: args
      }
    }
  });
};


duck.action.caseUpdated = {
  reducer: (segment, {caseId, updatedAt}) => ({...segment,
    data: [
      ...segment.data.map(row => (row.id === caseId ? {...row, updatedAt} : row)),
    ],
  }),
};

duck.thunk.getDetails = (caseId) => (
  (dispatch, getState) => {
    if(!duck.isNewId(caseId)){
      dispatch(caseDetailsDucks.caseBankRateDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseCostDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.casePaymentDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseTrancheDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseInstallmentAmountDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseInstallmentOverrideDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseInstallmentDayDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.caseInstallmentTypeDuck.thunk.getAll({urlParams: {caseId}}));
      dispatch(caseDetailsDucks.casePaymentHolidaysDuck.thunk.getAll({urlParams: {caseId}}));
    }
  }
);

duck.thunk.getAll = function(args){
  return RecordLibraryDuck.prototype._defaultThunks.getAll.bind(this)({...args, callback: resData => {
    // sessionSave(Object.fromEntries(resData.map(row => ([row.id, row.updatedAt]))));
  }});
};

duck.thunk.caseUpdated = function(caseId){
  return (dispatch, getState) => {
    const updatedAt = (new Date()).toISOString();
    sessionUpdate({[caseId]: updatedAt});
    dispatch(this.action.caseUpdated({caseId, updatedAt}));
  }
};

duck.thunk.fetchOrCreate = function({urlParams, initialData}){
  return (dispatch, getState) => {
    if(!this.isNewId(urlParams.id)){
      this.api.getOne({urlParams});
    } else {
      const foundRow = this.get.byId(urlParams.id)(getState());
      if(typeof foundRow === 'undefined'){
        dispatch(this.action.addOne(this.creator({id: urlParams.id, ...initialData})));
      }
    }
  }
};

duck.api.caseSessionUpdate = function(requestedId){ 
  
  return sessionRead()[requestedId];
};

const sessionSave = (data) => localStorage.projusteCaseUpdates = JSON.stringify(data);
const sessionUpdate = (data) => sessionSave({...sessionRead(), ...data});
const sessionRead = () => {
  try {
    return JSON.parse(localStorage.projusteCaseUpdates);
  } catch (e) {
    return {};
  }
};