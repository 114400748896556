import { RecordLibraryDuck } from 'redux/lib/Duck';
import { PostOffice } from 'redux/lib/PostOffice';
import store from 'redux/store';

export class CaseDetailsDuck extends RecordLibraryDuck {};

CaseDetailsDuck.prototype._defaultGets = {
  ...CaseDetailsDuck.prototype._defaultGets,
  byCaseId: function(caseId){
    return (state) => (
      this.get.allWithNew()(state)
        .filter(item => item.caseId == caseId) // eslint-disable-line eqeqeq
    );
  },
};

// CaseDetailsDuck.prototype._defaultActions = {
//   ...RecordLibraryDuck.prototype._defaultActions,
// };

CaseDetailsDuck.prototype._defaultThunks = {
  ...RecordLibraryDuck.prototype._defaultThunks,
  addBulkSync: function({urlParams, data, callback, onError}){
    urlParams || (urlParams = data);
    
    return (dispatch, getState) => {
      this.api.addMany({ 
        immediate: true, 
        urlParams, 
        data, 
        onAdded: resData => {
          dispatch(this.action.updateMany(resData));
          typeof callback === 'function' && callback(resData);
        },
        onError, 
      });
    }
  },
  deleteBulkSync: function({urlParams, data, callback, onError}){
    urlParams || (urlParams = data);

    return (dispatch, getState) => {
      this.api.deleteMany({
        immediate: true, 
        urlParams, 
        data,
        onDeleted: resData => {
          dispatch(this.action.deleteMany(data));
          typeof callback === 'function' && callback(resData);
        },
        onError,
      })
    };
  },
};

CaseDetailsDuck.prototype._defaultApis = {
  ...RecordLibraryDuck.prototype._defaultApis,
  addMany: function({urlParams, data, onAdded, ...options} = {urlParams: {id: ''}}){ 
    PostOffice.add({
      url: this.apiGeneralUrlCreator(urlParams)+'-bulk',
      model: this.name, 
      data,
      id: 'bulk',
      onAdded: function(resData){
        store.dispatch(this.action.updateMany(data.map((row, i) => ({...row, id: resData[i]}))))
        typeof onAdded === 'function' && onAdded(resData);
      }.bind(this),
      ...options,
    });
  },
  deleteMany: function({urlParams, data, ...options} = {urlParams: {id: ''}}){ 
    urlParams || (urlParams = data);
    PostOffice.delete({
      url: this.apiUrlCreator(urlParams)+'-bulk',
      model: this.name, 
      id: 'bulk',
      data,
      ...options,
    });
  },
};
