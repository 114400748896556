import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    minHeight: 64,
    marginBottom: theme.spacing(4),
    '& a': {
      display: "flex",
      alignItems: "center",
    },
    '& img': {
      width: 240,
      height: "auto",
    },
  },
}), {
  name: 'LargeLogo', 
  index: 1,
});
