import { fade, makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      width: "100%",
      backgroundColor: fade(theme.palette.common.white, 0.7),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 1),
      },
    },
  },
  cardContent: {
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
  formButton: {
    marginTop: theme.spacing(2),
  },
}), {
  name: 'KnowledgeBaseArticle', 
  index: 1,
});


