import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reduxWatch from 'redux-watch'

import initialState from './initialState';

import {casesDuck} from './ducks/cases';
import {currentUserDuck} from './ducks/currentUser';
import {companiesDuck} from './ducks/companies';
import {reportsDuck} from './ducks/reports';
import {usersDuck} from './ducks/users';
import {receiptsDuck} from './ducks/receipts';
import {ownerDuck} from './ducks/owner';
import {postOfficeDuck} from './ducks/postOffice';
import {statsDuck} from './ducks/stats';
import * as dictionaryDucks from './ducks/dictionaries';
import * as caseDetailsDucks from './ducks/caseDetails';
import { featuresDuck } from 'redux/ducks/features';

// define reducers
const reducers = {
  cases: casesDuck.reducer,
  companies: companiesDuck.reducer,
  currentUser: currentUserDuck.reducer,
  receipts: receiptsDuck.reducer,
  reports: reportsDuck.reducer,
  users: usersDuck.reducer,
  owner: ownerDuck.reducer,
  postOffice: postOfficeDuck.reducer,
  stats: statsDuck.reducer,
  features: featuresDuck.reducer,
  /* dictionaries */
  ...Object.values(dictionaryDucks).reduce((result, duck) => ({...result, [duck.name]: duck.reducer}), {}),
  /* caseDetails */
  ...Object.values(caseDetailsDucks).reduce((result, duck) => ({...result, [duck.name]: duck.reducer}), {}),
};

// add blank reducers for initial state properties without reducers
Object.keys(initialState).forEach(item => {
  if (typeof reducers[item] == 'undefined') {
    reducers[item] = (statePart = null) => statePart;
  }
});

// combine reducers
const storeReducer = combineReducers(reducers);
// const combinedReducers = combineReducers(reducers);

// merge all reducers with globalReducer
// const storeReducer = (state, action) => {
//   const modifiedState = globalReducer(state, action);
//   return combinedReducers(modifiedState, action);
// };

const resetEnhancer = rootReducer => (state, action) => {
  if (action.type !== 'RESET') return rootReducer(state, action);

  const newState = {...initialState, currentUser: {...state.currentUser, data: {login: null}}};
  // newState.router = state.router;
  return newState;
};

// create store
const store = createStore(resetEnhancer(storeReducer), initialState, composeWithDevTools(applyMiddleware(thunk)));
export default store;

const similarArrays = (aImm, bImm) => {
  const a = [...aImm];
  const b = [...bImm];

  for(let indA = a.length - 1; indA >= 0; indA--){
    const val = a[indA];
    const indB = b.indexOf(val);
    if(indB > -1){
      a.splice(indA, 1);
      b.splice(indB, 1);
    } else {
      return false;
    }
  }
  return a.length + b.length === 0;
};

const similarObjects = (a, b) => {
  if((a && !b) || (!a && b)){
    return false;
  }
  const keys = Object.keys({...a, ...b});
  for(let key of keys){
    if(typeof a[key] !== typeof b[key]){
      return false;
    }
    if(a[key] && typeof a[key] === 'object'){
      if(a[key].constructor.name === 'Object' && !similarObjects(a[key], b[key])){
        return false;
      }
      if(a[key].constructor.name === 'Array' && !similarArrays(a[key], b[key])){
        return false;
      }
    }
    if(a[key] !== b[key]){
      return false;
    }
  }
  return true;
};

store.subscribe(reduxWatch(store.getState, 'companies.data')((newData, oldData) => {
  const currentCompany = store.getState().currentUser.data.company;
  if(!currentCompany || !currentCompany.id){
    return;
  }
  const oldCompany = oldData.find(row => row.id === currentCompany.id);
  const newCompany = newData.find(row => row.id === currentCompany.id);
  if(oldCompany !== newCompany && !similarObjects(newCompany, currentCompany) ){ //JSON.stringify(newCompany) !== JSON.stringify(currentCompany)){
    store.dispatch(currentUserDuck.thunk.fieldUpdate({data: {company: newCompany}}));
  }
}));

store.subscribe(reduxWatch(store.getState, 'users.data')((newData, oldData) => {
  const currentUser = store.getState().currentUser.data;
  if(!currentUser.id){
    return;
  }
  const oldUser = oldData.find(row => row.id === currentUser.id);
  const newUser = newData.find(row => row.id === currentUser.id);
  if(oldUser !== newUser && !similarObjects(newUser, currentUser) ){ //JSON.stringify(newUser) !== JSON.stringify(currentUser)){
    store.dispatch(currentUserDuck.thunk.fieldUpdate({data: newUser}));
  }
}));

store.subscribe(reduxWatch(store.getState, 'currentUser.data')((newCurrentUser, oldCurrentUser) => {
  if(newCurrentUser.login && oldCurrentUser !== newCurrentUser){
    const usersUser = store.getState().users.data.find(row => row.id === newCurrentUser.id);
    if(!similarObjects(newCurrentUser, usersUser) ){ //JSON.stringify(newCurrentUser) !== JSON.stringify(usersUser)){
      store.dispatch(usersDuck.thunk.fieldUpdate({data: newCurrentUser}));
    }
    if(newCurrentUser.company && newCurrentUser.company.id){
      const companiesCompany = store.getState().companies.data.find(row => row.id === newCurrentUser.company.id);
      if(!similarObjects(newCurrentUser.company, companiesCompany) ){ //JSON.stringify(newCurrentUser.company) !== JSON.stringify(companiesCompany)){
        store.dispatch(companiesDuck.thunk.fieldUpdate({data: newCurrentUser.company}));
      }
    }
  }
}));

