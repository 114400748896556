import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
    fontSize: '0.85rem',
  },
}), {
  name: 'ButtonBack', 
  index: 1,
});
