import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import styles from './KnowledgeBaseCategoryList.style';
import Table from 'components/basic/Table';

import {phrasesDuck} from 'redux/ducks/dictionaries';
import { 
  AccessAlarmOutlined, 
  AccessAlarmsOutlined, 
  AccessibilityNewOutlined, 
  AccessibilityOutlined, 
  AccessibleForwardOutlined, 
  AccessibleOutlined, 
  AccessTimeOutlined, 
} from '@material-ui/icons';

const columns = [
  { field: 'iconUrl', title: 'Podgląd ikony', width: 150, editable: 'never', filtering: false, searchable: false,
    render: rowData => rowData && rowData.iconUrl ? <img src={rowData.iconUrl} alt=''/> : null
  },
  { field: 'title', title: 'Tytuł' },
  { field: 'iconUrl', title: 'Adres ikony' },
];

export const KnowledgeBaseCategoryList = ({active}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const dictionaryData = useSelector(phrasesDuck.get.kbCategories());

  useEffect(() => {
    if(active && (!dictionaryData || !dictionaryData.length)){
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'kb-cat', active: 'all'}}));
    }
  }, [active, dictionaryData])

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.fieldSave({data: phrasesDuck.prepareData({...newData, type: 'kb-cat', id: `new-${shortid.generate()}`})}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.fieldSave({data: phrasesDuck.prepareData({...newData, type: 'kb-cat'})}));
            resolve();
          }),
          onRowDelete: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.delete({data: phrasesDuck.prepareData({...newData, type: 'kb-cat'})}));
            resolve();
          }),
        }}
      />
    </div>
  );
}

KnowledgeBaseCategoryList.propTypes = {
  children: PropTypes.node,
};

export default KnowledgeBaseCategoryList;
