import { getIsCaseFromCalculationWidget } from "utils/getIsCaseFromCalculationWidget";

export const getCaseGuestData = (maybeCase, strict = false) => {
  if (!getIsCaseFromCalculationWidget(maybeCase, strict)) {
    return {};
  }

  try {
    return (
      JSON.parse(maybeCase.guestData) ||
      JSON.parse(maybeCase.ownerUser.guestData) ||
      {}
    );
  } catch {
    return {};
  }
};
