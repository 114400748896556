import { getIsCaseFromCalculationWidget } from "utils/getIsCaseFromCalculationWidget";

export const getFormattedCaseAgreementNo = (maybeCase, strict = false) => {
  if (!getIsCaseFromCalculationWidget(maybeCase, strict)) {
    return maybeCase;
  }

  if (!maybeCase.agreementNo) {
    return maybeCase;
  }

  const agreementNoSplitted = maybeCase.agreementNo?.split("-");
  const agreementNoJustNumber =
    agreementNoSplitted[agreementNoSplitted.length - 1];

  if (!agreementNoJustNumber) {
    return maybeCase;
  }

  const [maybeCaseEnv] = agreementNoSplitted;

  return {
    ...maybeCase,
    agreementNo: `Kalkulator${
      maybeCaseEnv?.toLowerCase() === "calculation" ? "" : " (dev)"
    } - ${agreementNoJustNumber}`,
  };
};
