import React from "react";

import { LibraryBooksOutlined } from "@material-ui/icons";

import PageTitle from "components/basic/PageTitle";

import styles from "./CalculationWidgetEmbed.style";
import { useSelector } from "react-redux";
import { currentUserDuck } from "redux/ducks/currentUser";
import CalculationWidgetAlertEmailWarning from "components/feature/CalculationWidgetAlertEmailWarning";

export default function CalculationWidgetEmbed() {
  const classes = styles();
  const currentUser = useSelector(currentUserDuck.get.data());
  const baseUrl =
    process.env.REACT_APP_ENV === "testing"
      ? "https://projustewidgetapp-testing.herokuapp.com"
      : "https://app.calculation-widget.projuste.pl";

  const link = `${baseUrl}/?c=${currentUser.company.id}&s=${currentUser.company.secret}&isBlobOn=true&isCardShadowOn=true`;
  const linkBlobOff = `${baseUrl}/?c=${currentUser.company.id}&s=${currentUser.company.secret}&isBlobOn=false&isCardShadowOn=true`;
  const linkShadowOff = `${baseUrl}/?c=${currentUser.company.id}&s=${currentUser.company.secret}&isBlobOn=true&isCardShadowOn=false`;

  return (
    <div className={classes.root}>
      <CalculationWidgetAlertEmailWarning />

      <PageTitle
        title="Instrukcje dołączania kalkulatora na stronę"
        hintName="W tym widoku znajdziesz informacje dot. spraw wygenerowanych przez kalkulator oraz dołączanie kalkulatora na stronę."
        buttons={[
          {
            label: "Lista umów wygenerowanych przez kalkulator",
            color: "secondary",
            target: "/calculation-widget",
            icon: LibraryBooksOutlined,
          },
        ]}
      />
      <h3>Dołączanie kalkulatora jako link</h3>
      <a href={link}>{link}</a>
      <p>
        Jest to najprostszy sposób dołączenia kalkulatora, który gwarantuje
        najlepsze doświadczenie z korzystania z kalkulatora. Link ten możesz
        podać jako atrybut href w tagu anchor (a). Kiedy użytkownik kliknie w
        link, otworzy się osobna zakładka z kalkulatorem.
      </p>
      <pre className={classes.pre}>
        <code className={classes.code}>{`
<a href='${link}' target='_blank' rel='noopener noreferrer'>Twój tekst tutaj</a>
        `}</code>
      </pre>
      <br />
      <h3>Dołączenie kalkulatora jako iframe</h3>
      <p>
        Iframe umożliwia dołączenie kalkulatora na swoją stronę. W ten sposób
        użytkownicy nie muszą przechodzić pomiędzy Twoją stroną, a zakładką z
        kalkulatorem.
      </p>
      <p>
        Dołączenie kalkulatora na stronę poprzez iframe jest stosunkowo proste.
        Wystarczy dopisać kod HTML, który zawiera iframe kalkulatora, a
        następnie dodać odpowiednie style (CSS), które umożliwiają zajęcie
        całego wymaganego miejsca przez kalkulator na stronie. Dodatkowe style
        mogą, ale nie muszą być wymagane, a wszystko zależy od tego, w jakim
        miejscu na swojej stronie zamierzamy dodać kalkulator. Kalkulator
        wyświetla się najlepiej przy 100% szerokości oraz 800px wysokości
        strony.
      </p>

      <p>
        W linku iframe przekazywane są parametry, które identyfikują Twoją
        kancelarię - parametry "c" oraz "s". Te parametry{" "}
        <strong>nie mogą</strong> zostać zmienione, są unikalne dla Twojej
        kancelarii.
      </p>

      <p>
        W linku znajdują się również parametry, które umożliwiają dostosowanie
        kalkulatora do Twoich potrzeb. Są to "isBlobOn" oraz "isCardShadowOn".
      </p>

      <p>
        Parametr "isBlobOn" włącza akcenty graficzne Projuste. Jeśli chcesz je
        wyłączyć ponieważ, np. nie pasują one kolorystycznie do Twojej strony
        przekaż wartość "false" do parametru "isBlobOn": "{linkBlobOff}".
        Domyślnie ten parametr jest ustawiony na "true" - czyli akcenty
        graficzne domyślnie są włączone.
      </p>

      <p>
        Parametr "isCardShadowOn" włącza cień elementu kalkulatora. Jeśli chcesz
        go wyłączyć ponieważ, np. na swojej stronie nie stosujesz cieni przekaż
        wartość "false" do parametru "isCardShadowOn": "{linkShadowOff}".
        Domyślnie ten parametr jest ustawiony na "true" - czyli cień elementu
        kalkulatora domyślnie jest włączony.
      </p>
      <h4>HTML</h4>
      <pre className={classes.pre}>
        <code className={classes.code}>{`
<div data-projuste-calculation-widget-root>
  <iframe data-projuste-calculation-widget-iframe src='${link}' width='100%' height='800'></iframe>
</div>
        `}</code>
      </pre>

      <h4>CSS</h4>

      <pre className={classes.pre}>
        <code className={classes.code}>{`
[data-projuste-calculation-widget-root] {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: 800px;
}

[data-projuste-calculation-widget-iframe] {
  display: inline-block;
  border: none;
}
  `}</code>
      </pre>
      <br />
      <br />
      <br />
    </div>
  );
}
