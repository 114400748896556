import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Button, Modal,
} from '@material-ui/core';
import {
  ReceiptOutlined
} from '@material-ui/icons';
import { formatValue } from 'material-table';

import Table from 'components/basic/Table';
import UserAvatar from 'components/basic/UserAvatar';

import { receiptsDuck } from 'redux/ducks/receipts';

import styles from './UnissuedInvoicesList.style';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

const columns = [
  { field: 'companyName', title: 'Firma', 
    width: 300,
    filtering: true, 
    searchable: true,
    render: rowData => (
      <div className='userName'>
        <UserAvatar user={{name: rowData.companyName, logo: rowData.companyLogo}}/>
        <span>{rowData.companyName}</span>
      </div>
    ),
  },
  { field: 'month', title: 'Okres rozliczeniowy', 
    width: 170,
    filtering: true, searchable: true,
  },
  { field: 'discount', title: 'Rabat', type: 'numeric', decimalDigits: 2,
    width: 170,
    filtering: true, searchable: true,
  },
  { field: 'amount', title: 'Kwota', type: 'numeric', decimalDigits: 2,
    width: 170,
    filtering: true, searchable: true,
    render: (rowData) =>
      formatValue(rowData.amount - rowData.discount, {
        type: "numeric",
        decimalDigits: 2,
      }),
    // currencySetting: { currencyCode: 'PLN', locale: 'pl' },
  },
];

const actions = ({setModalOptions}) => [
  {
    onClick: (e, rowData) => setModalOptions({...rowData, open: true}),
    icon: '',
    Button: (props) => (
      <Button {...props} 
        disableElevation 
        color='secondary' 
        startIcon={<ReceiptOutlined />}
        style={{
          height: 30, 
          width: 'auto',
          lineHeight: '18px', 
          marginLeft: 16,
          backgroundColor: 'transparent!important', 
          overflow: 'hidden',
        }}
      >
        Wystaw fakturę
      </Button>
    ),
  },  
];

export default function UnissuedInvoicesList() {
  const classes = styles();
  const dispatch = useDispatch();

  const invoices = useSelector(receiptsDuck.get.unissued());
  const [modalOptions, setModalOptions] = useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={modalOptions}
        onClose={() => setModalOptions(false)}
      >
        <ModalWrapper 
          variant='small'
          disabled={modalOptions.sending}
          title={`Faktura za ${modalOptions.month} dla ${modalOptions.companyName}`}
        >
          <Form 
            variant='small'
            fields={[
              { label: 'Numer faktury', name: 'name'},
              { label: 'Rabat', name: 'discount'},
            ]} 
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => setModalOptions(false)},
              { label: 'Wystaw fakturę', color: 'secondary', type: 'submit', icon: ReceiptOutlined},
            ]}
            onSubmit={values => {
              setModalOptions({...modalOptions, sending: true});
              const data = {id: modalOptions.id, ...values, issueStatus: 'issued'};
              receiptsDuck.api.updateOne({
                data, 
                immediate: true,
                onUpdated: (resData) => {
                  dispatch(receiptsDuck.thunk.fieldUpdate({data: resData}));
                  setModalOptions(false);
                },
              }); 
            
            }}
          />
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns}
        data={invoices}
        actions={actions({setModalOptions})}
        options={{
          paging: false,
        }}
      />
    </div>
  );
}