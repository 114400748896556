import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiTable-root th': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiToolbar-regular:not(.MuiTablePagination-toolbar):first-of-type': {
      display: "none",
    },
  },
}), {
  name: 'ReportsList', 
  index: 1,
});
