import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './TabsWithPanels.style';
import { Grid, Box, Tabs, Tab } from '@material-ui/core';
import { useParams, useHistory, generatePath, useRouteMatch } from 'react-router-dom';

export const TabsWithPanels = ({tabs = {}, activeTab = 0, urlParam = 'tab', title, actions, variant, onChange, panelProps, mt}) => {
  const classes = styles();
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const params = useParams();
  const [active, setActive] = React.useState(urlParam && typeof params[urlParam] !== 'undefined' ? tabs.findIndex(item => item.id === params[urlParam]) : activeTab);
  
  const handleChange = (e, newValue) => setActive(parseInt(newValue));

  useEffect(() => {
    typeof onChange === 'function' && onChange(active);
    if(urlParam){
      history.replace(generatePath(routeMatch.path, {[urlParam]: tabs[active].id }))
    }
  }, [active]);

  useEffect(() => {
    if(urlParam && typeof params[urlParam] !== 'undefined'){
      const newActive = tabs.findIndex(item => item.id === params[urlParam]);
      newActive !== active && setActive(newActive);
    }
  }, [urlParam]);

  return (
    <>
      <Grid item container xs={12} alignItems="center">
        {(
          typeof actions === 'function'
            ? actions(active)
            : actions
          ) || <div style={{flex: '1'}}></div>
        }
        <Box display="flex" mt={mt} alignItems="center" className={clsx(classes.root, variant && `variant-${variant}`)}>
          {title}
          <Tabs
            value={active}
            onChange={handleChange}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
          >
            {tabs.map(tab => (
              <Tab icon={<tab.icon/>} label={tab.label} />
            ))}
          </Tabs>
        </Box> 
      </Grid>
      <Grid item xs={12}>
        <Box mt={2} className={classes.tabsContent}>
          {tabs.map((tab, index) => (
            <Box p={3} hidden={active !== index}>
              <tab.panel active={active === index} {...(typeof panelProps === 'function' ? panelProps(index) : panelProps)}/>
            </Box>
          ))}
        </Box>
      </Grid>
    </>
  );
}

TabsWithPanels.propTypes = {
  children: PropTypes.node,
};

export default TabsWithPanels;
