import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Card,
  Typography,
  Grid
} from '@material-ui/core';

import CardTitle from 'components/basic/CardTitle';
import Fieldset from 'components/basic/Fieldset';

import styles from './Form.style';
import { useSnackbar } from 'notistack';

export const Form = ({title, variant, fields = [], buttons = [], sections = [], disabled, selectAllNone, gridItemProps, onChange, onSubmit, clear, ...props}) => {
  const classes = styles();
  const {closeSnackbar} = useSnackbar();
  const [values, setValues] = useState(Object.fromEntries(fields.map(({name, defaultValue, type, checked}) => [name, type !== 'checkbox' ? defaultValue : (checked ? true : false)])));

  useEffect(() => {
    setValues(Object.fromEntries(fields.map(({name, defaultValue, type, checked}) => [name, type !== 'checkbox' ? defaultValue : (checked ? true : false)])));
  }, [clear]);

  useEffect(() => {
    typeof onChange === 'function' && onChange(values);
  }, [values]);

  return (
    <Card className={clsx(classes.root, variant && (classes[`variant-${variant}`] || `variant-${variant}`))} {...props}>
      <Box 
        className={classes.formWrapper}
        component='form'
        onSubmit={event => {
          event.preventDefault();
          closeSnackbar();
          typeof onSubmit === 'function' && onSubmit(values);
        }}
      >
        {title && <CardTitle className={classes.formTitle} title={title} />}
        {sections.length > 0 && (
          <Grid item container xs={12} spacing={3}>
            {sections.map(section => (
              <Grid item xs={12}>
                <Box mt={section.margin}>
                  <CardTitle className={classes.sectionTitle} title={section.title} />
                  <Box mb={4}>
                    <Typography component='p' variant='body2' className={classes.sectionDescription}>{section.description}</Typography> 
                  </Box>
                  <Fieldset
                    disabled={disabled}
                    buttons={section.buttons}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        {fields.length + buttons.length > 0 && (
          <Fieldset
            disabled={disabled}
            selectAllNone={selectAllNone}
            fields={fields}
            buttons={buttons}
            values={values}
            setValues={setValues}
            gridItemProps={gridItemProps}
          />
        )}
      </Box>
    </Card>
  );
}

Form.propTypes = {
  children: PropTypes.node,
};

export default Form;
