import getIsFeatKnown from "utils/feats/getIsFeatKnown";

const getFeatsFromDict = (dict) => {
  const entries = Object.entries(dict).filter(
    ([maybeFeat, maybeShouldIncludeFeat]) => {
      if (!getIsFeatKnown(maybeFeat)) {
        return false;
      }

      return maybeShouldIncludeFeat;
    }
  );

  return Object.keys(Object.fromEntries(entries));
};

export default getFeatsFromDict;
