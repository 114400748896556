import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Tooltip,
  Box,
  Typography
} from '@material-ui/core';
import { 
  Help,
 } from '@material-ui/icons';

import styles from './Hint.style';
import { defaultPhrases } from 'dict';
import { useSelector } from 'react-redux';
import { phrasesDuck } from 'redux/ducks/dictionaries';
import { simpleMarkDown } from 'utils/formatHelpers';

export const Hint = ({name, variant = 'icon', ...props}) => {
  const classes = styles();

  const content = useSelector(phrasesDuck.get.contentByTitleWD('tooltip', name))

  /* LISTING ALL HINTS */
  if(!window.hints) window.hints = new Set();
  window.hints.add(name);
  window.listHints = () => JSON.stringify(
    {...defaultPhrases.tooltip, ...Array.from(window.hints).reduce((out, name) => ({...out, [name]: defaultPhrases.tooltip[name] || ''}), {})}, 
    null, 
    '  '
  ).replace(/"/g, "'");
  
  return (
    <Box className={clsx(classes.root, variant && (classes[`variant-${variant}`] || `variant-${variant}`))} {...props}>
      {variant === 'text' ? (
        <Typography component='p' variant='body2' className={classes.hintText}>{content}</Typography>
      ) : (
        <Tooltip title={content ? simpleMarkDown(content) : ''} interactive>
          <Help className={classes.icon} style={content ? {} : {opacity: 0}} />
        </Tooltip>
      )}
    </Box>
  );
}

Hint.propTypes = {
  children: PropTypes.node,
};

export default Hint;
