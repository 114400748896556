import React from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { currentUserDuck } from 'redux/ducks/currentUser';

import clsx from 'clsx';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Menu, 
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  ChevronLeft,
  ChevronRight,
  AccountBalanceWalletOutlined,
  LibraryBooksOutlined,
  HomeOutlined,
  SchoolOutlined,
  DeleteOutlineOutlined,
  LocalPostOfficeOutlined,
  PeopleAltOutlined,
  SettingsOutlined,
  ExitToAppOutlined,
  BusinessOutlined,
  CheckCircleOutlineOutlined,
  AdjustOutlined,
  SwapVerticalCircleOutlined,
  HighlightOffOutlined,
  FilterFramesOutlined,
} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../../assets/images/logo-color.svg'
import logoWhite from '../../../assets/images/logo-white.svg'
import styles from './Sidebar.style';
import { fieldValues } from 'dict';
import { postOfficeDuck } from 'redux/ducks/postOffice';
import UserAvatar from 'components/basic/UserAvatar';

const StyledMenu = withStyles({
  paper: {

  },
})((props) => (
  <Menu
    elevation={1}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "0.85rem",
  },
}))(MenuItem);

const connectionStatuses = {
  empty: {Icon: CheckCircleOutlineOutlined, label: 'OK'}, 
  waiting: {Icon: AdjustOutlined, label: 'oczekiwanie'}, 
  sending: {Icon: SwapVerticalCircleOutlined, label: 'wysyłanie'},
  error: {Icon: HighlightOffOutlined, label: 'błąd'},
};

const links = {
  regular: [
    {
      title: 'Moje sprawy',
      links: [
        {path: '/', text: 'Lista umów', icon: LibraryBooksOutlined},
        {path: '/kb', text: 'Baza wiedzy', icon: SchoolOutlined},
        {path: '/contact', text: 'Kontakt', icon: LocalPostOfficeOutlined},
      ],
    },
  ],
  manager: [
    {
      title: 'Moje sprawy',
      links: [
        {path: '/', text: 'Dashboard', icon: HomeOutlined},
        {path: '/cases', text: 'Lista umów', icon: LibraryBooksOutlined},
        {path: '/payments', text: 'Płatności', icon: AccountBalanceWalletOutlined},
        {path: '/users', text: 'Użytkownicy', icon: PeopleAltOutlined},
        {path: '/trash', text: 'Kosz', icon: DeleteOutlineOutlined},
      ],
    },
    {
      title: 'Pomoc',
      links: [
        {path: '/calculation-widget', text: 'Kalkulator', icon: FilterFramesOutlined},
        {path: '/kb', text: 'Baza wiedzy', icon: SchoolOutlined},
        {path: '/contact', text: 'Kontakt', icon: LocalPostOfficeOutlined},
      ],
    },
  ],
  supermanager: [
    {
      title: 'Moje sprawy',
      links: [
        {path: '/', text: 'Dashboard', icon: HomeOutlined},
        {path: '/cases', text: 'Lista umów', icon: LibraryBooksOutlined},
        {path: '/payments', text: 'Płatności', icon: AccountBalanceWalletOutlined},
        {path: '/users', text: 'Użytkownicy', icon: PeopleAltOutlined},
        {path: '/trash', text: 'Kosz', icon: DeleteOutlineOutlined},
      ],
    },
    {
      title: 'Pomoc',
      links: [
        {path: '/calculation-widget', text: 'Kalkulator', icon: FilterFramesOutlined},
        {path: '/kb', text: 'Baza wiedzy', icon: SchoolOutlined},
        {path: '/contact', text: 'Kontakt', icon: LocalPostOfficeOutlined},
      ],
    },
  ],
  admin: [
    {
      title: 'Moje sprawy',
      links: [
        {path: '/', text: 'Dashboard', icon: HomeOutlined},
        {path: '/companies', text: 'Klienci', icon: BusinessOutlined},
        {path: '/payments', text: 'Płatności klientów', icon: AccountBalanceWalletOutlined},
        {path: '/admins', text: 'Administratorzy', icon: PeopleAltOutlined},
      ],
    },
    {
      title: 'Pomoc',
      links: [
        {path: '/calculation-widget', text: 'Kalkulator', icon: FilterFramesOutlined},
        {path: '/kb', text: 'Baza wiedzy', icon: SchoolOutlined},
        {path: '/contact', text: 'Kontakt', icon: LocalPostOfficeOutlined},
      ],
    },
  ],
};

const headerLinks = {
  regular: [
    {
      title: '',
      links: [
        {path: '/profile', text: 'Edytuj swój profil'},
      ],
    },
  ],
  manager: [
    {
      title: '',
      links: [
        {path: '/profile', text: 'Edytuj swój profil'},
        {path: '/company', text: 'Edytuj dane kancelarii'},
      ],
    },
  ],
  supermanager: [
    {
      title: '',
      links: [
        {path: '/profile', text: 'Edytuj swój profil'},
        {path: '/company', text: 'Edytuj dane kancelarii'},
      ],
    },
  ],
  admin: [
    {
      title: '',
      links: [
        {path: '/profile', text: 'Edytuj swój profil'},
        {path: '/company', text: 'Edytuj dane spółki'},
        // {path: '/emails', text: 'Edytuj templatki maili'},
        {path: '/dictionaries', text: 'Edytuj słowniki'},
        {path: '/settings', text: 'Edytuj ustawienia'},
      ],
    },
  ],
};

function NavLinkTooltip({title, tooltips, ...props}){
  const classes = styles();
  return (
    <Tooltip 
      title={title} 
      placement="right" 
      classes={{tooltip: classes.tooltip}}
      disableFocusListener={!tooltips}
      disableHoverListener={!tooltips}
      disableTouchListener={!tooltips}
    >
      <NavLink exact={props.to === '/'} {...props}/>
    </Tooltip>
  );
}

function ListItemLink({icon: Icon, text, wrapperClassName, iconClassName, tooltips, ...props}) {
  return (
    <ListItem disableGutters button className={wrapperClassName}>
      <NavLinkTooltip title={text} tooltips={tooltips} {...props}>
        <ListItemIcon className={iconClassName}>
          <Icon/>
        </ListItemIcon>
        <ListItemText primary={text} />
      </NavLinkTooltip>
    </ListItem>
  );
}

export default function Sidebar() {
  const classes = styles();
  const theme = useTheme();
  const currentUserMainRole = useSelector(currentUserDuck.get.mainRole());
  const currentUserDetails = useSelector(currentUserDuck.get.data());
  const postOfficeStatus = useSelector(postOfficeDuck.get.status());
  
  // Sidebar

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // User menu

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleUserMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {auth && (
            <Box className={classes.appBarWrapper} display="flex" justifyContent="space-between" alignItems="center">
              <Box className={classes.appBarItem}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                  >
                  <MenuIcon />
                </IconButton>
                <IconButton 
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButtonMobile, classes.hideDesktop, {
                    [classes.hide]: open,
                  })}
                  >
                  <MenuIcon />
                </IconButton>
                <IconButton 
                  onClick={handleDrawerClose}
                  className={clsx(classes.menuButtonMobile, classes.hideDesktop, {
                    [classes.hide]: !open,
                  })}
                  >
                  <MenuIcon />
                </IconButton>
                {currentUserDetails.company && (
                  currentUserDetails.company.logoUrl ? (
                    <img className={classes.companyLogo} src={currentUserDetails.company.logoUrl} alt={currentUserDetails.company.name} />
                  ) : (
                    <Typography variant='h6' className={classes.companyName}>{currentUserDetails.company.name}</Typography>
                  )
                )} 
              </Box>
              <Box className={clsx(classes.appBarItem, classes.logo, classes.hideMobile)} justifyContent="center">
                <Link to="/">
                  <img src={logo} alt="ProJuste" />
                </Link>
              </Box>
              <Box display="flex" className={classes.actionLinks} justifyContent="flex-end">
                <List disablePadding className={classes.listHorizontal}>
                  <ListItem className={classes.listItemSmall}>
                    <Tooltip title={`Status połączenia: ${connectionStatuses[postOfficeStatus].label}`}>
                      <IconButton aria-label="status połączenia">
                        {React.createElement(connectionStatuses[postOfficeStatus].Icon)}
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <ListItem className={classes.listItemSmall} onClick={handleUserMenu}>
                    <Tooltip title="Ustawienia">
                      <IconButton aria-label="ustawienia">
                        <SettingsOutlined />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                  <StyledMenu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleUserMenuClose}
                  >
                    {headerLinks[currentUserMainRole] && headerLinks[currentUserMainRole].map((section, index) => (
                      <Box key={index}>
                        {section.links.map((link, linkIndex) => (
                          <StyledMenuItem 
                            key={linkIndex}
                            component={NavLink} 
                            to={link.path} 
                            onClick={handleUserMenuClose}
                          >
                            {link.text}
                          </StyledMenuItem>
                        ))}
                      </Box>
                    ))}
                  </StyledMenu>
                  <ListItem className={classes.listItemSmall} component={Link} to='/logout'>
                    <Tooltip title="Wyloguj">
                      <IconButton aria-label="wyloguj">
                        <ExitToAppOutlined />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                </List>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose} className={classes.buttonMenuClose}>
            {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Box className={clsx(classes.appBarItem, classes.logo, classes.hideDesktop)} justifyContent="center">
          <Link to="/">
            <img src={logoWhite} alt="ProJuste" />
          </Link>
        </Box>
        <Divider/>
        <List disablePadding className={classes.list}>
          <ListItem className={clsx(classes.listItem, classes.listItemPermanent)}>
            <ListItemIcon>
              <UserAvatar variant='small' user={currentUserDetails} className={classes.sidebarAvatar} />
            </ListItemIcon>
            <ListItemText className={classes.sidebarUsername} primary={`${currentUserDetails.firstName} ${currentUserDetails.lastName}`} secondary={fieldValues.users.roles[currentUserMainRole]} />
          </ListItem>
        </List>  
        <Divider/>      
        {links[currentUserMainRole] && links[currentUserMainRole].map((section, index) => (
          <Box mt={4} key={section.title}>
            {/* <Typography component="h5" className={classes.listItemTitle}>{section.title}</Typography> */}
            <List key={index} disablePadding className={classes.list}>
              {section.links.map((link, linkIndex) => (
                <ListItemLink 
                  key={linkIndex} 
                  to={link.path}
                  icon={link.icon}
                  text={link.text}
                  tooltips={!open}
                  className={classes.listItem} 
                  wrapperClassName={classes.listItemWrapper} 
                  iconClassName={classes.listIcon}
                />
              ))}
            </List>
          </Box>
        ))}
      </Drawer>
    </div>
  );
}

