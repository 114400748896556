import React from "react";
import * as Sentry from "@sentry/react";
import "./ErrorBoundary.css";

export const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <div className="error-boundary">
          <header>
            <h1 className="error-boundary__title">Wystąpił problem.</h1>
            <h2 className="error-boundary__explanation">
              Nasz zespół pracuje nad jego rozwiązaniem.
            </h2>
          </header>

          <main>
            <p className="error-boundary__help-contact">
              Jeśli potrzebujesz pomocy, skontaktuj się z nami:{" "}
              <a href="mailto:kontakt@projuste.pl">kontakt@projuste.pl</a>
            </p>

            <div className="error-boundary__reset-button-container">
              <button
                className="error-boundary__reset-button"
                onClick={() => {
                  window.location.assign("/");
                }}
              >
                Kliknij tutaj, aby odświeżyć
              </button>
            </div>
          </main>

          <details>
            <summary className="error-boundary__details-text">
              Szczegóły
            </summary>

            <div className="error-boundary__details">
              <div className="error-boundary__error">{error.toString()}</div>
              <div className="error-boundary__component-stack">
                {componentStack}
              </div>
            </div>
          </details>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
