import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: "100%",
    '& fieldset': {
      border: 0,
    },
    '& .MuiSvgIcon-root': {
      width: "1.2rem",
      height: "1.2rem",
      marginRight: theme.spacing(1),
    },
    '& .MuiSelect-selectMenu': {
      fontSize: "0.85rem",
    },
    '& .MuiFormControl-root': {
      width: theme.spacing(20),
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(4),
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiSelect-icon': {
      top: "calc(50% - 9px)",
    },
    '&.variant-right': {
      justifyContent: "flex-end",
    },
    '&.variant-label': {
      flex: 1,
      '& .MuiFormControl-root': {
        width: 200,
      },
      '& p': {
        fontWeight: theme.typography.fontWeightBold,
        marginRight: theme.spacing(1),
        fontSize: "0.85rem",
      }
    },
  },
}), {
  name: 'Dropdown', 
  index: 1,
});
