import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {casesDuck} from 'redux/ducks/cases';

import Table from 'components/basic/Table';

import {
  Modal
} from '@material-ui/core';
import {
  RestoreOutlined,
  DeleteOutlineOutlined,
} from '@material-ui/icons';

import ModalWrapper from 'components/basic/ModalWrapper';
import styles from './TrashedCasesList.style';
import { useSnackbar } from 'notistack';
import { usersDuck } from 'redux/ducks/users';

const columns = ({usersLookup}) => [
  { field: 'name', title: 'Sygnatura', 
    width: 250,
    ellipsis: true,
    filtering: true, searchable: true,
  },
  { field: 'agreementNo', title: 'Numer\u00a0i\u00a0data',
    width: 250,
    ellipsis: true, 
    filtering: true, searchable: true,
  },
  { field: 'person', title: 'Klient', 
    width: 200,
    ellipsis: true,
    filtering: true, searchable: true,
  },
  { field: 'bankId', title: 'Bank', 
    width: 150,
    ellipsis: true,
    filtering: true, searchable: true,
  },
  { field: 'createdAt', title: 'Data utworzenia', type: 'date',
    filtering: true,
  },
  { field: 'deletedAt', title: 'Data usunięcia',  type: 'date',
    filtering: true,
  },
  { field: 'ownerUserId', title: 'Kto usunął', lookup: usersLookup,
    width: 200,
    ellipsis: true,
    filtering: true,
  },
];

const actions = (openModal) => [
  {
    icon: RestoreOutlined,
    onClick: (e, rowData) => openModal.restore(rowData.id),
    tooltip: 'Przywróć umowę',
  },
  {
    icon: DeleteOutlineOutlined,
    onClick: (e, rowData) => openModal.delete(rowData.id),
    tooltip: 'Usuń na zawsze',
  },
];

export default function TrashedCasesList({onSelectionChange}) {
  const classes = styles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cases = useSelector(casesDuck.get.filtered({status: 'deactivated'}));
  const usersLookup = useSelector(usersDuck.get.lookupTable('id', 'fullName'));

  const [restoreModalOpen, setRestoreModalOpen] = React.useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={restoreModalOpen}
        onClose={() => setRestoreModalOpen(false)}
      >
        <ModalWrapper 
          title="Czy chcesz przywrócić tę umowę?" 
          description="Przywróconą umowę będzie można znów edytować."
          buttons={[
            { label: "Anuluj", color: "primary", onClick: () => setRestoreModalOpen(false) },
            { label: "Przywróć umowę", color: "secondary", icon: RestoreOutlined, 
              onClick: () => {
                dispatch(casesDuck.thunk.fieldSaveSync({
                  validate: false,
                  data: {id: restoreModalOpen, status: 'active'}, 
                  callback: () => {
                    setRestoreModalOpen(false);
                    enqueueSnackbar('Umowa została przywrócona', {variant: 'success'});
                  },
                })); 
              }, 
            },
          ]}  
        />
      </Modal>
      <Modal
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
      >
        <ModalWrapper 
          title="Czy chcesz skasować tę umowę?" 
          description="Ta opcja spowoduje, że umowa zostanie usunięta na stałe i nie będzie możliwości przywrócenia jej."
          buttons={[
            { label: "Anuluj", color: "primary", onClick: () => setModalDeleteOpen(false) },
            { label: "Skasuj umowę", color: "secondary", icon: DeleteOutlineOutlined, 
              onClick: () => {
                dispatch(casesDuck.thunk.fieldSaveSync({
                  validate: false,
                  data: {id: modalDeleteOpen, status: 'erased'}, 
                  callback: () => {
                    setModalDeleteOpen(false);
                    enqueueSnackbar('Umowa została usunięta', {variant: 'success'});
                  },
                })); 
              } 
            },
          ]}  
        />
      </Modal>
      <Table
        columns={columns({usersLookup})}
        data={cases}
        actions={actions({restore: (id) => setRestoreModalOpen(id), delete: (id) => setModalDeleteOpen(id)})}
        options={{selection: true}}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
}