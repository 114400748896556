import React, { useState } from 'react';

import {
  Grid, Modal, 
} from '@material-ui/core';
import { 
  SaveOutlined, 
  BlockOutlined, 
  LockOutlined,
  DeleteOutlineOutlined,
  SendOutlined
 } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import Form from 'components/basic/Form';
import UserCard from 'components/basic/UserCard';
import ButtonBack from 'components/basic/ButtonBack';

import styles from './UserEdit.style';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserDuck } from 'redux/ducks/currentUser';
import {fieldValues} from 'dict';
import { useSnackbar } from 'notistack';
import { usersDuck } from 'redux/ducks/users';
import { useParams, useHistory } from 'react-router-dom';
import ModalWrapper from 'components/basic/ModalWrapper';

export default function UserEdit() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId: urlId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  
  const currentUserDetails = useSelector(currentUserDuck.get.data()) || {}; 
  const editedUserId = urlId || currentUserDetails.id;

  const userDetails = useSelector(usersDuck.get.byId(parseInt(editedUserId))) || {};
  
  const companyId = userDetails.companyId;
  const filters = typeof companyId !== 'undefined' && companyId !== null ? {companyId} : {};
  const users = useSelector(usersDuck.get.filteredDetailed(filters));

  const [disableUserDetails, setDisableUserDetails] = useState(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState({sending: false, repeatedPasswordNotMatching: false});
  const [deleteOptions, updateDeleteOptions] = React.useReducer((s, a) => ({...s, ...a}), {open: false, disabled: false, rowData: {}});;

  const selfEdit = userDetails.id === currentUserDetails.id;
  
  const saveUserDetails = (formData) => {
    const data = {id: userDetails.id};
    formData.firstName !== userDetails.firstName && (data.firstName = formData.firstName);
    formData.lastName !== userDetails.lastName && (data.lastName = formData.lastName);
    formData.email !== userDetails.email && (data.email = formData.email);
    // formData.roleName !== userDetails.roleName && (data.privileges = [formData.roleName]);

    setDisableUserDetails(true);

    const errors = usersDuck.validationErrors({...userDetails, ...data});

    if(!errors){
      usersDuck.api.updateOne({
        data: data,
        immediate: true,
        onUpdated: () => {
          dispatch(usersDuck.thunk.fieldUpdate({data}));
          if(selfEdit || userDetails.privileges.includes(formData.roleName)){
            setDisableUserDetails(false);
          } else {
            userDetails.privileges.forEach(privilege => {
              usersDuck.api.removePrivilege({
                data: {id: userDetails.id, privilege},
                immediate: true,
              });
            });
            usersDuck.api.addPrivilege({
              data: {id: userDetails.id, privilege: formData.roleName},
              immediate: true,
              callback: () => {
                dispatch(usersDuck.thunk.fieldUpdate({data: {id: userDetails.id, privileges: [formData.roleName]}}));
                setDisableUserDetails(false);
              },
            });    
          }
        }, 
      });
    } else {
      setDisableUserDetails(false);
      // console.log('errors:', errors)
    }
  };

  const saveNewPassword = ({oldPassword, newPassword, repeatNewPassword}) => {
    if(newPassword !== repeatNewPassword){
      setPasswordChangeStatus({...passwordChangeStatus, repeatedPasswordNotMatching: true});
      return false;
    }
    setPasswordChangeStatus({...passwordChangeStatus, sending: true});
    
    usersDuck.api.updateOne({
      data: {
        id: userDetails.id, 
        password: newPassword, 
        oldPassword
      },
      immediate: true,
      onUpdated: () => {
        setPasswordChangeStatus({...passwordChangeStatus, sending: false, clear: Date.now()});
        enqueueSnackbar('Hasło zostało zmienione', {variant: 'success'});
      },
      onError: () => {
        setPasswordChangeStatus({...passwordChangeStatus, sending: false});
      },
    });

  };

  if(!userDetails || !userDetails.id) {
    return <>...</>
  }

  return (
    <div className={classes.root}>
      {!selfEdit &&
        <ButtonBack text='Powrót do listy użytkowników' />
      }
      <PageTitle 
        title='Edytuj profil' 
        buttons={!selfEdit && [
          ...(userDetails.status === 'pending_activation' ? [
            { label: 'Wyślij link aktywacyjny', color: 'primary', icon: SendOutlined, 
              onClick: () => usersDuck.api.sendActivationToken({
                data: {id: userDetails.id},
                callback: () => enqueueSnackbar('Mail z linkiem aktywacyjnym został wysłany', {variant: 'success'}),
              }),
            }
          ] : userDetails.status === 'deactivated' ? [
            { label: 'Aktywuj użytkownika', color: 'primary', icon: BlockOutlined, 
              onClick: () => dispatch(usersDuck.thunk.fieldSaveSync({
                data: {id: userDetails.id, status: 'active'},
                callback: () => enqueueSnackbar('Użytkownik został aktywowany', {variant: 'success'}),
              })),
            },
          ] : userDetails.status !== 'erased' ? [
            { label: 'Dezaktywuj użytkownika', color: 'primary', icon: BlockOutlined, 
              onClick: () => dispatch(usersDuck.thunk.fieldSaveSync({
                data: {id: userDetails.id, status: 'deactivated'},
                callback: () => enqueueSnackbar('Użytkownik został dezaktywowany', {variant: 'success'}),
              })),
            },
          ] : []),
        ]} 
      />
      <Grid container spacing={4} justify='space-between'>
        <Grid item xs={12} lg={4}> 
          <UserCard user={userDetails} />
          {userDetails.status === 'erased' ? null : !selfEdit ? 
            <Form 
              variant='last'
              sections={[
                ...(userDetails.status === 'pending_activation' ? [] : [{ 
                  title: 'Reset hasła', 
                  description: 'Kliknij poniższy przycisk, aby wysłać do użytkownika e-mail z linkiem do resetu hasła.', 
                  buttons: [
                    { label: 'Zresetuj hasło', color: 'primary', icon: LockOutlined, 
                    onClick: () => usersDuck.api.sendActivationToken({
                      data: {id: userDetails.id},
                      callback: () => enqueueSnackbar('Mail z linkiem do resetu hasła został wysłany', {variant: 'success'}),
                    }),
                  }
                  ],
                }]),
                { margin: 2, 
                  title: 'Usuń użytkownika', 
                  description: 'Usunięty użytkownik nie będzie mógł zalogować się na swoje konto i generować raportów.', 
                  buttons: [
                    {label: 'Usuń użytkownika', icon: DeleteOutlineOutlined,
                      onClick: () => {
                        updateDeleteOptions({open: true, rowData: {id: userDetails.id}});
                      },
                    }
                  ],
                }
              ]} 
            />
            :
            <Form 
              variant='last'
              title='Zmień hasło'
              fields={[
                { label: 'Stare hasło', name: 'oldPassword', type: 'password', required: true},
                { label: 'Nowe hasło', name: 'newPassword', type: 'password', required: true},
                { label: 'Potwórz nowe hasło', name: 'repeatNewPassword', type: 'password', required: true, 
                  error: passwordChangeStatus.repeatedPasswordNotMatching && 'Wpisz to samo hasło w obu polach',
                  onChange: value => setPasswordChangeStatus({...passwordChangeStatus, repeatedPasswordNotMatching: false}),
                },
              ]} 
              buttons={[
                { label: 'Zapisz nowe hasło', icon: SaveOutlined, type: 'submit'},
              ]}
              disabled={passwordChangeStatus.sending}
              clear={passwordChangeStatus.clear}
              onSubmit={saveNewPassword}
            />
          }
        </Grid>
        <Grid item xs={12} lg={8}>
          <Form 
            title='Dane użytkownika'
            disabled={disableUserDetails || userDetails.status === 'erased'}
            fields={[
              { half: true, label: 'Imię', name: 'firstName', required: true, defaultValue: userDetails.firstName },
              { half: true, label: 'Nazwisko', name: 'lastName', required: true, defaultValue: userDetails.lastName },
              ...(!selfEdit ? [
                { half: true, label: 'E-mail', name: 'email', type: 'email', required: true, defaultValue: userDetails.email },
                { half: true, label: 'Rola', name: 'roleName', defaultValue: userDetails.roleName, 
                  options: Object.entries(fieldValues.users.roles).reduce((result, [name, label]) => (Object.keys(result).length === 0 && name !== currentUserDetails.roleName ? result : {[name]: label, ...result}), {}) 
                }
              ] : [
                { half: false, label: 'E-mail', name: 'email', type: 'email', required: true, defaultValue: userDetails.email },
              ]),
            ]} 
            buttons={[
              { label: 'Zapisz zmiany', icon: SaveOutlined, type: 'submit'},
            ]}
            onSubmit={saveUserDetails}
          />
        </Grid>
      </Grid>  
      <Modal
        open={deleteOptions.open}
        onClose={() => updateDeleteOptions({open: false})}
      >
        <ModalWrapper 
          title='Czy chcesz skasować użytkownika?' 
          description={`Zanim skasujesz tego użytkownika, musisz przekazać jego sprawy innemu użytkownikowi. Wybierz z poniższej listy osobę, do której chcesz je przypisać. Pamiętaj, że usunięcie użytkownika jest nieodwracalne.`}
        >
          <Form
            variant='small'
            disabled={deleteOptions.disabled}
            fields={userDetails.privileges.includes('admin') ? [] : [
              {label: 'Przekaż sprawy do...', name: 'newUserIdForCases', options: Object.fromEntries(users.filter(user => user.id !== deleteOptions.rowData.id).map(user => [user.id, user.fullName])) }
            ]}
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => updateDeleteOptions({open: false}) },
              { label: 'Usuń użytkownika', color: 'secondary', icon: DeleteOutlineOutlined, type: 'submit' },  
            ]}
            onSubmit={values => {
              updateDeleteOptions({disabled: true});
              dispatch(usersDuck.thunk.fieldSaveSync({
                data: {id: deleteOptions.rowData.id, status: 'erased', ...(values.newUserIdForCases && {newUserIdForCases: values.newUserIdForCases})}, 
                callback: () => {
                  updateDeleteOptions({open: false});
                  history.goBack();
                  setTimeout(() => enqueueSnackbar('Użytkownik został usunięty', {variant: 'success'}), 100);
                },
                onError: () => {
                  updateDeleteOptions({disabled: false});
                },
              })); 
            }}
          />
        </ModalWrapper>
      </Modal>
    </div>
  );
}
