import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '&.variant-small': {
      '& p': {
        fontSize: '0.7rem',
      }
    },
  },
}), {
  name: 'Copyright', 
  index: 1,
});
