import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import printJS from 'print-js';

import {
  Modal,
} from '@material-ui/core';
import {
  PrintOutlined,
  DeleteOutlineOutlined,
  CloudDownloadOutlined
} from '@material-ui/icons';

import { reportsDuck } from 'redux/ducks/reports';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';

import styles from './ReportsList.style';
import { useSnackbar } from 'notistack';
import { usersDuck } from 'redux/ducks/users';
import backend from 'redux/lib/backend';

const columns = ({usersLookup, showCaseDetails}) => [
  ...(!showCaseDetails ? [] : [
    { field: 'caseName', title: 'Sygnatura umowy', 
      filtering: true, searchable: true,
    },
    { field: 'casePerson', title: 'Klient', 
      filtering: true, searchable: true,
    },
  ]),
  { field: 'createdAt', title: 'Timestamp', type: 'datetime',
    filtering: true, searchable: true,
  },
  { field: 'typeName', title: 'Rodzaj\u00a0raportu', 
    width: 150,
    filtering: true, searchable: true,
    render: rowData => <>{rowData?.typeName?.replace(/^raport /, '')}</>,
  },
  { field: 'ownerUserId', title: 'Użytkownik', 
    width: 200,
    ellipsis: true,
    filtering: true, searchable: true,
    lookup: usersLookup,
  },
  { field: 'description', title: 'Opis własny',
    ellipsis: true,
    filtering: true, searchable: true,
  },
];

const actions = (openModal, {enqueueSnackbar, closeSnackbar}) => [
  {
    icon: PrintOutlined,
    tooltip: 'Drukuj',
    onClick: (e, rowData) => {
      let snackKey = enqueueSnackbar('Trwa pobieranie raportu', {variant: 'info'});

      backend({
        axiosParams: {
          url: rowData.url,
          responseType: 'arraybuffer',
        },
        error: (err) => {
          snackKey && closeSnackbar(snackKey);
          snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});

          console.log('err:', err)
        },
        success: async (res) => {
          snackKey && closeSnackbar(snackKey);
          snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});
          
          const pdfFile = new Blob([res.data], {
            type: "application/pdf"
          });
          const pdfUrl = URL.createObjectURL(pdfFile);
          
          printJS({
            printable: pdfUrl, 
            onError: () => {
              snackKey && closeSnackbar(snackKey);
              snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
            },
            onPrintDialogClose: () => {
              snackKey && closeSnackbar(snackKey)

            },
          });
          setTimeout(() => {
            URL.revokeObjectURL(pdfUrl);
          }, 100);
        },
      });
    },
  },
  {
    icon: CloudDownloadOutlined,
    tooltip: 'Pobierz',
    onClick: (e, rowData) => window.open(rowData.url, '_blank'),
  },
  {
    icon: DeleteOutlineOutlined,
    tooltip: 'Usuń raport',
    onClick: (e, rowData) => openModal.delete(rowData.id),
  }
];

export default function ReportsList({reports, caseId, tableOptions, showCaseDetails}) {
  const classes = styles();
  const dispatch = useDispatch();
  const snackBar = useSnackbar();

  const usersLookup = useSelector(usersDuck.get.lookupTable('id', 'fullName'));

  // Modal

  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
      >
        <ModalWrapper 
          title='Czy chcesz skasować ten raport?' 
          description='Potwierdź, że chcesz przenieść ten raport do kosza.'
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => setModalDeleteOpen(false)},
            { label: 'Skasuj raport', color: 'secondary', icon: DeleteOutlineOutlined, onClick: () => {dispatch(reportsDuck.thunk.fieldSave({data: {id: modalDeleteOpen, caseId, status: 'deactivated' }})); setModalDeleteOpen(false)}},
          ]}  
        />
      </Modal>
      <Table
        columns={columns({usersLookup, showCaseDetails})}
        data={reports}
        actions={caseId && actions({delete: (id) => setModalDeleteOpen(id)}, snackBar)}
        options={tableOptions}
      />
    </div>
  );
}