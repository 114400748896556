import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiCard-root': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}), {
  name: 'HomepageClient', 
  index: 1,
});
