import React, { useState } from 'react';

import {
  ArrowBackOutlined,
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

import AuthForm from 'components/basic/AuthForm';
import Form from 'components/basic/Form';

import styles from './SetPassword.style';
import { usersDuck } from 'redux/ducks/users';
import { useParams, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { InputAdornment, IconButton } from '@material-ui/core';

export default function SetPassword() {
  const classes = styles();
  const history = useHistory();
  const {token} = useParams();
  const {closeSnackbar} = useSnackbar();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const eye = (
    <InputAdornment position="end">
      <IconButton
        onClick={() => setShowPassword(!showPassword)}
        edge="end"
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <div className={classes.root}>
      <AuthForm
        title='Ustaw hasło'
        description={success 
          ? 'Hasło ustawione poprawnie! Możesz teraz wrócić do strony logowania i rozpocząć korzystanie z aplikacji.'
          : 'Aby móc korzystać z serwisu, ustaw poniżej hasło dla swojego konta. Użyjesz go następnie podczas logowania.'
        }
        buttons={success && [
          { label: 'Powrót do strony logowania', target: '/login', icon: ArrowBackOutlined},
        ]}
      >
        {!success && (
          <Form 
            variant='inner'
            disabled={disabled}
            fields={[
              { 
                label: 'Hasło', 
                name: 'password', 
                defaultValue: '', 
                required: true, 
                type: showPassword ? 'text' : 'password',
                InputProps: {
                  endAdornment: eye,
                },
              },
              { 
                label: 'Powtórz hasło', 
                name: 'repeatPassword', 
                defaultValue: '', 
                error: error && 'Hasła muszą być takie same', 
                required: true, 
                type: showPassword ? 'text' : 'password', 
                InputProps: {
                  endAdornment: eye,
                },
                onChange: () => setError(false),
              },
            ]} 
            buttons={[
              { label: 'Ustaw hasło', color: 'secondary', type: 'submit'},
            ]}
            onSubmit={values => {
              if(values.password === values.repeatPassword){
                setDisabled(true);
                usersDuck.api.setPassword({data: {token, password: values.password}, callback: () => {
                  setSuccess(true);
                }, onError: () => {
                  setDisabled(false);
                }});
              } else {
                setError(true);
              }
            }}
          />
        )}
      </AuthForm>
    </div>
  );
}
