import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {banksDuck} from 'redux/ducks/dictionaries';

import Table from 'components/basic/Table';
import styles from './DictionaryBanks.style';
import shortid from 'shortid';

const columns = [
  { field: 'name',  title: 'Nazwa' },
  { field: 'fullName',  title: 'Pełna nazwa' },
  { field: 'exchangeRatesUrl',  title: 'Adres url' },
  { field: 'workdaySearchMode',  title: 'Tryb wyszukiwania' },
  { field: 'daysInYearPayment',  title: 'Dni w roku (płatności)' },
  { field: 'daysInYearInterest',  title: 'Dni w roku (odsetki)' },
  { field: 'daysInYearPrincipal',  title: 'Dni w roku (kapitał)' },
];

export default function Users() {
  const classes = styles();
  const dictionaryData = useSelector(banksDuck.get.all());
  const dispatch = useDispatch();

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(banksDuck.thunk.fieldSave({id: `new-${shortid.generate()}`, data: newData}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(banksDuck.thunk.fieldSave({data: newData}));
            resolve();
          }),
        }}
      />
    </div>
  );
}