import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
} from '@material-ui/core';

import styles from './Text.style';

export const Text = ({children}) => {
  const classes = styles();
  return (
    <Box className={classes.root}>
      {children}
    </Box>
  );
}

Text.propTypes = {
  children: PropTypes.node,
};

export default Text;
