import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip
} from '@material-ui/core';
import clsx from 'clsx';

import styles from './ButtonWrapper.style';

function ButtonWrapper({children, variant, buttons = {}, marginTop, marginBottom, className, ...props}) {
  const classes = styles();
  return (
    <Box className={clsx(classes.root, className, variant && `variant-${variant}`)} {...props}>
      {buttons.length && buttons.map(button => (
        button.type === 'chip' ? (
          <Chip 
            key={button.label}
            icon={button.icon && <button.icon />}
            label={button.label}
            variant={button.variant}
          />
        ):( 
          <Button
            key={button.label}
            disableElevation
            variant='contained'
            color={button.color}
            startIcon={button.icon && <button.icon />}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )
      ))}
      {children}
    </Box>
  );
}

ButtonWrapper.propTypes = {
  children: PropTypes.node,
};

export default ButtonWrapper;
