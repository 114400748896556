import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({

}), {
  name: 'SettingsList', 
  index: 1,
});


