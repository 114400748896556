import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  Paper,
  Grid,
  Typography,
  Divider,
  Button,
  CircularProgress
} from '@material-ui/core';

import ButtonWrapper from 'components/basic/ButtonWrapper';

import styles from './ModalWrapper.style';

const ModalWrapper = React.forwardRef(({title, description, buttons = {}, disabled, maxWidth = 450, children, variant, ...props}, ref) => {
  const classes = styles();

  return (
    <Box style={{ maxWidth: maxWidth + 'px' }} className={clsx(classes.root, variant && (classes[`variant-${variant}`] || `variant-${variant}`))} red={ref} {...props}>
      <Paper className={classes.modalInner}>
        <Grid container>
          <Grid item xs={12}>
            {title &&
              <Box className={classes.modalTitleWrapper}>
                <Typography component='h3' variant='h5' className={classes.modalTitle}>{title}</Typography>
                <Divider />
              </Box>
             }
            { description &&           
              <Box className={classes.modalDescription}>
                <Typography component='p' variant='body2'>{description}</Typography>
              </Box>
            }
          </Grid>
          {children}
          {buttons.length > 0 && 
            <Grid item container justify='center' xs={12}>
              <Box className={classes.modalButtons}>
                <ButtonWrapper>
                  {buttons.map(button => (
                    <div className={classes.buttonWrapper}>
                      <Button
                        disabled={disabled || button.disabled}
                        disableElevation
                        variant={button.variant ? button.variant : 'contained'}
                        color={button.color ? button.color : 'secondary'}
                        startIcon={button.icon && <button.icon />}
                        onClick={button.onClick}
                        >
                        {button.label}
                      </Button>
                      {disabled && button.icon && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  ))}
                </ButtonWrapper>
              </Box>
            </Grid>          
          }
        </Grid>
      </Paper>
    </Box>
  );
});

ModalWrapper.propTypes = {
  children: PropTypes.node,
};

export default ModalWrapper;
