import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import {Pie} from 'react-chartjs-2';
import {
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import { 
  Filter1Outlined,
  CalendarTodayOutlined,
  ScheduleOutlined,
  ReportProblemOutlined
} from '@material-ui/icons';
import red from '@material-ui/core/colors/red';

import PageTitle from 'components/basic/PageTitle';
import Dropdown from 'components/basic/Dropdown';
import ComplexStatsCard from 'components/basic/ComplexStatsCard';
import TabsWithPanels from 'components/basic/TabsWithPanels';

import UnissuedInvoicesList from 'components/feature/UnissuedInvoicesList';
import UnpaidInvoicesList from 'components/feature/UnpaidInvoicesList';

import styles from './HomepageAdmin.style';
import { useSelector } from 'react-redux';
import { statsDuck } from 'redux/ducks/stats';

const chartColor = red;

const chartData = data => ({
	labels: [
		'Raporty wstępne',
		'Raporty szacunkowe',
		'Raporty pełne'
	],
	datasets: [{
		data: data,
		backgroundColor: [
      chartColor[300],
      chartColor[600],
      chartColor[900]
		],
		hoverBackgroundColor: [
      chartColor[200],
      chartColor[500],
      chartColor[800]
		]
	}]
});

export default function HomepageManager() {
  const classes = styles();
  const [period, setPeriod] = useState('anytime');
  //  const stats = useSelector(statsDuck.get.casesAndReports({createdAt: dates.relative[period]}));
  const stats = useSelector(statsDuck.get.data());

  const reportStats = stats[period === 'currentMonth' ? 'reportsCurrentMonth' : period === 'lastMonth' ? 'reportsPrevMonth' : 'reportsTotal'];

  return (
    <div className={classes.root}>
      <PageTitle title='Przegląd spraw – wszystkie firmy'>
        <Dropdown
          variant='right'
          icon={CalendarTodayOutlined}
          value={period}
          handleChange={value => setPeriod(value)}
          options={[
            { label: 'Kiedykolwiek', value: 'anytime' },
            { label: 'Bieżący miesiąc', value: 'currentMonth' },
            { label: 'Poprzedni miesiąc', value: 'lastMonth' },
          ]}            
        />  
      </PageTitle>
      <Grid container>
        <Grid item container alignItems='stretch' spacing={6}>
          <Grid item xs={12} lg={6}>
            <ComplexStatsCard
              icon={Filter1Outlined}
              title='Wszystkie raporty'
              totalValue={reportStats && reportStats.total}
              cards={[
                { title: 'Raporty wstępne', value: reportStats && reportStats.rw},
                { title: 'Raporty szacunkowe', value: reportStats && reportStats.rs},
                { title: 'Raporty pełne', value: reportStats && reportStats.rp},
              ]}  
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Pie data={chartData(reportStats && [reportStats.rw, reportStats.rs, reportStats.rp])} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <PageTitle 
            title='Faktury – wszystkie firmy' 
            variant='dense' 
            mt={8}
          />            
        </Grid> */}
        <TabsWithPanels
          title={(
            <PageTitle 
              title='Faktury – wszystkie firmy' 
              variant='denser' 
              mt={1}
            />
          )}
          mt={8}
          variant='right'
          urlParam={false}
          tabs={[
            { id: 'unissued', label: 'Niewystawione',
              icon: ReportProblemOutlined,
              panel: UnissuedInvoicesList,
            },
            { id: 'unpaid', label: 'Nieopłacone',
              icon: ScheduleOutlined,
              panel: UnpaidInvoicesList,
            },
          ]}
        />
      </Grid>
    </div>
  );
}

