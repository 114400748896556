import React from 'react';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import styles from './Content.style';

function Content({children}) {
  const classes = styles();
  return (
    <main className={classes.root}>
      <Container maxWidth={false}>
        {children}
      </Container>
    </main>
  );
}

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
