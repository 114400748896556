import React from 'react';
import {RecordLibraryDuck} from 'redux/lib/Duck';
import {fieldValues} from 'dict';
import { PostOffice } from 'redux/lib/PostOffice';
import backend from 'redux/lib/backend';
import store from 'redux/store';
import { postOfficeDuck } from 'redux/ducks/postOffice';
import { casesDuck } from 'redux/ducks/cases';
import { reportsDuck } from 'redux/ducks/reports';

export const usersDuck = new RecordLibraryDuck('users', ({id}) => `/users/${id}`);
const duck = usersDuck;

duck.normalize = (singleUser, {users}) => ({ 
  ...singleUser, 
  userName: singleUser.username,
  statusName: fieldValues.users.status[singleUser.status],
  roleName: singleUser.privileges && Object.keys(fieldValues.users.roles).find(role => singleUser.privileges.includes(role)),
  fullName: (singleUser.firstName + ' ' + singleUser.lastName),
  // company: companyNames[singleUser.companyId],
});

duck.get.allDetailed = () => (state) => {
  const { users, ...rest } = state;
  return duck.get.all()(state)
    .map(
      singleUser => ({
        ...singleUser,
        ...( singleUser.roleName !== 'supermanager' ? {
          casesCount: casesDuck.get.allFiltered({ownerUserId: singleUser.id})(state).length,//rest.cases.data.filter(singleCase => singleCase.ownerUserId === singleUser.id).length,
          reportsCount: reportsDuck.get.allFiltered({ownerUserId: singleUser.id})(state).length, //rest.reports.data.filter(singleReport => singleReport.ownerUserId === singleUser.id).length,
        } : {
          casesCount: casesDuck.get.allFiltered({supermanagerUserId: singleUser.id})(state).length,//rest.cases.data.filter(singleCase => singleCase.ownerUserId === singleUser.id).length,
          reportsCount: reportsDuck.get.allFiltered({supermanagerUserId: singleUser.id})(state).length, //rest.reports.data.filter(singleReport => singleReport.ownerUserId === singleUser.id).length,
        })
      })
    )
};

duck.get.filteredDetailed = (filters) => (state) => {
  const { users, ...rest } = state;
  return duck.get.allFiltered(filters)(state)
    .map(
      singleUser => ({
        ...singleUser,
        // ...( singleUser.roleName !== 'supermanager' ? {
        //   casesCount: casesDuck.get.allFiltered({ownerUserId: singleUser.id})(state).length,//rest.cases.data.filter(singleCase => singleCase.ownerUserId === singleUser.id).length,
        //   reportsCount: reportsDuck.get.allFiltered({ownerUserId: singleUser.id})(state).length, //rest.reports.data.filter(singleReport => singleReport.ownerUserId === singleUser.id).length,
        // } : {
        //   casesCount: casesDuck.get.allFiltered({supermanagerUserId: singleUser.id})(state).length,//rest.cases.data.filter(singleCase => singleCase.ownerUserId === singleUser.id).length,
        //   reportsCount: reportsDuck.get.allFiltered({supermanagerUserId: singleUser.id})(state).length, //rest.reports.data.filter(singleReport => singleReport.ownerUserId === singleUser.id).length,
        // })
      })
    )
};

duck.get.byCompanyId = function(companyId){
  return (state) => {
    const all = duck.get.all()(state);
    if(!companyId){
      return all;
    }
    return all.filter(row => row.companyId === companyId);
  };
};

duck.get.lookupTableByCompanyId = function(companyId, valueKey = 'id', labelKey = 'name'){
  return (state) => {
    const companyUsers = duck.get.byCompanyId(companyId)(state);
    return Object.fromEntries(
      companyUsers
        .map(row => [row[valueKey], row[labelKey]])
    );
  };
};

duck.get.admins = () => (state) => (
  duck.get.all()(state)
    .filter(singleUser => singleUser.privileges && singleUser.privileges.includes('admin') && singleUser.status !== 'erased')
);

duck.api.setPassword = function({urlParams, data, callback, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  backend({
    url: this.apiGeneralUrlCreator({})+'/activation',
    method: 'post', 
    data,
    success: callback,
    error: (...args) => {
      backendError(...args);
      typeof options.onError !== 'undefined' && options.onError(...args);
    },
  });
};

duck.api.sendActivationToken = function({urlParams, data, callback, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  backend({
    url: this.apiUrlCreator(urlParams)+'/activation-token',
    method: 'post', 
    data: {sendEmail: true},
    success: callback,
    error: (...args) => {
      backendError(...args);
      typeof options.onError !== 'undefined' && options.onError(...args);
    },
  });
};

duck.api.resetPassword = function({urlParams, data, callback, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  backend({
    url: this.apiGeneralUrlCreator({})+'/reset-password',
    method: 'post', 
    data,
    success: callback,
    error: (...args) => {
      backendError(...args);
      typeof options.onError !== 'undefined' && options.onError(...args);
    },
  });
};

duck.api.addPrivilege = function({urlParams, data, callback, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  backend({
    url: this.apiUrlCreator(urlParams)+'/privileges',
    method: 'put', 
    data: {...Object.fromEntries(Object.entries(data).filter(([key, val]) => !['id', 'updatedAt', 'createdAt', 'caseId'].includes(key)))},
    success: callback,
    error: (...args) => {
      backendError(...args);
      typeof options.onError !== 'undefined' && options.onError(...args);
    },
  });
};

duck.api.removePrivilege = function({urlParams, data, callback, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  backend({
    url: this.apiUrlCreator(urlParams)+'/privileges',
    method: 'delete', 
    data: {...Object.fromEntries(Object.entries(data).filter(([key, val]) => !['id', 'updatedAt', 'createdAt', 'caseId'].includes(key)))},
    success: callback,
    error: (...args) => {
      backendError(...args);
      typeof options.onError !== 'undefined' && options.onError(...args);
    },
  });
};

duck.api.uploadAvatar = function({urlParams, data, callback, onError, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  const formData = new FormData();
  formData.append('avatar', data, data && data.name);
  backend({
    url: this.apiUrlCreator(urlParams)+'/avatar',
    method: 'put', 
    data: formData,
    axiosParams: {headers: {'Content-Type': 'multipart/form-data'}}, 
    success: res => callback(res.data),
    error: onError,
  });
};



const backendError = err => {
  store.dispatch(postOfficeDuck.action.addError({
    text: (<strong>Błąd komunikacji z serwerem. Zmiany nie zostały zapisane.<br/>Odśwież stronę by spróbować ponownie.</strong>),
    details: 
      err && err.response 
      && (
        (err.response.data.errors && err.response.data.errors.filter(item => !['hasło', 'haslo'].includes(item.param.toLowerCase())).map(item => `Pole "${item.param}" nie może mieć wartości "${item.value}". ${item.msg}`))
        || 
        (err.response.data.message && [err.response.data.message])
      ),
  }));
};

// duck.thunk.delete = requestedId => (
//   (dispatch, getState) => {
    
//     dispatch(duck.action.updateOne({id: requestedId, status: 'erased'}));
//   }
// );

// duck.thunk.deactivate = requestedId => (
//   (dispatch, getState) => {
//     dispatch(duck.action.updateOne({id: requestedId, status: 'deactivated'}));
//   }
// );

// duck.thunk.restore = requestedId => (
//   (dispatch, getState) => {
//     dispatch(duck.action.updateOne({id: requestedId, status: 'active'}));
//   }
// );
