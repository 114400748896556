import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { DropzoneArea } from 'material-ui-dropzone';

import {
  Box,
  Card,
  Typography,
  Modal,
  IconButton,
  Tooltip
} from '@material-ui/core';
import {
  SaveOutlined,
  EditOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons';

import ModalWrapper from 'components/basic/ModalWrapper';
import InfoChip from 'components/basic/InfoChip';

import styles from './UserCard.style';
import UserAvatar from 'components/basic/UserAvatar';
import { fieldValues } from 'dict';
import { usersDuck } from 'redux/ducks/users';
import { useDispatch } from 'react-redux';
import { companiesDuck } from 'redux/ducks/companies';

export const UserCard = ({user = {}, variant, ...props}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const [changeAvatar, setChangeAvatar] = useState(false);
  const [removeAvatar, setRemoveAvatar] = useState(false);
  const [newAvatar, setNewAvatar] = useState(null);
  
  const status = user.status || user.statusName;
  const name = user.fullName || user.name;

  return (
  <Card className={clsx(classes.root, variant && (classes[`variant-${variant}`] || `variant-${variant}`))} {...props}>
    <Modal
      open={removeAvatar}
      onClose={() => setRemoveAvatar(false)}
      >
      <ModalWrapper 
        title='Usuń avatar'
        description='Czy na pewno chcesz usunąć avatar?'
        buttons={[
          { label: 'Anuluj', color: 'primary', onClick: () => setRemoveAvatar(false)},
          { label: 'Usuń avatar', color: 'secondary', icon: DeleteOutlineOutlined},
        ]}
      >
      </ModalWrapper>
    </Modal>
    <Modal
      open={changeAvatar}
      onClose={() => setChangeAvatar(false)}
      >
      <ModalWrapper 
        title='Wgraj avatar'
        disabled={changeAvatar === 'sending'}
        buttons={[
          { label: 'Anuluj', color: 'primary', onClick: () => setChangeAvatar(false)},
          { label: 'Zapisz avatar', color: 'secondary', icon: SaveOutlined, onClick: () => {
            if(!newAvatar) return;
            setChangeAvatar('sending');
            if(typeof user.avatarUrl !== 'undefined'){
              usersDuck.api.uploadAvatar({
                data: newAvatar, 
                urlParams: {id: user.id},
                callback: resData => {
                  dispatch(usersDuck.thunk.fieldUpdate({data: resData}))
                  setChangeAvatar(false);
                },
                onError: err => {
                  setChangeAvatar(true);
                },
              });
            } else if(typeof user.logoUrl !== 'undefined'){
              companiesDuck.api.uploadLogo({
                data: newAvatar, 
                urlParams: {id: user.id},
                callback: resData => {
                  dispatch(companiesDuck.thunk.fieldUpdate({data: resData}))
                  setChangeAvatar(false);
                },
                onError: err => {
                  setChangeAvatar(true);
                },
              });
            }
          }},
        ]}
      >
        <DropzoneArea
          dropzoneClass={'fileUpload'}
          acceptedFiles={['image/*']}
          dropzoneText={'Kliknij, aby wybrać obrazek z dysku lub przeciągnij i upuść obrazek w to pole'}
          getFileAddedMessage={() => 'Plik dodany poprawnie.'}
          getFileRemovedMessage={() => 'Plik usunięty poprawnie.'}
          filesLimit={1}
          showFileNames={true}
          onChange={file => setNewAvatar(file[0])}
        />
      </ModalWrapper>
    </Modal>
    <Box className={classes.avatarWrapper}>
      <UserAvatar user={user} className={classes.avatar}/>
      <Box className={classes.avatarActions}>
        {false && user.avatarUrl && (
          <Tooltip title='Usuń avatar' onClick={() => setRemoveAvatar(true)}>
            <IconButton color='secondary' className={classes.avatarButton}>
              <DeleteOutlineOutlined />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title='Zmień avatar' onClick={() => setChangeAvatar(true)}>
          <IconButton className={classes.avatarButton}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
    <Box className={classes.usernameBox}>
      { name && <Typography variant='h4'>{name}</Typography> }
      { user.roleName && <Typography variant='body2' className={classes.userPosition}>{fieldValues.users.roles[user.roleName] || user.roleName}</Typography> }
      { status &&
        <Box className={classes.userStatus}>
          <InfoChip label={fieldValues.users.status[status] || status} variant={
            ['active', 'Aktywny'].indexOf(status) > -1 ? 'active' :
            ['pending', 'pending_activation', 'Oczekujący'].indexOf(status) > -1 ? 'pending' :
            ['pending_admin_approval', 'Nowozarejestrowany'].indexOf(status) > -1 ? 'pending' :
            ['inactive', 'deactivated', 'Nieaktywny'].indexOf(status) > -1 ? 'inactive' :
            ['erased', 'Usunięty'].indexOf(status) > -1 ? 'erased' :
          'error'
      }   />
        </Box>
      }
    </Box>
  </Card>
  );
}

UserCard.propTypes = {
  children: PropTypes.node,
};

export default UserCard;
