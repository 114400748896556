import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    // padding: `${theme.spacing()*2}px 0`,
  },
}), {
  name: 'NotFound', 
  index: 1,
});
