import React, { useEffect, useState, useReducer, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch, useHistory, useLocation, generatePath, Prompt, Link } from 'react-router-dom';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';
import shortid from 'shortid';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale'

import { 
  Grid,
  Stepper,
  Step,
  StepButton,
  Modal,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
} from '@material-ui/core';
import {
  Filter1Outlined,
  TableChartOutlined,
  PersonOutlineOutlined,
  AccountBalanceOutlined,
  MonetizationOnOutlined,
  InfoOutlined,
  Close,
  ArrowBackOutlined,
  ArrowForwardOutlined,
  CloudDownloadOutlined,
  PrintOutlined,
  Filter2Outlined,
  Settings,
  SaveOutlined,
  EmailOutlined,
  PhoneOutlined,
  AssignmentOutlined
} from '@material-ui/icons';

import printJS from 'print-js';

import Button from 'components/basic/Button';
import ButtonWrapper from 'components/basic/ButtonWrapper';
import ButtonBack from 'components/basic/ButtonBack';
import PageTitle from 'components/basic/PageTitle';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

import { reportsDuck } from 'redux/ducks/reports';
import {casesDuck} from 'redux/ducks/cases';
import * as  caseDetailsDucks from 'redux/ducks/caseDetails';

import styles from './NewCase.style';
import {steps, caseFields} from './NewCase.steps';
import NewCaseContext from './NewCase.context';
import { formatCurrency } from 'utils/formatHelpers';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { useSnackbar } from 'notistack';
import backend from 'redux/lib/backend';
import { singleCaseWithFlags } from 'utils/casesWithFlags';
import { usersDuck } from 'redux/ducks/users';
import config from 'config';
import AnalysisSettings from 'components/feature/AnalysisSettings';
import getFeatsFromDict from 'utils/feats/getFeatsFromDict';
import { getCaseGuestData } from 'utils/getCaseGuestData';
import { getIsCaseFromCalculationWidget } from 'utils/getIsCaseFromCalculationWidget';
import { featuresDuck } from 'redux/ducks/features';
import Text from 'components/basic/Text';
import InfoChip from 'components/basic/InfoChip';
import { fieldLabels } from 'dict';

const getCasePayload = (input, initialData) => {
  const result = {
    ...initialData,
  };

  caseFields.forEach(field => {
    const prop = input[field.name];
    if(typeof prop !== 'undefined' && prop.savedValue !== null){
      result[field.name] = input[field.name].savedValue;
    }
  });

  return result;
};

const Title = ({title, buttons, backUrl}) => (
  <>
    {backUrl && (
      <Grid container>
        <Grid item xs={12}>
          <ButtonBack text='Powrót do umowy' target={backUrl} />
        </Grid>
      </Grid>
    )}
    {title && (
      <Grid container>
        <Grid item xs={12}>
          <PageTitle 
            title={title}
            buttons={buttons}  
          /> 
        </Grid>
      </Grid>
    )}
  </>
);

export default function NewCase() {
  const classes = styles();
  const dispatch = useDispatch();
  const {id: urlId, step: urlStep} = useParams();
  const routeMatch = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const delayedSnackbar = (...args) => setTimeout(() => enqueueSnackbar(...args), 1000);
  const [costWarning, setCostWarning] = useState(undefined);
  const [connectionError, setConnectionError] = React.useState(false);
  
  const currentUser = useSelector(currentUserDuck.get.data());
  const users = useSelector(usersDuck.get.all());

  const caseDataRaw = useSelector(casesDuck.get.byId(urlId)) || '';
  const caseData = caseDataRaw && singleCaseWithFlags({
    thisCase: caseDataRaw,
    users: users,
    userId: currentUser.id,
    IAmSupermanager: currentUser.roleName === 'supermanager',
  });

  const isCaseFromCalculationWidget = useMemo(() => getIsCaseFromCalculationWidget(caseData), [caseData]);
  const caseGuestData = useMemo(() => getCaseGuestData(caseData), [caseData]);

  const data = {
    isDraft: urlId === 'new',
    id: caseData.id,
    // ...caseData,
    caseBankRate: useSelector(caseDetailsDucks.caseBankRateDuck.get.byCaseId(urlId)),
    caseCost: useSelector(caseDetailsDucks.caseCostDuck.get.byCaseId(urlId)),
    casePayment: useSelector(caseDetailsDucks.casePaymentDuck.get.byCaseId(urlId)),
    caseTranche: useSelector(caseDetailsDucks.caseTrancheDuck.get.byCaseId(urlId)),
    caseInstallmentAmount: useSelector(caseDetailsDucks.caseInstallmentAmountDuck.get.byCaseId(urlId)),
    caseInstallmentOverride: useSelector(caseDetailsDucks.caseInstallmentOverrideDuck.get.byCaseId(urlId)),
    caseInstallmentDay: useSelector(caseDetailsDucks.caseInstallmentDayDuck.get.byCaseId(urlId)),
    caseInstallmentType: useSelector(caseDetailsDucks.caseInstallmentTypeDuck.get.byCaseId(urlId)),
    casePaymentHolidays: useSelector(caseDetailsDucks.casePaymentHolidaysDuck.get.byCaseId(urlId)),
  };
  
  const enabledFeatures = useSelector(featuresDuck.get.enabledFeatures()) || [];
  const [initialInfoShown, setInitialInfoShown] = useState(false);
  const [editingDisabled, setEditingDisabled] = useState(false);
  const [activeStep, setActiveStep] = useState(steps.findIndex(step => step.slug === urlStep));
  const [completed, setCompleted] = useState(new Set());
  const [openEstimatedReportModal, setOpenEstimatedReportModal] = useState(false);
  const [openBasicReportModal, setOpenBasicReportModal] = useState(false);
  const [openGeneratedReportModal, setOpenGeneratedReportModal] = useState(false);
  const [openAnalysisSettingsModal, setOpenAnalysisSettingsModal] = useState(false);
  const [isAnalysisSettingsFormDisabled, setIsAnalysisSettingsFormDisabled] = useState(false);
  const [unsavedData, setUnsavedData] = useState(false);

  const [editedData, updateEditedData] = useReducer((state,action)=>{
    if(action.type === 'bulk'){
      return {
        ...(
          action.payload.reduce((result, row) => ({
            ...result, 
            [row.name]: {
              ...state[row.name], 
              ...row, 
            },
          }), {...state})
        ),
      }
    }

    return {
      ...state,
      [action.name]: {
        ...state[action.name], 
        ...action,
      },
    }
  }, {});

  data.case = editedData;

  // useEffect(() => {
  //   console.log('*** NEW CASE component mounted ***');
  // }, [])

  useEffect(() => {
    // console.log('typeof data.id:', typeof data.id)
    if(typeof data.id === 'undefined'){
      dispatch(casesDuck.thunk.fetchOrCreate({
        urlParams: {id: urlId}, 
        initialData: currentUser.roleName === 'supermanager' ? {
          supermanagerUserId: currentUser.id, 
          ownerUserId: null,
          ownerCompanyId: null,
          status: 'shared',
        } : {
          ownerUserId: currentUser.id, 
          ownerCompanyId: currentUser.company.id,
          status: 'active',
        },
      }));
    }
    dispatch(casesDuck.thunk.getDetails(urlId));
  }, [urlId])

  useEffect(() => {
    if(currentUser.id && caseData.id && users.length > 1 && !initialInfoShown){
      if(caseData.flags.isLockedBecauseShared){
        setInitialInfoShown(true);
        setEditingDisabled(true);
        delayedSnackbar('Nie możesz edytować umowy przekazanej do wsparcia klienta', { variant: 'info' });
      } else if(caseData.flags.isLockedBecauseAssigned){
        setInitialInfoShown(true);
        setEditingDisabled(true);
        delayedSnackbar('Nie możesz edytować umowy przypisanej do innego użytkownika', { variant: 'info' });
      } else if(caseData.flags.isLockedBecauseUnassigned && !editingDisabled){
        setInitialInfoShown(true);
        setEditingDisabled(true);
        delayedSnackbar('Ta umowa nie jest do Ciebie przypisana', {
          persist: true,
          variant: 'info',
          action: key => <>
            <Button
              disabled={initialInfoShown}
              onClick={() => {
                const data = currentUser.roleName === 'supermanager' ? {id: urlId, supermanagerUserId: currentUser.id} : {id: urlId, ownerUserId: currentUser.id};
                casesDuck.api.updateOne({
                  data, immediate: true,
                  onUpdated: () => {
                    setEditingDisabled(false);
                    dispatch(casesDuck.thunk.fieldUpdate({data}));
                    closeSnackbar(key);
                    // setTimeout(() => setInitialInfoShown(false), 500);
                  },
                  onError: () => setInitialInfoShown(false),
                }); 
              }}
            >Przypisz</Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}><Close fontSize="small" /></IconButton>
          </>
        });
      }
    }
  }, [urlId, currentUser, caseData.supermanagerUserId])

  useEffect(() => {
    window.onbeforeunload = !unsavedData ? undefined : e => {
      e.preventDefault();
      e.returnValue = '';
    };
  }, [unsavedData]);

  useEffect(() => {
    if(!(activeStep >= 0)){
      setActiveStep(0);
    } else {
      history.replace(generatePath(routeMatch.path, {...routeMatch.params, step: steps[activeStep].slug}));
  }
  }, [activeStep]);

  useEffect(() => {
    if(Object.keys(editedData).length === 0 && caseData && caseData.constructor.name === 'Object'){
      updateEditedData({
        type: 'bulk', 
        payload: Object.entries(caseData).map(([key, val]) => ({
          name: key, 
          value: val,
          savedValue: val,
          initialValue: val,
        })),
      });
      
      const errors = casesDuck.validationErrors(caseData);
      if(errors && !data.isDraft){
        delayedSnackbar((
          'Uwaga! Umowa nie zawiera wymaganych pól: ' 
          + errors.map(err => {
            const label = caseFields.find(field => field.name === err.name);
            return label ? label.label : err.name;
          }).join(', ')
        ), {variant: 'error', persist: true});
      }
    }
  }, [caseData]); 

  useEffect(() => {
    if (!openBasicReportModal || openBasicReportModal === "sending") {
      return;
    }

    generateReport('basic', {
      selectAllNone: false,
      invalidCase: true,
      plnLiborLoan: true,
      caseSettlementWiborInterestRate: false,
      caseSettlementSpreadRefund: false,
      ckkRrso: false,
      description: ''
    });
  }, [openBasicReportModal]);

  const handleEditedData = action => {
    updateEditedData(action);
    const newEditedData = {
      ...Object.fromEntries(Object.entries(editedData).map(([key, props]) => ([key, {...props}]))),
      [action.name]: {
        ...editedData[action.name], 
        ...action,
      },
    };

    const casePayload = getCasePayload(newEditedData, {
      status: caseData.status || null,  
      ownerUserId: caseData.ownerUserId, 
      ownerCompanyId: caseData.ownerCompanyId,  
      supermanagerUserId: caseData.supermanagerUserId || null, 
    });
    const errors = casesDuck.validationErrors(casePayload) || Object.values(newEditedData).some(props => props.error);
    
    if(data.isDraft){
      if(!errors){
        setUnsavedData(true);
        backend({
          url: '/cases',
          method: 'post',
          data: casePayload,
          success: res => {
            // console.log('res:', res)
            setUnsavedData(false);
            redirectToCaseEdit(res.data);
            enqueueSnackbar('Umowa została zapisana', {variant: 'success'});

            dispatchChange(casesDuck.thunk.fieldUpdate({data: res.data}));
            
            updateEditedData({
              type: 'bulk', 
              payload: Object.values(caseFields).map(({name}) => ({
                name, 
                value: newEditedData[name] && typeof newEditedData[name].value !== 'undefined' ? newEditedData[name].value : res.data[name],
                initialValue: res.data[name],
              })),
            });
          },
          error: err => {
            console.log('Error saving draft', err)
            if(err && err.response && err.response.data && Array.isArray(err.response.data.errors)){
              const msgs = {};
              err.response.data.errors.forEach(error => {
                msgs[error.param] = (msgs[error.param] ? msgs[error.param] + ' ' : '') + error.msg;
              });

              enqueueSnackbar('Dane umowy zawierają błędy. Sprawdź pola oznaczone na czerwono.', {variant: 'warning'});

              Object.entries(msgs).forEach(([name, msg]) => {
                if (name in caseData === false) {
                  enqueueSnackbar(
                    <div>
                      Wystąpił przy dodawaniu umowy.<br /><br />
                      <strong>{fieldLabels[name] || name}</strong>: <p>{msg}</p>
                    </div>,
                    { variant: "error", autoHideDuration: 10000 }
                  );
                } else {
                  updateEditedData({ name, error: msg });
                }
              });
            }
          },
        });
      }
    } else {
      if(errors){
        setUnsavedData(true);
        if(Array.isArray(errors)){
          errors.forEach(error => {
            updateEditedData({name: error.name, error: 'To pole jest wymagane'})
          });
        }
        enqueueSnackbar('Umowa zawiera błędy w krokach 1-2. Zmiany zostaną zapisane po poprawieniu błędów.', {variant: 'warning'});
      } else {
        const changedData = Object.entries(newEditedData).reduce(((result, [key, props]) => (
          '' + props.savedValue === '' + props.initialValue) ? ( 
            result 
          ) : {
            ...result, 
            [key]: props.savedValue,
          }
        ), {});
        if(Object.keys(changedData).length){
          setUnsavedData(true);
          backend({
            url: '/cases/' + caseData.id,
            method: 'put',
            data: changedData,
            success: res => {
              setUnsavedData(false);
              enqueueSnackbar('Zmiany zostały zapisane', {variant: 'success', autoHideDuration: 1000});

              dispatchChange(casesDuck.thunk.fieldUpdate({data: res.data}));
              
              updateEditedData({
                type: 'bulk', 
                payload: Object.values(caseFields).map(({name}) => ({
                  name, 
                  value: newEditedData[name] && typeof newEditedData[name].value !== 'undefined' ? newEditedData[name].value : res.data[name],
                  initialValue: res.data[name],
                })),
              });
              
            },
            error: err => {
              if(err && err.response && err.response.data && Array.isArray(err.response.data.errors)){
                const msgs = {};
                err.response.data.errors.forEach(error => {
                  msgs[error.param] = (msgs[error.param] ? msgs[error.param] + ' ' : '') + error.msg;
                });
                Object.entries(msgs).forEach(([name, msg]) => updateEditedData({name, error: msg}));
                enqueueSnackbar('Dane umowy zawierają błędy, zmiany nie zostały zapisane. Sprawdź pola oznaczone na czerwono w krokach 1-2.', {variant: 'warning'});
              }
              // todo: else show error message
            },
          });
        }
      }
    }
  };

  const handleDetailsData = (dataSector, thunk, action) => {
    const options = {...action};
    options.urlParams = {caseId: data.id, id: action.data.id};

    if(typeof action.data.id !== 'undefined'){
      options.data = Object.fromEntries(Object.entries(action.data).filter(([key, val]) => {
        return key === 'amountOverrideType' || val !== data[dataSector].find(row => row.id === action.data.id)[key];
      }));
      options.data.id = action.data.id;
    };

    options.data.caseId = data.id;
    // console.log('action.data.id, options.urlParams:', action.data.id, options.urlParams)
    // console.log('action.data, options.data:', action.data, options.data)
    
    if(Object.keys(options.data).length){
      dispatchChange(thunk(options));
    }
  };

  if(typeof data.id === 'undefined'){
    return <>...</>;
  } else if(!(activeStep >= 0)) {
    return null;
  }

  if(costWarning){
    return (
      <div className={classes.root}>
        <Modal
          open={costWarning}
          onClose={() => setCostWarning(false)}
        >
          <ModalWrapper 
            maxWidth='850'
            title='Czy chcesz kontynuować?'
            description='Uruchomienie generowania raportu szacunkowego spowoduje naliczenie opłaty. Czy chcesz kontynuować?'
            buttons={[
              { label: 'Nie, zamknij generowanie raportu', color: 'primary', onClick: () => setCostWarning(false)},
              { label: 'Tak, przejdź do generowania raportu szacunkowego', color: 'secondary', icon: TableChartOutlined, onClick: () => {
                setCostWarning(false);
                setOpenEstimatedReportModal(true);
              }},
            ]}     
          >
          </ModalWrapper>
        </Modal>
      </div>
    );
  }

  const dispatchChange = action => {
    dispatch(action);
    setTimeout(() => {
      dispatch(casesDuck.thunk.caseUpdated(data.id));
    }, 1000);
  };

  const redirectToCaseEdit = ({id}) => history.replace(generatePath(routeMatch.path, {...routeMatch.params, id}));

  const handleNext = () => {
      setActiveStep(activeStep + 1);
  };

  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleStep = step => () => setActiveStep(step);

  const handleGeneratedReportModalClose = () => setOpenGeneratedReportModal(false);
  const handleEstimatedReportModalOpen = () => setOpenEstimatedReportModal(true);
  const handleEstimatedReportModalClose = () => setOpenEstimatedReportModal(false);
  const handleBasicReportModalClose = () => setOpenBasicReportModal(false);

  const checkReport = (version, data) => {
    if(data.processingStatus === 'pending'){
      setTimeout(() => {
        reportsDuck.api.getOne({
          urlParams: {caseId: urlId, id: data.id},
          callback: resData => {
            checkReport(version, resData);
          },
        });
      }, 1000);
    } else if(data.processingStatus === 'complete'){
      dispatch(reportsDuck.thunk.fieldUpdate({ data: data }));
      dispatch(
        casesDuck.thunk.fetchOrCreate({
          urlParams: { id: urlId },
        })
      );

      if (version === 'basic') {
        openBasicReportModal && setOpenBasicReportModal(false);
      } else {
        openEstimatedReportModal && setOpenEstimatedReportModal(false);
      }
      
      setOpenGeneratedReportModal(data.url);
    } else {
      if (version === 'basic') {
        openBasicReportModal && setOpenBasicReportModal(false);
      } else {
        openEstimatedReportModal && setOpenEstimatedReportModal(false);
      }
      
      enqueueSnackbar('Wystąpił błąd generowania raportu. Spróbuj ponownie lub skontaktuj się ze wsparciem klienta.');
      console.error('Error details:', data);
    }
  };
  
  const generateReport = (version, values) => {
    if (version === 'basic') {
      openBasicReportModal && setOpenBasicReportModal('sending');
    } else {
      openEstimatedReportModal && setOpenEstimatedReportModal('sending');
    }

    if (version === 'basic' && values && typeof values === 'object') {
      values = { ...values, knf: signatureDateYear > 2004 };
    }

    reportsDuck.api.addOne({
      urlParams: {caseId: urlId}, 
      data: {version, modules: values, id: 'new-'+shortid.generate()},
      onAdded: (resData) => checkReport(version, resData),
    });
  };

  // console.log(caseDataRaw);
  // console.log(caseData);
  // console.log(data);
  // console.log(caseGuestData);

  const [signatureDateYear = 0] = caseData.signatureDate?.split('-') || [];
  
  return (
    <div className={classes.root}>
      {/* <Prompt 
        when={unsavedData}
        message='Nie wszystkie zmiany zostały zapisane. Czy chcesz porzucić wprowadzone zmiany?'
      /> */}
      <Modal
        open={openGeneratedReportModal}
        onClose={handleGeneratedReportModalClose}
      >
        <ModalWrapper 
          title='Twój raport jest gotowy!'
          description={<>Raport został wygenerowany poprawnie. Znajdziesz go na <Link to={`/cases/${caseData.id}/reports`}>liście raportów</Link> tej umowy. Możesz go również pobrać lub wyświetlić, używając przycisków poniżej.</>} 
          buttons={[
            { label: 'Zamknij', color: 'primary', onClick: handleGeneratedReportModalClose},
            { label: 'Pobierz', color: 'secondary', icon: CloudDownloadOutlined, onClick: () => window.open(openGeneratedReportModal, '_blank')},
            { label: 'Drukuj', color: 'secondary', icon: PrintOutlined, onClick: () => {
              let snackKey;
              
              backend({
                axiosParams: {
                  url: openGeneratedReportModal,
                  responseType: 'arraybuffer',
                },
                error: (err) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
        
                  console.log('err:', err)
                },
                success: async (res) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});

                  const pdfFile = new Blob([res.data], {
                    type: "application/pdf"
                  });
                  const pdfUrl = URL.createObjectURL(pdfFile);

                  printJS({
                    printable: pdfUrl, 
                    onLoadingStart: () => snackKey = enqueueSnackbar('Trwa pobieranie raportu', {variant: 'info'}),
                    onLoadingEnd: () => {
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});
                    },
                    onError: (error) => {
                      console.log(error);
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
                    },
                    onPrintDialogClose: () => {
                      snackKey && closeSnackbar(snackKey)
                      setTimeout(() => {
                        URL.revokeObjectURL(pdfUrl);
                      }, 100);
                    },
                  });
                },
              });
            }},
          ]}     
        >
        </ModalWrapper>
      </Modal>
      <Modal
        open={openEstimatedReportModal}
        onClose={() => setOpenEstimatedReportModal(false)}
      >

        
        <ModalWrapper 
          maxWidth={600}
          variant='small'
          title='Wygeneruj raport szacunkowy'  
        >
          <Form
            variant='small'
            disabled={openEstimatedReportModal==='sending'} 
            gridItemProps={{
              style: {paddingTop: 0, paddingBottom: 0}
            }}
            selectAllNone='selectAllNone'
            fields={[
              { label: 'Zaznacz/odznacz wszystkie', name: 'selectAllNone', type: 'checkbox'},
              { label: 'Nieważność umowy', name: 'invalidCase', type: 'checkbox', checked: true },
              { label: 'LIBOR + PLN', name: 'plnLiborLoan', type: 'checkbox', checked: true },
              { label: 'WIBOR + PLN', name: 'caseSettlementWiborInterestRate', type: 'checkbox' },
              { label: 'Kurs średni NBP', name: 'caseSettlementSpreadRefund', type: 'checkbox' },
              ...(config.feat.caseExchangePropsEnabled && (caseData.exchangeRateAsk !== null && caseData.exchangeRateBid !== null) ? [{ label: 'CKK + RRSO', name: 'ckkRrso', type: 'checkbox', checked: false }] : []),
              ...((enabledFeatures.includes('knf')) ? [{ label: 'KNF', name: 'knf', type: 'checkbox', checked: false, disabled: Number(signatureDateYear) < 2005 }] : []),
              { label: 'Opcjonalny opis', name: 'description', multiline: true, rows: 4 },
            ]} 
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: handleEstimatedReportModalClose},
              { label: 'Generuj raport', color: 'secondary', icon: Filter2Outlined, type: 'submit'},
            ]}
            onSubmit={values => {
              if (!costWarning) {
                generateReport('estimation', Object.fromEntries(Object.entries(values).filter(([key]) => key !== 'selectAll')));
              }
            }}
          />

        </ModalWrapper>
      </Modal>

      <Modal
        open={openBasicReportModal}
        onClose={() => setOpenEstimatedReportModal(false)}
      >
        <ModalWrapper 
          variant='small'
          title='Generowanie raportu wstępnego...'  
        >
          <div className={classes.progressContainer}>
            <CircularProgress size={24} />
          </div>
        </ModalWrapper>
      </Modal>

      <Modal
        open={openAnalysisSettingsModal}
        onClose={() => setOpenAnalysisSettingsModal(false)}
      >
        <ModalWrapper 
          variant='small'
          title='Ustawienia analizy'  
        >
          <AnalysisSettings
            variant='small'
            feats={caseData.featsList}
            disabled={isAnalysisSettingsFormDisabled}
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => setOpenAnalysisSettingsModal(false)},
              { label: 'Zapisz zmiany', color: 'secondary', icon: SaveOutlined, type: 'submit'},
            ]}
            onSubmit={(values) => {
              const data = { feats: values.join(',') };

              setIsAnalysisSettingsFormDisabled(true);
              casesDuck.api.updateOne({
                data,
                urlParams: {
                  id: caseData.id, 
                },
                immediate: true,
                onUpdated: (nextData) => {
                  enqueueSnackbar('Zaktualizowano ustawienia analizy', {variant: 'success'})
                  dispatch(casesDuck.thunk.fieldUpdate({ data: nextData }));
                  setIsAnalysisSettingsFormDisabled(false);
                },
                onError: () => {
                  enqueueSnackbar('Błąd aktualizacji ustawień analizy', {variant: 'success'})
                  setIsAnalysisSettingsFormDisabled(false);
                }
              }); 
            }}
          />
        </ModalWrapper>
      </Modal>
      
      <ButtonBack text='Powrót' onClick={() => window.history.back()} />
      <PageTitle 
            title={data.isDraft ? 'Dodaj nową umowę' : `Umowa nr ${caseData.agreementNo} z dnia ${caseData.signatureDate}`}
            buttons={!data.isDraft && [
              {
                disabled: data.caseTranche.length === 0,
                tooltip: 'Dodaj co najmniej jedną transzę, aby wygenerować raport wstępny',
                label: "Wygeneruj raport wstępny",
                color: "primary",
                icon: Filter1Outlined,
                onClick: () => {
                  setOpenBasicReportModal(true);
                },
              },
              {
                disabled: data.caseTranche.length === 0,
                tooltip: 'Dodaj co najmniej jedną transzę, aby wygenerować raport szacunkowy',
                label: "Wygeneruj raport szacunkowy",
                color: "primary",
                icon: Filter2Outlined,
                onClick: () => {
                  if (caseData.calculationsCount > 0) {
                    setCostWarning(null);
                    setOpenEstimatedReportModal(true);
                  } else if (caseData.calculationsCount <= 0) {
                    if (currentUser.company.reportPrice <= 0) {
                      setCostWarning(null);
                      setOpenEstimatedReportModal(true);
                    } else {
                      setCostWarning(true);
                    }
                  }
                },
              },
              { 
                label: 'Przejdź do widoku rozszerzonego', 
                color: 'secondary', 
                icon: TableChartOutlined, 
                disabled: data.caseTranche.length === 0, 
                tooltip: 'Dodaj co najmniej jedną transzę, aby przejść do widoku rozszerzonego',
                newTab: true, 
                target: generatePath(routeMatch.path, {...routeMatch.params, step: 'full'})
              },
              {
                hideLabel: true,
                label: "Ustawienia analizy",
                tooltip: "Otwórz ustawienia analizy",
                color: "transparent",
                icon: () => (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    paddingLeft={1.25}
                  >
                    <Settings />
                  </Box>
                ),
                onClick: () => {
                  setOpenAnalysisSettingsModal(true)
                },
              },
            ]} 
          />

          {Number(signatureDateYear) < 2005 && Number(signatureDateYear) !== 0 && (
            <Box css={{ bgcolor: "#e4eefd", width: '100%', py: '1rem', px: '1rem', borderRadius: '0.25rem', mt: '-0.5rem', mb: '1rem' }}>
              <Box css={{ fontSize: '1rem', lineHeight: 1.45, mb: '0.5rem' }}>Przepraszamy, nie mamy możliwości wygenerowania symulacji opartej na propozycji KNF.</Box>
              <Box css={{ fontSize: '0.75rem', lineHeight: 1.45, color: '#444' }}>Prezes KNF nie opublikował danych potrzebnych do przygotowania symulacji dla kredytów zawartych przed 2005 r.</Box>
            </Box>
          )}
          
          {(
            <ButtonWrapper 
              mb={isCaseFromCalculationWidget ? 2 : 4}
              buttons={
                data.isDraft ? [
                  { type: 'chip', icon: InfoOutlined, label: 'Umowa zostanie zapisana automatycznie po wypełnieniu wymaganych pól.' },
                ] : [
                  { type: 'chip', label: caseData.person, icon: PersonOutlineOutlined },
                  { type: 'chip', label: caseData.bankName, icon: AccountBalanceOutlined },
                  { type: 'chip', label: caseData.currency, icon: MonetizationOnOutlined },
                ]
              } 
            />
          )}

      {isCaseFromCalculationWidget && (
        <ButtonWrapper 
          mb={4}
          buttons={[
            { type: 'chip', icon: PhoneOutlined, label: `Nr telefonu: ${caseGuestData.phone}`, variant: 'outline' },
            { type: 'chip', icon: EmailOutlined, label: `Email: ${caseGuestData.email}`, variant: 'outline' },
            { type: 'chip', icon: AssignmentOutlined, label: caseGuestData.marketingAgreement ? `Zgoda marketingowa: tak, udzielono ${format(new Date(caseGuestData.marketingAgreementDate), 'k:mm:ss, dd.MM.u (cccc BBBB)', { locale: pl })}` : 'Zgoda marketingowa: nie', variant: 'outline' },
          ]}
        />
      )}

      <Grid container>
      </Grid>
  
      <Grid container className={classes.formWrapper}>
        <Grid item xs={12}>
          <IconButton IconButton onClick={handleBack} className={clsx(classes.navArrow, classes.arrowBackward)} variant='text' disabled={activeStep === 0}>
            <ArrowBackOutlined />
          </IconButton>
          <Stepper alternativeLabel nonLinear activeStep={activeStep}>
            {steps.map(({title, onlyOnEdit}, index) => (
              <Tooltip 
                key={title}
                title={data.isDraft && onlyOnEdit ? 'Krok dostępny po wypełnieniu wszystkich wymaganych pól' : ''}
                placement='top'
                PopperProps={{className: classes.stepTooltip}}
              >
                <Step completed={completed.has(index)} className={classes.step}>
                  <StepButton
                    onClick={!(data.isDraft && onlyOnEdit) && handleStep(index)}
                    completed={completed.has(index)}
                    disabled={data.isDraft && onlyOnEdit}
                    
                  >
                    {title}
                  </StepButton>
                </Step>
              </Tooltip>
            ))}
          </Stepper>
          <IconButton onClick={handleNext} disabled={activeStep === steps.length-1 || (steps[activeStep+1].onlyOnEdit && data.isDraft)} className={clsx(classes.navArrow, classes.arrowForward)}>
            <ArrowForwardOutlined />
          </IconButton>
        </Grid>
        <Grid item container xs={12} justify="center">
          <form noValidate autoComplete="off" className={classes.form}>  
            <Grid item container xs={12} spacing={3} justify="space-between">
              <NewCaseContext.Provider 
                value={{
                  editingDisabled, 
                  data, 
                  currentUserRole: currentUser.roleName,
                  // update: item => {dispatch(casesDuck.thunk.fieldUpdate({urlParams: {id: data.id}, data: item}))},
                  // updateAndSend: item => {dispatchChange(casesDuck.thunk.fieldSave({urlParams: {id: data.id}, data: item, onAdded: redirectToCaseEdit}))},
                  update: item => {updateEditedData(item)},
                  updateAndSend: item => {handleEditedData(item)},
                  
                  saveTranche: item => {handleDetailsData('caseTranche', caseDetailsDucks.caseTrancheDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveCost: item => {handleDetailsData('caseCost', caseDetailsDucks.caseCostDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveBankRate: item => {handleDetailsData('caseBankRate', caseDetailsDucks.caseBankRateDuck.thunk.fieldSaveWithNulls, {data: item})},
                  savePayment: item => {handleDetailsData('casePayment', caseDetailsDucks.casePaymentDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveInstallmentAmount: item => {handleDetailsData('caseInstallmentAmount', caseDetailsDucks.caseInstallmentAmountDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveInstallmentOverride: item => {handleDetailsData('caseInstallmentOverride', caseDetailsDucks.caseInstallmentOverrideDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveInstallmentDay: item => {handleDetailsData('caseInstallmentDay', caseDetailsDucks.caseInstallmentDayDuck.thunk.fieldSaveWithNulls, {data: item})},
                  saveInstallmentType: item => {handleDetailsData('caseInstallmentType', caseDetailsDucks.caseInstallmentTypeDuck.thunk.fieldSaveWithNulls, {data: item})},
                  savePaymentHolidays: item => {handleDetailsData('casePaymentHolidays', caseDetailsDucks.casePaymentHolidaysDuck.thunk.fieldSaveWithNulls, {data: item})},

                  deleteTranche: (item, callback, onError) => {handleDetailsData('caseTranche', caseDetailsDucks.caseTrancheDuck.thunk.delete, {data: item, callback, onError})},
                  deleteCost: (item, callback, onError) => {handleDetailsData('caseCost', caseDetailsDucks.caseCostDuck.thunk.delete, {data: item, callback, onError})},
                  deleteBankRate: (item, callback, onError) => {handleDetailsData('caseBankRate', caseDetailsDucks.caseBankRateDuck.thunk.delete, {data: item, callback, onError})},
                  deletePayment: (item, callback, onError) => {handleDetailsData('casePayment', caseDetailsDucks.casePaymentDuck.thunk.delete, {data: item, callback, onError})},
                  deleteInstallmentAmount: (item, callback, onError) => {handleDetailsData('caseInstallmentAmount', caseDetailsDucks.caseInstallmentAmountDuck.thunk.delete, {data: item, callback, onError})},
                  deleteInstallmentOverride: (item, callback, onError) => {handleDetailsData('caseInstallmentOverride', caseDetailsDucks.caseInstallmentOverrideDuck.thunk.delete, {data: item, callback, onError})},
                  deleteInstallmentDay: (item, callback, onError) => {handleDetailsData('caseInstallmentDay', caseDetailsDucks.caseInstallmentDayDuck.thunk.delete, {data: item, callback, onError})},
                  deleteInstallmentType: (item, callback, onError) => {handleDetailsData('caseInstallmentType', caseDetailsDucks.caseInstallmentTypeDuck.thunk.delete, {data: item, callback, onError})},
                  deletePaymentHolidays: (item, callback, onError) => {handleDetailsData('casePaymentHolidays', caseDetailsDucks.casePaymentHolidaysDuck.thunk.delete, {data: item, callback, onError})},

                  saveTrancheBulk: item => {dispatchChange(caseDetailsDucks.caseTrancheDuck.thunk.addBulkSync({...item, urlParams: {caseId: data.id}}))},
                  saveCostBulk: item => {dispatchChange(caseDetailsDucks.caseCostDuck.thunk.addBulkSync({...item, urlParams: {caseId: data.id}}))},
                  saveBankRateBulk: item => {dispatchChange(caseDetailsDucks.caseBankRateDuck.thunk.addBulkSync({...item, urlParams: {caseId: data.id}}))},
                  savePaymentBulk: item => {dispatchChange(caseDetailsDucks.casePaymentDuck.thunk.addBulkSync({...item, urlParams: {caseId: data.id}}))},

                  deleteTrancheBulk: item => {dispatchChange(caseDetailsDucks.caseTrancheDuck.thunk.deleteBulkSync({...item, urlParams: {caseId: data.id}}))}, 
                  deleteCostBulk: item => {dispatchChange(caseDetailsDucks.caseCostDuck.thunk.deleteBulkSync({...item, urlParams: {caseId: data.id}}))}, 
                  deleteBankRateBulk: item => {dispatchChange(caseDetailsDucks.caseBankRateDuck.thunk.deleteBulkSync({...item, urlParams: {caseId: data.id}}))}, 
                  deletePaymentBulk: item => {dispatchChange(caseDetailsDucks.casePaymentDuck.thunk.deleteBulkSync({...item, urlParams: {caseId: data.id}}))}, 
                }}
              >
                {React.createElement( steps[activeStep].Step )}
              </NewCaseContext.Provider>
            </Grid>
          </form>  
        </Grid>
        <Grid item container xs={12}>
          <Box className={classes.arrowsBottom}>
            <IconButton IconButton onClick={handleBack} className={clsx(classes.navArrow, classes.arrowBackward)} variant='text' disabled={activeStep === 0}>
              <ArrowBackOutlined />
            </IconButton>
            <IconButton onClick={handleNext} disabled={activeStep === steps.length-1 || (steps[activeStep+1].onlyOnEdit && data.isDraft)} className={clsx(classes.navArrow, classes.arrowForward)}>
              <ArrowForwardOutlined />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}




