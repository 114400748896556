// import Axios from 'axios';
import {SingleRecordDuck} from 'redux/lib/Duck';
import { casesDuck } from 'redux/ducks/cases';
import { reportsDuck } from 'redux/ducks/reports';
import { dates } from 'dict';

export const statsDuck = new SingleRecordDuck('stats', ({endpoint = '', companyId, companyIds}) => `/stats/${endpoint}` + (!companyId && !companyIds ? '' : '?'+[companyId && `companyId=${companyId}`, companyIds && `companyIds=${companyIds}`].filter(Boolean).join('&')));
const duck = statsDuck;

duck.get.cases = (filters) => (state) => {
  const filteredCases = casesDuck.get.allFiltered(filters)(state);
  
  return {all: filteredCases.length};
};

duck.get.reports = (filters) => (state) => {
  const filteredReports = reportsDuck.get.allFiltered(filters)(state);

  const reports = {all: 0, cases: 0, estimation: 0};

  filteredReports.forEach(row => {
    reports.all++;
    reports[row.version]++;
  });
  
  return reports;
};

duck.get.reportsPerPeriod = (filters) => (state) => {
  const reports = {
    anytime: null,
    currentYear: null,
    currentMonth: null,
    lastMonth: null,
  };

  Object.keys(reports).forEach( period => reports[period] = duck.get.reports({...filters, createdAt: dates.relative[period]})(state) );
  
  return reports;
};

duck.get.casesAndReports = (filters) => (state) => {
  return {
    cases: duck.get.cases(filters)(state),
    reports: duck.get.reports(filters)(state),
  };
};
