import React from 'react';
//import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Grid,
} from '@material-ui/core';
import { 
  Add 
} from '@material-ui/icons';
import PageTitle from 'components/basic/PageTitle';
import KnowledgeBaseList from 'components/feature/KnowledgeBaseList';

import styles from './KnowledgeBase.style';

import { currentUserDuck } from 'redux/ducks/currentUser';

export default function KnowledgeBase() {
  const classes = styles();

  const currentUser = useSelector(currentUserDuck.get.data());

  return (
    <div className={classes.root}>
      <PageTitle 
        mb={2} 
        title='Baza wiedzy'
        buttons={currentUser.roleName === 'admin' && [
          { label: 'Dodaj nowy artykuł', color: 'secondary', target: '/kb/new/edit', icon: Add },
        ]}  
      />  
      <Grid container justify='center'>
        <Grid item xs={12} md={8}>
          <KnowledgeBaseList/>
        </Grid>
      </Grid>   
    </div>
  );
}
