import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core';

import styles from './Dropdown.style';

export const Dropdown = ({icon: Icon, disabled, label, options = {}, title, variant, value, handleChange}) => {
  const classes = styles();

  const onChange = (event) => {
    typeof handleChange === 'function' && handleChange(event.target.value);
  };

  return (
    <Box display='flex' alignItems='center' className={clsx(classes.root, variant && `variant-${variant}`)}>
    { Icon && <Icon/> }
    { title && <Typography variant='body2'>{title}</Typography> }
    <FormControl variant='outlined' size='small'>
      <Select value={value} onChange={onChange} disabled={disabled}>
        {options.map((option, index) => (
          <MenuItem value={option.value} disabled={option.disabled}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
    </Box>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node,
};

export default Dropdown;


