// import React from 'react';
// import PropTypes from 'prop-types';

import { Button as MUIButton } from '@material-ui/core';

const Button = MUIButton;

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
};

export default Button;
