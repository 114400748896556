import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Divider
} from '@material-ui/core';

function CardTitle({title, ...props}) {;
  return (
    <Box mb={4} {...props}>
      <Typography component='h3' variant='h5'>{title}</Typography>
      <Divider />
    </Box>
  );
}

CardTitle.propTypes = {
  children: PropTypes.node,
};

export default CardTitle;
