import React from 'react';
import PropTypes from 'prop-types';

import { 
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  ListSubheader
} from '@material-ui/core';

import ButtonWrapper from 'components/basic/ButtonWrapper';

import styles from './Fieldset.style';

export const Fieldset = ({fields = {}, buttons = {}, values, setValues, disabled, selectAllNone, gridItemProps}) => {
  const classes = styles();

  // fields && Array.isArray(fields) && fields.forEach((field, i) => {
  //   window.addLabel(field.name, field.label);
  // });

  return (
    <Grid item container xs={12} className={classes.root} spacing={3}>
      {fields.length && fields.map(({half = false, third = false, options = {}, InputProps, visible, ...field}) => (
        field.type === 'checkbox' ? (
          <Grid item xs={12} lg={half ? 6 : third ? 4 : 12} spacing={0} {...gridItemProps}>
            <FormControlLabel
              control={<Checkbox color="primary" />}
              label={field.label}
              labelPlacement="end"
              size='small'
              variant='outlined'
              name={field.name}
              checked={values[field.name] === (field.defaultValue || true)}
              value={field.defaultValue || true} 
              onChange={!field.disabled && (
                field.name === selectAllNone ? (event => {
                  const newValue = event.target.checked ? (field.defaultValue || true) : false;
                  const newValuesSet = {[selectAllNone]: newValue};
                  fields.filter(({name, type}) => type === 'checkbox' && name !== selectAllNone && name !== 'knf').forEach(({name}) => newValuesSet[name] = newValue);
                  setValues({...values, ...newValuesSet})
                }) : (event => {
                  const newValue = event.target.checked ? (field.defaultValue || true) : false;
                  setValues({
                    ...values, 
                    [field.name]: newValue, 
                    ...(selectAllNone && {
                      [selectAllNone]: fields.filter(({name, type}) => {
                        if(type !== 'checkbox') return false;
                        if(name === selectAllNone) return false;
                        if(name === field.name) return !newValue;
                        return values[name] !== true;
                      }).length === 0,
                    }),
                  });
                  typeof field.onChange === 'function' && field.onChange(newValue);
                })
              )}
              required={field.required}
              disabled={disabled || field.disabled}
              error={field.error}
              helperText={field.error || field.helperText}
            />
          </Grid>
        ) : (typeof visible !== 'function' || visible(values)) && (
          <Grid item xs={12} lg={half ? 6 : third ? 4 : 12} {...gridItemProps}>
            <TextField
              label={field.label}
              size='small'
              variant='outlined'
              name={field.name}
              value={typeof values[field.name] === 'number' || values[field.name] ? values[field.name] : ''} 
              onChange={!field.disabled && (event => {
                const newVal = event.target.value === '' ? null : field.type === 'number' ? parseFloat(event.target.value.split(' ').join('')) : event.target.value;
                setValues({...values, [field.name] : newVal});
                typeof field.onChange === 'function' && field.onChange(newVal);
              })}
              required={field.required}
              disabled={disabled || field.disabled}
              multiline={field.rows > 1}
              rows={field.rows}
              type={field.type || 'text'}
              select={Object.keys(options).length}
              error={field.error}
              helperText={field.error || field.helperText}
              autoFocus={field.autoFocus}
              InputProps={InputProps}
            >
              {Object.keys(options).length && (
                Object.entries(options).map(([value, label]) => (
                  /^subheader/.test(value) ? (
                    <ListSubheader>{label}</ListSubheader>
                  ) : label && label.constructor.name === 'Object' ? (
                    <MenuItem value={value} {...(label.disabled && {disabled: 'disabled'})}>{label.label}</MenuItem>
                  ) : (
                    <MenuItem value={value}>{label}</MenuItem>
                  )
                ))
              )}
            </TextField>
          </Grid>
        )
      ))}
      {buttons.length > 0 && (
        <Grid item container justify='center' xs={12}>
          <ButtonWrapper>
            {buttons.map(button => (
              <div className={classes.buttonWrapper}>
                <Button
                  disabled={disabled}
                  key={button.label}
                  type={button.type || 'button'}
                  disableElevation
                  variant={button.variant ? button.variant : 'contained'}
                  color={button.color ? button.color : 'primary'}
                  startIcon={button.icon && <button.icon />}
                  to={button.target}
                  onClick={button.onClick}
                >
                  {button.label}
                </Button>
                {button.type === 'submit' && disabled && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            ))}
          </ButtonWrapper>
        </Grid>
      )}
    </Grid>
  );
}

Fieldset.propTypes = {
  children: PropTypes.node,
};

export default Fieldset;
