import {SingleRecordDuck} from 'redux/lib/Duck';
import { fieldValues } from 'dict';
import { PostOffice } from 'redux/lib/PostOffice';
import { companiesDuck } from 'redux/ducks/companies';

export const currentUserDuck = new SingleRecordDuck('currentUser', ({endpoint = 'me'}) => `/users/${endpoint}`);
const duck = currentUserDuck;

duck.normalize = (data) => ({...data,
  companyName: data.company && data.company.name,
  fullName: `${data.firstName} ${data.lastName}`,
  roleName: data.privileges && Object.keys(fieldValues.users.roles).find(role => data.privileges.includes(role))
});

duck.get.loggedIn = () => ({ currentUser }) => typeof currentUser.data.login === 'undefined' ? null : (currentUser.data.login === null ? false : true);
duck.get.login = () => ({ currentUser }) => currentUser.data.login;
duck.get.privileges = () => ({ currentUser }) => currentUser.data.privileges;
duck.get.company = () => ({ currentUser }) => currentUser.data.company || {};
duck.get.mainRole = () => ({ currentUser }) => currentUser.data.privileges && Object.keys(fieldValues.users.roles).find(role => currentUser.data.privileges.includes(role));

duck.action.loggedIn = {
  reducer: (currentUser, payload) => ({
    ...currentUser,
    meta: {
      ...currentUser.meta,
      loggedIn: payload,
    }
  })
};

duck.thunk.get = function(credentials){
  return (dispatch, getState) => {
    // dispatch(duck.action.replace({}));
    this.api.get({
      urlParams: {endpoint: 'me'},
      success: res => {
        dispatch(duck.action.replace(res.data));
        res.data.company && dispatch(companiesDuck.action.updateOne(res.data.company));
      },
      error: () => dispatch(duck.action.replace({login: null})),
    });
  }
};

duck.thunk.login = function(credentials){
  return (dispatch, getState) => {
    // dispatch(duck.action.replace({login: null}));

    this.api.login({
      credentials,
      onAdded: resData => dispatch(duck.action.replace(resData)),
    });
  }
};

duck.thunk.logout = function(){
  return (dispatch, getState) => {
    this.api.logout({
      onAdded: resData => dispatch({type: 'RESET'}),
    });
  }
};

duck.api.login = function({credentials, onAdded, ...options} = {urlParams: {}}){ 
  PostOffice.add({
    url: this.apiUrlCreator({endpoint: 'login'}),
    model: this.name, 
    data: credentials,
    onAdded: onAdded || true,
    ...options,
  });
}

duck.api.logout = function({onAdded, ...options} = {urlParams: {}}){ 
  PostOffice.add({
    url: this.apiUrlCreator({endpoint: 'logout'}),
    model: this.name, 
    data: {id: 'me'},
    onAdded: onAdded || true,
    ...options,
  });
}

duck.api.contact = function({data, onAdded, ...options} = {urlParams: {}}){ 
  PostOffice.add({
    url: '/contact',
    model: this.name, 
    data: {id: 'contact', ...data},
    onAdded: onAdded || true,
    ...options,
  });
}



