import axios from 'axios';
import config from 'config';

export const keyDict = {
  cases: {
    name: 'name',
    ownerUserId: 'ownerUserId',
    ownerCompanyId: 'ownerCompanyId',
    agreementNo: 'agreementNo',
    person: 'person',
    signatureDate: 'signatureDate',
    installmentAmount: 'installmentAmount',
    installmentType: 'installmentType',
    paymentDayNoNo: 'paymentDayNo',
    optionalNotes: 'optionalNotes',
  },
};

export const translateKeys = (dataObj, dict) => ({
  ...Object.fromEntries(Object.entries(dataObj).map(([apiKey, val]) => [(dict[apiKey] || apiKey), val]))
});

export const translateKeysForAPI = (dataObj, dict) => ({
  ...Object.fromEntries(Object.entries(dataObj).map(([key, val]) => [(Object.keys(dict).find(apiKey => dict[apiKey] === key) || key), val]))
});

const allowed401Paths = /^\/(login|forgot-password|register|user-activation)/;

const backend = async (options) => {
  try {
    let res = await axios.request({
      method: options.method || 'get', 
      url: config.apiUrl + options.url,
      data: options.data ? options.data : undefined,
      withCredentials: true,
      ...options.axiosParams,
    });
    typeof options.transform === 'function' && (res.data = options.transform(res.data, options));
    typeof options.success === 'function' && options.success(res);
  } catch (err) {
    if(err.response && err.response.status === 401 && !allowed401Paths.test(window.location.pathname)){
      window.location.href = '/login';
      return;
    } 
    if(typeof options.error === 'function'){
      options.error(err);
    } else if(options.error && options.error.constructor.name === 'Object'){
      if(err.response && typeof options.error[err.response.status] === 'function') {
        options.error[err.response.status](err);
      } else if(typeof options.error.default === 'function'){
        options.error.default(err);
      }
    }
  }
  typeof options.always === 'function' && options.always();
};

export default backend;