// import Axios from 'axios';
import {RecordLibraryDuck} from 'redux/lib/Duck';
import {fieldValues} from 'dict';

export const receiptsDuck = new RecordLibraryDuck('receipts', ({companyId, id, year, month}) => {
  const url =
    typeof companyId === "undefined"
      ? `/companies/${companyId}/receipts/${id}`
      : `/companies/receipts/${id}`;

  const params = Object.entries({ year, month }).filter(([, v]) => v);
  const query = new URLSearchParams(params).toString();

  return `${url}${query ? `?${query}` : ''}`;
});
const duck = receiptsDuck;

duck.normalize = (singleInvoice, {companies}) => ({ 
  ...singleInvoice, 
    month: singleInvoice.periodFrom.replace(/-\d+$/, ''),
    paymentStatusName: fieldValues.receipts.paymentStatus[singleInvoice.paymentStatus],
    issueStatusName: fieldValues.receipts.issueStatus[singleInvoice.issueStatus],
    ...(
      companies.data
        .filter(company => company.id === singleInvoice.companyId)
        .map(row => ({
          companyName: row.name,
          companyId: row.id,
          companyLogo: row.logoUrl,
        }))
        [0]
    ),
});

duck.get.issued = () => ({ receipts, ...rest }) => (
  receipts.data
    .filter(singleInvoice => singleInvoice.issueStatus === 'issued') 
    .map(singleInvoice => duck.normalize(singleInvoice, rest))
);

duck.get.unissued = () => ({ receipts, ...rest }) => (
  receipts.data
    .filter(singleInvoice => singleInvoice.issueStatus === 'unissued')
    .map(singleInvoice => duck.normalize(singleInvoice, rest))
);

duck.get.unpaid = () => ({ receipts, ...rest }) => (
  receipts.data
    .filter(singleInvoice => singleInvoice.issueStatus === 'issued' && singleInvoice.paymentStatus !== 'paid')
    .map(singleInvoice => duck.normalize(singleInvoice, rest))
);

duck.thunk.setIssued = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, issueStatus: 'issued'}));
  }
);

duck.thunk.setPaid = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, paymentStatus: 'paid'}));
  }
);
