import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {iborsDuck} from 'redux/ducks/dictionaries';

import Table from 'components/basic/Table';
import styles from './DictionaryIbors.style';
import { dates } from 'dict';
import shortid from 'shortid';

const columns = [
  { field: 'date', title: 'Data', 
    filtering: true, searchable: true,
  },
  { field: 'value', title: 'Wartość', 
    filtering: true, searchable: true,
  },
  { field: 'type', title: 'Typ', 
    filtering: true, searchable: true,
  },
];

export default function DictionaryIbors({active, year}) {
  const classes = styles();
  const dispatch = useDispatch();

  const filters = {
    date: dates.relative.givenYear(year),
  };

  const dictionaryData = useSelector(iborsDuck.get.filtered(filters)).sort((a, b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1);
  
  useEffect(() => {
    active && year && dispatch(iborsDuck.thunk.getAll({
      urlParams: {
        from: filters.date.start.toISOString(), 
        ...(year === dates.values.thisYear ? {} : {to: filters.date.end.toISOString()}),
      }
    }));
  }, [active, year]);

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(iborsDuck.thunk.fieldSave({id: `new-${shortid.generate()}`, data: newData}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(iborsDuck.thunk.fieldSave({data: newData}));
            resolve();
          }),
        }}
      />
    </div>
  );
}