import React, { useState } from 'react';

import {
  Box,
  Grid
} from '@material-ui/core';
import { 
  SaveOutlined,
  SendOutlined,
  BlockOutlined
 } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import Form from 'components/basic/Form';
import UserCard from 'components/basic/UserCard';
import ButtonBack from 'components/basic/ButtonBack';
import AnalysisSettings from 'components/feature/AnalysisSettings';

import styles from './CompanyEdit.style';
import { useSelector, useDispatch } from 'react-redux';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { companiesDuck } from 'redux/ducks/companies';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { fieldValues } from 'dict';
import saveCompanyDetails from 'utils/saveCompanyDetails';
import getFeatsFromDict from 'utils/feats/getFeatsFromDict';

export default function EditCompany() {
  const classes = styles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { companyId: urlId } = useParams();

  const currentCompanyDetails = useSelector(currentUserDuck.get.company()); 
  const editedCompanyId = urlId || currentCompanyDetails.id;

  const company = useSelector(companiesDuck.get.byId(parseInt(editedCompanyId))) || {};

  const selfEdit = company.id === currentCompanyDetails.id;

  const [contactFormDisabled, setContactFormDisabled] = useState(false);
  const [infoFormDisabled, setInfoFormDisabled] = useState(false);
  const [paymentFormDisabled, setPaymentFormDisabled] = useState(false);
  const [analysisSettingsFormDisabled, setAnalysisSettingsFormDisabled] = useState(false);

  console.log(company);
  
  return (
    <div className={classes.root}>
      {!selfEdit &&
        <ButtonBack target='/companies' text='Powrót do listy klientów' />
      }
      {company.id && <>
        <PageTitle 
          title='Edytuj dane kancelarii' 
          buttons={!selfEdit && [
            ...(company.status === 'deactivated' ? [
              { label: 'Aktywuj kancelarię', color: 'primary', icon: BlockOutlined, 
                onClick: () => dispatch(companiesDuck.thunk.fieldSaveSync({
                  data: {id: company.id, status: 'active'},
                  callback: () => enqueueSnackbar('Kancelaria została aktywowana', {variant: 'success'}),
                })),
              },
            ] : company.status !== 'erased' ? [
              { label: 'Dezaktywuj kancelarię', color: 'primary', icon: BlockOutlined, 
                onClick: () => dispatch(companiesDuck.thunk.fieldSaveSync({
                  data: {id: company.id, status: 'deactivated'},
                  callback: () => enqueueSnackbar('Kancelaria została dezaktywowana', {variant: 'success'}),
                })),
              },
            ] : []),
          ]} 
        /> 
        <Grid container spacing={4} justify='space-between'>
          <Grid item xs={12} lg={4}>
            <UserCard user={company} />
            <Form 
              variant='last'
              title='Dane kontaktowe'
              disabled={contactFormDisabled}
              fields={[
                { label: 'Telefon', name: 'phone', required: true, defaultValue: company.phone },
                { label: 'E-mail firmowy', name: 'contactEmail', type: 'email', required: true, defaultValue: company.contactEmail },
                { label: 'E-mail do monitów', name: 'alertsEmail', type: 'email', defaultValue: company.alertsEmail },
              ]} 
              buttons={[
                { label: 'Zapisz zmiany', icon: SaveOutlined, type: 'submit'},
              ]}
              onSubmit={values => saveCompanyDetails({values, company, dispatch, init: () => {
                setContactFormDisabled(true);
              }, callback: success => {
                success && enqueueSnackbar('Zmiany zostały zapisane', {variant: 'success'});
                setContactFormDisabled(false);          
              }})}
          />                
          </Grid>
          <Grid item xs={12} lg={8}>
          <Form 
                title='Dane do faktury'
                disabled={infoFormDisabled}
                fields={[
                  { label: 'Nazwa firmy', name: 'name', required: true, defaultValue: company.name},
                  { half: true, label: 'NIP', name: 'taxNumber', required: true, defaultValue: company.taxNumber},
                  { half: true, label: 'Numer konta', name: 'bankAccountNumber', defaultValue: company.bankAccountNumber},
                  { half: true, label: 'Ulica', name: 'addressStreetName', required: true, defaultValue: company.addressStreetName},
                  { half: true, label: 'Numer domu/lokalu', name: 'addressBuildingNo', required: true, defaultValue: company.addressBuildingNo},
                  { half: true, label: 'Kod pocztowy', name: 'addressPostalCode', required: true, defaultValue: company.addressPostalCode},
                  { half: true, label: 'Miasto', name: 'addressCity', required: true, defaultValue: company.addressCity},
                ]} 
                buttons={[
                  { label: 'Zapisz zmiany', icon: SaveOutlined, type: 'submit'},
                ]}
                onSubmit={values => saveCompanyDetails({values, company, dispatch, init: () => {
                  setInfoFormDisabled(true);
                }, callback: success => {
                  success && enqueueSnackbar('Zmiany zostały zapisane', {variant: 'success'});
                  setInfoFormDisabled(false);          
                }})}
              />

          <Grid item xs={12} lg={12}>
            <Box mt={4}>
              <AnalysisSettings
                title="Domyślne ustawienia stosowanych analiz"
                feats={company.featsList}
                disabled={analysisSettingsFormDisabled}
                onSubmit={(values) => {
                  saveCompanyDetails({
                    values: { feats: values.join(',') },
                    company,
                    dispatch,
                    init: () => {
                      setAnalysisSettingsFormDisabled(true);
                    },
                    callback: (success) => {
                      success &&
                        enqueueSnackbar("Zmiany zostały zapisane", {
                          variant: "success",
                        });
                      setAnalysisSettingsFormDisabled(false);
                    },
                  })
                }}
              />
            </Box>
          </Grid>
              
            {!selfEdit && (
              <Form 
                variant='last'
                title='Płatności'
                disabled={paymentFormDisabled}
                fields={[
                  { label: 'Sposób płatności', name: 'paymentMethod', required: true, defaultValue: company.paymentMethod, 
                    options: fieldValues.companies.paymentMethods,
                  },
                  { half: true, label: 'Cena za raport', name: 'reportPrice', type: 'number', required: true, defaultValue: company.reportPrice},
                  { half: true, label: 'Cena za przekazanie umowy', name: 'assistancePrice', type: 'number', required: true, defaultValue: company.assistancePrice},
                  { half: true, label: 'Opłaty za dostęp', name: 'priceForAccess', type: 'number', required: true, defaultValue: company.priceForAccess},
                  { half: true, label: 'Liczba dostępów', name: 'maxAccounts', type: 'number', required: true, defaultValue: company.maxAccounts},
                ]} 
                buttons={[
                  { label: 'Zapisz zmiany', icon: SaveOutlined, type: 'submit'},
                ]}
                onSubmit={values => saveCompanyDetails({values, company, dispatch, init: () => {
                  setPaymentFormDisabled(true);
                }, callback: success => {
                  success && enqueueSnackbar('Zmiany zostały zapisane', {variant: 'success'});
                  setPaymentFormDisabled(false);          
                }})}
              />   
            )}

            {!selfEdit && (
              <Form 
                variant='last'
                title='Kalkulator'
                disabled={contactFormDisabled}
                fields={[
                  { label: 'Dostęp do kalkulatora', name: 'isCalculatorOn', type: 'checkbox', required: true, checked: company.isCalculatorOn},
                ]} 
                buttons={[
                  { label: 'Zapisz zmiany', icon: SaveOutlined, type: 'submit'},
                ]}
                onSubmit={values => saveCompanyDetails({values, company, dispatch, init: () => {
                  setContactFormDisabled(true);
                }, callback: success => {
                  success && enqueueSnackbar('Zmiany zostały zapisane', {variant: 'success'});
                  setContactFormDisabled(false);          
                }})}
              />
            )}
          </Grid>
        </Grid>
      </>}
    </div>
  );
}
