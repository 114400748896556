import React, { useEffect, useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import PageTitle from "components/basic/PageTitle";
import CasesList from "components/feature/CasesList";

import styles from "./Cases.style";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { usersDuck } from "redux/ducks/users";
import { currentUserDuck } from "redux/ducks/currentUser";
import ButtonBack from "components/basic/ButtonBack";
import { casesDuck } from "redux/ducks/cases";
import { getIsCaseFromCalculationWidget } from "utils/getIsCaseFromCalculationWidget";
import { getFormattedCaseAgreementNo } from "utils/getFormattedCaseAgreementNo";

export default function Cases() {
  const classes = styles();
  const history = useHistory();
  const { userId: urlUserId } = useParams();
  const userData = useSelector(usersDuck.get.byId(urlUserId));
  const currentUser = useSelector(currentUserDuck.get.data());
  const cases = useSelector(casesDuck.get.all());
  const casesNotFromWidget = useMemo(
    () =>
      cases
        .filter((c) => !getIsCaseFromCalculationWidget(c, true))
        .map((c) => getFormattedCaseAgreementNo(c)),
    [cases]
  );

  useEffect(() => {
    if (
      history.location.pathname === "/cases" &&
      currentUser &&
      currentUser.roleName === "regular"
    ) {
      history.replace("/");
    }
  }, [currentUser, history]);

  return (
    <div className={classes.root}>
      {userData && userData.fullName && (
        <ButtonBack target="/users" text="Powrót do listy użytkowników" />
      )}
      <PageTitle
        title={
          userData && userData.fullName
            ? `${userData.fullName} – lista umów`
            : "Lista umów"
        }
        hintName="W tym widoku znajdziesz wszystkie umowy, do których masz dostęp" //'cases/pageTitle'
        buttons={[
          {
            label: "Dodaj nową umowę",
            color: "secondary",
            target: "/cases/new",
            icon: Add,
          },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <CasesList cases={casesNotFromWidget} />
        </Grid>
      </Grid>
    </div>
  );
}
