import React from 'react';

import {
  Grid,
} from '@material-ui/core';
import { 
  AccountBalanceOutlined,
  MonetizationOnOutlined,
  EventBusyOutlined,
  ShowChartOutlined
 } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import TabsWithPanels from 'components/basic/TabsWithPanels';
import Dropdown from 'components/basic/Dropdown';
import ButtonWrapper from 'components/basic/ButtonWrapper';

import DictionaryBanks from 'components/feature/DictionaryBanks';
import DictionaryExchangeRates from 'components/feature/DictionaryExchangeRates';
import DictionaryHolidays from 'components/feature/DictionaryHolidays';
import DictionaryIbors from 'components/feature/DictionaryIbors';

import styles from './Dictionaries.style';
import { dates, fieldValues } from 'dict';

const tabs = [
  { id: 'banks', label: 'Banki', addLabel: 'Dodaj nowy bank',
    icon: AccountBalanceOutlined,
    panel: DictionaryBanks,
  },
  { id: 'exchangeRates', label: 'Kursy', addLabel: 'Dodaj nowy kurs',
    icon: MonetizationOnOutlined,
    panel: DictionaryExchangeRates,
  },
  { id: 'holidays', label: 'Święta', addLabel: 'Dodaj nowe święto',
    icon: EventBusyOutlined,
    panel: DictionaryHolidays,
  },
  { id: 'ibors', label: 'Ibory', addLabel: 'Dodaj nowy ibor',
    icon: ShowChartOutlined,
    panel: DictionaryIbors,
  },
];

export default function Dictionaries() {
  const classes = styles();

  const [filterYear, setFilterYear] = React.useState(dates.values.thisYear);
  const [filterCurrency, setFilterCurrency] = React.useState('CHF');
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <div className={classes.root}>
      <PageTitle title='Słowniki' /> 
      <Grid container>
        <TabsWithPanels
          variant='small'
          tabs={tabs}
          activeTab={activeTab}
          onChange={tab => setActiveTab(tab)}
          panelProps={tabIndex => [1, 2, 3].includes(tabIndex) && {
            year: filterYear, 
            ...(tabIndex !== 1 ? {} : {currency: filterCurrency})
          }}
          actions={activeTab => [1, 2, 3].includes(activeTab) && (
            <ButtonWrapper style={{flex: '1'}}>
              <Dropdown
                variant='label'
                title='Filtruj:'
                value={filterYear}
                handleChange={value => setFilterYear(value)}
                options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(i => ({value: dates.values.thisYear - i, label: dates.values.thisYear - i}))}
              /> 
              {activeTab === 1 && (
                <Dropdown
                  value={filterCurrency}
                  handleChange={value => setFilterCurrency(value)}
                  options={Object.entries(fieldValues.cases.currency).map(([value, label]) => ({value, label}))}
                /> 
              )}
            </ButtonWrapper>
          )}
        />
      </Grid>   
    </div>
  );
}
