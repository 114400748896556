import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import {
  Box,
  Typography,
  Button,
  Grid
} from '@material-ui/core';

import Copyright from 'components/basic/Copyright';
import LargeLogo from 'components/basic/LargeLogo';

import styles from './AuthForm.style';

export const AuthForm = ({title, description, buttons = [], onClick, children}) => {
  const classes = styles();
  const history = useHistory();

  return (
    <Box className={classes.outerWrapper}>
      <Box className={classes.innerWrapper}>
        <Grid container>
          <Grid item xs={12}>
            <LargeLogo/>
            <Typography component='h1' variant='h5' className={classes.title}>{title}</Typography>
            {description &&
              <Typography component='p' variant='body2' className={classes.description}>
                {description}
              </Typography>
            }
            <Box className={classes.form}>
              {children}
            </Box>
          </Grid>
        </Grid>
        {buttons.length > 0 && (
        <Grid container>
          <Grid item container justify='space-between' xs={12}>
            {buttons.map(button => (
              <Button
                key={button.label}
                type={button.type || 'button'}
                variant='text'
                disableElevation
                startIcon={button.icon && <button.icon />}
                component={Link}
                {...(onClick ? {onClick: button.onClick} : (button.target ? {to: button.target} : {onClick: () => history.goBack()}))}
              >
                {button.label}
              </Button>
            ))}
          </Grid>
        </Grid>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Copyright mt={8} pr={3} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

AuthForm.propTypes = {
  children: PropTypes.node,
};

export default AuthForm;
