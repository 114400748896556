import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {exchangeRatesDuck} from 'redux/ducks/dictionaries';

import Table from 'components/basic/Table';
import styles from './DictionaryExchangeRates.style';
import { dates, fieldValues } from 'dict';
import shortid from 'shortid';

const columns = [
  { field: 'currency',  title: 'Waluta', 
    filtering: true, searchable: true,
    lookup: fieldValues.cases.currency,
  },
  { field: 'date',  title: 'Data', type: 'date',
    filtering: true, searchable: true,
  },
  { field: 'value',  title: 'Kurs', type: 'numeric', decimalDigits: 7,
    filtering: true, searchable: true,
  },
  { field: 'type',  title: 'Typ kursu', 
    filtering: true, searchable: true,
    lookup: fieldValues.exchangeRates.type,
  },
  { field: 'source',  title: 'Źródło', 
    filtering: true, searchable: true,
    lookup: fieldValues.exchangeRates.source,
  },
];

export default function DictionaryExchangeRates({active, year, currency}) {
  const classes = styles();
  const dispatch = useDispatch();

  const filters = {
    date: dates.relative.givenYear(year),
    currency,
  };
  
  const dictionaryData = useSelector(exchangeRatesDuck.get.filtered(filters)).sort((a, b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1);

  useEffect(() => {
    active && year && dispatch(exchangeRatesDuck.thunk.getAll({
      urlParams: {
        from: filters.date.start.toISOString(), 
        ...(year === dates.values.thisYear ? {} : {to: filters.date.end.toISOString()}),
        currency,
      }
    }));
  }, [active, year, currency]);

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(exchangeRatesDuck.thunk.fieldSave({id: `new-${shortid.generate()}`, data: newData}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(exchangeRatesDuck.thunk.fieldSave({data: newData}));
            resolve();
          }),
        }}
      />
    </div>
  );
}