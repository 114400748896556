import React, { useMemo } from "react";
import { SaveOutlined } from "@material-ui/icons";
import Form from "components/basic/Form";
import getFeatsFromDict from "utils/feats/getFeatsFromDict";
import getDictFromFeats from "utils/feats/getDictFromFeats";
import { feats, featsToDescriptions } from "utils/feats/feats";

const AnalysisSettings = ({
  children,
  feats = [],
  buttons = [{ label: "Zapisz zmiany", icon: SaveOutlined, type: "submit" }],
  onSubmit = (values) => {
    console.log(values);
  },
  ...props
}) => {
  const featsDict = useMemo(() => getDictFromFeats(feats), [feats]);
  const formFields = useMemo(() => getFields(featsDict), [featsDict]);

  return (
    <Form
      {...props}
      fields={formFields}
      buttons={buttons}
      onSubmit={(values) => {
        onSubmit(getFeatsFromDict(values));
      }}
    />
  );
};

const getFields = (featsDict) =>
  Object.keys(feats).map((feat) => ({
    type: "checkbox",
    required: true,
    name: feat,
    label: featsToDescriptions[feat],
    checked: Boolean(featsDict[feat]),
  }));

export default AnalysisSettings;
