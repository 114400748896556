import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import MaterialTable, { MTableEditField, MTableEditRow } from 'material-table';
import TextField from "@material-ui/core/TextField";

import icons from 'utils/mt-icons';

import styles, { GlobalCss } from './Table.style';
import { DateInput } from './DateInput';
import PopupDialog from 'components/basic/PopupDialog';
import SmartSelect from 'components/basic/SmartSelect';

const EditComponentNumeric = (props) => (
  <TextField
    {...props}
    value={props.value}
    onChange={(e) => {
      e.persist();
      let newVal = e.target.value;

      if (newVal !== null) {
        newVal = e.target.value.replace(/\s/g, "");
        newVal = newVal.replace(/,/g, ".");
      }

      props.onChange(newVal);
    }}
    onBlur={(e) => {
      e.persist();
      let newVal = e.target.value;

      if (newVal !== null) {
        newVal = e.target.value.replace(/\s/g, "");
        newVal = newVal.replace(/,/g, ".");
      }

      newVal = parseFloat(newVal);

      props.onChange(isNaN(newVal) ? null : newVal);
    }}
  />
);

export const Table = ({
  children,
  columns,
  className, tableClassName,
  options,
  ...props
}) => {
  const classes = styles();

  return (
    <div className={clsx(classes.root, className)}>
      <GlobalCss />
      <MaterialTable
        title=""
        className={clsx(tableClassName)}
        icons={icons}
        components={{
          EditFieldSelect: SmartSelect,
        }}
        columns={[
          ...columns.map((col) => {
            if (col.type === "percentage")
              return {
                width: 100,
                ...col,
                type: "numeric",
                numberType: "percentage",
                cellStyle: { textAlign: "right", ...col.cellStyle },
              };
            if (col.type === "numeric")
              return {
                width: 100,
                ...col,
                cellStyle: { textAlign: "right", ...col.cellStyle },
                editComponent: EditComponentNumeric,
              };
            if (col.type === "date")
              return {
                width: 130,
                editWidth: 130,
                ...col,
                cellStyle: { textAlign: "right", ...col.cellStyle },
                editComponent: DateInput,
              };
            if (col.type === "datetime")
              return {
                width: 150,
                ...col,
                cellStyle: { textAlign: "right", ...col.cellStyle },
              };
            return {
              ...col,
              cellStyle: { textAlign: "left", ...col.cellStyle },
            };
          }),
        ]}
        options={{
          search: false,
          filtering: true,
          actionsColumnIndex: -1,
          paging: false,
          draggable: false,
          internalActionsColumnIndex: -1,
          headerStyle: { width: "auto", textAlign: "left" },
          DialogComponent: PopupDialog,
          bottomToolbar:
            (props.editable && props.editable.onRowAdd) ||
            (props.actions &&
              props.actions.some(
                (a) =>
                  a.position === "toolbar" || a.position === "toolbar-bottom"
              )),
          showTextRowsSelected: false,
          ...options,
        }}
        localization={{
          addTooltip: "Dodaj wiersz",
          pagination: {
            labelDisplayedRows: "{from}-{to} z {count}",
            labelRowsSelect: "na stronie",
          },
          toolbar: {
            nRowsSelected: "{0} zaznaczonych",
            searchTooltip: "Szukaj",
            searchPlaceholder: "Szukaj",
          },
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: "Brak wyników do wyświetlenia",
            addTooltip: "Dodaj",
            editTooltip: "Edytuj",
            deleteTooltip: "Usuń",
            editRow: {
              boolTrue: "TAK",
              boolFalse: "nie",
              saveTooltip: "Zapisz",
              cancelTooltip: "Anuluj zmiany",
              deleteText: "Czy na pewno chcesz usunąć wiersz?",
            },
            filterRow: {
              filterTooltip: "Filtruj",
              range: {
                empty: "",
                startOnly: "od\u00a0",
                endOnly: "do\u00a0",
                suffixSingle: "",
                startBoth: "",
                endBoth: "\u00a0–\u00a0",
                suffixBoth: "",
              },
              ranges: {
                // numeric: {
                //   empty: 'Filter numbers...',
                //   startOnly: 'from ',
                //   endOnly: 'to ',
                //   suffixSingle: ' value',
                //   startBoth: 'between ',
                //   endBoth: ' and ',
                //   suffixBoth: ' values',
                // },
                // date: {
                //   empty: 'Filter date...',
                //   startOnly: 'from ',
                //   endOnly: 'to ',
                //   suffixSingle: ' value',
                //   startBoth: 'between ',
                //   endBoth: ' and ',
                //   suffixBoth: ' values',
                // },
                // datetime: {
                //   empty: 'Filter datetime...',
                //   startOnly: 'from ',
                //   endOnly: 'to ',
                //   suffixSingle: ' value',
                //   startBoth: 'between ',
                //   endBoth: ' and ',
                //   suffixBoth: ' values',
                // },
              },
            },
          },
        }}
        {...props}
      />
    </div>
  );
}

Table.propTypes = {
  children: PropTypes.node,
};

export default Table;
