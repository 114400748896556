export const feats = {
  omitLoanedCosts: "omitLoanedCosts",
  extractOverpaymentOverLoanPlnPeriod: "extractOverpaymentOverLoanPlnPeriod",
  includeOverpaymentOverLoanChfPeriod: "includeOverpaymentOverLoanChfPeriod",
};

export const featsToDescriptions = {
  omitLoanedCosts:
    "Pomiń koszty kredytowane w raporcie w module nieważności umowy",
  extractOverpaymentOverLoanPlnPeriod:
    "Wyodrębnij nadpłatę w okresie spłaty kredytu w PLN",
  includeOverpaymentOverLoanChfPeriod:
    "Podaj nadpłatę dokonaną w okresie spłaty kredytu w CHF w tej walucie",
};
