import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  cardContent: {
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  }
}), {
  name: 'KnowledgeBaseArticle', 
  index: 1,
});


