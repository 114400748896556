import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';

import {
  Grid,
  Chip,
  Modal
} from '@material-ui/core';
import { 
  Filter1Outlined,
  PersonOutlineOutlined,
  AccountBalanceOutlined,
  MonetizationOnOutlined,
  TableChartOutlined,
  Filter2Outlined,
  Filter3Outlined,
  CloudDownloadOutlined,
  PrintOutlined,
  RefreshOutlined
 } from '@material-ui/icons';
 
import { useSnackbar } from 'notistack';
import shortid from 'shortid';
import printJS from 'print-js';

import backend from 'redux/lib/backend';
import { reportsDuck } from 'redux/ducks/reports';
import {casesDuck} from 'redux/ducks/cases';

import PageTitle from 'components/basic/PageTitle';
import ButtonBack from 'components/basic/ButtonBack';
import ButtonWrapper from 'components/basic/ButtonWrapper';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

import ReportsList from 'components/feature/ReportsList';

import styles from './Reports.style';
//import { formatCurrency } from 'utils/formatHelpers';

export default function Reports() {
  const classes = styles();

  const dispatch = useDispatch();
  const { id: caseId } = useParams();
  const caseDetails = useSelector(casesDuck.get.byId(caseId));
  const reports = useSelector(reportsDuck.get.byCaseId(caseId));
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [fetched, setFetched] = useState(false);
  const [openEstimatedReportModal, setOpenEstimatedReportModal] = React.useState(false);
  const [openGeneratedReportModal, setOpenGeneratedReportModal] = React.useState(false);


  const handleGeneratedReportModalClose = () => setOpenGeneratedReportModal(false);
  const handleEstimatedReportModalOpen = () => setOpenEstimatedReportModal(true);
  const handleEstimatedReportModalClose = () => setOpenEstimatedReportModal(false);

  const checkReport = (data) => {
    if(data.processingStatus === 'pending'){
      setTimeout(() => {
        reportsDuck.api.getOne({
          urlParams: {caseId, id: data.id},
          callback: resData => {
            checkReport(resData);
          },
        });
      }, 1000);
    } else if(data.processingStatus === 'complete'){
      dispatch(reportsDuck.thunk.fieldUpdate({data: data}));
      openEstimatedReportModal && setOpenEstimatedReportModal(false);
      setOpenGeneratedReportModal(data.url);
    } else {
      openEstimatedReportModal && setOpenEstimatedReportModal(true);
      enqueueSnackbar('Wystąpił błąd generowania raportu. Spróbuj ponownie lub skontaktuj się ze wsparciem klienta.');
      console.error('Error details:', data);
    }
  };
  
  const generateReport = (version, values) => {
    openEstimatedReportModal && setOpenEstimatedReportModal('sending');

    reportsDuck.api.addOne({
      urlParams: {caseId}, 
      data: {version, modules: values, id: 'new-'+shortid.generate()},
      onAdded: (resData) => checkReport(resData),
    });
  };

  useEffect(() => {
    if(!fetched && caseDetails && typeof caseDetails.id !== 'undefined'){
      dispatch(reportsDuck.thunk.getAll({urlParams: {caseId: caseDetails.id}}));
      setFetched(true);
    }
  }, [caseDetails]);

  if(!caseId || !caseDetails){
    return <>...</>
  }

  return (
    <div className={classes.root}>
      <Modal
        open={openGeneratedReportModal}
        onClose={handleGeneratedReportModalClose}
      >
        <ModalWrapper 
          title='Twój raport jest gotowy!'
          description={<>Raport został wygenerowany poprawnie. Znajdziesz go na <Link to={`/cases/${caseId}/reports`}>liście raportów</Link> tej umowy. Możesz go również pobrać lub wyświetlić, używając przycisków poniżej.</>} 
          buttons={[
            { label: 'Zamknij', color: 'primary', onClick: handleGeneratedReportModalClose},
            { label: 'Pobierz', color: 'secondary', icon: CloudDownloadOutlined, onClick: () => window.open(openGeneratedReportModal, '_blank')},
            { label: 'Drukuj', color: 'secondary', icon: PrintOutlined, onClick: () => {
              let snackKey;
              
              backend({
                axiosParams: {
                  url: openGeneratedReportModal,
                  responseType: 'arraybuffer',
                },
                error: (err) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
        
                  console.log('err:', err)
                },
                success: async (res) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});

                  const pdfFile = new Blob([res.data], {
                    type: "application/pdf"
                  });
                  const pdfUrl = URL.createObjectURL(pdfFile);

                  printJS({
                    printable: pdfUrl, 
                    onLoadingStart: () => snackKey = enqueueSnackbar('Trwa pobieranie raportu', {variant: 'info'}),
                    onLoadingEnd: () => {
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});
                    },
                    onError: (error) => {
                      console.log(error);
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
                    },
                    onPrintDialogClose: () => {
                      snackKey && closeSnackbar(snackKey)
                      setTimeout(() => {
                        URL.revokeObjectURL(pdfUrl);
                      }, 100);
                    },
                  });
                },
              });
            }},
          ]}     
        >
        </ModalWrapper>
      </Modal>
      <Modal
        open={openEstimatedReportModal}
        onClose={() => setOpenEstimatedReportModal(false)}
      >
        <ModalWrapper 
          variant='small'
          title='Wygeneruj raport szacunkowy'  
        >
          <Form 
            variant='small'
            fields={[
              { label: 'Opcjonalny opis', multiline: true, rows: 4},
            ]} 
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => setOpenEstimatedReportModal(false)},
              { label: 'Generuj raport', color: 'secondary', icon: Filter1Outlined, type: 'submit'},
            ]}
            onSubmit={values => {
              generateReport('estimation', values);
            }}
          />
        </ModalWrapper>
      </Modal>
      <ButtonBack target='/cases' text='Powrót do listy umów' />
      <PageTitle 
        title={`Umowa nr ${caseDetails.agreementNo} z dnia ${caseDetails.preparationDate}`}
        hintName='cases/reports/pageTitle'
        buttons={[
          { disabled: false, label: 'Wygeneruj raport szacunkowy', color: 'secondary', icon: Filter1Outlined, onClick: () => setOpenEstimatedReportModal(true) },
          { label: 'Przejdź do widoku rozszerzonego', color: 'primary', icon: TableChartOutlined, newTab: true, target: history.location.pathname.replace(/\/[^/]*$/, '/full')},
        ]} 
      />  
      <Grid container>
        <Grid item xs={12}>
          <ButtonWrapper mb={4}>
            <Chip icon={<PersonOutlineOutlined />} label={caseDetails.person} />
            <Chip icon={<AccountBalanceOutlined />} label={caseDetails.bankName} />
            <Chip icon={<MonetizationOnOutlined />} label={caseDetails.currency} />
          </ButtonWrapper>    
        </Grid>
        <Grid item xs={12}>
          <ReportsList reports={reports} caseId={caseId} />
        </Grid>
      </Grid>  
    </div>
  );
}
