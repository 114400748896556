import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './ContactSubjectList.style';
import {phrasesDuck} from 'redux/ducks/dictionaries';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/basic/Table';
import shortid from 'shortid';
import {fieldValues, defaultPhrases} from 'dict';

const columns = [
  { field: 'active', title: 'Aktywny', type: 'boolean', width: 75 },
  { field: 'title', title: 'Identyfikator', initialEditValue: () => `new-${shortid.generate()}`, width: 150 },
  { field: 'content', title: 'Treść tytułu' },
  { field: 'defaultContent', title: 'Treść domyślna', width: 300, editable: 'never' },
];

export const ContactSubjectList = ({active}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const apiData = useSelector(phrasesDuck.get.filtered({type: 'contact-subject'}));
  const dictionaryData = [
    ...apiData.map(row => ({...row, defaultContent: defaultPhrases['contact-subject'][row.title]})),
    ...Object.entries(defaultPhrases['contact-subject']).map(([key, value]) => (!apiData.some(row => row.title === key) && {title: key, defaultContent: value, active: true, id: `new-${shortid.generate()}`})).filter(Boolean),
  ];

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if(!fetched && active && (!apiData || !apiData.length)){
      setFetched(true);
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-subject', active: 'all'}}));
    }
  }, [active, apiData])

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.fieldSave({data: phrasesDuck.prepareData({...newData, type: 'contact-subject', id: `new-${shortid.generate()}`})}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.fieldSave({data: phrasesDuck.prepareData({...newData, type: 'contact-subject'})}));
            resolve();
          }),
        }}
      />
    </div>
  );
}

ContactSubjectList.propTypes = {
  children: PropTypes.node,
};

export default ContactSubjectList;
