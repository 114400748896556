import React from 'react'

import backend, { translateKeysForAPI } from 'redux/lib/backend';
import { debounce } from 'debounce';
import store from 'redux/store';
import {casesDuck} from 'redux/ducks/cases';
import { postOfficeDuck } from 'redux/ducks/postOffice';
import { fieldLabels } from 'dict';

export class PostOffice {
  static boxes = {};
  static status = 'empty'; // error, waiting, empty, sending

  static updateStatus(){
    let newStatus = 'empty';
    for(let boxName in this.boxes){
      const box = this.boxes[boxName];
      if(box.status === 'error'){
        newStatus = 'error';
        break;
      } else if(box.status === 'sending'){
        newStatus = 'sending';
      } else if(box.status === 'waiting' && newStatus === 'empty'){
        newStatus = 'waiting';
      } 
    }
    this.status = newStatus;
    // console.log('*** POST-OFFICE', this.status, '***');
    store.dispatch(postOfficeDuck.action.updateStatus(this.status));
  }

  static add({model, data, url, dict, immediate, onAdded, onError}){
    PostOffice.boxes[`${model}/${data.id}`] = new PostBox({model, data, url, dict, immediate, onAdded, onError});
  }

  static update({model, data, url, dict, immediate, onUpdated, onError}){
    const boxName = `${model}/${data.id}`;
    if(PostOffice.boxes[boxName]){
      PostOffice.boxes[boxName].addToQueue({data, url, immediate, onUpdated, onError});
    } else {
      PostOffice.boxes[`${model}/${data.id}`] = new PostBox({model, data, url, dict, immediate, onUpdated, onError});
    }
  }

  static delete({model, data, url, immediate, onDeleted, onError}){
    PostOffice.boxes[`${model}/${data.id}`] = new PostBox({model, data, url, immediate, onDeleted, onError});
  }

  static updateId({model, oldId, newId}){
    PostOffice.boxes[`${model}/${newId}`] = PostOffice.boxes[`${model}/${oldId}`];
    delete PostOffice.boxes[`${model}/${oldId}`];
  }

  // static deleteBox({model, id}){
  //   delete PostOffice.boxes[`${model}/${id}`];
  // }
}

export class PostBox {
  constructor({model, data, url, dict, immediate, onAdded, onError, onUpdated, onDeleted}){
    this.model = model;
    this.url = url;
    this.history = {
      successes: [], 
      errors: [],
    };
    this.onAdded = onAdded;
    this.onUpdated = onUpdated;
    this.onDeleted = onDeleted;
    this.onError = onError;
    this.dict = dict;
    this.id = data.id;

    this.updateStatus('waiting'); // error, waiting, empty, sending
    this.queue = data;
    this.transit = null;

    this.next(this.onAdded || immediate);
  }
  
  addToQueue({data, url, immediate, onUpdated, onError}){
    onUpdated && (this.onUpdated = onUpdated);
    onError && (this.onError = onError);
    this.url = url;
    this.queue = {...this.queue, ...data};
    this.updateStatus('waiting');
    this.next(immediate);
  }

  sendAdd(){
    const dataToSend = this.prepareData(this.transit, ['id']);

    backend({
      url: this.url, 
      method: 'post', 
      data: dataToSend,
      success: res => this.added(res.data),
      error: err => this.error(err),
    });
  }

  sendUpdate(){
    const dataToSend = this.prepareData(this.transit, ['id', 'updatedAt', 'createdAt', 'caseId']);

    backend({
      url: this.url,//+(typeof this.id === 'undefined' ? '' : `/${this.id}`), 
      method: 'put', 
      data: dataToSend,
      success: res => this.updated(res.data),
      error: err => this.error(err),
    });
  }

  sendDelete(){
    const dataToSend = this.prepareData(this.transit, ['id', 'updatedAt', 'createdAt', 'caseId']);

    backend({
      url: this.url, 
      method: 'delete', 
      data: dataToSend,
      success: res => this.deleted(res.data),
      error: err => this.error(err),
    });
  }

  prepareData(data, excludeKeys){
    if(Array.isArray(data) && data[0].constructor.name === 'Object'){
      return data.map(row => this.prepareDataRow(row, excludeKeys))
    } else if(data && data.constructor.name === 'Object') {
      return this.prepareDataRow(data, excludeKeys);
    } else {
      return data;
    }
    // return this.dict ? translateKeysForAPI(data, this.dict) : data;
  }
  
  prepareDataRow(data, excludeKeys){
    return Object.fromEntries(
      Object.entries(data)
        .map(([key, val]) => excludeKeys.includes(key) ? false : [key, key === 'id' || key.match(/Id$/) ? parseInt(val) : val])
        .filter(Boolean)
    );
  }

  next(force = false){
    // console.log('NEXT', force ? 'forced' : '')
    // debounce(this.nextTry.bind(this), 5000, force)();
    debounce(this.nextTry.bind(this), force ? 50 : 1000)();
    // debounce(this.nextTry.bind(this), force ? 0 : 100)();
  }

  nextTry(){
    // console.log(this.status, !!this.lastError, this.lastError && this.lastError.response.status)

    if(this.status === 'error' && this.lastError){
      return;
    }
    if(this.status === 'sending'){
      this.next();
    } else if(!this.isEmpty(this.transit)){
      this.updateStatus('sending');
      this.onAdded ? this.sendAdd() : this.onDeleted ? this.sendDelete() : this.sendUpdate();
    } else if(!this.isEmpty(this.queue)){
      this.updateStatus('sending');
      this.transit = this.queue;
      this.queue = {};
      this.onAdded ? this.sendAdd() : this.onDeleted ? this.sendDelete() : this.sendUpdate();
    } else if(this.status !== 'empty') {
      this.updateStatus('empty');
    }
  }

  added(data){
    PostOffice.updateId({
      model: this.model, 
      oldId: this.id,
      newId: data.id,
    });
    this.id = data.id;
    typeof this.onAdded === 'function' && this.onAdded(data);
    this.onAdded = false;
    this.success(data);
  }
  
  updated(data){
    typeof this.onUpdated === 'function' && this.onUpdated(data);
    this.success(data);
  }

  deleted(data){
    typeof this.onDeleted === 'function' && this.onDeleted(data);
    this.onDeleted = false;
    this.success(data);
  }

  success(data){
    this.history.successes.push(this.transit);
    this.transit = null;
    this.updateStatus(this.isEmpty(this.queue) ? 'empty' : 'waiting');
    this.next();
    data.showMessage && store.dispatch(postOfficeDuck.action.addMessage({text: (<strong>{data.showMessage}</strong>)}));
  }

  error(err){
    // console.log('PostOffice ERROR', err.response);
    store.dispatch(postOfficeDuck.action.addError({
      text: (<strong>Wystąpił błąd!</strong>),
      details: 
        err && err.response 
        && [
          ...(!err.response.data.showMessage ? [] : [err.response.data.showMessage]),
          ...(!err.response.data.message ? [] : [err.response.data.message]),
          ...(!err.response.data.errors ? [] : err.response.data.errors.map(item => `Pole "${fieldLabels[item.param] || item.param}" nie może mieć wartości "${item.value}". ${item.msg}`)),
        ],
    }));
    typeof this.onError === 'function' && this.onError(err);
    this.lastError = err && err.response && err;
    this.history.errors.push(this.transit);
    this.transit = null;
    this.updateStatus('error');
    this.next(true);
  }

  updateStatus(newStatus){
    this.status = newStatus;
    PostOffice.updateStatus();
  }

  isEmpty(obj){
    return !obj || Object.keys(obj).length === 0;
  }
}