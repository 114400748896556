import React, { useReducer } from 'react';
//import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { 
  CalendarTodayOutlined,
} from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import Dropdown from 'components/basic/Dropdown';
import ButtonWrapper from 'components/basic/ButtonWrapper';
import ButtonBack from 'components/basic/ButtonBack';

import PaymentsListAdmin from 'components/feature/PaymentsListAdmin';

import styles from './PaymentsAdmin.style';
import { dates } from 'dict';
import { useParams } from 'react-router-dom';
import { companiesDuck } from 'redux/ducks/companies';
import { useSelector } from 'react-redux';

export default function PaymentsAdmin() {
  const classes = styles();
  const {companyId} = useParams();

  const company = useSelector(companiesDuck.get.byId(companyId));

  const [period, updatePeriod] = useReducer((s,a)=>({...s,...a}), {year: dates.values.thisYear, month: dates.values.thisMonth});

  return (
    <div className={classes.root}>
      {typeof companyId !== 'undefined' && (
        <ButtonBack target='/companies' text='Powrót do listy klientów' />
      )}
      <PageTitle title={company && company.id ? `Płatności – ${company.name}` : 'Płatności klientów'}>
        <ButtonWrapper>
        <Dropdown
          variant='right'
          icon={CalendarTodayOutlined}
          value={period.year}
          handleChange={value => updatePeriod({year: value, month: value === 'anytime' ? 'anytime' : period.month})}
          options={
            [
              { label: 'wszystkie', value: 'anytime' }
            ].concat([0,0,0].map((v, i) => (
              { label: (dates.values.thisYear - i), value: (dates.values.thisYear - i) }
            )))
          }
        /> 
        <Dropdown
          variant='right'
          value={period.month}
          handleChange={value => updatePeriod({month: value})}
          options={
            [
              { label: 'wszystkie', value: 'anytime' }
            ].concat([0,0,0,0,0,0,0,0,0,0,0,0].map((v,i) => period.year !== 'anytime' && (period.year !== dates.values.thisYear || i <= dates.values.thisMonth) && (
              { label: (new Date('2020', i)).toLocaleString('pl', {month: 'long'}), value: i }
            )).filter(Boolean))
          }
        /> 
        </ButtonWrapper>
      </PageTitle>
      <Grid container>
        <Grid item xs={12}>
          <PaymentsListAdmin period={period} />
        </Grid>
      </Grid>
    </div>
  );
}

