import { companiesDuck } from "redux/ducks/companies";

const saveCompanyDetails = ({ values, company, dispatch, init, callback }) => {
  const newData = Object.entries(values).reduce(
    (result, [key, val]) =>
      val === company[key] ? result : { ...result, [key]: val },
    {}
  );

  if (Object.keys(newData).length <= 0) {
    return;
  }

  init();

  companiesDuck.api.updateOne({
    data: {
      id: company.id,
      ...newData,
    },
    immediate: true,
    onUpdated: (data) => {
      dispatch(companiesDuck.thunk.fieldUpdate({ data }));
      callback(true);
    },
    onError: () => {
      callback();
    },
  });
};

export default saveCompanyDetails;
