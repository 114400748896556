import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './SettingsList.style';
import Table from 'components/basic/Table';

import {settingsDuck} from 'redux/ducks/dictionaries';
import { useSelector, useDispatch } from 'react-redux';
import shortid from 'shortid';
import ButtonWrapper from 'components/basic/ButtonWrapper';
import { InfoOutlined } from '@material-ui/icons';

const columns = [
  { field: 'type', title: 'Typ', width: 130,
    editable: 'onAdd',
    lookup: {string: 'Tekst', integer: 'Liczba całkowita', float: 'Liczba rzeczywista'},
  },
  { field: 'category', title: 'Kategoria', width: 100, 
    editable: 'onAdd',
    lookup: {default: 'Domyślne', general: 'Ogólne', server: 'Serwer'},
  },
  { field: 'description', title: 'Opis', width: 'auto', ellipsis: true,
    editable: 'onAdd',
  },
  { field: 'value', title: 'Wartość', width: 'auto', 
  },
  { field: 'key', title: 'Klucz', width: 100, ellipsis: true,
    editable: 'onAdd',
  },
];

export const SettingsList = ({active}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const dictionaryData = useSelector(settingsDuck.get.all());

  useEffect(() => {
    if(active && (!dictionaryData || !dictionaryData.length)){
      dispatch(settingsDuck.thunk.getAll());
    }
  }, [active, dictionaryData])

  return (
    <div className={classes.root}>
      <ButtonWrapper 
        mb={4}
        buttons={[
          { type: 'chip', icon: InfoOutlined, label: 'Po zapisaniu nowych ustawień należy zrestartować backend.' },
        ]} 
      />
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(settingsDuck.thunk.fieldSave({id: `new-${shortid.generate()}`, data: newData}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(settingsDuck.thunk.fieldSave({data: newData}));
            resolve();
          }),
        }}
      />
    </div>
  );
}

SettingsList.propTypes = {
  children: PropTypes.node,
};

export default SettingsList;
