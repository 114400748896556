import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& a, & a:hover, & a:visited': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
    '&:focus': {
      outline: '0',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(4),
      fontSize: '0.8rem',
   },
  },
  'variant-small': {
    '& $modalInner': {
      paddingRight: theme.spacing(3),
    },
    '& $modalTitleWrapper': {
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& $modalDescription': {
      paddingRight: theme.spacing(2),
    },
    '& $modalButtons': {
      paddingRight: theme.spacing(4),
    },
    '& .MuiInputBase-root': {
      marginBottom: 0,
   },
  },
  modalInner: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  modalTitleWrapper: {
    paddingRight: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  modalTitle: {
    fontSize: '0.94rem',
  },
  modalDescription: {
    marginBottom: theme.spacing(4),
  },
  modalButtons: {
    paddingRight: 0,
  },
  '& .fileUpload': {
    display: 'flex',
    alignItems: 'center',
    minHeight: 120,
    backgroundColor: theme.palette.grey[100],
    borderColor: theme.palette.grey[200],
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}), {
  name: 'ModalWrapper', 
  index: 1,
});
