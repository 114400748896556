// import Axios from 'axios';
import {RecordLibraryDuck} from 'redux/lib/Duck';
import {fieldValues, dates} from 'dict';
// import { statsDuck } from 'redux/ducks/stats';
import { receiptsDuck } from 'redux/ducks/receipts';
import backend from 'redux/lib/backend';

export const companiesDuck = new RecordLibraryDuck('companies', ({id}) => `/companies/${id}`);
const duck = companiesDuck;

duck.normalize = (singleCompany, companies) => ({ 
  ...singleCompany, 
  // companyName: (companies.data.find(company => company.id === singleCompany.companyId) || {}).name,
  statusName: fieldValues.users.status[singleCompany.status],
});

duck.get.allWithStats = function(){
  return (state) => {
    const { [this.name]: segment, ...rest } = state;
    return this.get.all()(state)
      .map(row => ({
        ...row, 
        // reportStats: statsDuck.get.reportsPerPeriod({caseId: row.id})(state),
        currentMonthAmount: receiptsDuck.get.filtered({companyId: row.id, periodFrom: dates.relative.currentMonth})(state).reduce((sum, row) => sum + row.amount, 0),
        lastMonthAmount: receiptsDuck.get.filtered({companyId: row.id, periodFrom: dates.relative.lastMonth})(state).reduce((sum, row) => sum + row.amount, 0),
      }))
  };
};


duck.thunk.deactivate = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, status: 'deactivated'}));
  }
);

duck.api.uploadLogo = function({urlParams, data, callback, onError, ...options} = {urlParams: {id: ''}}){ 
  urlParams || (urlParams = data);
  const formData = new FormData();
  formData.append('logo', data, data && data.name);
  backend({
    url: this.apiUrlCreator(urlParams)+'/logo',
    method: 'put', 
    data: formData,
    axiosParams: {headers: {'Content-Type': 'multipart/form-data'}}, 
    success: res => callback(res.data),
    error: onError,
  });
};

export const registrationsDuck = new RecordLibraryDuck('registrations', () => '/registrations')
