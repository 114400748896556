export class DuckAction {
  constructor(type, config){
    if(!type){
      return null;
    }
    this.type = type;

    if(typeof config.payload === 'function'){
      this.payload = config.payload;
    } else if(typeof config.payload !== 'undefined'){
      this.payload = () => config.payload;
    }

    if(typeof config.reducer === 'function'){
      this.reducer = config.reducer;
    }

    this.creator = this._creator.bind(this);
  }

  payload(args){
    return args;
  }

  _creator(args){
    return {
      type: this.type, 
      payload: this.payload(args)
    };
  }

  reducer(stateSlice){
    return stateSlice;
  }
}
