import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Chip
} from '@material-ui/core';

import styles from './InfoChip.style';

export const InfoChip = ({variant, ...props}) => {
  const classes = styles();
  return (
    <Chip size='small' variant='outlined' className={clsx(classes.root, variant && `variant-${variant}`)} {...props}/>
  );
}

InfoChip.propTypes = {
  children: PropTypes.node,
};

export default InfoChip;
