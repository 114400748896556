import { makeStyles } from '@material-ui/core/styles';
import { ThemeConsumer } from 'styled-components';

export default makeStyles(theme => ({
  root: {
    '& .mTable-report-type': {
      display: 'flex',
      margin: 0,
      '& dt': {
        fontWeight: theme.typography.fontWeightBold,
        width: 30,
      },
      '& dd': {
        margin: 0,
      }
    }
  },
}), {
  name: 'CompaniesList', 
  index: 1,
});
