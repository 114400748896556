import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom';

import {
  Grid,
  Modal,
  Typography,
} from '@material-ui/core';
import { 
  PersonOutlineOutlined,
  AccountBalanceOutlined,
  MonetizationOnOutlined,
  Filter2Outlined,
  Filter3Outlined,
  TableChartOutlined,
  CloudDownloadOutlined,
  PrintOutlined,
  RefreshOutlined,
  Filter1Outlined,
 } from '@material-ui/icons';

import { casesDuck } from 'redux/ducks/cases';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { featuresDuck } from 'redux/ducks/features';

import PageTitle from 'components/basic/PageTitle';
import ButtonBack from 'components/basic/ButtonBack';
import ButtonWrapper from 'components/basic/ButtonWrapper';
import CaseDetails from 'components/feature/CaseDetails';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';
import Copyright from 'components/basic/Copyright';

import styles from './FullView.style';
import { formatCurrency } from 'utils/formatHelpers';
import { caseSimulationDuck } from 'redux/ducks/caseDetails';
import { reportsDuck } from 'redux/ducks/reports';
import backend from 'redux/lib/backend';
import shortid from 'shortid';
import printJS from 'print-js';
import { useSnackbar } from 'notistack';

const Title = ({title, buttons, backUrl}) => (
  <>
    {backUrl && (
      <Grid container>
        <Grid item xs={12}>
          <ButtonBack text='Powrót do umowy' target={backUrl} />
        </Grid>
      </Grid>
    )}
    {title && (
      <Grid container>
        <Grid item xs={12}>
          <PageTitle 
            title={title}
            buttons={buttons}  
          /> 
        </Grid>
      </Grid>
    )}
  </>
);

export default function Reports() {
  const classes = styles();

  const enabledFeatures = useSelector(featuresDuck.get.enabledFeatures());
  const { id: caseId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const caseDetails = useSelector(casesDuck.get.byId(caseId));
  const currentUser = useSelector(currentUserDuck.get.data());
  
  const [caseSimulation, setCaseSimulation] = useState(null);
  const [costWarning, setCostWarning] = useState(undefined);
  // undefined = case not loaded; true = show warning; false = clicked CANCEL; null = clicked OK or already paid;

  const [isLoading, setIsLoading] = React.useState(false);
  const [connectionError, setConnectionError] = React.useState(false);
  const [openGeneratedReportModal, setOpenGeneratedReportModal] = React.useState(false);
  const [openEstimatedReportModal, setOpenEstimatedReportModal] = React.useState(false);
  const [openFullReportModal, setOpenFullReportModal] = React.useState(false);
  const [lastFetched, setLastFetched] = React.useState(false);
  const [caseSessionUpdate, setCaseSessionUpdate] = React.useState(casesDuck.api.caseSessionUpdate(caseId));

  const updateSimulation = () => {
    setLastFetched(caseSessionUpdate);
    setIsLoading(true);
    caseSimulationDuck.api.get({
      urlParams: {caseId}, 
      data: {id: caseId},
      callback: data => {
        setCaseSimulation(data);
        setIsLoading(false);
      },
      error: err => {
        setConnectionError(`${err.response.data.message}.`)
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    setInterval(() => setCaseSessionUpdate(casesDuck.api.caseSessionUpdate(caseId)), 1000);
    casesDuck.api.getOne({urlParams: {id: caseId}});
  }, []);

  useEffect(() => {
    if(typeof costWarning === 'undefined' && caseDetails){
      if (caseDetails.calculationsCount > 0) {
        setCostWarning(null);
      } else if (caseDetails.calculationsCount <= 0) {
        if (currentUser.company.reportPrice <= 0) {
          setCostWarning(null);
        } else {
          setCostWarning(true);
        }
      }
    }
  }, [caseDetails, currentUser.company.reportPrice])

  useEffect(() => {
    if(caseDetails && (caseDetails.calculationsCount > 0 || costWarning === null) && caseSessionUpdate !== lastFetched){
      updateSimulation();
    }
  }, [caseDetails, caseSessionUpdate, costWarning])

  if(connectionError){
    return (
      <div className={classes.root}>
        <ButtonBack text='Powrót do umowy' target={location.pathname.replace('/full', '')} />
        <PageTitle 
          title={`Umowa nr ${caseDetails.agreementNo} z dnia ${caseDetails.signatureDate}`}
          info={
          <ButtonWrapper 
            variant='full'
            buttons={[
              { type: 'chip', label: `${caseDetails.person}`, icon: PersonOutlineOutlined },
              { type: 'chip', label: `${caseDetails.bankName}`, icon: AccountBalanceOutlined },
              { type: 'chip', label: `${caseDetails.currency}`, icon: MonetizationOnOutlined },
            ]}  
          />}
        /> 

        <Typography component='h2' variant='h5'>Wystąpił błąd</Typography>
        <Typography component='p' variant='body2'>{connectionError}</Typography>
        {caseDetails.calculationsCount === 0 && (
          <Typography component='p' variant='body2'>Opłata nie została naliczona.</Typography>
        )}
      </div>
    );
  }
  if(costWarning){
    return (
      <div className={classes.root}>
        <Modal
          open={costWarning}
          onClose={() => setCostWarning(false)}
        >
          <ModalWrapper 
            maxWidth='650'
            title='Czy chcesz kontynuować?'
            description='Uruchomienie widoku rozszerzonego spowoduje naliczenie opłaty za raport. Czy chcesz kontynuować?'
            buttons={[
              { label: 'Nie, zamknij widok rozszerzony', color: 'primary', onClick: () => window.close()},
              { label: 'Tak, pokaż widok rozszerzony', color: 'secondary', icon: TableChartOutlined, onClick: () => setCostWarning(null)},
            ]}     
          >
          </ModalWrapper>
        </Modal>
      </div>
    );
  }
  if(costWarning === false){
    return <div className={classes.root}><Title title='Nie można wyświetlić danych bez zgody na opłatę' backUrl={location.pathname.replace('/full', '')}/></div>;
  }
  if(!caseDetails || !caseSimulation || !Object.keys(caseSimulation).length){
    return <div className={classes.root}><Title title='Wczytywanie...' backUrl={location.pathname.replace('/full', '')}/></div>;
    // return <div className={classes.root}><Title title='Brak danych' backUrl={location.pathname.replace('/full', '')}/></div>;
  }

  const handleGeneratedReportModalClose = () => setOpenGeneratedReportModal(false);
  const handleEstimatedReportModalOpen = () => setOpenEstimatedReportModal(true);
  const handleEstimatedReportModalClose = () => setOpenEstimatedReportModal(false);
  const handleFullReportModalOpen = () => setOpenFullReportModal(true);
  const handleFullReportModalClose = () => setOpenFullReportModal(false);

  const checkReport = (data) => {
    if(data.processingStatus === 'pending'){
      setTimeout(() => {
        reportsDuck.api.getOne({
          urlParams: {caseId, id: data.id},
          callback: resData => {
            checkReport(resData);
          },
        });
      }, 1000);
    } else if(data.processingStatus === 'complete'){
      dispatch(reportsDuck.thunk.fieldUpdate({data: data}));
      openEstimatedReportModal && setOpenEstimatedReportModal(false);
      openFullReportModal && setOpenFullReportModal(false);
      setOpenGeneratedReportModal(data.url);
    } else {
      openEstimatedReportModal && setOpenEstimatedReportModal(true);
      openFullReportModal && setOpenFullReportModal(true);
      enqueueSnackbar('Wystąpił błąd generowania raportu. Spróbuj ponownie lub skontaktuj się ze wsparciem klienta.');
      console.error('Error details:', data);
    }
  };

  const generateReport = (version, values) => {
    openEstimatedReportModal && setOpenEstimatedReportModal('sending');
    openFullReportModal && setOpenFullReportModal('sending');

    reportsDuck.api.addOne({
      urlParams: {caseId}, 
      data: {version, modules: values, id: 'new-'+shortid.generate()},
      onAdded: (resData) => checkReport(resData),
    });
  };

  return (
    <div className={classes.root}>
      <Modal
        open={openGeneratedReportModal}
        onClose={handleGeneratedReportModalClose}
      >
        <ModalWrapper 
          title='Twój raport jest gotowy!'
          description={<>Raport został wygenerowany poprawnie. Znajdziesz go na <Link to={`/cases/${caseId}/reports`}>liście raportów</Link> tej umowy. Możesz go również pobrać lub wyświetlić, używając przycisków poniżej.</>} 
          buttons={[
            { label: 'Zamknij', color: 'primary', onClick: handleGeneratedReportModalClose},
            { label: 'Pobierz', color: 'secondary', icon: CloudDownloadOutlined, onClick: () => window.open(openGeneratedReportModal, '_blank')},
            { label: 'Drukuj', color: 'secondary', icon: PrintOutlined, onClick: () => {
              let snackKey;
              
              backend({
                axiosParams: {
                  url: openGeneratedReportModal,
                  responseType: 'arraybuffer',
                },
                error: (err) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
        
                  console.log('err:', err)
                },
                success: async (res) => {
                  snackKey && closeSnackbar(snackKey);
                  snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});

                  const pdfFile = new Blob([res.data], {
                    type: "application/pdf"
                  });
                  const pdfUrl = URL.createObjectURL(pdfFile);

                  printJS({
                    printable: pdfUrl, 
                    onLoadingStart: () => snackKey = enqueueSnackbar('Trwa pobieranie raportu', {variant: 'info'}),
                    onLoadingEnd: () => {
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Pobrano raport', {variant: 'success'});
                    },
                    onError: (error) => {
                      console.log(error);
                      snackKey && closeSnackbar(snackKey);
                      snackKey = enqueueSnackbar('Wystąpił błąd pobierania raportu', {variant: 'error'});
                    },
                    onPrintDialogClose: () => {
                      snackKey && closeSnackbar(snackKey)
                      setTimeout(() => {
                        URL.revokeObjectURL(pdfUrl);
                      }, 100);
                    },
                  });
                },
              });
            }},
          ]}     
        >
        </ModalWrapper>
      </Modal>
      <Modal
        open={openEstimatedReportModal}
        onClose={handleEstimatedReportModalClose}
      >
        <ModalWrapper 
          variant='small'
          title='Wygeneruj raport szacunkowy'
        >
          <Form 
            variant='small'
            disabled={openEstimatedReportModal==='sending'} 
            fields={[
              { label: 'Opcjonalny opis', multiline: true, rows: 4},
            ]} 
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: handleEstimatedReportModalClose},
              { label: 'Generuj raport', color: 'secondary', icon: Filter2Outlined, type: 'submit'},
            ]}
            onSubmit={values => {
              generateReport('estimation', values);
            }}
          />
        </ModalWrapper>
      </Modal>
      <Modal
        open={openFullReportModal}
        onClose={handleFullReportModalClose}
      >
        <ModalWrapper 
          variant='small'
          title='Wygeneruj raport pełny'
        >
          <Form 
            disabled={openFullReportModal==='sending'}
            variant='small'
            gridItemProps={{
              style: {paddingTop: 0, paddingBottom: 0}
            }}
            selectAllNone='selectAllNone'
            fields={[
              { label: 'Zaznacz/odznacz wszystkie', name: 'selectAllNone', type: 'checkbox'},
              { label: 'Nieważność umowy', name: 'invalidCase', type: 'checkbox', checked: true },
              { label: 'LIBOR + PLN', name: 'plnLiborLoan', type: 'checkbox', checked: true },
              { label: 'WIBOR + PLN', name: 'caseSettlementWiborInterestRate', type: 'checkbox' },
              { label: 'Kurs średni NBP', name: 'caseSettlementSpreadRefund', type: 'checkbox' },
              ...(caseDetails.exchangeRateAsk !== null && caseDetails.exchangeRateBid !== null ? [{ label: 'CKK + RRSO', name: 'ckkRrso', type: 'checkbox', checked: false }] : []),
              ...(enabledFeatures.includes('knf') ? [{ label: 'KNF', name: 'knf', type: 'checkbox', checked: false }] : []),
              { label: 'Opcjonalny opis', name: 'description', multiline: true, rows: 4 },
            ]} 
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: handleFullReportModalClose},
              { label: 'Generuj raport', color: 'secondary', icon: Filter3Outlined, type: 'submit'},
            ]}
            onSubmit={values => {
              // console.log('values:', Object.fromEntries(Object.entries(values).filter(([key]) => key !== 'selectAll')))
              generateReport('cases', Object.fromEntries(Object.entries(values).filter(([key]) => key !== 'selectAll')));
            }}
          />
        </ModalWrapper>
      </Modal>
      <ButtonBack text='Powrót do umowy' target={location.pathname.replace('/full', '')} />
      <PageTitle 
        title={`Umowa nr ${caseDetails.agreementNo} z dnia ${caseDetails.signatureDate}`}
        info={
        <ButtonWrapper 
          variant='full'
          buttons={[
            { type: 'chip', label: `${caseDetails.person}`, icon: PersonOutlineOutlined },
            { type: 'chip', label: `${caseDetails.bankName}`, icon: AccountBalanceOutlined },
            { type: 'chip', label: `${caseDetails.currency}`, icon: MonetizationOnOutlined },
          ]}  
        />}
        buttons={[
          { label: 'Odśwież dane', color: 'secondary', icon: RefreshOutlined, onClick: updateSimulation },
          { disabled: true, label: 'Wygeneruj raport szacunkowy', color: 'secondary', icon: Filter2Outlined, onClick: handleEstimatedReportModalOpen },
          { label: 'Wygeneruj raport pełny', color: 'secondary', icon: Filter3Outlined, onClick: handleFullReportModalOpen },
        ]}   
      /> 
      <Grid container>
        <Grid item xs={12}>
          <CaseDetails caseSimulation={caseSimulation} isLoading={isLoading} currency={caseDetails.currency} />
        </Grid>
        <Grid item container justify='center' xs={12}>
          <Copyright
            variant='small'
            text='Wygenerowane przez '
            mt={1}
          />
        </Grid>
      </Grid>  
    </div>
  );
}
