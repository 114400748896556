import { feats } from "utils/feats/feats";

const getIsFeatKnown = (maybeFeat) => {
  const isFeatAnUnknownOne = maybeFeat in feats === false;

  if (isFeatAnUnknownOne) {
    console.warn(
      `Unknown feat, expected feat to be one of "${featsKeysFormatted}", got ${maybeFeat} instead.`
    );
  }

  return !isFeatAnUnknownOne;
};

const featsKeysFormatted = Object.keys(feats).join(", ");

export default getIsFeatKnown;
