import { makeStyles } from '@material-ui/core/styles';
// import red from '@material-ui/core/colors/red';
// import pink from '@material-ui/core/colors/pink';
import purple from '@material-ui/core/colors/purple';
// import indigo from '@material-ui/core/colors/indigo';
// import orange from '@material-ui/core/colors/orange';

const backgroundShade = 50;
const textShade = 900;

// const headerCellColor = orange;
// const groupPlnColor = red;
// const groupForeignColor = pink;
// const groupBalanceColor = purple;
// const groupInterestsColor = indigo;

export default makeStyles(theme => ({
  root: {
    '& .mTable-wrapper': {
      maxHeight: 'calc(100vh - 180px) !important',
      minHeight: '500px',
    },
    '& .MuiTable-root th': {
      fontSize: '0.65rem',
      lineHeight: 1.5,
    },
    '& .MuiTable-root': {
      tableLayout: 'initial !important',
      width: 'min-content',
      maxWidth: '100%',
    },
    '& .mTable-header-cell': {
      backgroundColor: '#edefee',
    },
    /* Columns groups coloring */
    '& .groupPln': {
      '&.mTable-colgroup-header, &.mTable-header-cell, &.mTable-summary-row': {
        backgroundColor: '#91ccec !important',
      },
      // '&.mTable-column-cell': { 
      //   color: groupPlnColor[textShade], 
      // },
      },
    '& .mTable-table .mTable-body-row:nth-child(2n) .mTable-column-cell.groupPln': {
      backgroundColor: '#dff0f8',
    },
    '& .groupForeign': {
      '&.mTable-colgroup-header, &.mTable-header-cell, &.mTable-summary-row': {
        backgroundColor: '#bde4ab !important',
      },
      // '&.mTable-column-cell': { 
      //   color: groupForeignColor[textShade], 
      // },
    },
    '& .mTable-table .mTable-body-row:nth-child(2n) .mTable-column-cell.groupForeign': {
      backgroundColor: '#e3f8d9',
    },
    '& .groupBalance': {
      '&.mTable-colgroup-header, &.mTable-header-cell, &.mTable-summary-row': {
        backgroundColor: '#fcf9d6 !important',
      },
      // '&.mTable-column-cell': { 
      //   color: groupBalanceColor[textShade], 
      // },
    },
    '& .mTable-table .mTable-body-row:nth-child(2n) .mTable-column-cell.groupBalance': {
      backgroundColor: '#f3f2de',
    },
    '& .groupInterests': {
      '&.mTable-colgroup-header, &.mTable-header-cell, &.mTable-summary-row': {
        backgroundColor: '#ff995f !important',
      },
      // '&.mTable-column-cell': { 
      //   color: groupInterestsColor[textShade], 
      // },
    },
    '& .mTable-table .mTable-body-row:nth-child(2n) .mTable-column-cell.groupInterests': {
      backgroundColor: '#f8e5d7',
    },
    '& .highlight-column': {
      fontWeight: 'bold',
    },
    '& .mTable-table .mTable-body-row td.mTable-column-cell:not(.optional-notes-column)': {
      '&.mTable-focused-row, &.mTable-focused-column': {
        backgroundColor: purple[100],
      },
      '&.mTable-focused-row.mTable-focused-column': {
        backgroundColor: purple[300],
      },
    },
    /* Sticky row */
    '& tbody td': {
      transform: 'translate3d(0,0,0)',
      position: 'relative',
    },
    '& .mTable-body-row:first-child:hover': {
      '& .mTable-column-cell, & .mTable-action-cell, & .mTable-internalAction-cell': {
        backgroundColor: '#edefee',
      },
    },
    '& thead tr:first-child th': {
      position: 'sticky', 
      top: 0,
      zIndex: 6,
      '&:first-child': {zIndex: 7},
      '&:nth-child(2)': {zIndex: 7},
    },
    '& tbody tr:first-child td': {
      position: 'sticky', 
      top: 81,
      zIndex: 6,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: '#edefee',
    },
    '& tbody tr:first-child td:first-child, & tbody tr:first-child td:nth-child(2)': {
      zIndex: 7,
    },
    '& thead th:first-child, & thead th:nth-child(2), & tbody td:first-child, & tbody td:nth-child(2)': {
      position: 'sticky', 
      left: 0,
      zIndex: 11,
      borderRight: theme.utils.border,
    },
    '& tbody td:first-child, & tbody td:nth-child(2)': {
      zIndex: 5,
      backgroundColor: 'inherit',
    },
    '& thead th:nth-child(2), & tbody td:nth-child(2)': {
      left: 45,
    },
  },
}), {
  name: 'CaseDetails', 
  index: 1,
});
