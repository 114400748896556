import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 220;

export default makeStyles(theme => ({
  root: {
    '& .MuiDivider-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none!important',
    }
  },
  hideDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'none!important',
    }
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: theme.utils.border,
    color: theme.palette.common.black,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  menuButton: {
    marginRight: 13,
    marginLeft: -20,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: theme.spacing(2),
      },
    },
    '& svg': {
      color: theme.palette.common.white,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 57,
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerClose: {
    [theme.breakpoints.down('sm')]: {
      transition: theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    [theme.breakpoints.up('md')]: {
      overflowX: 'hidden',
      width: 57,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }

    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(7) + 1,
    // },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    }
  },
  appBarWrapper: {
    width: '100%',
  },
  appBarItem: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% / 3)',  
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'flex-start',
    }
  },
  companyLogo: {
    height: '64px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  companyName: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logo: {
    minHeight: 64,
    [theme.breakpoints.down('sm')]: {
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(2),
      },
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '& img': {
      width: 100,
      height: 'auto',
      marginRight: 30,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 20,
      },
    },
  },
  listHorizontal: {
    display: 'flex',
  },
  listItemSmall: {
    width: 52,
    height: 52,
    borderRadius: '50%',
    opacity: 0.7,
    fontSize: '0.85rem',
    '& .MuiListItemIcon-root': {
      minWidth: 20,
      width: '100%',
    },
    '& .MuiSvgIcon-root': {
      width: '1.2rem',
      height: '1.2rem',
      color: theme.palette.common.black,
    },
  },
  actionLinks: {
    width: 'calc(100% / 3)',
  },
  listIcon: {
    display: 'flex',
    justifyContent: 'center',
    color: 'inherit',
    minWidth: 40,
    marginRight: theme.spacing(1),
  },
  listItemTitle: {
    marginBottom: theme.spacing(2),
    fontSize: '0.75rem',
    fontWeight: 300,
    letterSpacing: '1.2px', 
    textTransform: 'uppercase', 
  },
  wrapperClassName: {
    
  },
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    fontSize: '0.85rem',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textDecoration: 'none',
    opacity: '0.8',
    transition: 'all 0.3s',
    '&:hover': {
      opacity: '1',
    },
    '& .MuiListItemText-root': {
      margin: 0,
    },
    '& .MuiListItemText-root span': {
      fontSize: '0.8rem',
    },
    '& .MuiSvgIcon-root': {
      width: '1.2rem',
      height: '1.2rem',
    },
    '&.active': {
      opacity: '1',
    },
    '&.active .MuiTypography-body1': {
      fontWeight: '600',
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'center',
    },
    '&.active .MuiSvgIcon-root': {
      color: theme.palette.secondary.main,
    },
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
      fontSize: '0.75rem',
    }
  },
  listItemPermanent: {
    opacity: '1',
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
  buttonMenuClose: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButtonMobile: {
    color: theme.palette.common.black,
  },
  sidebarUsername: {
    '& span': {
      [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'nowrap', 
        width: 135,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    }
  },
}), {
  name: 'Sidebar', 
  index: 1,
});


