import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '&.variant-active': {
      border: "1px solid #4caf50", 
      color: "#4caf50",
    },
    '&.variant-pending': {
      border: "1px solid #ff9800", 
      color: "#ff9800",
    },
    '&.variant-inactive': {
      border: "1px solid #666", 
      color: "#666",
    },
    '&.variant-erased': {
      border: "1px solid #D5D5D5", 
      color: "#D5D5D5",
    },
    '&.variant-error': {
      border: "1px solid #CC0000", 
      color: "#CC0000",
    }
  },
}), {
  name: 'InfoChip', 
  index: 1,
});
