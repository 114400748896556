import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import styles from "./ButtonBack.style";

function ButtonBack({ target, onClick, text = "Powrót", ...props }) {
  const classes = styles();
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Button
          disableElevation
          variant="text"
          startIcon={<ArrowBackOutlined />}
          className={classes.root}
          {...(onClick
            ? { onClick: onClick }
            : target
            ? { to: target, component: Link }
            : { onClick: () => history.goBack() })}
          {...props}
        >
          {text}
        </Button>
      </Grid>
    </Grid>
  );
}

ButtonBack.propTypes = {
  children: PropTypes.node,
};

export default ButtonBack;
