import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
} from '@material-ui/core';
import { SettingsOutlined, SchoolOutlined, LocalPostOfficeOutlined, Info, LoopOutlined } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import TabsWithPanels from 'components/basic/TabsWithPanels';

import SettingsList from 'components/feature/SettingsList';
import KnowledgeBaseCategoryList from 'components/feature/KnowledgeBaseCategoryList';
import ContactSubjectList from 'components/feature/ContactSubjectList';

import styles from './Settings.style';
import TooltipList from 'components/feature/TooltipList';
import { settingsDuck } from 'redux/ducks/dictionaries';
import { useSnackbar } from 'notistack';
import ContactInfoList from 'components/feature/ContactInfoList';

const tabs = [
  { id: 'tooltips', label: 'Tooltipy',
    icon: Info,
    panel: TooltipList,
  },
  { id: 'contact-form-subjects', label: 'Tematy\u00a0kontaktu',
    icon: LocalPostOfficeOutlined,
    panel: ContactSubjectList,
  },
  { id: 'contact-info', label: 'Dane\u00a0kontakt.',
    icon: LocalPostOfficeOutlined,
    panel: ContactInfoList,
  },
  { id: 'knowledge-base-categories', label: 'Kategorie\u00a0BW',
    icon: SchoolOutlined,
    panel: KnowledgeBaseCategoryList,
  },
  { id: 'system', label: 'Backend',
    icon: SettingsOutlined,
    panel: SettingsList,
  },
];

export const Settings = ({children}) => {
  const classes = styles();
  const {enqueueSnackbar} = useSnackbar();
  const [restartPending, setRestartPending] = useState(false);

  return (
    <div className={classes.root}>
      <PageTitle title='Ustawienia' 
        buttons={[
          { label: 'Zrestartuj backend', icon: LoopOutlined, disabled: restartPending, onClick: () => {
            setRestartPending(true);
            settingsDuck.api.reload({
              callback: resData => {
                setRestartPending(false);
                enqueueSnackbar('Serwer zostanie zrestartowany', {variant: 'info'});
              },
              error: err => {
                setRestartPending(false);
                enqueueSnackbar('Błąd połączenia – serwer nie zostanie zrestartowany', {variant: 'warning'});
              },
            })
          }},
        ]}
      /> 
      <Grid container>
        <TabsWithPanels
          variant='small'
          tabs={tabs}
        />
      </Grid>   
    </div>
  );
}

Settings.propTypes = {
  children: PropTypes.node,
};

export default Settings;
