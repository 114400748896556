import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Table from 'components/basic/Table';

import {
  Modal,
  Select,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
} from '@material-ui/core';
import {
  EditOutlined,
  LibraryBooksOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons';

import { usersDuck } from 'redux/ducks/users';

import ModalWrapper from 'components/basic/ModalWrapper';
import InfoChip from 'components/basic/InfoChip';

import styles from './UsersList.style';
import UserAvatar from 'components/basic/UserAvatar';
import { fieldValues } from 'dict';
import { useSnackbar } from 'notistack';
import Form from 'components/basic/Form';
//import UserCard from 'components/basic/UserCard';

const columns = [
  { 
    field: 'fullName', title: 'Imię i nazwisko',
    width: 250,
    ellipsis: true,
    filtering: true, searchable: true,
    render: rowData => (
      <>
        <UserAvatar user={rowData} variant='horizontal' />
        <span>{rowData.fullName}</span>
      </>
    ),
  },
  { 
    field: 'casesCount', title: 'Liczba\u00a0spraw', type: 'numeric',
    width: 170,
    filtering: true, searchable: true,
  },
  { 
    field: 'reportsCount', title: 'Liczba\u00a0raportów', type: 'numeric',
    width: 170,
    filtering: true, searchable: true,
  },
  { 
    field: 'status', title: 'Status', 
    width: 140,
    filtering: true, searchable: true,
    lookup: fieldValues.users.status,
    render: rowData => <InfoChip label={rowData.statusName} variant={
      rowData.status === 'active' ? 'active' :
      rowData.status === 'pending_activation' || rowData.status === 'pending_admin_approval' ? 'pending' :
      rowData.status === 'deactivated' ? 'inactive' :
      rowData.status === 'erased' ? 'erased' :
      'error'
    } /> 
  },
  { 
    field: 'roleName', title: 'Rola', 
    width: 140,
    filtering: true, searchable: true,
    render: rowData => fieldValues.users.roles[rowData.roleName],
  },
];

const actions = (history, openModal, noShowCasesAction) => [
  ...(noShowCasesAction ? [] : [{
    icon: LibraryBooksOutlined,
    tooltip: 'Zobacz sprawy użytkownika',
    onClick: (e, rowData) => history.push(`/users/${rowData.id}/cases`),
  }]),
  {
    icon: EditOutlined,
    tooltip: rowData => rowData.status !== 'erased' ? 'Edytuj użytkownika' : 'Użytkownik usunięty',
    onClick: (e, rowData) => history.push(`/users/${rowData.id}/edit`),
    disabled: rowData => rowData.status === 'erased',
  },  
  {
    icon: DeleteOutlineOutlined,
    tooltip: rowData => rowData.status !== 'erased' ? 'Usuń użytkownika' : 'Użytkownik usunięty',
    disabled: rowData => rowData.status === 'erased',
    onClick: (e, rowData) => openModal.delete(rowData),
  }

];

export default function Users({noShowCasesAction}) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {companyId} = useParams();

  const filters = typeof companyId !== 'undefined' ? {companyId} : {};
  const users = useSelector(usersDuck.get.filteredDetailed(filters));

  const [deleteOptions, updateDeleteOptions] = React.useReducer((s, a) => ({...s, ...a}), {open: false, disabled: false, rowData: {}});;

  return (
    <div className={classes.root}>
      <Modal
        open={deleteOptions.open}
        onClose={() => updateDeleteOptions({open: false})}
      >
        <ModalWrapper 
          title='Czy chcesz skasować użytkownika?' 
          description={`Zanim skasujesz tego użytkownika, musisz przekazać jego sprawy innemu użytkownikowi. Wybierz z poniższej listy osobę, do której chcesz je przypisać. Pamiętaj, że usunięcie użytkownika jest nieodwracalne.`}
        >
          <Form
            variant='small'
            disabled={deleteOptions.disabled}
            fields={[
              { label: 'Przekaż sprawy do...', name: 'newUserIdForCases', 
                options: Object.fromEntries(
                  users
                  .filter(user => user.status === 'active' && user.id !== deleteOptions.rowData.id)
                  .map(user => [user.id, user.fullName])
                ) 
              }
            ]}
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => updateDeleteOptions({open: false}) },
              { label: 'Usuń użytkownika', color: 'secondary', icon: DeleteOutlineOutlined, type: 'submit' },  
            ]}
            onSubmit={values => {
              updateDeleteOptions({disabled: true});
              dispatch(usersDuck.thunk.fieldSaveSync({
                data: {id: deleteOptions.rowData.id, status: 'erased', ...(values.newUserIdForCases && {newUserIdForCases: values.newUserIdForCases})}, 
                callback: () => {
                  updateDeleteOptions({open: false, disabled: false, rowData: {}});
                  enqueueSnackbar('Użytkownik został usunięty', {variant: 'success'});
                },
                onError: () => {
                  updateDeleteOptions({disabled: false});
                },
              })); 
            }}
          />
          {/* <UserCard user={deleteOptions.rowData} variant='small' /> */}
          {/* <Grid item xs={12}>
            <FormControl variant='outlined' size='small'>
              <InputLabel id='select-role'>Przekaż sprawy do...</InputLabel>
              <Select labelId='select-role' name='newUserIdForCases' value={userType} onChange={handleChange} label='Przekaż sprawy do...'>
                <MenuItem value={10}>nie zaimplementowane</MenuItem>
                <MenuItem value={20}>brak api</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns}
        data={users}
        actions={actions(history, {delete: (rowData) => updateDeleteOptions({open: true, rowData})}, noShowCasesAction)}
      />
    </div>
  );
}