import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& legend': {
      fontSize: '0.75rem',
      transformOrigin: 'top left',
      whiteSpace: 'nowrap',
      background: 'lime',
    },

    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink), & .MuiInputBase-input': {
      fontSize: '0.8rem',
    }
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}), {
  name: 'Fieldset', 
  index: 1,
});
