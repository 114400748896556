import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none', 
      margin: 0,
    },
    '& .mTable-filter-options': {
      paddingTop: theme.spacing(4),
      paddingRight: theme.spacing(4),
      '& > div': {
        marginBottom: theme.spacing(1),
      },
      '& a': {
        cursor: 'pointer',
      }
    },
    '& .MuiDialogTitle-root': {
      '& .MuiTypography-h6': {
        textTransform: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        width: 'calc(100% - 16px)',
      },
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: theme.palette.common.gray,
    },
    '& .mTable-filter-buttons': {
      justifyContent: 'center',
      paddingBottom: theme.spacing(3),
    },
    '& .mTable-filter': {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '&.mTable-filter-number': {
        width: '90%',
      },
    },
    '& .MuiInputBase-root': {
      width: '85%',
      '&:before, &::after': {
        display: 'none',
      },
    },
    '& .MuiInputBase-input': {
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.light,
    },
    '& .MuiToolbar-root': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    '& .mTable-filter-prefix': {
      display: 'inline-flex',
      transform: 'translate(30px, 7px)',
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .mTable-filter-popup-field-wrapper': {
      display: 'flex',
      '& .mTable-filter-prefix': {
        transform: 'translate(0, 7px)',
      },
      '& .MuiInputBase-root': {
        width: '90%',
      }
    }
  },
}), {
  name: 'PopupDialog', 
  index: 1,
});
