import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MaterialSelect from "@appgeist/react-select-material-ui";

import styles from './SmartSelect.style';
import clsx from 'clsx';

export const SmartSelect = ({
  mTable, 
  className, 
  disabled, 
  open: propsOpen, 
  onOpen: propsOnOpen, 
  onClose: propsOnClose, 
  onKeyDown, 
  onChange: propsOnChange, 
  value: propsValue, 
  openOnEnter, 
  error, 
  helperText,
  required, 
  ...props
}) => {
  const classes = styles();

  const [value, setValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    typeof propsOpen !== 'undefined' && setIsOpen(propsOpen);
  }, [propsOpen]);

  useEffect(() => {
    const newVal = props.options.find(opt => opt.value == propsValue); // eslint-disable-line eqeqeq
    // console.log('propsValue, newVal:', propsValue, newVal)
    if(newVal){
      setValue(newVal);
    } else if(propsValue === null){
      setValue(propsValue);
    }
  }, [propsValue, props.options]);

  const onOpen = () => {
    typeof propsOnOpen === 'function' ? propsOnOpen() : setIsOpen(true);
  };

  const onClose = () => {
    typeof propsOnClose === 'function' ? propsOnClose() : setIsOpen(false);
  };

  return (
    <MaterialSelect 
      className={clsx(className, classes.root, mTable && classes.mTable)}
      focused={false}
      shrink={false}
      size='small'
      {...props}
      isDisabled={disabled}
      menuIsOpen={isOpen}
      onMenuOpen={onOpen}
      onMenuClose={onClose}
      maxMenuHeight={200}
      menuPortalTarget={document.body}
      value={value}
      onChange={option => propsOnChange({target: {value: option && option.value}})}
      {...(!required && {
        backspaceRemovesValue: true, 
        // escapeClearsValue: true, 
        isClearable: true, 
      })}
      TextFieldProps={{
        error,
        helperText,
        disabled: disabled,
        label: props.label,
        required: required,
        fullWidth: true,
        margin: 'dense',
        variant: 'outlined',
        InputLabelProps:{shrink: !!value || undefined},
        onKeyDown: e => {
          if(openOnEnter && e.key === 'Enter' && !isOpen){
            e.stopPropagation();
            onOpen();
          } else if(!required && ['Backspace', 'Delete'].includes(e.key)){
            onClose();
          }
        }
      }}
    />
  );
}

export default SmartSelect;
