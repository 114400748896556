import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './TooltipList.style';
import {phrasesDuck} from 'redux/ducks/dictionaries';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'components/basic/Table';
import shortid from 'shortid';
import {fieldValues, defaultPhrases} from 'dict';

const columns = [
  { field: 'active', title: 'Aktywny', type: 'boolean', width: 75 },
  { field: 'title', title: 'Lokalizacja', editable: 'never', initialEditValue: () => `new-${shortid.generate()}`, width: 150 },
  { field: 'content', title: 'Treść podpowiedzi', type: 'text' },
  { field: 'defaultContent', title: 'Treść domyślna', type: 'text', width: 300, editable: 'never' },
];

export const TooltipList = ({active = true}) => {
  const classes = styles();
  const dispatch = useDispatch();

  const apiData = useSelector(phrasesDuck.get.filtered({type: 'tooltip'}));
  const dictionaryData = [
    ...apiData.map(row => ({...row, defaultContent: defaultPhrases['tooltip'][row.title]})),
    ...Object.entries(defaultPhrases['tooltip']).map(([key, value]) => (!apiData.some(row => row.title === key) && {title: key, defaultContent: value, active: true, id: `new-${shortid.generate()}`})).filter(Boolean),
  ];

  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if(!fetched && active && (!apiData || !apiData.length)){
      setFetched(true);
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'tooltip', active: 'all'}}));
    }
  }, [active, apiData])

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(phrasesDuck.thunk.fieldSave({data: phrasesDuck.prepareData({...newData, type: 'tooltip'})}));
            resolve();
          }),
        }}
      />
    </div>
  );

}

TooltipList.propTypes = {
  children: PropTypes.node,
};

export default TooltipList;
