import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
} from '@material-ui/core';

import styles from './StatsCards.style';

export const StatsCards = ({icon, cards = {}, cardSizeMd, cardSizeLg, titleComponent = "h3", titleVariant = "body2", textComponent = "h4", textVariant = "h2", variant}) => {
  const classes = styles();
  return (
    <Grid item xs={12} container alignItems='stretch'>
      {cards.map(card => (
      <Grid item xs={12} md={cardSizeMd} lg={cardSizeLg}>
        <Box className={clsx(classes.cardWrapper, card.variant && `variant-${card.variant}`)}>
          <Card className={classes.card}>
            <CardContent>
              <Avatar className={classes.cardBadge}>
                {<card.icon/>}
              </Avatar>
            </CardContent>
            <CardContent className={classes.cardContent}>
              <Typography component={titleComponent} variant={titleVariant} className={clsx(classes.cardTitle, card.variant && `variant-${card.variant}`)}>{card.title}</Typography>
              <Typography component={textComponent} variant={textVariant}>
              {card.value.toString().split('\n').map((line, i) => (
                <>
                  {i>0 && <br/>}
                  {line}
                 </>
              ))}               
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      ))}
    </Grid>
  );
}

StatsCards.propTypes = {
  children: PropTypes.node,
};

export default StatsCards;
