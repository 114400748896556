import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      width: "100%",
    },
    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      fontSize: '0.8rem',
    },
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none', 
      margin: 0,
    },
    '& .MuiPaper-root .mTable-wrapper': {
      minHeight: 250,
      maxHeight: 'calc(100vh - 600px)',
    },
    '& .auto-height .MuiPaper-root .mTable-wrapper': {
      minHeight: 0,
      maxHeight: 'none',
    },
  },
  step: {
    '& .Mui-disabled': {
      opacity: 0.6,
    },
    '& .MuiStepIcon-active': {
      color: theme.palette.secondary.main,
    },  
  },
  stepTooltip: {
    maxWidth: 200, 
    textAlign: 'center',
  },
  formWrapper: {
    position: 'relative',
    background: theme.palette.common.white,
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0), 0px 1px 1px 0px rgba(0,0,0,0), 0px 1px 16px 0px rgba(0,0,0,0.07)!important",
    borderRadius: 4,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  inputs: {
    width: '100%',
  },
  navArrow: {
    position: 'absolute',
    '& .MuiButtonBase-root': {
      width: 48,
      height: 48,
    },  
  },
  arrowBackward: {
    top: 28,
    left: 10,
  },
  arrowForward: {
    right: 10,
    top: 28, 
  },
  arrowsBottom: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: 48,
    '& $arrowBackward': {
      top: 0,
      left: -20,
    },
    '& $arrowForward': {
      top: 0,
      right: 0,
    },
  },
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '3rem 0',
    transform: 'translateX(-5%)'
  },
  calculationWidgetBottomInfo: {
    position: 'relative',
    display: 'flex',
    // justifyContent: 'space-between',
    width: '100%',
    padding: '1rem 0'
  },
}), {
  name: 'NewCase', 
  index: 1,
});

export const stepsStyles = makeStyles(theme => ({
  formRow: {
    display: 'flex', 
  },
  input: {
    width: '100%',
  },
  dateInputWrapper: {
    width: '100%',
  },
}));
