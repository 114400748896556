import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { receiptsDuck } from 'redux/ducks/receipts';

import InfoChip from 'components/basic/InfoChip';
import Table from 'components/basic/Table';

import styles from './PaymentsListManager.style';
import { dates, fieldValues } from 'dict';

const columns = [
  { 
    field: 'month', title: 'Miesiąc', 
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'accountsNumber', title: 'Liczba dostępów', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'priceForAccess', title: 'Cena za dostęp', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'reportsNumber', title: 'Liczba raportów', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'reportPrice', title: 'Cena za raport', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'assistanceNumber', title: 'Liczba przekazanych umów', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'assistancePrice', title: 'Cena za przekazanie umowy', type: 'numeric',
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'discount', title: 'Rabat', type: 'numeric', decimalDigits: 2,
    currencySetting: { currencyCode: 'PLN', locale: 'pl' },
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'amount', title: 'Kwota', type: 'numeric', decimalDigits: 2,
    currencySetting: { currencyCode: 'PLN', locale: 'pl' },
    width: 150,
    filtering: true, searchable: true,
  },
  { 
    field: 'paymentStatus', title: 'Status płatności',
    width: 150,
    filtering: true, searchable: true,
    lookup: fieldValues.receipts.paymentStatus,
    render: rowData => <InfoChip label={rowData.paymentStatusName} variant={
      rowData.paymentStatus === 'paid' ? 'active' :
      'error'
    } /> 
  },
  {
    title: '',
    sorting: false, filtering: false, searchable: false,
  }
];

export default function PaymentsListManager({year}) {
  const classes = styles();
  const dispatch = useDispatch();
  const receipts = useSelector(receiptsDuck.get.filtered({periodFrom: dates.relative.givenYear(year)}));

  useEffect(() => {
    dispatch(receiptsDuck.thunk.getAll({ urlParams: { year }} ));
  }, [dispatch, year])

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={receipts}
      />
    </div>
  );
}