/* eslint-disable no-extra-bind */
import config from 'config';
import { DuckAction } from 'redux/lib/Duck';

export class Duck {
  constructor(name, apiUrlCreator){
    this.name = name;
    
    this.reducer = this.reducer.bind(this);
    this.rawApiUrlCreator = apiUrlCreator

    this._actions = {};

    this.action = new Proxy(this._actions, {
      get: (obj, prop) => obj[this._makeActionType(prop)] ? obj[this._makeActionType(prop)].creator : {error: `no ${this._makeActionType(prop)} duck defined`},
      set: (obj, prop, config) => Object.assign(obj, this._makeNewDuckAction(prop, config)),
    });

    this.get = new Proxy({}, {
      get: (obj, prop) => obj[prop],
      set: (obj, prop, config) => obj[prop] = typeof config === 'function' ? config.bind(this) : config,
    });

    this.thunk = new Proxy({}, {
      get: (obj, prop) => obj[prop],
      set: (obj, prop, config) => obj[prop] = typeof config === 'function' ? config.bind(this) : config,
    });

    this.api = new Proxy({}, {
      get: (obj, prop) => obj[prop],
      set: (obj, prop, config) => obj[prop] = typeof config === 'function' ? config.bind(this) : config,
    });

    Object.entries(this._defaultActions).forEach(([prop, config]) => this.action[prop] = config);
    Object.entries(this._defaultGets).forEach(([prop, config]) => this.get[prop] = config);
    Object.entries(this._defaultThunks).forEach(([prop, config]) => this.thunk[prop] = config);
    this.apiUrlCreator && Object.entries(this._defaultApis).forEach(([prop, config]) => this.api[prop] = config);

  }

  creator(initialData = {}){
    return Object.assign({}, initialData);
  }

  _makeActionType(prop){
    return `${config.appName}/${this.name}/${prop}`;
  }

  _makeNewDuckAction(prop, config){
    const type = this._makeActionType(prop);
    return {[type]: new DuckAction(type, config)};
  }

  reducer(stateSlice = {}, action){
    const duckAction = this._actions[action.type];
    return duckAction ? duckAction.reducer(stateSlice, action.payload) : stateSlice;
  }

  normalize(row){
    return {...row};
  }

  validationErrors(row){
    const errors = [];
    for(let key of this.requiredFields){
      const field = row[key];
      if(typeof field === 'undefined' || field === '' || field === null){
        errors.push({
          name: key, 
          msg: `Pole ${key} jest wymagane`,
        });
      }
    }
    return errors.length > 0 && errors;
  }

  isNewId(id){
    return (
      (typeof id === 'string' && /^(new(-.*)?)?$/.test(id))
      || (typeof id === 'number' && !(id >= 0))
      || (typeof id === 'undefined')
    );
  }

  apiUrlCreator(urlParams = {}){
    // Object.entries(urlParams).forEach(([key, val]) => {
    //   typeof val === 'string' || (typeof val === 'number' && !isNaN(val)) || (urlParams[key] = '')
    // });
    return this.rawApiUrlCreator(urlParams).replace(/\bundefined\b/g, '').replace(/\/(?=\/|$)/g, '');
  }
}

Duck.prototype.requiredFields = [];

Duck.prototype._defaultGets = {
  meta: function(){
    return ({ [this.name]: segment }) => segment.meta;
  },
};

Duck.prototype._defaultActions = {};

Duck.prototype._defaultThunks = {};

Duck.prototype._defaultApis = {};
