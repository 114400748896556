import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: theme.shadows[0],
    borderRight: theme.utils.border,
    borderRadius: 0,
    width: 'calc(100% / 3)',
    '&:last-of-type': {
      borderRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
    },
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },
  cardBadge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.primary.main,
    },
  },
  cardContent: {
    flex: 1,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
  cardSecondaryContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: theme.utils.border,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}), {
  name: 'ComplexStatsCard', 
  index: 1,
});
