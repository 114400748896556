// import Axios from 'axios';
import {CaseDetailsDuck} from 'redux/lib/Duck';
import {fieldValues} from 'dict';
import { casesDuck } from 'redux/ducks/cases';

export const reportsDuck = new CaseDetailsDuck('reports', ({companyId, userId, caseId, id}) => {
  if(typeof companyId !== 'undefined'){ return `/companies/${companyId}/reports`; }
  if(typeof userId !== 'undefined'){ return `/users/${userId}/reports`; }
  return `/cases/${caseId}/reports/${id}`;
});
const duck = reportsDuck;

duck.normalize = (singleReport, users) => ({ 
  ...singleReport, 
  // userName: (users.data.find(user => user.id === singleReport.ownerUserId) || {}).username, 
  typeName: fieldValues.reports.versions[singleReport.version],
});

duck.deletedStatuses = ['erased', 'deactivated'];

duck.get.allWithCases = function(){ return (state) => {
  return this.get.all()(state)
    .map(row => {
      const aCase = casesDuck.get.byId(row.caseId)(state);
      return {
        ...row, 
        caseName: aCase && aCase.name, 
        casePerson: aCase && aCase.person,
      }
    });
}};

duck.get.filteredWithCases = function(filters){ return (state) => {
  return this.get.filtered(filters)(state)
    .map(row => {
      const aCase = casesDuck.get.byId(row.caseId)(state);
      return {
        ...row, 
        caseName: aCase.name, 
        casePerson: aCase.person,
      }
    });
}};

duck.thunk.trash = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, status: 'deactivated'}));
  }
);

duck.thunk.erase = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, status: 'erased'}));
  }
);

duck.thunk.restore = requestedId => (
  (dispatch, getState) => {
    dispatch(duck.action.updateOne({id: requestedId, status: 'active'}));
  }
);
