import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
} from '@material-ui/core';
import {
  EditOutlined,
  SaveOutlined,
} from '@material-ui/icons';
import { formatValue } from 'material-table';

import { receiptsDuck } from 'redux/ducks/receipts';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';
import InfoChip from 'components/basic/InfoChip';

import styles from './PaymentsListAdmin.style';
import { dates, fieldValues } from 'dict';
import Form from 'components/basic/Form';
import { useParams } from 'react-router-dom';

const columns = ({showCompanies}) => [
  ...(!showCompanies ? [] : [
    { field: 'companyName', title: 'Firma',
      width: 150,
      ellipsis: true, 
      filtering: true, searchable: true,
    }
  ]),
  { field: 'month', title: 'Okres rozliczeniowy', 
    width: 130,
    filtering: true, searchable: true,
  },
  { field: 'reportPrice', title: 'Cena raportu', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true,
  },
  { field: 'reportsNumber', title: 'Liczba raportów', 
    type: 'numeric',
    filtering: true, searchable: true,
  },
  { field: 'allReportsPrice', title: 'Cena za raporty', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true,
    render: rowData => <>{rowData.reportPrice * rowData.reportsNumber}</>,
  },
  { field: 'assistancePrice', title: 'Cena za przekazanie umowy', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true,
  },
  { field: 'assistanceNumber', title: 'Liczba przekazanych umów', 
    type: 'numeric',
    filtering: true, searchable: true,
  },
  { field: 'allAssistancePrice', title: 'Cena przekazanych umów', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true,
  },
  { field: 'accountsNumber', title: 'Liczba dostępów', 
    type: 'numeric',
    filtering: true, searchable: true,
  },
  { field: 'priceForAccess', title: 'Opłaty za dostępy', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true, defaultValue: 0,
  },
  { field: 'discount', title: 'Rabat', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true, defaultValue: 0,
  },
  { field: 'amount', title: 'Suma za miesiąc', 
    type: 'numeric', decimalDigits: 2,
    filtering: true, searchable: true,
    render: (rowData) =>
      formatValue(rowData.amount - rowData.discount, {
        type: "numeric",
        decimalDigits: 2,
      }),
  },
  { field: 'name', title: 'Nr faktury', 
    filtering: true, searchable: true,
  },
  { field: 'issueStatus', title: 'Status faktury',
    width: 150,
    filtering: true, searchable: true,
    render: rowData => <InfoChip label={rowData.issueStatusName} variant={
      rowData.issueStatus === 'issued' ? 'active' :
      'error'
    } /> 
  },
  { field: 'paymentStatus', title: 'Status płatności',
    width: 150,
    filtering: true, searchable: true,
    render: rowData => <InfoChip label={rowData.paymentStatusName} variant={
      rowData.paymentStatus === 'paid' ? 'active' :
      'error'
    } /> 
  },
];

const actions = (openModal) => [
  {
    icon: EditOutlined,
    onClick: (e, rowData) => openModal.edit(rowData),
    tooltip: 'Zmień numer faktury, jej status, oraz status płatności',
  },
];

export default function PaymentsListAdmin({period}) {
  const classes = styles();
  const dispatch = useDispatch();
  const {companyId} = useParams();

  const filters = {
    periodFrom: dates.relative.givenMonth(period),
    ...(typeof companyId === 'undefined' ? {} : {companyId})
  };

  const invoices = useSelector(receiptsDuck.get.filtered(filters));

  const [modalOptions, setModalOptions] = React.useState(false);

  useEffect(() => {
    dispatch(receiptsDuck.thunk.getAll({ urlParams: { ...period }} ));
  }, [dispatch, period])

  return (
    <div className={classes.root}>
      <Modal
        open={modalOptions}
        onClose={() => setModalOptions(false)}
      >
        <ModalWrapper 
          title='Edytuj płatność'
          >
          <Form
            fields={[
              { label: 'Numer faktury', name: 'name', defaultValue: modalOptions.name },
              { label: 'Status faktury', name: 'issueStatus', defaultValue: modalOptions.issueStatus, 
                options: fieldValues.receipts.issueStatus,
              },
              { label: 'Status płatności', name: 'paymentStatus', defaultValue: modalOptions.paymentStatus, 
                options: fieldValues.receipts.paymentStatus,
              },
              { label: 'Rabat', name: 'discount', defaultValue: modalOptions.discount },
            ]}
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => setModalOptions(false)},
              { label: 'Zapisz zmiany', color: 'secondary', icon: SaveOutlined, type: 'submit'},
            ]}  
            onSubmit={values => {
              setModalOptions({...modalOptions, sending: true});
              const data = {id: modalOptions.id, ...values};
              receiptsDuck.api.updateOne({
                data, 
                immediate: true,
                onUpdated: (resData) => {
                  dispatch(receiptsDuck.thunk.fieldUpdate({data: resData}));
                  setModalOptions(false);
                },
              }); 
            }}
          />
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns({showCompanies: typeof companyId === 'undefined'})}
        data={invoices}
        actions={actions({
          edit: (rowData) => setModalOptions(rowData)
        })}
      />
    </div>
  );
}