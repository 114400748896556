import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import styles from './ListCard.style';

export const ListCard = ({cards = {}, variant, form}) => {
  const classes = styles();
  return (
    <>
      {cards.map((card) =>
      <Card className={clsx(classes.card, variant && `variant-${variant}`)}>
        <CardContent className={classes.cardHeader}>
          {card.icon && (
            <Avatar className={classes.cardBadge}>
              {<card.icon/>}
            </Avatar>
          )}
          <Typography variant="h5" component="h2" className={classes.cardTitle}>{card.title}</Typography>
        </CardContent>
        <CardContent className={classes.cardContent}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <List disablePadding dense>
              {card.links && card.links.map((link) =>
                <li>
                  <ListItem component={Link} to={link.target}>
                    <ListItemText disableTypography primary={link.label} />
                  </ListItem>
                </li>
              )}
            </List>
            {form}
          </Box>
        </CardContent>
      </Card>
      )}  
    </>
  );
}

ListCard.propTypes = {
  children: PropTypes.node,
};

export default ListCard;
