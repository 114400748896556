import { makeStyles } from '@material-ui/core/styles';

export default makeStyles( theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    minHeight: "100vh",
  },
}), {
  name: 'MainLayout', 
  index: 1,
});
