import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      margin: 0,
    },
  },
  mTable: {
    fontSize: 'inherit',
    '& p':{
      marginBottom: 0,
    },
    '& .MuiInputBase-root': {
      border: 0,
      '&.Mui-focused > fieldset': {
        borderWidth: 1,
      },
    },
    '& .MuiPaper-root': {
      marginTop: 29,
    },
    '& .MuiInputBase-input': {
      padding: '1px 0!important',
      minHeight: 0, 
      '& > :first-child': {
        flexWrap: 'nowrap',
        '& .MuiTypography-root': {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis', 
          overflow: 'hidden',
        },
      },
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(224, 224, 224, 1)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(224, 224, 224, 1)',
    },
    '& .MuiTypography-body1': {
      fontSize: 'inherit', 
    },
  },
}), {
  name: 'SmartSelect', 
  index: 1,
});
