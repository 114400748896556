import {Duck} from 'redux/lib/Duck';

import shortid from 'shortid';

export const postOfficeDuck = new Duck('postOffice', () => null);
const duck = postOfficeDuck;

duck.get.status = () => ({ [duck.name]: segment }) => segment.status;
duck.get.allErrors = () => ({ [duck.name]: segment }) => segment.errors;
duck.get.newErrors = () => ({ [duck.name]: segment }) => segment.errors.filter(item => item.status === 'new');
duck.get.allMessages = () => ({ [duck.name]: segment }) => segment.messages;
duck.get.newMessages = () => ({ [duck.name]: segment }) => segment.messages.filter(item => item.status === 'new');

duck.action.updateStatus = {
  reducer: (segment, payload) => ({...segment,
    status: payload,
  }),
};

duck.action.addErrors = {
  reducer: (segment, payload) => ({...segment, 
    errors: [...segment.errors, ...payload.map(item => ({id: shortid.generate(), status: 'new', ...item}))],
  }),
};

duck.action.addError = {
  reducer: (segment, payload) => ({...segment, 
    errors: [...segment.errors, {id: shortid.generate(), status: 'new', ...payload}],
  }),
};

duck.action.setErrorSeen = {
  reducer: (segment, payload) => ({...segment, 
    errors: segment.errors.map(item => item.id === payload ? {...item, status: 'seen'} : item),
  }),
};

duck.action.setErrorClosed = {
  reducer: (segment, payload) => ({...segment, 
    errors: segment.errors.map(item => item.id === payload ? {...item, status: 'closed'} : item),
  }),
};



duck.action.addMessages = {
  reducer: (segment, payload) => ({...segment, 
    messages: [...segment.messages, ...payload.map(item => ({id: shortid.generate(), status: 'new', ...item}))],
  }),
};

duck.action.addMessage = {
  reducer: (segment, payload) => ({...segment, 
    messages: [...segment.messages, {id: shortid.generate(), status: 'new', ...payload}],
  }),
};

duck.action.setMessageSeen = {
  reducer: (segment, payload) => ({...segment, 
    messages: segment.messages.map(item => item.id === payload ? {...item, status: 'seen'} : item),
  }),
};

duck.action.setMessageClosed = {
  reducer: (segment, payload) => ({...segment, 
    messages: segment.messages.map(item => item.id === payload ? {...item, status: 'closed'} : item),
  }),
};
