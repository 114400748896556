const initialState = {
  postOffice: {
    status: 'empty',
    errors: [],
    messages: [],
  },
  currentUser: {
    meta: {
      loading: false,
      errors: []
    },
    data: {
      // id: 4,
      // login: 'worker@alpha.pl',
      // email: 'worker@alpha.pl',
      // firstName: 'Alpha Worker',
      // lastName: 'Alpha Worker',
      // status: 'active',
      // ownerCompanyId: 2,
      // company: {
      //   id: 2,
      //   name: 'Company Alpha',
      //   legalName: 'Company Alpha',
      //   taxNumber: '6462933516',
      //   addressStreetName: 'ul. Grunwaldzka',
      //   addressBuildingNo: '14/10',
      //   addressPostalCode: '44-002',
      //   addressCity: 'Berlin',
      //   web: null,
      //   logoUrl: null,
      //   status: 'active',
      //   createdAt: '2020-04-21T20:24:23.000Z',
      //   updatedAt: '2020-04-21T20:24:23.000Z'
      // },
      // privileges: [
      //   'regular'
      // ]
    }
  },
  user: {
    meta: {loading: false, errors: []},
    data: {
      // name: 'Jan Nowak',
      // type: 'user', // user, manager, admin
    },
  },
  owner: {
    meta: {loading: false, errors: []},
    data: {
      name: 'PMBT sp. z o.o.',
      logo: 'https://logoipsum.com/logo/logo-11.svg',
      email: 'contact@pmbt.com',
      accountNumber: '1234567890',
      taxNumber: '112233445566',
      addressStreetName: 'Okopowa',
      addressBuildingNo: '125',
      addressPostalCode: '00-000', 
      addressCity: 'Wrocław',
      phone: '123-456-789',
    },
  },
  cases: {
    meta: {loading: false, errors: []},
    data: [
      // { localId: null, id: 1, name: '67618/2019', ownerUserId: 6, ownerCompanyId: 1, amount: '4651000', bank: 'Nordea', agreementNo: '6531/9ja/244', person: 'Dr. Monty Hackett', signatureDate: '2007.01.05', preparationDate: '2007.01.04', installmentAmount: 144, installmentType: 'equal', interestRate: 0.1226, margin: 0.17, temporaryIncrease: 4.00, liborType: '3M', currency: 'JPY', calculationDay: 2, paymentDayNo: 24, status: 'active', optionalNotes: 'Iste suscipit est illo consequatur nobis.'},
      // { localId: null, id: 2, name: '50201/2012', ownerUserId: 4, ownerCompanyId: 2, amount: '1894000', bank: 'Alior', agreementNo: '7929/qz5/761', person: 'Theodore Hilll', signatureDate: '2010.06.08', preparationDate: '2010.06.02', installmentAmount: 288, installmentType: 'equal', interestRate: 0.319, margin: 0.9, temporaryIncrease: 8.00, liborType: '12M', currency: 'CHF', calculationDay: 27, paymentDayNo: 22, status: 'shared', optionalNotes: ''},
      // { localId: null, id: 3, name: '12365/2013', ownerUserId: 9, ownerCompanyId: 1, amount: '3192000', bank: 'Credit Agricole', agreementNo: '4419/kzm/978', person: 'Katherine Harber', signatureDate: '2008.09.23', preparationDate: '2008.09.20', installmentAmount: 120, installmentType: 'decreasing', interestRate: 0.518, margin: 0.11, temporaryIncrease: 6.00, liborType: '1M', currency: 'USD', calculationDay: 11, paymentDayNo: 24, status: 'active', optionalNotes: 'Provident reprehenderit non cumque distinctio exercitationem.'},
      // { localId: null, id: 4, name: '65985/2005', ownerUserId: 1, ownerCompanyId: 2, amount: '3996000', bank: 'PKO', agreementNo: '1316/9qo/467', person: 'Vladimir Cummings', signatureDate: '2013.04.23', preparationDate: '2013.04.19', installmentAmount: 348, installmentType: 'decreasing', interestRate: 0.1855, margin: 0.12, temporaryIncrease: 5.00, liborType: '6M', currency: 'CHF', calculationDay: 26, paymentDayNo: 4, status: 'active', optionalNotes: ''},
      // { localId: null, id: 5, name: '76656/2009', ownerUserId: 6, ownerCompanyId: 1, amount: '3225000', bank: 'Raiffeisen', agreementNo: '8440/og3/904', person: 'Sarah Trantow', signatureDate: '2014.07.01', preparationDate: '2014.06.30', installmentAmount: 336, installmentType: 'equal', interestRate: 0.219, margin: 0.6, temporaryIncrease: 4.00, liborType: '1M', currency: 'CHF', calculationDay: 27, paymentDayNo: 25, status: 'active', optionalNotes: 'Laudantium modi praesentium.'},
      // { localId: null, id: 6, name: '84263/2009', ownerUserId: 7, ownerCompanyId: 2, amount: '3009000', bank: 'Alior', agreementNo: '5706/10t/850', person: 'Dorris Boehm', signatureDate: '2006.01.25', preparationDate: '2006.01.18', installmentAmount: 240, installmentType: 'decreasing', interestRate: 0.1124, margin: 0.19, temporaryIncrease: 3.00, liborType: '6M', currency: 'CHF', calculationDay: 4, paymentDayNo: 13, status: 'shared', optionalNotes: 'Dolore odit totam eligendi amet sunt.'},
      // { localId: null, id: 7, name: '76665/2006', ownerUserId: 1, ownerCompanyId: 2, amount: '2923000', bank: 'ING', agreementNo: '2166/e35/974', person: 'Garland Stark', signatureDate: '2005.07.24', preparationDate: '2005.07.18', installmentAmount: 240, installmentType: 'decreasing', interestRate: 0.947, margin: 0.9, temporaryIncrease: 4.00, liborType: '12M', currency: 'JPY', calculationDay: 6, paymentDayNo: 19, status: 'active', optionalNotes: 'Quam fugiat delectus nemo voluptatibus itaque tempore.'},
      // { localId: null, id: 8, name: '10513/2017', ownerUserId: 8, ownerCompanyId: 3, amount: '4673000', bank: 'Credit Agricole', agreementNo: '3070/dhp/642', person: 'Kali Steuber', signatureDate: '2010.05.14', preparationDate: '2010.05.12', installmentAmount: 288, installmentType: 'equal', interestRate: 0.607, margin: 0.14, temporaryIncrease: 4.00, liborType: '6M', currency: 'JPY', calculationDay: 3, paymentDayNo: 21, status: 'shared', optionalNotes: 'Ea id magni omnis sed ut molestias quibusdam.'},
      // { localId: null, id: 9, name: '66065/2019', ownerUserId: 9, ownerCompanyId: 1, amount: '4086000', bank: 'PKO', agreementNo: '7629/wsp/641', person: 'Hallie Abbott', signatureDate: '2013.09.15', preparationDate: '2013.09.14', installmentAmount: 360, installmentType: 'equal', interestRate: 0.596, margin: 0.15, temporaryIncrease: 6.00, liborType: '12M', currency: 'EUR', calculationDay: 12, paymentDayNo: 27, status: 'active', optionalNotes: 'Quas voluptates ut nesciunt.'},
      // { localId: null, id: 10, name: '23610/2008', ownerUserId: 2, ownerCompanyId: 3, amount: '3820000', bank: 'PKO', agreementNo: '2970/3ra/178', person: 'Keith Wilkinson', signatureDate: '2012.07.02', preparationDate: '2012.06.29', installmentAmount: 324, installmentType: 'decreasing', interestRate: 0.445, margin: 0.18, temporaryIncrease: 4.00, liborType: '12M', currency: 'EUR', calculationDay: 20, paymentDayNo: 13, status: 'shared', optionalNotes: 'Sequi modi sunt dolorem alias.'},
      // { localId: null, id: 11, name: '83258/2014', ownerUserId: 2, ownerCompanyId: 3, amount: '3618000', bank: 'Millenium', agreementNo: '1036/fol/601', person: 'Mrs. Nicola Konopelski', signatureDate: '2008.02.25', preparationDate: '2008.02.23', installmentAmount: 204, installmentType: 'decreasing', interestRate: 0.175, margin: 0.16, temporaryIncrease: 4.00, liborType: '6M', currency: 'JPY', calculationDay: 28, paymentDayNo: 9, status: 'active', optionalNotes: 'Consequatur repellendus provident sunt pariatur qui recusandae et distinctio.'},
      // { localId: null, id: 12, name: '57068/2012', ownerUserId: 7, ownerCompanyId: 2, amount: '3530000', bank: 'PKO', agreementNo: '6915/0sy/242', person: 'Elias Nienow', signatureDate: '2017.11.17', preparationDate: '2017.11.11', installmentAmount: 228, installmentType: 'decreasing', interestRate: 0.987, margin: 0.13, temporaryIncrease: 3.00, liborType: '12M', currency: 'JPY', calculationDay: 14, paymentDayNo: 20, status: 'active', optionalNotes: 'Sed vero qui quo commodi voluptas id sed pariatur cum.'},
      // { localId: null, id: 13, name: '74755/2006', ownerUserId: 3, ownerCompanyId: 1, amount: '820000', bank: 'Millenium', agreementNo: '1461/noh/910', person: 'Johathan Mayert', signatureDate: '2006.06.14', preparationDate: '2006.06.13', installmentAmount: 240, installmentType: 'decreasing', interestRate: 0.1233, margin: 0.13, temporaryIncrease: 3.00, liborType: '12M', currency: 'USD', calculationDay: 17, paymentDayNo: 14, status: 'active', optionalNotes: 'Tempore ullam saepe assumenda qui.'},
      // { localId: null, id: 14, name: '32278/2012', ownerUserId: 3, ownerCompanyId: 1, amount: '1442000', bank: 'DeutscheBank', agreementNo: '1725/xlf/666', person: 'Carolanne Auer', signatureDate: '2013.08.04', preparationDate: '2013.08.01', installmentAmount: 360, installmentType: 'equal', interestRate: 0.922, margin: 0.20, temporaryIncrease: 4.00, liborType: '3M', currency: 'CHF', calculationDay: 12, paymentDayNo: 3, status: 'active', optionalNotes: 'Eos fuga labore ducimus molestiae eveniet sed cumque quis quia.'},
      // { localId: null, id: 15, name: '67456/2019', ownerUserId: 6, ownerCompanyId: 1, amount: '3806000', bank: 'Nordea', agreementNo: '9700/8gl/342', person: 'Daisha Stokes', signatureDate: '2011.12.25', preparationDate: '2011.12.17', installmentAmount: 264, installmentType: 'equal', interestRate: 0.524, margin: 0.12, temporaryIncrease: 7.00, liborType: '12M', currency: 'JPY', calculationDay: 2, paymentDayNo: 27, status: 'active', optionalNotes: 'Fugit pariatur placeat minima ratione cum ad unde iste.'},
      // { localId: null, id: 16, name: '57057/2015', ownerUserId: 5, ownerCompanyId: 3, amount: '1003000', bank: 'IdeaBank', agreementNo: '3275/hx5/819', person: 'Dr. Jorge Kuphal', signatureDate: '2012.12.22', preparationDate: '2012.12.19', installmentAmount: 276, installmentType: 'equal', interestRate: 0.1188, margin: 0.13, temporaryIncrease: 7.00, liborType: '12M', currency: 'EUR', calculationDay: 9, paymentDayNo: 22, status: 'active', optionalNotes: 'Aut aut atque qui qui animi ipsa nostrum praesentium minima.'},
      // { localId: null, id: 17, name: '24456/2010', ownerUserId: 1, ownerCompanyId: 2, amount: '1716000', bank: 'DeutscheBank', agreementNo: '6971/a6w/658', person: 'Weston Kemmer', signatureDate: '2015.01.08', preparationDate: '2014.12.28', installmentAmount: 216, installmentType: 'decreasing', interestRate: 0.283, margin: 0.7, temporaryIncrease: 6.00, liborType: '12M', currency: 'EUR', calculationDay: 1, paymentDayNo: 13, status: 'active', optionalNotes: 'Est et aut reiciendis et enim qui placeat.'},
      // { localId: null, id: 18, name: '36044/2006', ownerUserId: 1, ownerCompanyId: 2, amount: '4782000', bank: 'Millenium', agreementNo: '6496/2gz/855', person: 'Julianne Friesen', signatureDate: '2015.12.08', preparationDate: '2015.11.30', installmentAmount: 180, installmentType: 'equal', interestRate: 0.1643, margin: 0.16, temporaryIncrease: 5.00, liborType: '6M', currency: 'EUR', calculationDay: 7, paymentDayNo: 21, status: 'active', optionalNotes: 'Eum laudantium quaerat temporibus id nemo dicta vel quia.'},
      // { localId: null, id: 19, name: '22880/2014', ownerUserId: 4, ownerCompanyId: 2, amount: '2749000', bank: 'DeutscheBank', agreementNo: '5644/8ua/104', person: 'Else Kunze Jr.', signatureDate: '2006.07.07', preparationDate: '2006.06.26', installmentAmount: 336, installmentType: 'decreasing', interestRate: 0.1716, margin: 0.11, temporaryIncrease: 8.00, liborType: '6M', currency: 'USD', calculationDay: 10, paymentDayNo: 26, status: 'active', optionalNotes: 'Dicta qui ea quia ab ut doloremque qui.'},
      // { localId: null, id: 20, name: '93879/2012', ownerUserId: 10, ownerCompanyId: 2, amount: '2506000', bank: 'Alior', agreementNo: '9947/yew/182', person: 'Darrell Schinner', signatureDate: '2017.07.07', preparationDate: '2017.07.06', installmentAmount: 216, installmentType: 'equal', interestRate: 0.1944, margin: 0.13, temporaryIncrease: 8.00, liborType: '1M', currency: 'JPY', calculationDay: 22, paymentDayNo: 14, status: 'active', optionalNotes: 'Similique et quam.'},
      // { localId: null, id: 21, name: '97072/2010', ownerUserId: 6, ownerCompanyId: 1, amount: '4684000', bank: 'mBank', agreementNo: '4724/6hd/276', person: 'Kaycee Ryan MD', signatureDate: '2016.09.01', preparationDate: '2016.08.25', installmentAmount: 204, installmentType: 'equal', interestRate: 0.1561, margin: 0.8, temporaryIncrease: 4.00, liborType: '1M', currency: 'USD', calculationDay: 21, paymentDayNo: 11, status: 'shared', optionalNotes: 'Autem illum eos aut expedita molestiae voluptatem dolore magnam quis.'},
      // { localId: null, id: 22, name: '13560/2015', ownerUserId: 5, ownerCompanyId: 3, amount: '3554000', bank: 'GetinNoble', agreementNo: '7951/365/747', person: 'Ali Hill', signatureDate: '2018.09.22', preparationDate: '2018.09.14', installmentAmount: 288, installmentType: 'decreasing', interestRate: 0.761, margin: 0.10, temporaryIncrease: 8.00, liborType: '12M', currency: 'CHF', calculationDay: 21, paymentDayNo: 17, status: 'active', optionalNotes: 'Dolores similique quidem cupiditate repellat.'},
      // { localId: null, id: 23, name: '15947/2012', ownerUserId: 4, ownerCompanyId: 2, amount: '3582000', bank: 'Nordea', agreementNo: '3154/8n1/614', person: 'Ms. Noelia Dietrich', signatureDate: '2006.07.07', preparationDate: '2006.06.28', installmentAmount: 204, installmentType: 'equal', interestRate: 0.57, margin: 0.16, temporaryIncrease: 4.00, liborType: '12M', currency: 'EUR', calculationDay: 19, paymentDayNo: 21, status: 'shared', optionalNotes: 'Qui laborum minus totam.'},
      // { localId: null, id: 24, name: '77136/2018', ownerUserId: 5, ownerCompanyId: 3, amount: '1911000', bank: 'DeutscheBank', agreementNo: '9229/kyz/439', person: 'Zetta Ruecker', signatureDate: '2019.07.24', preparationDate: '2019.07.22', installmentAmount: 312, installmentType: 'decreasing', interestRate: 0.591, margin: 0.11, temporaryIncrease: 7.00, liborType: '12M', currency: 'EUR', calculationDay: 4, paymentDayNo: 9, status: 'active', optionalNotes: 'Quia corrupti et blanditiis cupiditate.'},
      // { localId: null, id: 25, name: '16437/2005', ownerUserId: 4, ownerCompanyId: 2, amount: '3534000', bank: 'Alior', agreementNo: '3517/mi3/699', person: 'Violet Hermann', signatureDate: '2015.11.03', preparationDate: '2015.10.27', installmentAmount: 324, installmentType: 'decreasing', interestRate: 0.472, margin: 0.8, temporaryIncrease: 7.00, liborType: '6M', currency: 'EUR', calculationDay: 10, paymentDayNo: 26, status: 'active', optionalNotes: 'Possimus ex deleniti est illo et et.'},
    ],
  },
  reports: {
    meta: {loading: false, errors: []},
    data: [
      // { id: 1, caseId: 1, type: 'r1', ownerUserId: 1, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: 'Lorem ipsum dolor cat amet.', status: 'trashed' },
      // { id: 2, caseId: 1, type: 'r2', ownerUserId: 1, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: '', status: 'active' },
      // { id: 3, caseId: 1, type: 'r3', ownerUserId: 2, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: '', status: 'active' },
      // { id: 4, caseId: 1, type: 'r1', ownerUserId: 1, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: '', status: 'active' },
      // { id: 5, caseId: 1, type: 'r2', ownerUserId: 1, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: 'Also, lorem ipsum dolor cat amet.', status: 'active' },
      // { id: 6, caseId: 1, type: 'r3', ownerUserId: 2, creationDateTime: '2020-01-14T13:18:27.153Z', removalDate: '2015.10.27', description: '', status: 'active' },
    ],
  },
  companies: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, name: 'Pro Bono Consultancy', legalName: 'Pro Bono Consultancy sp. z o.o.', taxNumber: '260735633754', addressStreetName: 'Botaniczna', addressBuildingNo: '59', addressPostalCode: '80-298', addressCity: 'Gdańsk', web: 'https://google.pl', creationDate: '03.02.2020', status: 'active', logoUrl: 'https://logoipsum.com/logo/logo-1.svg', manager: 'Moya Sayer-Jones', accountNumber: '87249000052227057876458978', phone: '202-555-096', companyEmail: 'office@probonoconsultancy.com', notificationsEmail: 'accounting@probonoconsultancy.com'},
      // {id: 2, name: 'Sullivan & Rothman', legalName: 'Sullivan & Rothman sp. z o.o.', taxNumber: '790470149719', addressStreetName: 'Kochanowskiego', addressBuildingNo: '17', addressPostalCode: '20-434', addressCity: 'Lublin', web: 'https://google.pl', creationDate: '14.07.2020', status: 'pending_activation', logoUrl: 'https://logoipsum.com/logo/logo-11.svg', manager: 'Lucia Berlin', accountNumber: '51132000067974965277723172', phone: '843-555-069', companyEmail: 'contact@sullivanrothman.com', notificationsEmail: 'accounting@sullivanrothman.com'},
      // {id: 3, name: 'Smith Corporate Law', legalName: 'Smith Corporate Law sp. z o.o.', taxNumber: '148610950546', addressStreetName: 'Zadroże', addressBuildingNo: '96/2', addressPostalCode: '03-684', addressCity: 'Warszawa', web: 'https://google.pl', creationDate: '25.01.2020', status: 'deactivated', logoUrl: 'https://logoipsum.com/logo/logo-12.svg', manager: 'Neil Tyson', accountNumber: '85249000059450669451570233', phone: '573-555-015', companyEmail: 'company@smithcorp.com', notificationsEmail: 'accounting@smithcorp.com'},
      // {id: 4, name: 'Affaires Legales', legalName: 'Affaires Legales sp. z o.o.', taxNumber: '93759461735', addressStreetName: 'Legnicka', addressBuildingNo: '152/34', addressPostalCode: '54-204', addressCity: 'Wrocław', web: 'https://google.pl', creationDate: '25.01.2020', status: 'erased', logoUrl: 'https://logoipsum.com/logo/logo-9.svg', manager: 'Neil Tyson', accountNumber: '85249000059450669451570233', phone: '234-367-656', companyEmail: 'company@smithcorp.com', notificationsEmail: 'accounting@affaireslegales.com'},
      // {id: 5, name: 'Legal Capital', legalName: 'Legal Capital sp. z o.o.', taxNumber: '26408613564', addressStreetName: 'Krokiew', addressBuildingNo: '30', addressPostalCode: '92-006', addressCity: 'Łódź', web: 'https://google.pl', creationDate: '25.01.2020', status: 'erased', logoUrl: 'https://logoipsum.com/logo/logo-12.svg', manager: 'Anne Rice', accountNumber: '85249000059450669451570233', phone: '653-789-432', companyEmail: 'company@smithcorp.com', notificationsEmail: 'accounting@legalcapital.com'},
      // {id: 6, name: 'Capital Tax Lawyers', legalName: 'Capital Tax Lawyers sp. z o.o.', taxNumber: '26408153864', addressStreetName: 'Zbrachlińska', addressBuildingNo: '39/3', addressPostalCode: '85-569', addressCity: 'Bydgoszcz', web: 'https://google.pl', creationDate: '25.01.2020', status: 'deactivated', logoUrl: 'https://logoipsum.com/logo/logo-12.svg', manager: 'Steven Pinker', accountNumber: '85249000059450669451570233', phone: '265-457-327', companyEmail: 'company@smithcorp.com', notificationsEmail: 'accounting@capitaltaxlawyers.com'},
    ],
  },
  registrations:             { data: [], meta: {loading: false, errors: []}},
  users: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, companyId: 2, login: 'j-paxman', email: 'paxman@sullivanrothman.com', firstName: 'Jeremy', lastName: 'Paxman', status: 'active', avatar: 'https://randomuser.me/api/portraits/men/75.jpg', role: 'regular'},
      // {id: 2, companyId: 3, login: 'e-morgenstern', email: 'morgenstern@smithcorp.com', firstName: 'Erin', lastName: 'Morgenstern', status: 'pending_activation', avatar: 'https://randomuser.me/api/portraits/women/72.jpg', role: 'regular'},
      // {id: 3, companyId: 1, login: 'k-chopin', email: 'chopin@probonoconsultancy.com', firstName: 'Kate', lastName: 'Chopin', status: 'pending_admin_approval', avatar: 'https://randomuser.me/api/portraits/women/28.jpg', role: 'regular'},
      // {id: 4, companyId: 2, login: 'i-thornton', email: 'thornton@sullivanrothman.com', firstName: 'Ian', lastName: 'Thornton', status: 'deactivated', avatar: 'https://randomuser.me/api/portraits/men/28.jpg', role: 'regular'},
      // {id: 5, companyId: 3, login: 'p-pullman', email: 'pullman@smithcorp.com', firstName: 'Philip', lastName: 'Pullman', status: 'erased', avatar: 'https://randomuser.me/api/portraits/men/91.jpg', role: 'regular'},
      // {id: 6, companyId: 1, login: 'a-niffenegger', email: 'niffenegger@probonoconsultancy.com', firstName: 'Audrey', lastName: 'Niffenegger', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/27.jpg', role: 'regular'},
      // {id: 7, companyId: 2, login: 'p-beatty', email: 'beatty@sullivanrothman.com', firstName: 'Paul', lastName: 'Beatty', status: 'active', avatar: 'https://randomuser.me/api/portraits/men/98.jpg', role: 'regular'},
      // {id: 8, companyId: 3, login: 'j-burke', email: 'burke@smithcorp.com', firstName: 'James', lastName: 'Burke', status: 'active', avatar: 'https://randomuser.me/api/portraits/men/45.jpg', role: 'regular'},
      // {id: 9, companyId: 1, login: 'm-axelsson', email: 'axelsson@probonoconsultancy.com', firstName: 'Majgull', lastName: 'Axelsson', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/47.jpg', role: 'regular'},
      // {id: 10, companyId: 2, login: 'j-crouch', email: 'crouch@sullivanrothman.com', firstName: 'Julia', lastName: 'Crouch', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/89.jpg', role: 'supermanager'},
      // {id: 11, companyId: 3, login: 'a-duncan', email: 'duncan@smithcorp.com', firstName: 'Andrew', lastName: 'Duncan', status: 'erased', avatar: 'https://randomuser.me/api/portraits/men/61.jpg', role: 'supermanager'},
      // {id: 12, companyId: 1, login: 'm-sayer-jones', email: 'sayerjones@probonoconsultancy.com', firstName: 'Moya', lastName: 'Sayer-Jones', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/38.jpg', role: 'manager'},
      // {id: 13, companyId: 2, login: 'l-berlin', email: 'berlin@sullivanrothman.com', firstName: 'Lucia', lastName: 'Berlin', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/39.jpg', role: 'manager'},
      // {id: 14, companyId: 3, login: 'n-tyson', email: 'tyson@smithcorp.com', firstName: 'Neil', lastName: 'Tyson', status: 'active', avatar: 'https://randomuser.me/api/portraits/men/83.jpg', role: 'manager'},
      // {id: 15, companyId: 3, login: 'd-dumaurier', email: 'dumaurier@projuste.pl', firstName: 'Daphne', lastName: 'du Maurier', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/83.jpg', role: 'admin'},
      // {id: 16, companyId: 3, login: 'f-mayes', email: 'mayes@projuste.pl', firstName: 'Frances', lastName: 'Moyes', status: 'active', avatar: 'https://randomuser.me/api/portraits/women/41.jpg', role: 'admin'},
      // {id: 17, companyId: 3, login: 'j-eugenides', email: 'eugenides@projuste.pl', firstName: 'Jeffrey', lastName: 'Eugenides', status: 'deactivated', avatar: 'https://randomuser.me/api/portraits/men/6.jpg', role: 'admin'},
      // {id: 18, companyId: 3, login: 'a-hoffman', email: 'hoffman@projuste.pl', firstName: 'Alice', lastName: 'Hoffman', status: 'pending_activation', avatar: 'https://randomuser.me/api/portraits/women/21.jpg', role: 'admin'},
    ],
  },
  receipts: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, number: 'FV_12345', companyId: 1, month: 'Styczeń', year: '2020', reportPrice: '125', allReportsPrice: '', caseTransferPrice: '285', allTransferredCasesPrice: '23', accountsPrice: '534', accountsNumber: '3', reportsNumber: '10', transferredCases: '0', amount: '3500', issueStatus: 'issued', paymentStatus: 'unpaid'},
      // {id: 2, number: 'FV_54267', companyId: 2, month: 'Marzec', year: '2020', reportPrice: '230', allReportsPrice: '', caseTransferPrice: '200', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '30', reportsNumber: '5', transferredCases: '17', amount: '5500', issueStatus: 'unissued', paymentStatus: 'unpaid'},
      // {id: 3, number: 'FV_78326', companyId: 3, month: 'Luty', year: '2020', reportPrice: '100', allReportsPrice: '', caseTransferPrice: '250', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '23', reportsNumber: '8', transferredCases: '12', amount: '1200', issueStatus: 'unissued', paymentStatus: 'unpaid'},
      // {id: 4, number: 'FV_08735', companyId: 4, month: 'Maj', year: '2020', reportPrice: '125', allReportsPrice: '', caseTransferPrice: '285', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '5', reportsNumber: '7', transferredCases: '15', amount: '2550', issueStatus: 'issued', paymentStatus: 'paid'},
      // {id: 5, number: 'FV_48264', companyId: 5, month: 'Kwiecień', year: '2020', reportPrice: '150', allReportsPrice: '', caseTransferPrice: '285', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '7', reportsNumber: '11', transferredCases: '0', amount: '1500', issueStatus: 'unissued', paymentStatus: 'unpaid'},
      // {id: 6, number: 'FV_44892', companyId: 6, month: 'Luty', year: '2020', reportPrice: '125', allReportsPrice: '', caseTransferPrice: '125', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '3', reportsNumber: '23', transferredCases: '1', amount: '3900', issueStatus: 'unissued', paymentStatus: 'unpaid'},
      // {id: 7, number: 'FV_39462', companyId: 1, month: 'Marzec', year: '2020', reportPrice: '125', allReportsPrice: '', caseTransferPrice: '200', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '3', reportsNumber: '10', transferredCases: '2', amount: '2200', issueStatus: 'issued', paymentStatus: 'unpaid'},
      // {id: 8, number: 'FV_11957', companyId: 2, month: 'Maj', year: '2020', reportPrice: '125', allReportsPrice: '', caseTransferPrice: '230', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '19', reportsNumber: '10', transferredCases: '4', amount: '1900', issueStatus: 'issued', paymentStatus: 'paid'},
      // {id: 9, number: 'FV_39105', companyId: 3, month: 'Kwiecień', year: '2020', reportPrice: '175', allReportsPrice: '', caseTransferPrice: '250', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '13', reportsNumber: '45', transferredCases: '3', amount: '2750', issueStatus: 'unissued', paymentStatus: 'unpaid'},
      // {id: 10, number: 'FV_92737', companyId: 4, month: 'Styczeń', year: '2020', reportPrice: '120', allReportsPrice: '', caseTransferPrice: '285', allTransferredCasesPrice: '', accountsPrice: '534', accountsNumber: '8', reportsNumber: '10', transferredCases: '0', amount: '2450', issueStatus: 'issued', paymentStatus: 'unpaid'},
    ],
  },

  banks: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, name: 'Alior', exchangeRatesUrl: 'https://example.test', workdaySearchMode: -1, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 2, name: 'Deutsche Bank', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 1, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 3, name: 'Nordea', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 4, name: 'GetinNoble', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 5, name: 'mBank', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 6, name: 'Millenium', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 7, name: 'IdeaBank', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 8, name: 'PKO', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 9, name: 'Credit Agricole', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 11, name: 'ING', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
      // {id: 11, name: 'Raiffeisen', exchangeRatesUrl: 'https://example.test', workdaySearchMode: 0, daysInYearPayment: -1, daysInYearInterest: -1, daysInYearPrincipal: -1},
    ],
  },
  exchangeRates: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, currency: 'PLN', date: '2020.01.01', value: 1.23, type: 'avg', source: 'nbp' },
      // {id: 2, currency: 'PLN', date: '2020.01.02', value: 1.24, type: 'bid', source: 'bank' },
      // {id: 3, currency: 'PLN', date: '2020.01.03', value: 1.25, type: 'ask', source: 'nbp' },
      // {id: 4, currency: 'PLN', date: '2020.01.04', value: 1.26, type: 'avg', source: 'nbp' },
      // {id: 5, currency: 'PLN', date: '2020.01.05', value: 1.27, type: 'avg', source: 'nbp' },
      // {id: 6, currency: 'PLN', date: '2020.01.06', value: 1.28, type: 'avg', source: 'nbp' },
      // {id: 7, currency: 'PLN', date: '2020.01.07', value: 1.29, type: 'avg', source: 'nbp' },
      // {id: 8, currency: 'PLN', date: '2020.01.08', value: 1.30, type: 'avg', source: 'nbp' },
    ],
  },
  holidays: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, start: '2020.01.01', end: '2020.01.01', name: 'Lorem 1', substitute: false, note: ''},
      // {id: 2, start: '2020.02.01', end: '2020.02.02', name: 'Lorem 2', substitute: true, note: ''},
      // {id: 3, start: '2020.03.01', end: '2020.03.03', name: 'Lorem 3', substitute: false, note: 'Notatka do święta'},
    ],
  },
  ibors: {
    meta: {loading: false, errors: []},
    data: [
      // {id: 1, date: '2020.01.01', value: 12.34, type: 'WIBOR3M'},
      // {id: 2, date: '2020.01.02', value: 13.46, type: 'EURIBOR3M'},
      // {id: 3, date: '2020.01.03', value: 14.58, type: 'CHF_LIBOR3M'},
    ],
  },
  paymentTypes:             { data: [], meta: {loading: false, errors: []}},
  phrases:             { data: [], meta: {loading: false, errors: []}},
  settings:                 { data: [], meta: {loading: false, errors: []}},
  caseBankRate:             { data: [], meta: {loading: false, errors: []}},
  caseCost:                 { data: [], meta: {loading: false, errors: []}},
  casePayment:              { data: [], meta: {loading: false, errors: []}},
  caseTranche:              { data: [], meta: {loading: false, errors: []}},
  caseInstallmentAmount:    { data: [], meta: {loading: false, errors: []}},
  caseInstallmentOverride:  { data: [], meta: {loading: false, errors: []}},
  caseInstallmentDay:       { data: [], meta: {loading: false, errors: []}},
  caseInstallmentType:      { data: [], meta: {loading: false, errors: []}},
  casePaymentHolidays:      { data: [], meta: {loading: false, errors: []}},

  caseSimulation: {
    meta: {loading: false, errors: []},
    data: {},
    /* [
      {
        "caseId": 1,
        "table": {
          "rowSummary": {
            "_calculationWarns": [],
            "index": 0,
            "date": "2008-11-04T00:00:00.000Z",
            "simulationBalancePln": 41586.12,
            "simulationBalanceForeign": 17921.21,
            "bankBalanceForeign": 17921.49,
            "days": 4138,
            "bankRate": 0.0082152,
            "bankRateReversed": 0.0082069,
            "periodsTotal": 135,
            "bankCollectedPln": 275729.12,
            "bankCollectedForeign": 78531.78,
            "bankCollectedOverpaymentPln": 0,
            "bankCollectedOverpaymentForeign": 0,
            "bankCollectedInterestPln": 19380.38,
            "bankCollectedInterestForeign": 5938.29,
            "bankCollectedPrincipalPln": 256348.74,
            "bankCollectedPrincipalForeign": 72593.49,
            "paymentPln": 182233.51,
            "paymentForeign": 78532.01,
            "principalPln": 168453.89,
            "principalForeign": 72593.77,
            "interestPln": 13780.3,
            "interestForeign": 5938.52,
            "paymentDifferencePln": 93495.61,
            "controlSumForeign": 0.28
          },
          "rows": [
            {
              "_calculationWarns": [
                "pKapitał 648.86"
              ],
              "index": 2, //Nr raty
              "date": "2009-02-01T00:00:00.000Z", // Data transzy
              "currencyDate": "2009-02-02T00:00:00.000Z", //Data waluty (dzień roboczy)
              "_payments": [
                {
                  "id": 3,
                  "caseId": 1,
                  "position": 2,
                  "type": "Odsetki",
                  "date": "2009-02-02",
                  "transactionDate": "2009-02-02",
                  "installmentType": "equal",
                  "amountPln": 769.49,
                  "amountForeign": 249.73,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 4,
                  "caseId": 1,
                  "position": 3,
                  "type": "Kapitał",
                  "date": "2009-02-02",
                  "transactionDate": "2009-02-02",
                  "installmentType": "equal",
                  "amountPln": 1999.34,
                  "amountForeign": 648.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-02-02T00:00:00.000Z", //Data raty (wg umowy)
              "bankCollectedPln": 2768.83, // PLN bank pobrał (bez nadpłat)
              "bankCollectedForeign": 898.59,
              "bankCollectedInterestPln": 769.49,
              "bankCollectedInterestForeign": 249.73, // Bank pobrał – odsetki (CHF)
              "bankCollectedOverpaymentForeign": 0, // Nadpłacone kwoty (CHF)
              "bankCollectedOverpaymentPln": 0, // Nadpłacone kwoty (PLN)
              "bankCollectedPrincipalForeign": 648.86, // Bank pobrał – kapitał (CHF)
              "bankCollectedPrincipalPln": 1999.34,
              "paymentPln": 2085.18, // Kwota transzy (PLN)
              "paymentForeign": 898.59, // Kwota transzy (CHF)
              "interestPln": 579.5, // Odsetki (PLN)
              "interestForeign": 249.73, // Rata odsetki (CHF)
              "principalPln": 1505.69, // Kapitał (PLN)
              "principalForeign": 648.86,
              "days": 31, // # dni dla odsetek
              "paymentDifferencePln": 683.65, // Różnica saldo wpłat z zaśw. i z kalkulatora
              "periodsTotal": 118, // # rat
              "simulationBalanceForeign": 89270.34,
              "simulationBalancePln": 207151.81,
              "bankBalanceForeign": 89270.34,
              "bankRate": 0.0327,
              "bankRateReversed": 0.0327001,
              "controlSumForeign": 0
            },
            {
              "_calculationWarns": [
                "pKapitał 691.15"
              ],
              "index": 3,
              "date": "2009-03-01T00:00:00.000Z",
              "currencyDate": "2009-03-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 5,
                  "caseId": 1,
                  "position": 4,
                  "type": "Odsetki",
                  "date": "2009-03-02",
                  "transactionDate": "2009-03-02",
                  "installmentType": "equal",
                  "amountPln": 607.78,
                  "amountForeign": 182.85,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 6,
                  "caseId": 1,
                  "position": 5,
                  "type": "Kapitał",
                  "date": "2009-03-02",
                  "transactionDate": "2009-03-02",
                  "installmentType": "equal",
                  "amountPln": 2297.31,
                  "amountForeign": 691.15,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-03-02T00:00:00.000Z",
              "bankCollectedPln": 2905.09,
              "bankCollectedForeign": 874,
              "bankCollectedInterestPln": 607.78,
              "bankCollectedInterestForeign": 182.85,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 691.15,
              "bankCollectedPrincipalPln": 2297.31,
              "paymentPln": 2028.13,
              "paymentForeign": 874,
              "interestPln": 424.29,
              "interestForeign": 182.85,
              "principalPln": 1603.83,
              "principalForeign": 691.16,
              "days": 28,
              "paymentDifferencePln": 876.96,
              "periodsTotal": 118,
              "simulationBalanceForeign": 88579.18,
              "simulationBalancePln": 205547.98,
              "bankBalanceForeign": 88579.19,
              "bankRate": 0.0267,
              "bankRateReversed": 0.0267007,
              "controlSumForeign": 0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 677.87"
              ],
              "index": 4,
              "date": "2009-04-01T00:00:00.000Z",
              "currencyDate": "2009-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 7,
                  "caseId": 1,
                  "position": 6,
                  "type": "Odsetki",
                  "date": "2009-04-01",
                  "transactionDate": "2009-04-01",
                  "installmentType": "equal",
                  "amountPln": 596.18,
                  "amountForeign": 190.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 8,
                  "caseId": 1,
                  "position": 7,
                  "type": "Kapitał",
                  "date": "2009-04-01",
                  "transactionDate": "2009-04-01",
                  "installmentType": "equal",
                  "amountPln": 2123.23,
                  "amountForeign": 677.87,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-04-01T00:00:00.000Z",
              "bankCollectedPln": 2719.41,
              "bankCollectedForeign": 868.21,
              "bankCollectedInterestPln": 596.18,
              "bankCollectedInterestForeign": 190.34,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 677.87,
              "bankCollectedPrincipalPln": 2123.23,
              "paymentPln": 2014.68,
              "paymentForeign": 868.21,
              "interestPln": 441.68,
              "interestForeign": 190.34,
              "principalPln": 1573,
              "principalForeign": 677.87,
              "days": 31,
              "paymentDifferencePln": 704.73,
              "periodsTotal": 118,
              "simulationBalanceForeign": 87901.31,
              "simulationBalancePln": 203974.98,
              "bankBalanceForeign": 87901.32,
              "bankRate": 0.0253,
              "bankRateReversed": 0.0253005,
              "controlSumForeign": 0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 718.41"
              ],
              "index": 5,
              "date": "2009-05-01T00:00:00.000Z",
              "currencyDate": "2009-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 10,
                  "caseId": 1,
                  "position": 9,
                  "type": "Kapitał",
                  "date": "2009-05-04",
                  "transactionDate": "2009-05-04",
                  "installmentType": "equal",
                  "amountPln": 2154.22,
                  "amountForeign": 718.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 9,
                  "caseId": 1,
                  "position": 8,
                  "type": "Odsetki",
                  "date": "2009-05-04",
                  "transactionDate": "2009-05-04",
                  "installmentType": "equal",
                  "amountPln": 331.47,
                  "amountForeign": 110.54,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-05-04T00:00:00.000Z",
              "bankCollectedPln": 2485.69,
              "bankCollectedForeign": 828.95,
              "bankCollectedInterestPln": 331.47,
              "bankCollectedInterestForeign": 110.54,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 718.41,
              "bankCollectedPrincipalPln": 2154.22,
              "paymentPln": 1923.58,
              "paymentForeign": 828.95,
              "interestPln": 256.51,
              "interestForeign": 110.54,
              "principalPln": 1667.07,
              "principalForeign": 718.41,
              "days": 30,
              "paymentDifferencePln": 562.11,
              "periodsTotal": 118,
              "simulationBalanceForeign": 87182.9,
              "simulationBalancePln": 202307.91,
              "bankBalanceForeign": 87182.91,
              "bankRate": 0.0153,
              "bankRateReversed": 0.0153001,
              "controlSumForeign": 0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 720.29"
              ],
              "index": 6,
              "date": "2009-06-01T00:00:00.000Z",
              "currencyDate": "2009-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 11,
                  "caseId": 1,
                  "position": 10,
                  "type": "Odsetki",
                  "date": "2009-06-01",
                  "transactionDate": "2009-06-01",
                  "installmentType": "equal",
                  "amountPln": 314.07,
                  "amountForeign": 103.66,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 12,
                  "caseId": 1,
                  "position": 11,
                  "type": "Kapitał",
                  "date": "2009-06-01",
                  "transactionDate": "2009-06-01",
                  "installmentType": "equal",
                  "amountPln": 2182.33,
                  "amountForeign": 720.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-06-01T00:00:00.000Z",
              "bankCollectedPln": 2496.4,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 314.07,
              "bankCollectedInterestForeign": 103.66,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 720.29,
              "bankCollectedPrincipalPln": 2182.33,
              "paymentPln": 1911.98,
              "paymentForeign": 823.95,
              "interestPln": 240.55,
              "interestForeign": 103.66,
              "principalPln": 1671.43,
              "principalForeign": 720.29,
              "days": 31,
              "paymentDifferencePln": 584.42,
              "periodsTotal": 118,
              "simulationBalanceForeign": 86462.61,
              "simulationBalancePln": 200636.48,
              "bankBalanceForeign": 86462.62,
              "bankRate": 0.014,
              "bankRateReversed": 0.0139995,
              "controlSumForeign": 0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 724.46"
              ],
              "index": 7,
              "date": "2009-07-01T00:00:00.000Z",
              "currencyDate": "2009-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 13,
                  "caseId": 1,
                  "position": 12,
                  "type": "Odsetki",
                  "date": "2009-07-01",
                  "transactionDate": "2009-07-01",
                  "installmentType": "equal",
                  "amountPln": 296.04,
                  "amountForeign": 99.49,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 14,
                  "caseId": 1,
                  "position": 13,
                  "type": "Kapitał",
                  "date": "2009-07-01",
                  "transactionDate": "2009-07-01",
                  "installmentType": "equal",
                  "amountPln": 2155.71,
                  "amountForeign": 724.46,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-07-01T00:00:00.000Z",
              "bankCollectedPln": 2451.75,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 296.04,
              "bankCollectedInterestForeign": 99.49,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 724.46,
              "bankCollectedPrincipalPln": 2155.71,
              "paymentPln": 1912.03,
              "paymentForeign": 823.97,
              "interestPln": 230.87,
              "interestForeign": 99.49,
              "principalPln": 1681.16,
              "principalForeign": 724.48,
              "days": 30,
              "paymentDifferencePln": 539.72,
              "periodsTotal": 118,
              "simulationBalanceForeign": 85738.13,
              "simulationBalancePln": 198955.32,
              "bankBalanceForeign": 85738.16,
              "bankRate": 0.014,
              "bankRateReversed": 0.0139998,
              "controlSumForeign": 0.03
            },
            {
              "_calculationWarns": [
                "pKapitał 722"
              ],
              "index": 8,
              "date": "2009-08-01T00:00:00.000Z",
              "currencyDate": "2009-08-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 16,
                  "caseId": 1,
                  "position": 15,
                  "type": "Kapitał",
                  "date": "2009-08-03",
                  "transactionDate": "2009-08-03",
                  "installmentType": "equal",
                  "amountPln": 2008.1,
                  "amountForeign": 722,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 15,
                  "caseId": 1,
                  "position": 14,
                  "type": "Odsetki",
                  "date": "2009-08-03",
                  "transactionDate": "2009-08-03",
                  "installmentType": "equal",
                  "amountPln": 283.55,
                  "amountForeign": 101.95,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-08-03T00:00:00.000Z",
              "bankCollectedPln": 2291.65,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 283.55,
              "bankCollectedInterestForeign": 101.95,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 722,
              "bankCollectedPrincipalPln": 2008.1,
              "paymentPln": 1912,
              "paymentForeign": 823.96,
              "interestPln": 236.57,
              "interestForeign": 101.95,
              "principalPln": 1675.43,
              "principalForeign": 722.01,
              "days": 31,
              "paymentDifferencePln": 379.65,
              "periodsTotal": 118,
              "simulationBalanceForeign": 85016.12,
              "simulationBalancePln": 197279.89,
              "bankBalanceForeign": 85016.16,
              "bankRate": 0.014,
              "bankRateReversed": 0.0140005,
              "controlSumForeign": 0.04
            },
            {
              "_calculationWarns": [
                "pKapitał 722.86"
              ],
              "index": 9,
              "date": "2009-09-01T00:00:00.000Z",
              "currencyDate": "2009-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 17,
                  "caseId": 1,
                  "position": 16,
                  "type": "Odsetki",
                  "date": "2009-09-01",
                  "transactionDate": "2009-09-01",
                  "installmentType": "equal",
                  "amountPln": 280.79,
                  "amountForeign": 101.09,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 18,
                  "caseId": 1,
                  "position": 17,
                  "type": "Kapitał",
                  "date": "2009-09-01",
                  "transactionDate": "2009-09-01",
                  "installmentType": "equal",
                  "amountPln": 2007.81,
                  "amountForeign": 722.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-09-01T00:00:00.000Z",
              "bankCollectedPln": 2288.6,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 280.79,
              "bankCollectedInterestForeign": 101.09,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 722.86,
              "bankCollectedPrincipalPln": 2007.81,
              "paymentPln": 1912.04,
              "paymentForeign": 823.98,
              "interestPln": 234.57,
              "interestForeign": 101.09,
              "principalPln": 1677.46,
              "principalForeign": 722.89,
              "days": 31,
              "paymentDifferencePln": 376.56,
              "periodsTotal": 118,
              "simulationBalanceForeign": 84293.23,
              "simulationBalancePln": 195602.43,
              "bankBalanceForeign": 84293.3,
              "bankRate": 0.014,
              "bankRateReversed": 0.0140003,
              "controlSumForeign": 0.07
            },
            {
              "_calculationWarns": [
                "pKapitał 726.95"
              ],
              "index": 10,
              "date": "2009-10-01T00:00:00.000Z",
              "currencyDate": "2009-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 20,
                  "caseId": 1,
                  "position": 19,
                  "type": "Kapitał",
                  "date": "2009-10-01",
                  "transactionDate": "2009-10-01",
                  "installmentType": "equal",
                  "amountPln": 2091.22,
                  "amountForeign": 726.95,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 19,
                  "caseId": 1,
                  "position": 18,
                  "type": "Odsetki",
                  "date": "2009-10-01",
                  "transactionDate": "2009-10-01",
                  "installmentType": "equal",
                  "amountPln": 279.04,
                  "amountForeign": 97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-10-01T00:00:00.000Z",
              "bankCollectedPln": 2370.26,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 279.04,
              "bankCollectedInterestForeign": 97,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 726.95,
              "bankCollectedPrincipalPln": 2091.22,
              "paymentPln": 1912.08,
              "paymentForeign": 824,
              "interestPln": 225.08,
              "interestForeign": 97,
              "principalPln": 1687,
              "principalForeign": 727,
              "days": 30,
              "paymentDifferencePln": 458.18,
              "periodsTotal": 118,
              "simulationBalanceForeign": 83566.23,
              "simulationBalancePln": 193915.43,
              "bankBalanceForeign": 83566.35,
              "bankRate": 0.014,
              "bankRateReversed": 0.0140007,
              "controlSumForeign": 0.12
            },
            {
              "_calculationWarns": [
                "pKapitał 724.59"
              ],
              "index": 11,
              "date": "2009-11-01T00:00:00.000Z",
              "currencyDate": "2009-11-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 21,
                  "caseId": 1,
                  "position": 20,
                  "type": "Odsetki",
                  "date": "2009-11-02",
                  "transactionDate": "2009-11-02",
                  "installmentType": "equal",
                  "amountPln": 288.34,
                  "amountForeign": 99.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 22,
                  "caseId": 1,
                  "position": 21,
                  "type": "Kapitał",
                  "date": "2009-11-02",
                  "transactionDate": "2009-11-02",
                  "installmentType": "equal",
                  "amountPln": 2102.76,
                  "amountForeign": 724.59,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-11-02T00:00:00.000Z",
              "bankCollectedPln": 2391.1,
              "bankCollectedForeign": 823.95,
              "bankCollectedInterestPln": 288.34,
              "bankCollectedInterestForeign": 99.36,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 724.59,
              "bankCollectedPrincipalPln": 2102.76,
              "paymentPln": 1912.05,
              "paymentForeign": 823.98,
              "interestPln": 230.57,
              "interestForeign": 99.36,
              "principalPln": 1681.48,
              "principalForeign": 724.62,
              "days": 31,
              "paymentDifferencePln": 479.05,
              "periodsTotal": 118,
              "simulationBalanceForeign": 82841.61,
              "simulationBalancePln": 192233.95,
              "bankBalanceForeign": 82841.76,
              "bankRate": 0.014,
              "bankRateReversed": 0.0139995,
              "controlSumForeign": 0.15
            },
            {
              "_calculationWarns": [
                "pKapitał 732.19"
              ],
              "index": 12,
              "date": "2009-12-01T00:00:00.000Z",
              "currencyDate": "2009-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 23,
                  "caseId": 1,
                  "position": 22,
                  "type": "Odsetki",
                  "date": "2009-12-01",
                  "transactionDate": "2009-12-01",
                  "installmentType": "equal",
                  "amountPln": 247.87,
                  "amountForeign": 87.83,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 24,
                  "caseId": 1,
                  "position": 23,
                  "type": "Kapitał",
                  "date": "2009-12-01",
                  "transactionDate": "2009-12-01",
                  "installmentType": "equal",
                  "amountPln": 2066.39,
                  "amountForeign": 732.19,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2009-12-01T00:00:00.000Z",
              "bankCollectedPln": 2314.26,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 247.87,
              "bankCollectedInterestForeign": 87.83,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 732.19,
              "bankCollectedPrincipalPln": 2066.39,
              "paymentPln": 1902.85,
              "paymentForeign": 820.02,
              "interestPln": 203.82,
              "interestForeign": 87.83,
              "principalPln": 1699.03,
              "principalForeign": 732.18,
              "days": 30,
              "paymentDifferencePln": 411.41,
              "periodsTotal": 118,
              "simulationBalanceForeign": 82109.43,
              "simulationBalancePln": 190534.92,
              "bankBalanceForeign": 82109.57,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0128993,
              "controlSumForeign": 0.14
            },
            {
              "_calculationWarns": [
                "pKapitał 730.06"
              ],
              "index": 13,
              "date": "2010-01-01T00:00:00.000Z",
              "currencyDate": "2010-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 26,
                  "caseId": 1,
                  "position": 25,
                  "type": "Kapitał",
                  "date": "2010-01-04",
                  "transactionDate": "2010-01-04",
                  "installmentType": "equal",
                  "amountPln": 2070.89,
                  "amountForeign": 730.06,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 25,
                  "caseId": 1,
                  "position": 24,
                  "type": "Odsetki",
                  "date": "2010-01-04",
                  "transactionDate": "2010-01-04",
                  "installmentType": "equal",
                  "amountPln": 255.18,
                  "amountForeign": 89.96,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-01-04T00:00:00.000Z",
              "bankCollectedPln": 2326.07,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 255.18,
              "bankCollectedInterestForeign": 89.96,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 730.06,
              "bankCollectedPrincipalPln": 2070.89,
              "paymentPln": 1902.82,
              "paymentForeign": 820,
              "interestPln": 208.75,
              "interestForeign": 89.96,
              "principalPln": 1694.07,
              "principalForeign": 730.05,
              "days": 31,
              "paymentDifferencePln": 423.25,
              "periodsTotal": 118,
              "simulationBalanceForeign": 81379.38,
              "simulationBalancePln": 188840.85,
              "bankBalanceForeign": 81379.51,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0128999,
              "controlSumForeign": 0.13
            },
            {
              "_calculationWarns": [
                "pKapitał 730.86"
              ],
              "index": 14,
              "date": "2010-02-01T00:00:00.000Z",
              "currencyDate": "2010-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 27,
                  "caseId": 1,
                  "position": 26,
                  "type": "Odsetki",
                  "date": "2010-02-01",
                  "transactionDate": "2010-02-01",
                  "installmentType": "equal",
                  "amountPln": 250.37,
                  "amountForeign": 89.16,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 28,
                  "caseId": 1,
                  "position": 27,
                  "type": "Kapitał",
                  "date": "2010-02-01",
                  "transactionDate": "2010-02-01",
                  "installmentType": "equal",
                  "amountPln": 2052.33,
                  "amountForeign": 730.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-02-01T00:00:00.000Z",
              "bankCollectedPln": 2302.7,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 250.37,
              "bankCollectedInterestForeign": 89.16,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 730.86,
              "bankCollectedPrincipalPln": 2052.33,
              "paymentPln": 1902.86,
              "paymentForeign": 820.02,
              "interestPln": 206.9,
              "interestForeign": 89.16,
              "principalPln": 1695.96,
              "principalForeign": 730.86,
              "days": 31,
              "paymentDifferencePln": 399.84,
              "periodsTotal": 118,
              "simulationBalanceForeign": 80648.52,
              "simulationBalancePln": 187144.89,
              "bankBalanceForeign": 80648.65,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0128999,
              "controlSumForeign": 0.13
            },
            {
              "_calculationWarns": [
                "pKapitał 740.21"
              ],
              "index": 15,
              "date": "2010-03-01T00:00:00.000Z",
              "currencyDate": "2010-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 30,
                  "caseId": 1,
                  "position": 29,
                  "type": "Kapitał",
                  "date": "2010-03-01",
                  "transactionDate": "2010-03-01",
                  "installmentType": "equal",
                  "amountPln": 2057.34,
                  "amountForeign": 740.21,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 29,
                  "caseId": 1,
                  "position": 28,
                  "type": "Odsetki",
                  "date": "2010-03-01",
                  "transactionDate": "2010-03-01",
                  "installmentType": "equal",
                  "amountPln": 221.82,
                  "amountForeign": 79.81,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-03-01T00:00:00.000Z",
              "bankCollectedPln": 2279.16,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 221.82,
              "bankCollectedInterestForeign": 79.81,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 740.21,
              "bankCollectedPrincipalPln": 2057.34,
              "paymentPln": 1902.9,
              "paymentForeign": 820.04,
              "interestPln": 185.2,
              "interestForeign": 79.81,
              "principalPln": 1717.7,
              "principalForeign": 740.23,
              "days": 28,
              "paymentDifferencePln": 376.26,
              "periodsTotal": 118,
              "simulationBalanceForeign": 79908.29,
              "simulationBalancePln": 185427.19,
              "bankBalanceForeign": 79908.44,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0129002,
              "controlSumForeign": 0.15
            },
            {
              "_calculationWarns": [
                "pKapitał 732.47"
              ],
              "index": 16,
              "date": "2010-04-01T00:00:00.000Z",
              "currencyDate": "2010-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 31,
                  "caseId": 1,
                  "position": 30,
                  "type": "Odsetki",
                  "date": "2010-04-01",
                  "transactionDate": "2010-04-01",
                  "installmentType": "equal",
                  "amountPln": 244.48,
                  "amountForeign": 87.55,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 32,
                  "caseId": 1,
                  "position": 31,
                  "type": "Kapitał",
                  "date": "2010-04-01",
                  "transactionDate": "2010-04-01",
                  "installmentType": "equal",
                  "amountPln": 2045.43,
                  "amountForeign": 732.47,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-04-01T00:00:00.000Z",
              "bankCollectedPln": 2289.91,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 244.48,
              "bankCollectedInterestForeign": 87.55,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 732.47,
              "bankCollectedPrincipalPln": 2045.43,
              "paymentPln": 1902.74,
              "paymentForeign": 819.97,
              "interestPln": 203.16,
              "interestForeign": 87.55,
              "principalPln": 1699.58,
              "principalForeign": 732.42,
              "days": 31,
              "paymentDifferencePln": 387.17,
              "periodsTotal": 118,
              "simulationBalanceForeign": 79175.87,
              "simulationBalancePln": 183727.61,
              "bankBalanceForeign": 79175.97,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0129001,
              "controlSumForeign": 0.1
            },
            {
              "_calculationWarns": [
                "pKapitał 736.07"
              ],
              "index": 17,
              "date": "2010-05-01T00:00:00.000Z",
              "currencyDate": "2010-05-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 33,
                  "caseId": 1,
                  "position": 32,
                  "type": "Odsetki",
                  "date": "2010-05-03",
                  "transactionDate": "2010-05-03",
                  "installmentType": "equal",
                  "amountPln": 236.5,
                  "amountForeign": 83.95,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 34,
                  "caseId": 1,
                  "position": 33,
                  "type": "Kapitał",
                  "date": "2010-05-03",
                  "transactionDate": "2010-05-03",
                  "installmentType": "equal",
                  "amountPln": 2073.66,
                  "amountForeign": 736.07,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-05-03T00:00:00.000Z",
              "bankCollectedPln": 2310.16,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 236.5,
              "bankCollectedInterestForeign": 83.95,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 736.07,
              "bankCollectedPrincipalPln": 2073.66,
              "paymentPln": 1902.78,
              "paymentForeign": 819.99,
              "interestPln": 194.8,
              "interestForeign": 83.95,
              "principalPln": 1707.97,
              "principalForeign": 736.04,
              "days": 30,
              "paymentDifferencePln": 407.38,
              "periodsTotal": 118,
              "simulationBalanceForeign": 78439.83,
              "simulationBalancePln": 182019.64,
              "bankBalanceForeign": 78439.9,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0129003,
              "controlSumForeign": 0.07
            },
            {
              "_calculationWarns": [
                "pKapitał 734.08"
              ],
              "index": 18,
              "date": "2010-06-01T00:00:00.000Z",
              "currencyDate": "2010-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 35,
                  "caseId": 1,
                  "position": 34,
                  "type": "Odsetki",
                  "date": "2010-06-01",
                  "transactionDate": "2010-06-01",
                  "installmentType": "equal",
                  "amountPln": 257.15,
                  "amountForeign": 85.94,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 36,
                  "caseId": 1,
                  "position": 35,
                  "type": "Kapitał",
                  "date": "2010-06-01",
                  "transactionDate": "2010-06-01",
                  "installmentType": "equal",
                  "amountPln": 2196.51,
                  "amountForeign": 734.08,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-06-01T00:00:00.000Z",
              "bankCollectedPln": 2453.66,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 257.15,
              "bankCollectedInterestForeign": 85.94,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 734.08,
              "bankCollectedPrincipalPln": 2196.51,
              "paymentPln": 1902.75,
              "paymentForeign": 819.97,
              "interestPln": 199.42,
              "interestForeign": 85.94,
              "principalPln": 1703.32,
              "principalForeign": 734.03,
              "days": 31,
              "paymentDifferencePln": 550.91,
              "periodsTotal": 118,
              "simulationBalanceForeign": 77705.8,
              "simulationBalancePln": 180316.32,
              "bankBalanceForeign": 77705.82,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0129,
              "controlSumForeign": 0.02
            },
            {
              "_calculationWarns": [
                "pKapitał 737.63"
              ],
              "index": 19,
              "date": "2010-07-01T00:00:00.000Z",
              "currencyDate": "2010-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 38,
                  "caseId": 1,
                  "position": 37,
                  "type": "Kapitał",
                  "date": "2010-07-01",
                  "transactionDate": "2010-07-01",
                  "installmentType": "equal",
                  "amountPln": 2395.08,
                  "amountForeign": 737.63,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 37,
                  "caseId": 1,
                  "position": 36,
                  "type": "Odsetki",
                  "date": "2010-07-01",
                  "transactionDate": "2010-07-01",
                  "installmentType": "equal",
                  "amountPln": 267.52,
                  "amountForeign": 82.39,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-07-01T00:00:00.000Z",
              "bankCollectedPln": 2662.6,
              "bankCollectedForeign": 820.02,
              "bankCollectedInterestPln": 267.52,
              "bankCollectedInterestForeign": 82.39,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 737.63,
              "bankCollectedPrincipalPln": 2395.08,
              "paymentPln": 1902.79,
              "paymentForeign": 819.99,
              "interestPln": 191.18,
              "interestForeign": 82.39,
              "principalPln": 1711.6,
              "principalForeign": 737.6,
              "days": 30,
              "paymentDifferencePln": 759.81,
              "periodsTotal": 118,
              "simulationBalanceForeign": 76968.2,
              "simulationBalancePln": 178604.72,
              "bankBalanceForeign": 76968.19,
              "bankRate": 0.0129,
              "bankRateReversed": 0.0129001,
              "controlSumForeign": -0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 741.4"
              ],
              "index": 20,
              "date": "2010-08-01T00:00:00.000Z",
              "currencyDate": "2010-08-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 39,
                  "caseId": 1,
                  "position": 38,
                  "type": "Odsetki",
                  "date": "2010-08-02",
                  "transactionDate": "2010-08-02",
                  "installmentType": "equal",
                  "amountPln": 219.15,
                  "amountForeign": 72.56,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 40,
                  "caseId": 1,
                  "position": 39,
                  "type": "Kapitał",
                  "date": "2010-08-02",
                  "transactionDate": "2010-08-02",
                  "installmentType": "equal",
                  "amountPln": 2239.17,
                  "amountForeign": 741.4,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-08-02T00:00:00.000Z",
              "bankCollectedPln": 2458.32,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 219.15,
              "bankCollectedInterestForeign": 72.56,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 741.4,
              "bankCollectedPrincipalPln": 2239.17,
              "paymentPln": 1888.79,
              "paymentForeign": 813.96,
              "interestPln": 168.38,
              "interestForeign": 72.56,
              "principalPln": 1720.41,
              "principalForeign": 741.4,
              "days": 31,
              "paymentDifferencePln": 569.53,
              "periodsTotal": 118,
              "simulationBalanceForeign": 76226.8,
              "simulationBalancePln": 176884.31,
              "bankBalanceForeign": 76226.79,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110999,
              "controlSumForeign": -0.01
            },
            {
              "_calculationWarns": [
                "pKapitał 742.1"
              ],
              "index": 21,
              "date": "2010-09-01T00:00:00.000Z",
              "currencyDate": "2010-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 41,
                  "caseId": 1,
                  "position": 40,
                  "type": "Odsetki",
                  "date": "2010-09-01",
                  "transactionDate": "2010-09-01",
                  "installmentType": "equal",
                  "amountPln": 227.23,
                  "amountForeign": 71.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 42,
                  "caseId": 1,
                  "position": 41,
                  "type": "Kapitał",
                  "date": "2010-09-01",
                  "transactionDate": "2010-09-01",
                  "installmentType": "equal",
                  "amountPln": 2346.59,
                  "amountForeign": 742.1,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-09-01T00:00:00.000Z",
              "bankCollectedPln": 2573.82,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 227.23,
              "bankCollectedInterestForeign": 71.86,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 742.1,
              "bankCollectedPrincipalPln": 2346.59,
              "paymentPln": 1888.82,
              "paymentForeign": 813.97,
              "interestPln": 166.76,
              "interestForeign": 71.86,
              "principalPln": 1722.07,
              "principalForeign": 742.11,
              "days": 31,
              "paymentDifferencePln": 685,
              "periodsTotal": 118,
              "simulationBalanceForeign": 75484.69,
              "simulationBalancePln": 175162.24,
              "bankBalanceForeign": 75484.69,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110997,
              "controlSumForeign": 0
            },
            {
              "_calculationWarns": [
                "pKapitał 745.09"
              ],
              "index": 22,
              "date": "2010-10-01T00:00:00.000Z",
              "currencyDate": "2010-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 44,
                  "caseId": 1,
                  "position": 43,
                  "type": "Kapitał",
                  "date": "2010-10-01",
                  "transactionDate": "2010-10-01",
                  "installmentType": "equal",
                  "amountPln": 2257.55,
                  "amountForeign": 745.09,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 43,
                  "caseId": 1,
                  "position": 42,
                  "type": "Odsetki",
                  "date": "2010-10-01",
                  "transactionDate": "2010-10-01",
                  "installmentType": "equal",
                  "amountPln": 208.67,
                  "amountForeign": 68.87,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-10-01T00:00:00.000Z",
              "bankCollectedPln": 2466.22,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 208.67,
              "bankCollectedInterestForeign": 68.87,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 745.09,
              "bankCollectedPrincipalPln": 2257.55,
              "paymentPln": 1888.85,
              "paymentForeign": 813.99,
              "interestPln": 159.81,
              "interestForeign": 68.87,
              "principalPln": 1729.05,
              "principalForeign": 745.12,
              "days": 30,
              "paymentDifferencePln": 577.37,
              "periodsTotal": 118,
              "simulationBalanceForeign": 74739.57,
              "simulationBalancePln": 173433.19,
              "bankBalanceForeign": 74739.6,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111005,
              "controlSumForeign": 0.03
            },
            {
              "_calculationWarns": [
                "pKapitał 743.5"
              ],
              "index": 23,
              "date": "2010-11-01T00:00:00.000Z",
              "currencyDate": "2010-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 45,
                  "caseId": 1,
                  "position": 44,
                  "type": "Odsetki",
                  "date": "2010-11-02",
                  "transactionDate": "2010-11-02",
                  "installmentType": "equal",
                  "amountPln": 208.53,
                  "amountForeign": 70.46,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 46,
                  "caseId": 1,
                  "position": 45,
                  "type": "Kapitał",
                  "date": "2010-11-02",
                  "transactionDate": "2010-11-02",
                  "installmentType": "equal",
                  "amountPln": 2200.47,
                  "amountForeign": 743.5,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-11-02T00:00:00.000Z",
              "bankCollectedPln": 2409,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 208.53,
              "bankCollectedInterestForeign": 70.46,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 743.5,
              "bankCollectedPrincipalPln": 2200.47,
              "paymentPln": 1888.83,
              "paymentForeign": 813.98,
              "interestPln": 163.5,
              "interestForeign": 70.46,
              "principalPln": 1725.33,
              "principalForeign": 743.52,
              "days": 31,
              "paymentDifferencePln": 520.17,
              "periodsTotal": 118,
              "simulationBalanceForeign": 73996.05,
              "simulationBalancePln": 171707.86,
              "bankBalanceForeign": 73996.1,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111,
              "controlSumForeign": 0.05
            },
            {
              "_calculationWarns": [
                "pKapitał 746.45"
              ],
              "index": 24,
              "date": "2010-12-01T00:00:00.000Z",
              "currencyDate": "2010-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 48,
                  "caseId": 1,
                  "position": 47,
                  "type": "Kapitał",
                  "date": "2010-12-01",
                  "transactionDate": "2010-12-01",
                  "installmentType": "equal",
                  "amountPln": 2345.42,
                  "amountForeign": 746.45,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 47,
                  "caseId": 1,
                  "position": 46,
                  "type": "Odsetki",
                  "date": "2010-12-01",
                  "transactionDate": "2010-12-01",
                  "installmentType": "equal",
                  "amountPln": 212.12,
                  "amountForeign": 67.51,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2010-12-01T00:00:00.000Z",
              "bankCollectedPln": 2557.54,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 212.12,
              "bankCollectedInterestForeign": 67.51,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 746.45,
              "bankCollectedPrincipalPln": 2345.42,
              "paymentPln": 1888.86,
              "paymentForeign": 813.99,
              "interestPln": 156.65,
              "interestForeign": 67.51,
              "principalPln": 1732.21,
              "principalForeign": 746.48,
              "days": 30,
              "paymentDifferencePln": 668.68,
              "periodsTotal": 118,
              "simulationBalanceForeign": 73249.57,
              "simulationBalancePln": 169975.65,
              "bankBalanceForeign": 73249.65,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111002,
              "controlSumForeign": 0.08
            },
            {
              "_calculationWarns": [
                "pKapitał 744.9"
              ],
              "index": 25,
              "date": "2011-01-01T00:00:00.000Z",
              "currencyDate": "2011-01-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 49,
                  "caseId": 1,
                  "position": 48,
                  "type": "Odsetki",
                  "date": "2011-01-03",
                  "transactionDate": "2011-01-03",
                  "installmentType": "equal",
                  "amountPln": 226.71,
                  "amountForeign": 69.06,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 50,
                  "caseId": 1,
                  "position": 49,
                  "type": "Kapitał",
                  "date": "2011-01-03",
                  "transactionDate": "2011-01-03",
                  "installmentType": "equal",
                  "amountPln": 2445.36,
                  "amountForeign": 744.9,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-01-03T00:00:00.000Z",
              "bankCollectedPln": 2672.07,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 226.71,
              "bankCollectedInterestForeign": 69.06,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 744.9,
              "bankCollectedPrincipalPln": 2445.36,
              "paymentPln": 1888.84,
              "paymentForeign": 813.98,
              "interestPln": 160.24,
              "interestForeign": 69.06,
              "principalPln": 1728.6,
              "principalForeign": 744.92,
              "days": 31,
              "paymentDifferencePln": 783.23,
              "periodsTotal": 118,
              "simulationBalanceForeign": 72504.65,
              "simulationBalancePln": 168247.05,
              "bankBalanceForeign": 72504.75,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111007,
              "controlSumForeign": 0.1
            },
            {
              "_calculationWarns": [
                "pKapitał 745.61"
              ],
              "index": 26,
              "date": "2011-02-01T00:00:00.000Z",
              "currencyDate": "2011-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 51,
                  "caseId": 1,
                  "position": 50,
                  "type": "Odsetki",
                  "date": "2011-02-01",
                  "transactionDate": "2011-02-01",
                  "installmentType": "equal",
                  "amountPln": 213.71,
                  "amountForeign": 68.35,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 52,
                  "caseId": 1,
                  "position": 51,
                  "type": "Kapitał",
                  "date": "2011-02-01",
                  "transactionDate": "2011-02-01",
                  "installmentType": "equal",
                  "amountPln": 2331.3,
                  "amountForeign": 745.61,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-02-01T00:00:00.000Z",
              "bankCollectedPln": 2545.01,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 213.71,
              "bankCollectedInterestForeign": 68.35,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 745.61,
              "bankCollectedPrincipalPln": 2331.3,
              "paymentPln": 1888.87,
              "paymentForeign": 813.99,
              "interestPln": 158.61,
              "interestForeign": 68.35,
              "principalPln": 1730.26,
              "principalForeign": 745.64,
              "days": 31,
              "paymentDifferencePln": 656.14,
              "periodsTotal": 118,
              "simulationBalanceForeign": 71759.01,
              "simulationBalancePln": 166516.79,
              "bankBalanceForeign": 71759.14,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110995,
              "controlSumForeign": 0.13
            },
            {
              "_calculationWarns": [
                "pKapitał 752.86"
              ],
              "index": 27,
              "date": "2011-03-01T00:00:00.000Z",
              "currencyDate": "2011-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 53,
                  "caseId": 1,
                  "position": 52,
                  "type": "Odsetki",
                  "date": "2011-03-01",
                  "transactionDate": "2011-03-01",
                  "installmentType": "equal",
                  "amountPln": 194.69,
                  "amountForeign": 61.1,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 54,
                  "caseId": 1,
                  "position": 53,
                  "type": "Kapitał",
                  "date": "2011-03-01",
                  "transactionDate": "2011-03-01",
                  "installmentType": "equal",
                  "amountPln": 2398.99,
                  "amountForeign": 752.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-03-01T00:00:00.000Z",
              "bankCollectedPln": 2593.68,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 194.69,
              "bankCollectedInterestForeign": 61.1,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 752.86,
              "bankCollectedPrincipalPln": 2398.99,
              "paymentPln": 1888.91,
              "paymentForeign": 814.01,
              "interestPln": 141.79,
              "interestForeign": 61.1,
              "principalPln": 1747.12,
              "principalForeign": 752.9,
              "days": 28,
              "paymentDifferencePln": 704.77,
              "periodsTotal": 118,
              "simulationBalanceForeign": 71006.11,
              "simulationBalancePln": 164769.67,
              "bankBalanceForeign": 71006.28,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110994,
              "controlSumForeign": 0.17
            },
            {
              "_calculationWarns": [
                "pKapitał 747.02"
              ],
              "index": 28,
              "date": "2011-04-01T00:00:00.000Z",
              "currencyDate": "2011-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 56,
                  "caseId": 1,
                  "position": 55,
                  "type": "Kapitał",
                  "date": "2011-04-01",
                  "transactionDate": "2011-04-01",
                  "installmentType": "equal",
                  "amountPln": 2371.57,
                  "amountForeign": 747.02,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 55,
                  "caseId": 1,
                  "position": 54,
                  "type": "Odsetki",
                  "date": "2011-04-01",
                  "transactionDate": "2011-04-01",
                  "installmentType": "equal",
                  "amountPln": 212.51,
                  "amountForeign": 66.94,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-04-01T00:00:00.000Z",
              "bankCollectedPln": 2584.08,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 212.51,
              "bankCollectedInterestForeign": 66.94,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 747.02,
              "bankCollectedPrincipalPln": 2371.57,
              "paymentPln": 1888.77,
              "paymentForeign": 813.95,
              "interestPln": 155.33,
              "interestForeign": 66.94,
              "principalPln": 1733.43,
              "principalForeign": 747.01,
              "days": 31,
              "paymentDifferencePln": 695.31,
              "periodsTotal": 118,
              "simulationBalanceForeign": 70259.1,
              "simulationBalancePln": 163036.24,
              "bankBalanceForeign": 70259.26,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110999,
              "controlSumForeign": 0.16
            },
            {
              "_calculationWarns": [
                "pKapitał 749.86"
              ],
              "index": 29,
              "date": "2011-05-01T00:00:00.000Z",
              "currencyDate": "2011-05-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 57,
                  "caseId": 1,
                  "position": 56,
                  "type": "Odsetki",
                  "date": "2011-05-02",
                  "transactionDate": "2011-05-02",
                  "installmentType": "equal",
                  "amountPln": 202.43,
                  "amountForeign": 64.1,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 58,
                  "caseId": 1,
                  "position": 57,
                  "type": "Kapitał",
                  "date": "2011-05-02",
                  "transactionDate": "2011-05-02",
                  "installmentType": "equal",
                  "amountPln": 2368.14,
                  "amountForeign": 749.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-05-02T00:00:00.000Z",
              "bankCollectedPln": 2570.57,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 202.43,
              "bankCollectedInterestForeign": 64.1,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 749.86,
              "bankCollectedPrincipalPln": 2368.14,
              "paymentPln": 1888.8,
              "paymentForeign": 813.96,
              "interestPln": 148.74,
              "interestForeign": 64.1,
              "principalPln": 1740.06,
              "principalForeign": 749.87,
              "days": 30,
              "paymentDifferencePln": 681.77,
              "periodsTotal": 118,
              "simulationBalanceForeign": 69509.23,
              "simulationBalancePln": 161296.18,
              "bankBalanceForeign": 69509.4,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111001,
              "controlSumForeign": 0.17
            },
            {
              "_calculationWarns": [
                "pKapitał 748.43"
              ],
              "index": 30,
              "date": "2011-06-01T00:00:00.000Z",
              "currencyDate": "2011-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 59,
                  "caseId": 1,
                  "position": 58,
                  "type": "Odsetki",
                  "date": "2011-06-01",
                  "transactionDate": "2011-06-01",
                  "installmentType": "equal",
                  "amountPln": 216.59,
                  "amountForeign": 65.53,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 60,
                  "caseId": 1,
                  "position": 59,
                  "type": "Kapitał",
                  "date": "2011-06-01",
                  "transactionDate": "2011-06-01",
                  "installmentType": "equal",
                  "amountPln": 2473.71,
                  "amountForeign": 748.43,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-06-01T00:00:00.000Z",
              "bankCollectedPln": 2690.3,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 216.59,
              "bankCollectedInterestForeign": 65.53,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 748.43,
              "bankCollectedPrincipalPln": 2473.71,
              "paymentPln": 1888.78,
              "paymentForeign": 813.95,
              "interestPln": 152.06,
              "interestForeign": 65.53,
              "principalPln": 1736.72,
              "principalForeign": 748.42,
              "days": 31,
              "paymentDifferencePln": 801.52,
              "periodsTotal": 118,
              "simulationBalanceForeign": 68760.81,
              "simulationBalancePln": 159559.46,
              "bankBalanceForeign": 68760.97,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111001,
              "controlSumForeign": 0.16
            },
            {
              "_calculationWarns": [
                "pKapitał 751.23"
              ],
              "index": 31,
              "date": "2011-07-01T00:00:00.000Z",
              "currencyDate": "2011-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 61,
                  "caseId": 1,
                  "position": 60,
                  "type": "Odsetki",
                  "date": "2011-07-01",
                  "transactionDate": "2011-07-01",
                  "installmentType": "equal",
                  "amountPln": 209.62,
                  "amountForeign": 62.73,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 62,
                  "caseId": 1,
                  "position": 61,
                  "type": "Kapitał",
                  "date": "2011-07-01",
                  "transactionDate": "2011-07-01",
                  "installmentType": "equal",
                  "amountPln": 2510.39,
                  "amountForeign": 751.23,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-07-01T00:00:00.000Z",
              "bankCollectedPln": 2720.01,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 209.62,
              "bankCollectedInterestForeign": 62.73,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 751.23,
              "bankCollectedPrincipalPln": 2510.39,
              "paymentPln": 1888.81,
              "paymentForeign": 813.97,
              "interestPln": 145.57,
              "interestForeign": 62.73,
              "principalPln": 1743.24,
              "principalForeign": 751.23,
              "days": 30,
              "paymentDifferencePln": 831.2,
              "periodsTotal": 118,
              "simulationBalanceForeign": 68009.58,
              "simulationBalancePln": 157816.22,
              "bankBalanceForeign": 68009.74,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110995,
              "controlSumForeign": 0.16
            },
            {
              "_calculationWarns": [
                "pKapitał 749.84"
              ],
              "index": 32,
              "date": "2011-08-01T00:00:00.000Z",
              "currencyDate": "2011-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 64,
                  "caseId": 1,
                  "position": 63,
                  "type": "Kapitał",
                  "date": "2011-08-01",
                  "transactionDate": "2011-08-01",
                  "installmentType": "equal",
                  "amountPln": 2704.3,
                  "amountForeign": 749.84,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 63,
                  "caseId": 1,
                  "position": 62,
                  "type": "Odsetki",
                  "date": "2011-08-01",
                  "transactionDate": "2011-08-01",
                  "installmentType": "equal",
                  "amountPln": 231.25,
                  "amountForeign": 64.12,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-08-01T00:00:00.000Z",
              "bankCollectedPln": 2935.55,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 231.25,
              "bankCollectedInterestForeign": 64.12,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 749.84,
              "bankCollectedPrincipalPln": 2704.3,
              "paymentPln": 1888.79,
              "paymentForeign": 813.96,
              "interestPln": 148.78,
              "interestForeign": 64.11,
              "principalPln": 1740.01,
              "principalForeign": 749.84,
              "days": 31,
              "paymentDifferencePln": 1046.76,
              "periodsTotal": 118,
              "simulationBalanceForeign": 67259.74,
              "simulationBalancePln": 156076.21,
              "bankBalanceForeign": 67259.9,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111008,
              "controlSumForeign": 0.16
            },
            {
              "_calculationWarns": [
                "pKapitał 726.16",
                "pKapitał 24.39"
              ],
              "index": 33,
              "date": "2011-09-01T00:00:00.000Z",
              "currencyDate": "2011-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 65,
                  "caseId": 1,
                  "position": 64,
                  "type": "Odsetki",
                  "date": "2011-09-01",
                  "transactionDate": "2011-09-01",
                  "installmentType": "equal",
                  "amountPln": 237.68,
                  "amountForeign": 63.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 66,
                  "caseId": 1,
                  "position": 65,
                  "type": "Kapitał",
                  "date": "2011-09-01",
                  "transactionDate": "2011-09-01",
                  "installmentType": "equal",
                  "amountPln": 2721.88,
                  "amountForeign": 726.16,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 67,
                  "caseId": 1,
                  "position": 66,
                  "type": "Kapitał",
                  "date": "2011-09-02",
                  "transactionDate": "2011-09-02",
                  "installmentType": "equal",
                  "amountPln": 93.59,
                  "amountForeign": 24.39,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-09-01T00:00:00.000Z",
              "bankCollectedPln": 3053.15,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 237.68,
              "bankCollectedInterestForeign": 63.41,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 750.55,
              "bankCollectedPrincipalPln": 2815.47,
              "paymentPln": 1888.82,
              "paymentForeign": 813.97,
              "interestPln": 147.14,
              "interestForeign": 63.41,
              "principalPln": 1741.68,
              "principalForeign": 750.56,
              "days": 31,
              "paymentDifferencePln": 1164.33,
              "periodsTotal": 118,
              "simulationBalanceForeign": 66509.18,
              "simulationBalancePln": 154334.53,
              "bankBalanceForeign": 66509.35,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111002,
              "controlSumForeign": 0.17
            },
            {
              "_calculationWarns": [
                "pKapitał 753.28"
              ],
              "index": 34,
              "date": "2011-10-01T00:00:00.000Z",
              "currencyDate": "2011-10-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 68,
                  "caseId": 1,
                  "position": 67,
                  "type": "Odsetki",
                  "date": "2011-10-03",
                  "transactionDate": "2011-10-03",
                  "installmentType": "equal",
                  "amountPln": 227.32,
                  "amountForeign": 60.68,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 69,
                  "caseId": 1,
                  "position": 68,
                  "type": "Kapitał",
                  "date": "2011-10-03",
                  "transactionDate": "2011-10-03",
                  "installmentType": "equal",
                  "amountPln": 2821.94,
                  "amountForeign": 753.28,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-10-03T00:00:00.000Z",
              "bankCollectedPln": 3049.26,
              "bankCollectedForeign": 813.96,
              "bankCollectedInterestPln": 227.32,
              "bankCollectedInterestForeign": 60.68,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 753.28,
              "bankCollectedPrincipalPln": 2821.94,
              "paymentPln": 1888.85,
              "paymentForeign": 813.99,
              "interestPln": 140.81,
              "interestForeign": 60.68,
              "principalPln": 1748.05,
              "principalForeign": 753.31,
              "days": 30,
              "paymentDifferencePln": 1160.41,
              "periodsTotal": 118,
              "simulationBalanceForeign": 65755.87,
              "simulationBalancePln": 152586.48,
              "bankBalanceForeign": 65756.07,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111003,
              "controlSumForeign": 0.2
            },
            {
              "_calculationWarns": [
                "pKapitał 754.73"
              ],
              "index": 35,
              "date": "2011-11-01T00:00:00.000Z",
              "currencyDate": "2011-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 70,
                  "caseId": 1,
                  "position": 69,
                  "type": "Odsetki",
                  "date": "2011-11-02",
                  "transactionDate": "2011-11-02",
                  "installmentType": "equal",
                  "amountPln": 210.46,
                  "amountForeign": 56.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 71,
                  "caseId": 1,
                  "position": 70,
                  "type": "Kapitał",
                  "date": "2011-11-02",
                  "transactionDate": "2011-11-02",
                  "installmentType": "equal",
                  "amountPln": 2815.82,
                  "amountForeign": 754.73,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-11-02T00:00:00.000Z",
              "bankCollectedPln": 3026.28,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 210.46,
              "bankCollectedInterestForeign": 56.41,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 754.73,
              "bankCollectedPrincipalPln": 2815.82,
              "paymentPln": 1882.24,
              "paymentForeign": 811.14,
              "interestPln": 130.89,
              "interestForeign": 56.4,
              "principalPln": 1751.35,
              "principalForeign": 754.73,
              "days": 31,
              "paymentDifferencePln": 1144.04,
              "periodsTotal": 118,
              "simulationBalanceForeign": 65001.14,
              "simulationBalancePln": 150835.13,
              "bankBalanceForeign": 65001.34,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101007,
              "controlSumForeign": 0.2
            },
            {
              "_calculationWarns": [
                "pKapitał 757.18"
              ],
              "index": 36,
              "date": "2011-12-01T00:00:00.000Z",
              "currencyDate": "2011-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 73,
                  "caseId": 1,
                  "position": 72,
                  "type": "Kapitał",
                  "date": "2011-12-01",
                  "transactionDate": "2011-12-01",
                  "installmentType": "equal",
                  "amountPln": 2852.07,
                  "amountForeign": 757.18,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 72,
                  "caseId": 1,
                  "position": 71,
                  "type": "Odsetki",
                  "date": "2011-12-01",
                  "transactionDate": "2011-12-01",
                  "installmentType": "equal",
                  "amountPln": 203.25,
                  "amountForeign": 53.96,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2011-12-01T00:00:00.000Z",
              "bankCollectedPln": 3055.32,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 203.25,
              "bankCollectedInterestForeign": 53.96,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 757.18,
              "bankCollectedPrincipalPln": 2852.07,
              "paymentPln": 1882.27,
              "paymentForeign": 811.15,
              "interestPln": 125.21,
              "interestForeign": 53.96,
              "principalPln": 1757.06,
              "principalForeign": 757.19,
              "days": 30,
              "paymentDifferencePln": 1173.05,
              "periodsTotal": 118,
              "simulationBalanceForeign": 64243.95,
              "simulationBalancePln": 149078.07,
              "bankBalanceForeign": 64244.16,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101,
              "controlSumForeign": 0.21
            },
            {
              "_calculationWarns": [
                "pKapitał 756.03"
              ],
              "index": 37,
              "date": "2012-01-01T00:00:00.000Z",
              "currencyDate": "2012-01-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 74,
                  "caseId": 1,
                  "position": 73,
                  "type": "Odsetki",
                  "date": "2012-01-02",
                  "transactionDate": "2012-01-02",
                  "installmentType": "equal",
                  "amountPln": 207.87,
                  "amountForeign": 55.11,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 75,
                  "caseId": 1,
                  "position": 74,
                  "type": "Kapitał",
                  "date": "2012-01-02",
                  "transactionDate": "2012-01-02",
                  "installmentType": "equal",
                  "amountPln": 2851.67,
                  "amountForeign": 756.03,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-01-02T00:00:00.000Z",
              "bankCollectedPln": 3059.54,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 207.87,
              "bankCollectedInterestForeign": 55.11,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 756.03,
              "bankCollectedPrincipalPln": 2851.67,
              "paymentPln": 1882.25,
              "paymentForeign": 811.14,
              "interestPln": 127.88,
              "interestForeign": 55.11,
              "principalPln": 1754.37,
              "principalForeign": 756.03,
              "days": 31,
              "paymentDifferencePln": 1177.29,
              "periodsTotal": 118,
              "simulationBalanceForeign": 63487.92,
              "simulationBalancePln": 147323.7,
              "bankBalanceForeign": 63488.13,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101002,
              "controlSumForeign": 0.21
            },
            {
              "_calculationWarns": [
                "pKapitał 756.68"
              ],
              "index": 38,
              "date": "2012-02-01T00:00:00.000Z",
              "currencyDate": "2012-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 76,
                  "caseId": 1,
                  "position": 75,
                  "type": "Odsetki",
                  "date": "2012-02-01",
                  "transactionDate": "2012-02-01",
                  "installmentType": "equal",
                  "amountPln": 195.52,
                  "amountForeign": 54.46,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 77,
                  "caseId": 1,
                  "position": 76,
                  "type": "Kapitał",
                  "date": "2012-02-01",
                  "transactionDate": "2012-02-01",
                  "installmentType": "equal",
                  "amountPln": 2716.63,
                  "amountForeign": 756.68,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-02-01T00:00:00.000Z",
              "bankCollectedPln": 2912.15,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 195.52,
              "bankCollectedInterestForeign": 54.46,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 756.68,
              "bankCollectedPrincipalPln": 2716.63,
              "paymentPln": 1882.28,
              "paymentForeign": 811.15,
              "interestPln": 126.37,
              "interestForeign": 54.46,
              "principalPln": 1755.9,
              "principalForeign": 756.69,
              "days": 31,
              "paymentDifferencePln": 1029.87,
              "periodsTotal": 118,
              "simulationBalanceForeign": 62731.23,
              "simulationBalancePln": 145567.8,
              "bankBalanceForeign": 62731.45,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100999,
              "controlSumForeign": 0.22
            },
            {
              "_calculationWarns": [
                "pKapitał 760.8"
              ],
              "index": 39,
              "date": "2012-03-01T00:00:00.000Z",
              "currencyDate": "2012-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 79,
                  "caseId": 1,
                  "position": 78,
                  "type": "Kapitał",
                  "date": "2012-03-01",
                  "transactionDate": "2012-03-01",
                  "installmentType": "equal",
                  "amountPln": 2681.44,
                  "amountForeign": 760.8,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 78,
                  "caseId": 1,
                  "position": 77,
                  "type": "Odsetki",
                  "date": "2012-03-01",
                  "transactionDate": "2012-03-01",
                  "installmentType": "equal",
                  "amountPln": 177.42,
                  "amountForeign": 50.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-03-01T00:00:00.000Z",
              "bankCollectedPln": 2858.86,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 177.42,
              "bankCollectedInterestForeign": 50.34,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 760.8,
              "bankCollectedPrincipalPln": 2681.44,
              "paymentPln": 1882.31,
              "paymentForeign": 811.17,
              "interestPln": 116.81,
              "interestForeign": 50.34,
              "principalPln": 1765.5,
              "principalForeign": 760.82,
              "days": 29,
              "paymentDifferencePln": 976.55,
              "periodsTotal": 118,
              "simulationBalanceForeign": 61970.41,
              "simulationBalancePln": 143802.3,
              "bankBalanceForeign": 61970.65,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 757.98"
              ],
              "index": 40,
              "date": "2012-04-01T00:00:00.000Z",
              "currencyDate": "2012-04-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 80,
                  "caseId": 1,
                  "position": 79,
                  "type": "Odsetki",
                  "date": "2012-04-02",
                  "transactionDate": "2012-04-02",
                  "installmentType": "equal",
                  "amountPln": 188.47,
                  "amountForeign": 53.16,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 81,
                  "caseId": 1,
                  "position": 80,
                  "type": "Kapitał",
                  "date": "2012-04-02",
                  "transactionDate": "2012-04-02",
                  "installmentType": "equal",
                  "amountPln": 2687.26,
                  "amountForeign": 757.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-04-02T00:00:00.000Z",
              "bankCollectedPln": 2875.73,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 188.47,
              "bankCollectedInterestForeign": 53.16,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 757.98,
              "bankCollectedPrincipalPln": 2687.26,
              "paymentPln": 1882.24,
              "paymentForeign": 811.13,
              "interestPln": 123.36,
              "interestForeign": 53.16,
              "principalPln": 1758.88,
              "principalForeign": 757.97,
              "days": 31,
              "paymentDifferencePln": 993.49,
              "periodsTotal": 118,
              "simulationBalanceForeign": 61212.44,
              "simulationBalancePln": 142043.42,
              "bankBalanceForeign": 61212.67,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101002,
              "controlSumForeign": 0.23
            },
            {
              "_calculationWarns": [
                "pKapitał 760.33"
              ],
              "index": 41,
              "date": "2012-05-01T00:00:00.000Z",
              "currencyDate": "2012-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 83,
                  "caseId": 1,
                  "position": 82,
                  "type": "Kapitał",
                  "date": "2012-05-02",
                  "transactionDate": "2012-05-02",
                  "installmentType": "equal",
                  "amountPln": 2707.84,
                  "amountForeign": 760.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 82,
                  "caseId": 1,
                  "position": 81,
                  "type": "Odsetki",
                  "date": "2012-05-02",
                  "transactionDate": "2012-05-02",
                  "installmentType": "equal",
                  "amountPln": 180.95,
                  "amountForeign": 50.81,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-05-02T00:00:00.000Z",
              "bankCollectedPln": 2888.79,
              "bankCollectedForeign": 811.14,
              "bankCollectedInterestPln": 180.95,
              "bankCollectedInterestForeign": 50.81,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 760.33,
              "bankCollectedPrincipalPln": 2707.84,
              "paymentPln": 1882.27,
              "paymentForeign": 811.15,
              "interestPln": 117.91,
              "interestForeign": 50.81,
              "principalPln": 1764.35,
              "principalForeign": 760.33,
              "days": 30,
              "paymentDifferencePln": 1006.52,
              "periodsTotal": 118,
              "simulationBalanceForeign": 60452.11,
              "simulationBalancePln": 140279.07,
              "bankBalanceForeign": 60452.34,
              "bankRate": 0.0101,
              "bankRateReversed": 0.010099,
              "controlSumForeign": 0.23
            },
            {
              "_calculationWarns": [
                "pKapitał 756.76"
              ],
              "index": 42,
              "date": "2012-06-01T00:00:00.000Z",
              "currencyDate": "2012-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 84,
                  "caseId": 1,
                  "position": 83,
                  "type": "Odsetki",
                  "date": "2012-06-01",
                  "transactionDate": "2012-06-01",
                  "installmentType": "equal",
                  "amountPln": 215.82,
                  "amountForeign": 56.99,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 85,
                  "caseId": 1,
                  "position": 84,
                  "type": "Kapitał",
                  "date": "2012-06-01",
                  "transactionDate": "2012-06-01",
                  "installmentType": "equal",
                  "amountPln": 2865.85,
                  "amountForeign": 756.76,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-06-01T00:00:00.000Z",
              "bankCollectedPln": 3081.67,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 215.82,
              "bankCollectedInterestForeign": 56.99,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 756.76,
              "bankCollectedPrincipalPln": 2865.85,
              "paymentPln": 1888.3,
              "paymentForeign": 813.75,
              "interestPln": 132.25,
              "interestForeign": 56.99,
              "principalPln": 1756.05,
              "principalForeign": 756.76,
              "days": 31,
              "paymentDifferencePln": 1193.37,
              "periodsTotal": 118,
              "simulationBalanceForeign": 59695.35,
              "simulationBalancePln": 138523.02,
              "bankBalanceForeign": 59695.58,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110998,
              "controlSumForeign": 0.23
            },
            {
              "_calculationWarns": [
                "pKapitał 759.29"
              ],
              "index": 43,
              "date": "2012-07-01T00:00:00.000Z",
              "currencyDate": "2012-07-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 86,
                  "caseId": 1,
                  "position": 85,
                  "type": "Odsetki",
                  "date": "2012-07-02",
                  "transactionDate": "2012-07-02",
                  "installmentType": "equal",
                  "amountPln": 197.52,
                  "amountForeign": 54.46,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 87,
                  "caseId": 1,
                  "position": 86,
                  "type": "Kapitał",
                  "date": "2012-07-02",
                  "transactionDate": "2012-07-02",
                  "installmentType": "equal",
                  "amountPln": 2753.79,
                  "amountForeign": 759.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-07-02T00:00:00.000Z",
              "bankCollectedPln": 2951.31,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 197.52,
              "bankCollectedInterestForeign": 54.46,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 759.29,
              "bankCollectedPrincipalPln": 2753.79,
              "paymentPln": 1888.33,
              "paymentForeign": 813.76,
              "interestPln": 126.38,
              "interestForeign": 54.46,
              "principalPln": 1761.95,
              "principalForeign": 759.3,
              "days": 30,
              "paymentDifferencePln": 1062.98,
              "periodsTotal": 118,
              "simulationBalanceForeign": 58936.05,
              "simulationBalancePln": 136761.07,
              "bankBalanceForeign": 58936.29,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110996,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 758.19"
              ],
              "index": 44,
              "date": "2012-08-01T00:00:00.000Z",
              "currencyDate": "2012-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 88,
                  "caseId": 1,
                  "position": 87,
                  "type": "Odsetki",
                  "date": "2012-08-01",
                  "transactionDate": "2012-08-01",
                  "installmentType": "equal",
                  "amountPln": 196.05,
                  "amountForeign": 55.56,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 89,
                  "caseId": 1,
                  "position": 88,
                  "type": "Kapitał",
                  "date": "2012-08-01",
                  "transactionDate": "2012-08-01",
                  "installmentType": "equal",
                  "amountPln": 2675.43,
                  "amountForeign": 758.19,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-08-01T00:00:00.000Z",
              "bankCollectedPln": 2871.48,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 196.05,
              "bankCollectedInterestForeign": 55.56,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 758.19,
              "bankCollectedPrincipalPln": 2675.43,
              "paymentPln": 1888.31,
              "paymentForeign": 813.75,
              "interestPln": 128.93,
              "interestForeign": 55.56,
              "principalPln": 1759.38,
              "principalForeign": 758.19,
              "days": 31,
              "paymentDifferencePln": 983.17,
              "periodsTotal": 118,
              "simulationBalanceForeign": 58177.86,
              "simulationBalancePln": 135001.69,
              "bankBalanceForeign": 58178.1,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110997,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 758.9"
              ],
              "index": 45,
              "date": "2012-09-01T00:00:00.000Z",
              "currencyDate": "2012-09-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 91,
                  "caseId": 1,
                  "position": 90,
                  "type": "Kapitał",
                  "date": "2012-09-03",
                  "transactionDate": "2012-09-03",
                  "installmentType": "equal",
                  "amountPln": 2717.39,
                  "amountForeign": 758.9,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 90,
                  "caseId": 1,
                  "position": 89,
                  "type": "Odsetki",
                  "date": "2012-09-03",
                  "transactionDate": "2012-09-03",
                  "installmentType": "equal",
                  "amountPln": 196.4,
                  "amountForeign": 54.85,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-09-03T00:00:00.000Z",
              "bankCollectedPln": 2913.79,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 196.4,
              "bankCollectedInterestForeign": 54.85,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 758.9,
              "bankCollectedPrincipalPln": 2717.39,
              "paymentPln": 1888.34,
              "paymentForeign": 813.76,
              "interestPln": 127.27,
              "interestForeign": 54.85,
              "principalPln": 1761.07,
              "principalForeign": 758.92,
              "days": 31,
              "paymentDifferencePln": 1025.45,
              "periodsTotal": 118,
              "simulationBalanceForeign": 57418.94,
              "simulationBalancePln": 133240.62,
              "bankBalanceForeign": 57419.2,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111006,
              "controlSumForeign": 0.26
            },
            {
              "_calculationWarns": [
                "pKapitał 761.36"
              ],
              "index": 46,
              "date": "2012-10-01T00:00:00.000Z",
              "currencyDate": "2012-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 92,
                  "caseId": 1,
                  "position": 91,
                  "type": "Odsetki",
                  "date": "2012-10-01",
                  "transactionDate": "2012-10-01",
                  "installmentType": "equal",
                  "amountPln": 183.91,
                  "amountForeign": 52.39,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 93,
                  "caseId": 1,
                  "position": 92,
                  "type": "Kapitał",
                  "date": "2012-10-01",
                  "transactionDate": "2012-10-01",
                  "installmentType": "equal",
                  "amountPln": 2672.68,
                  "amountForeign": 761.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-10-01T00:00:00.000Z",
              "bankCollectedPln": 2856.59,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 183.91,
              "bankCollectedInterestForeign": 52.39,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 761.36,
              "bankCollectedPrincipalPln": 2672.68,
              "paymentPln": 1888.37,
              "paymentForeign": 813.78,
              "interestPln": 121.56,
              "interestForeign": 52.39,
              "principalPln": 1766.81,
              "principalForeign": 761.39,
              "days": 30,
              "paymentDifferencePln": 968.22,
              "periodsTotal": 118,
              "simulationBalanceForeign": 56657.55,
              "simulationBalancePln": 131473.81,
              "bankBalanceForeign": 56657.84,
              "bankRate": 0.0111,
              "bankRateReversed": 0.011101,
              "controlSumForeign": 0.29
            },
            {
              "_calculationWarns": [
                "pKapitał 760.34"
              ],
              "index": 47,
              "date": "2012-11-01T00:00:00.000Z",
              "currencyDate": "2012-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 94,
                  "caseId": 1,
                  "position": 93,
                  "type": "Odsetki",
                  "date": "2012-11-02",
                  "transactionDate": "2012-11-02",
                  "installmentType": "equal",
                  "amountPln": 188.05,
                  "amountForeign": 53.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 95,
                  "caseId": 1,
                  "position": 94,
                  "type": "Kapitał",
                  "date": "2012-11-02",
                  "transactionDate": "2012-11-02",
                  "installmentType": "equal",
                  "amountPln": 2677,
                  "amountForeign": 760.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-11-02T00:00:00.000Z",
              "bankCollectedPln": 2865.05,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 188.05,
              "bankCollectedInterestForeign": 53.41,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 760.34,
              "bankCollectedPrincipalPln": 2677,
              "paymentPln": 1888.35,
              "paymentForeign": 813.77,
              "interestPln": 123.94,
              "interestForeign": 53.41,
              "principalPln": 1764.41,
              "principalForeign": 760.36,
              "days": 31,
              "paymentDifferencePln": 976.7,
              "periodsTotal": 118,
              "simulationBalanceForeign": 55897.19,
              "simulationBalancePln": 129709.4,
              "bankBalanceForeign": 55897.5,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0110993,
              "controlSumForeign": 0.31
            },
            {
              "_calculationWarns": [
                "pKapitał 762.75"
              ],
              "index": 48,
              "date": "2012-12-01T00:00:00.000Z",
              "currencyDate": "2012-12-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 97,
                  "caseId": 1,
                  "position": 96,
                  "type": "Kapitał",
                  "date": "2012-12-03",
                  "transactionDate": "2012-12-03",
                  "installmentType": "equal",
                  "amountPln": 2674.13,
                  "amountForeign": 762.75,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 96,
                  "caseId": 1,
                  "position": 95,
                  "type": "Odsetki",
                  "date": "2012-12-03",
                  "transactionDate": "2012-12-03",
                  "installmentType": "equal",
                  "amountPln": 178.8,
                  "amountForeign": 51,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2012-12-03T00:00:00.000Z",
              "bankCollectedPln": 2852.93,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 178.8,
              "bankCollectedInterestForeign": 51,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 762.75,
              "bankCollectedPrincipalPln": 2674.13,
              "paymentPln": 1888.38,
              "paymentForeign": 813.78,
              "interestPln": 118.34,
              "interestForeign": 51,
              "principalPln": 1770.05,
              "principalForeign": 762.79,
              "days": 30,
              "paymentDifferencePln": 964.55,
              "periodsTotal": 118,
              "simulationBalanceForeign": 55134.4,
              "simulationBalancePln": 127939.35,
              "bankBalanceForeign": 55134.75,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111007,
              "controlSumForeign": 0.35
            },
            {
              "_calculationWarns": [
                "pKapitał 761.77"
              ],
              "index": 49,
              "date": "2013-01-01T00:00:00.000Z",
              "currencyDate": "2013-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 98,
                  "caseId": 1,
                  "position": 97,
                  "type": "Odsetki",
                  "date": "2013-01-02",
                  "transactionDate": "2013-01-02",
                  "installmentType": "equal",
                  "amountPln": 180.48,
                  "amountForeign": 51.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 99,
                  "caseId": 1,
                  "position": 98,
                  "type": "Kapitał",
                  "date": "2013-01-02",
                  "transactionDate": "2013-01-02",
                  "installmentType": "equal",
                  "amountPln": 2645.02,
                  "amountForeign": 761.77,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-01-02T00:00:00.000Z",
              "bankCollectedPln": 2825.5,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 180.48,
              "bankCollectedInterestForeign": 51.98,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 761.77,
              "bankCollectedPrincipalPln": 2645.02,
              "paymentPln": 1888.36,
              "paymentForeign": 813.77,
              "interestPln": 120.61,
              "interestForeign": 51.98,
              "principalPln": 1767.75,
              "principalForeign": 761.8,
              "days": 31,
              "paymentDifferencePln": 937.14,
              "periodsTotal": 118,
              "simulationBalanceForeign": 54372.6,
              "simulationBalancePln": 126171.6,
              "bankBalanceForeign": 54372.98,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111005,
              "controlSumForeign": 0.38
            },
            {
              "_calculationWarns": [
                "pKapitał 762.49"
              ],
              "index": 50,
              "date": "2013-02-01T00:00:00.000Z",
              "currencyDate": "2013-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 101,
                  "caseId": 1,
                  "position": 100,
                  "type": "Kapitał",
                  "date": "2013-02-01",
                  "transactionDate": "2013-02-01",
                  "installmentType": "equal",
                  "amountPln": 2662.54,
                  "amountForeign": 762.49,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 100,
                  "caseId": 1,
                  "position": 99,
                  "type": "Odsetki",
                  "date": "2013-02-01",
                  "transactionDate": "2013-02-01",
                  "installmentType": "equal",
                  "amountPln": 178.99,
                  "amountForeign": 51.26,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-02-01T00:00:00.000Z",
              "bankCollectedPln": 2841.53,
              "bankCollectedForeign": 813.75,
              "bankCollectedInterestPln": 178.99,
              "bankCollectedInterestForeign": 51.26,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 762.49,
              "bankCollectedPrincipalPln": 2662.54,
              "paymentPln": 1888.39,
              "paymentForeign": 813.79,
              "interestPln": 118.95,
              "interestForeign": 51.26,
              "principalPln": 1769.45,
              "principalForeign": 762.53,
              "days": 31,
              "paymentDifferencePln": 953.14,
              "periodsTotal": 118,
              "simulationBalanceForeign": 53610.07,
              "simulationBalancePln": 124402.15,
              "bankBalanceForeign": 53610.49,
              "bankRate": 0.0111,
              "bankRateReversed": 0.0111001,
              "controlSumForeign": 0.42
            },
            {
              "_calculationWarns": [
                "pKapitał 769.96"
              ],
              "index": 51,
              "date": "2013-03-01T00:00:00.000Z",
              "currencyDate": "2013-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 102,
                  "caseId": 1,
                  "position": 101,
                  "type": "Odsetki",
                  "date": "2013-03-01",
                  "transactionDate": "2013-03-01",
                  "installmentType": "equal",
                  "amountPln": 145.11,
                  "amountForeign": 41.54,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 103,
                  "caseId": 1,
                  "position": 102,
                  "type": "Kapitał",
                  "date": "2013-03-01",
                  "transactionDate": "2013-03-01",
                  "installmentType": "equal",
                  "amountPln": 2689.62,
                  "amountForeign": 769.96,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-03-01T00:00:00.000Z",
              "bankCollectedPln": 2834.73,
              "bankCollectedForeign": 811.5,
              "bankCollectedInterestPln": 145.11,
              "bankCollectedInterestForeign": 41.54,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 769.96,
              "bankCollectedPrincipalPln": 2689.62,
              "paymentPln": 1883.06,
              "paymentForeign": 811.49,
              "interestPln": 96.39,
              "interestForeign": 41.54,
              "principalPln": 1786.68,
              "principalForeign": 769.95,
              "days": 28,
              "paymentDifferencePln": 951.67,
              "periodsTotal": 118,
              "simulationBalanceForeign": 52840.12,
              "simulationBalancePln": 122615.47,
              "bankBalanceForeign": 52840.53,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101007,
              "controlSumForeign": 0.41
            },
            {
              "_calculationWarns": [
                "pKapitał 766.17"
              ],
              "index": 52,
              "date": "2013-04-01T00:00:00.000Z",
              "currencyDate": "2013-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 104,
                  "caseId": 1,
                  "position": 103,
                  "type": "Odsetki",
                  "date": "2013-04-02",
                  "transactionDate": "2013-04-02",
                  "installmentType": "equal",
                  "amountPln": 160.35,
                  "amountForeign": 45.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 105,
                  "caseId": 1,
                  "position": 104,
                  "type": "Kapitał",
                  "date": "2013-04-02",
                  "transactionDate": "2013-04-02",
                  "installmentType": "equal",
                  "amountPln": 2710.25,
                  "amountForeign": 766.17,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-04-02T00:00:00.000Z",
              "bankCollectedPln": 2870.6,
              "bankCollectedForeign": 811.5,
              "bankCollectedInterestPln": 160.35,
              "bankCollectedInterestForeign": 45.33,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 766.17,
              "bankCollectedPrincipalPln": 2710.25,
              "paymentPln": 1882.94,
              "paymentForeign": 811.44,
              "interestPln": 105.18,
              "interestForeign": 45.33,
              "principalPln": 1777.76,
              "principalForeign": 766.11,
              "days": 31,
              "paymentDifferencePln": 987.66,
              "periodsTotal": 118,
              "simulationBalanceForeign": 52074.01,
              "simulationBalancePln": 120837.71,
              "bankBalanceForeign": 52074.36,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101007,
              "controlSumForeign": 0.35
            },
            {
              "_calculationWarns": [
                "pKapitał 768.27"
              ],
              "index": 53,
              "date": "2013-05-01T00:00:00.000Z",
              "currencyDate": "2013-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 106,
                  "caseId": 1,
                  "position": 105,
                  "type": "Odsetki",
                  "date": "2013-05-02",
                  "transactionDate": "2013-05-02",
                  "installmentType": "equal",
                  "amountPln": 151.73,
                  "amountForeign": 43.23,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 107,
                  "caseId": 1,
                  "position": 106,
                  "type": "Kapitał",
                  "date": "2013-05-02",
                  "transactionDate": "2013-05-02",
                  "installmentType": "equal",
                  "amountPln": 2696.55,
                  "amountForeign": 768.27,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-05-02T00:00:00.000Z",
              "bankCollectedPln": 2848.28,
              "bankCollectedForeign": 811.5,
              "bankCollectedInterestPln": 151.73,
              "bankCollectedInterestForeign": 43.23,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 768.27,
              "bankCollectedPrincipalPln": 2696.55,
              "paymentPln": 1882.97,
              "paymentForeign": 811.45,
              "interestPln": 100.31,
              "interestForeign": 43.23,
              "principalPln": 1782.66,
              "principalForeign": 768.22,
              "days": 30,
              "paymentDifferencePln": 965.31,
              "periodsTotal": 118,
              "simulationBalanceForeign": 51305.79,
              "simulationBalancePln": 119055.05,
              "bankBalanceForeign": 51306.09,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101003,
              "controlSumForeign": 0.3
            },
            {
              "_calculationWarns": [
                "pKapitał 336.83",
                "pKapitał 51.75"
              ],
              "index": 54,
              "date": "2013-06-01T00:00:00.000Z",
              "currencyDate": "2013-06-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 109,
                  "caseId": 1,
                  "position": 108,
                  "type": "Kapitał",
                  "date": "2013-06-03",
                  "transactionDate": "2013-06-03",
                  "installmentType": "equal",
                  "amountPln": 1192.53,
                  "amountForeign": 336.83,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 108,
                  "caseId": 1,
                  "position": 107,
                  "type": "Odsetki",
                  "date": "2013-06-03",
                  "transactionDate": "2013-06-03",
                  "installmentType": "equal",
                  "amountPln": 155.81,
                  "amountForeign": 44.01,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 110,
                  "caseId": 1,
                  "position": 109,
                  "type": "Kapitał",
                  "date": "2013-06-05",
                  "transactionDate": "2013-06-05",
                  "installmentType": "equal",
                  "amountPln": 182.42,
                  "amountForeign": 51.75,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-06-03T00:00:00.000Z",
              "bankCollectedPln": 1530.76,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 155.81,
              "bankCollectedInterestForeign": 44.01,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 388.58,
              "bankCollectedPrincipalPln": 1374.95,
              "paymentPln": 1003.82,
              "paymentForeign": 432.59,
              "interestPln": 102.13,
              "interestForeign": 44.01,
              "principalPln": 901.7,
              "principalForeign": 388.58,
              "days": 31,
              "paymentDifferencePln": 526.94,
              "periodsTotal": 178,
              "simulationBalanceForeign": 50917.21,
              "simulationBalancePln": 118153.35,
              "bankBalanceForeign": 50917.51,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100998,
              "controlSumForeign": 0.3
            },
            {
              "_calculationWarns": [
                "pKapitał 390.32"
              ],
              "index": 55,
              "date": "2013-07-01T00:00:00.000Z",
              "currencyDate": "2013-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 111,
                  "caseId": 1,
                  "position": 110,
                  "type": "Odsetki",
                  "date": "2013-07-01",
                  "transactionDate": "2013-07-01",
                  "installmentType": "equal",
                  "amountPln": 152.96,
                  "amountForeign": 42.27,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 112,
                  "caseId": 1,
                  "position": 111,
                  "type": "Kapitał",
                  "date": "2013-07-01",
                  "transactionDate": "2013-07-01",
                  "installmentType": "equal",
                  "amountPln": 1412.45,
                  "amountForeign": 390.32,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-07-01T00:00:00.000Z",
              "bankCollectedPln": 1565.41,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 152.96,
              "bankCollectedInterestForeign": 42.27,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 390.32,
              "bankCollectedPrincipalPln": 1412.45,
              "paymentPln": 1003.84,
              "paymentForeign": 432.6,
              "interestPln": 98.08,
              "interestForeign": 42.27,
              "principalPln": 905.76,
              "principalForeign": 390.33,
              "days": 30,
              "paymentDifferencePln": 561.57,
              "periodsTotal": 178,
              "simulationBalanceForeign": 50526.88,
              "simulationBalancePln": 117247.59,
              "bankBalanceForeign": 50527.19,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101004,
              "controlSumForeign": 0.31
            },
            {
              "_calculationWarns": [
                "pKapitał 389.25"
              ],
              "index": 56,
              "date": "2013-08-01T00:00:00.000Z",
              "currencyDate": "2013-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 113,
                  "caseId": 1,
                  "position": 112,
                  "type": "Odsetki",
                  "date": "2013-08-01",
                  "transactionDate": "2013-08-01",
                  "installmentType": "equal",
                  "amountPln": 153.9,
                  "amountForeign": 43.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 114,
                  "caseId": 1,
                  "position": 113,
                  "type": "Kapitał",
                  "date": "2013-08-01",
                  "transactionDate": "2013-08-01",
                  "installmentType": "equal",
                  "amountPln": 1382.23,
                  "amountForeign": 389.25,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-08-01T00:00:00.000Z",
              "bankCollectedPln": 1536.13,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 153.9,
              "bankCollectedInterestForeign": 43.34,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 389.25,
              "bankCollectedPrincipalPln": 1382.23,
              "paymentPln": 1003.83,
              "paymentForeign": 432.59,
              "interestPln": 100.58,
              "interestForeign": 43.34,
              "principalPln": 903.25,
              "principalForeign": 389.25,
              "days": 31,
              "paymentDifferencePln": 532.3,
              "periodsTotal": 178,
              "simulationBalanceForeign": 50137.63,
              "simulationBalancePln": 116344.34,
              "bankBalanceForeign": 50137.94,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100994,
              "controlSumForeign": 0.31
            },
            {
              "_calculationWarns": [
                "pKapitał 389.58"
              ],
              "index": 57,
              "date": "2013-09-01T00:00:00.000Z",
              "currencyDate": "2013-09-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 115,
                  "caseId": 1,
                  "position": 114,
                  "type": "Odsetki",
                  "date": "2013-09-02",
                  "transactionDate": "2013-09-02",
                  "installmentType": "equal",
                  "amountPln": 153.34,
                  "amountForeign": 43.01,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 116,
                  "caseId": 1,
                  "position": 115,
                  "type": "Kapitał",
                  "date": "2013-09-02",
                  "transactionDate": "2013-09-02",
                  "installmentType": "equal",
                  "amountPln": 1388.97,
                  "amountForeign": 389.58,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-09-02T00:00:00.000Z",
              "bankCollectedPln": 1542.31,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 153.34,
              "bankCollectedInterestForeign": 43.01,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 389.58,
              "bankCollectedPrincipalPln": 1388.97,
              "paymentPln": 1003.84,
              "paymentForeign": 432.6,
              "interestPln": 99.8,
              "interestForeign": 43.01,
              "principalPln": 904.04,
              "principalForeign": 389.59,
              "days": 31,
              "paymentDifferencePln": 538.47,
              "periodsTotal": 178,
              "simulationBalanceForeign": 49748.04,
              "simulationBalancePln": 115440.3,
              "bankBalanceForeign": 49748.36,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101003,
              "controlSumForeign": 0.32
            },
            {
              "_calculationWarns": [
                "pKapitał 391.29"
              ],
              "index": 58,
              "date": "2013-10-01T00:00:00.000Z",
              "currencyDate": "2013-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 118,
                  "caseId": 1,
                  "position": 117,
                  "type": "Kapitał",
                  "date": "2013-10-01",
                  "transactionDate": "2013-10-01",
                  "installmentType": "equal",
                  "amountPln": 1392.99,
                  "amountForeign": 391.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 117,
                  "caseId": 1,
                  "position": 116,
                  "type": "Odsetki",
                  "date": "2013-10-01",
                  "transactionDate": "2013-10-01",
                  "installmentType": "equal",
                  "amountPln": 147.03,
                  "amountForeign": 41.3,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-10-01T00:00:00.000Z",
              "bankCollectedPln": 1540.02,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 147.03,
              "bankCollectedInterestForeign": 41.3,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 391.29,
              "bankCollectedPrincipalPln": 1392.99,
              "paymentPln": 1003.86,
              "paymentForeign": 432.6,
              "interestPln": 95.83,
              "interestForeign": 41.3,
              "principalPln": 908.03,
              "principalForeign": 391.31,
              "days": 30,
              "paymentDifferencePln": 536.16,
              "periodsTotal": 178,
              "simulationBalanceForeign": 49356.73,
              "simulationBalancePln": 114532.27,
              "bankBalanceForeign": 49357.07,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101005,
              "controlSumForeign": 0.34
            },
            {
              "_calculationWarns": [
                "pKapitał 108.38",
                "pKapitał 281.87"
              ],
              "index": 59,
              "date": "2013-11-01T00:00:00.000Z",
              "currencyDate": "2013-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 119,
                  "caseId": 1,
                  "position": 118,
                  "type": "Odsetki",
                  "date": "2013-11-04",
                  "transactionDate": "2013-11-04",
                  "installmentType": "equal",
                  "amountPln": 148.45,
                  "amountForeign": 42.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 120,
                  "caseId": 1,
                  "position": 119,
                  "type": "Kapitał",
                  "date": "2013-11-04",
                  "transactionDate": "2013-11-04",
                  "installmentType": "equal",
                  "amountPln": 379.98,
                  "amountForeign": 108.38,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 121,
                  "caseId": 1,
                  "position": 120,
                  "type": "Kapitał",
                  "date": "2013-11-05",
                  "transactionDate": "2013-11-05",
                  "installmentType": "equal",
                  "amountPln": 986.55,
                  "amountForeign": 281.87,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-11-04T00:00:00.000Z",
              "bankCollectedPln": 1514.98,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 148.45,
              "bankCollectedInterestForeign": 42.34,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 390.25,
              "bankCollectedPrincipalPln": 1366.53,
              "paymentPln": 1003.85,
              "paymentForeign": 432.6,
              "interestPln": 98.25,
              "interestForeign": 42.34,
              "principalPln": 905.6,
              "principalForeign": 390.26,
              "days": 31,
              "paymentDifferencePln": 511.13,
              "periodsTotal": 178,
              "simulationBalanceForeign": 48966.47,
              "simulationBalancePln": 113626.67,
              "bankBalanceForeign": 48966.82,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101003,
              "controlSumForeign": 0.35
            },
            {
              "_calculationWarns": [
                "pKapitał 391.94"
              ],
              "index": 60,
              "date": "2013-12-01T00:00:00.000Z",
              "currencyDate": "2013-12-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 122,
                  "caseId": 1,
                  "position": 121,
                  "type": "Odsetki",
                  "date": "2013-12-02",
                  "transactionDate": "2013-12-02",
                  "installmentType": "equal",
                  "amountPln": 142.83,
                  "amountForeign": 40.65,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 123,
                  "caseId": 1,
                  "position": 122,
                  "type": "Kapitał",
                  "date": "2013-12-02",
                  "transactionDate": "2013-12-02",
                  "installmentType": "equal",
                  "amountPln": 1377.16,
                  "amountForeign": 391.94,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2013-12-02T00:00:00.000Z",
              "bankCollectedPln": 1519.99,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 142.83,
              "bankCollectedInterestForeign": 40.65,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 391.94,
              "bankCollectedPrincipalPln": 1377.16,
              "paymentPln": 1003.86,
              "paymentForeign": 432.61,
              "interestPln": 94.33,
              "interestForeign": 40.65,
              "principalPln": 909.54,
              "principalForeign": 391.96,
              "days": 30,
              "paymentDifferencePln": 516.13,
              "periodsTotal": 178,
              "simulationBalanceForeign": 48574.51,
              "simulationBalancePln": 112717.13,
              "bankBalanceForeign": 48574.88,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101002,
              "controlSumForeign": 0.37
            },
            {
              "_calculationWarns": [
                "pKapitał 390.92"
              ],
              "index": 61,
              "date": "2014-01-01T00:00:00.000Z",
              "currencyDate": "2014-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 124,
                  "caseId": 1,
                  "position": 123,
                  "type": "Odsetki",
                  "date": "2014-01-02",
                  "transactionDate": "2014-01-02",
                  "installmentType": "equal",
                  "amountPln": 145.22,
                  "amountForeign": 41.67,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 125,
                  "caseId": 1,
                  "position": 124,
                  "type": "Kapitał",
                  "date": "2014-01-02",
                  "transactionDate": "2014-01-02",
                  "installmentType": "equal",
                  "amountPln": 1362.4,
                  "amountForeign": 390.92,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-01-02T00:00:00.000Z",
              "bankCollectedPln": 1507.62,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 145.22,
              "bankCollectedInterestForeign": 41.67,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 390.92,
              "bankCollectedPrincipalPln": 1362.4,
              "paymentPln": 1003.85,
              "paymentForeign": 432.6,
              "interestPln": 96.69,
              "interestForeign": 41.67,
              "principalPln": 907.16,
              "principalForeign": 390.93,
              "days": 31,
              "paymentDifferencePln": 503.77,
              "periodsTotal": 178,
              "simulationBalanceForeign": 48183.58,
              "simulationBalancePln": 111809.97,
              "bankBalanceForeign": 48183.96,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101005,
              "controlSumForeign": 0.38
            },
            {
              "_calculationWarns": [
                "pKapitał 391.26"
              ],
              "index": 62,
              "date": "2014-02-01T00:00:00.000Z",
              "currencyDate": "2014-02-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 127,
                  "caseId": 1,
                  "position": 126,
                  "type": "Kapitał",
                  "date": "2014-02-03",
                  "transactionDate": "2014-02-03",
                  "installmentType": "equal",
                  "amountPln": 1401.85,
                  "amountForeign": 391.26,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 126,
                  "caseId": 1,
                  "position": 125,
                  "type": "Odsetki",
                  "date": "2014-02-03",
                  "transactionDate": "2014-02-03",
                  "installmentType": "equal",
                  "amountPln": 148.08,
                  "amountForeign": 41.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-02-03T00:00:00.000Z",
              "bankCollectedPln": 1549.93,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 148.08,
              "bankCollectedInterestForeign": 41.33,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 391.26,
              "bankCollectedPrincipalPln": 1401.85,
              "paymentPln": 1003.87,
              "paymentForeign": 432.61,
              "interestPln": 95.91,
              "interestForeign": 41.33,
              "principalPln": 907.96,
              "principalForeign": 391.28,
              "days": 31,
              "paymentDifferencePln": 546.06,
              "periodsTotal": 178,
              "simulationBalanceForeign": 47792.3,
              "simulationBalancePln": 110902.01,
              "bankBalanceForeign": 47792.7,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100994,
              "controlSumForeign": 0.4
            },
            {
              "_calculationWarns": [
                "pKapitał 395.56"
              ],
              "index": 63,
              "date": "2014-03-01T00:00:00.000Z",
              "currencyDate": "2014-03-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 128,
                  "caseId": 1,
                  "position": 127,
                  "type": "Odsetki",
                  "date": "2014-03-03",
                  "transactionDate": "2014-03-03",
                  "installmentType": "equal",
                  "amountPln": 131.42,
                  "amountForeign": 37.03,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 129,
                  "caseId": 1,
                  "position": 128,
                  "type": "Kapitał",
                  "date": "2014-03-03",
                  "transactionDate": "2014-03-03",
                  "installmentType": "equal",
                  "amountPln": 1403.89,
                  "amountForeign": 395.56,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-03-03T00:00:00.000Z",
              "bankCollectedPln": 1535.31,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 131.42,
              "bankCollectedInterestForeign": 37.03,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 395.56,
              "bankCollectedPrincipalPln": 1403.89,
              "paymentPln": 1003.88,
              "paymentForeign": 432.62,
              "interestPln": 85.93,
              "interestForeign": 37.03,
              "principalPln": 917.96,
              "principalForeign": 395.59,
              "days": 28,
              "paymentDifferencePln": 531.43,
              "periodsTotal": 178,
              "simulationBalanceForeign": 47396.71,
              "simulationBalancePln": 109984.05,
              "bankBalanceForeign": 47397.14,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101001,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 391.93"
              ],
              "index": 64,
              "date": "2014-04-01T00:00:00.000Z",
              "currencyDate": "2014-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 130,
                  "caseId": 1,
                  "position": 129,
                  "type": "Odsetki",
                  "date": "2014-04-01",
                  "transactionDate": "2014-04-01",
                  "installmentType": "equal",
                  "amountPln": 143.14,
                  "amountForeign": 40.66,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 131,
                  "caseId": 1,
                  "position": 130,
                  "type": "Kapitał",
                  "date": "2014-04-01",
                  "transactionDate": "2014-04-01",
                  "installmentType": "equal",
                  "amountPln": 1379.79,
                  "amountForeign": 391.93,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-04-01T00:00:00.000Z",
              "bankCollectedPln": 1522.93,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 143.14,
              "bankCollectedInterestForeign": 40.66,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 391.93,
              "bankCollectedPrincipalPln": 1379.79,
              "paymentPln": 1003.82,
              "paymentForeign": 432.59,
              "interestPln": 94.35,
              "interestForeign": 40.66,
              "principalPln": 909.47,
              "principalForeign": 391.93,
              "days": 31,
              "paymentDifferencePln": 519.11,
              "periodsTotal": 178,
              "simulationBalanceForeign": 47004.78,
              "simulationBalancePln": 109074.58,
              "bankBalanceForeign": 47005.21,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101006,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 393.57"
              ],
              "index": 65,
              "date": "2014-05-01T00:00:00.000Z",
              "currencyDate": "2014-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 133,
                  "caseId": 1,
                  "position": 132,
                  "type": "Kapitał",
                  "date": "2014-05-02",
                  "transactionDate": "2014-05-02",
                  "installmentType": "equal",
                  "amountPln": 1394.26,
                  "amountForeign": 393.57,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 132,
                  "caseId": 1,
                  "position": 131,
                  "type": "Odsetki",
                  "date": "2014-05-02",
                  "transactionDate": "2014-05-02",
                  "installmentType": "equal",
                  "amountPln": 138.23,
                  "amountForeign": 39.02,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-05-02T00:00:00.000Z",
              "bankCollectedPln": 1532.49,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 138.23,
              "bankCollectedInterestForeign": 39.02,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 393.57,
              "bankCollectedPrincipalPln": 1394.26,
              "paymentPln": 1003.83,
              "paymentForeign": 432.59,
              "interestPln": 90.55,
              "interestForeign": 39.02,
              "principalPln": 913.29,
              "principalForeign": 393.57,
              "days": 30,
              "paymentDifferencePln": 528.66,
              "periodsTotal": 178,
              "simulationBalanceForeign": 46611.21,
              "simulationBalancePln": 108161.29,
              "bankBalanceForeign": 46611.64,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100998,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 392.61"
              ],
              "index": 66,
              "date": "2014-06-01T00:00:00.000Z",
              "currencyDate": "2014-06-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 134,
                  "caseId": 1,
                  "position": 133,
                  "type": "Odsetki",
                  "date": "2014-06-02",
                  "transactionDate": "2014-06-02",
                  "installmentType": "equal",
                  "amountPln": 139.68,
                  "amountForeign": 39.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 135,
                  "caseId": 1,
                  "position": 134,
                  "type": "Kapitał",
                  "date": "2014-06-02",
                  "transactionDate": "2014-06-02",
                  "installmentType": "equal",
                  "amountPln": 1371.66,
                  "amountForeign": 392.61,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-06-02T00:00:00.000Z",
              "bankCollectedPln": 1511.34,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 139.68,
              "bankCollectedInterestForeign": 39.98,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 392.61,
              "bankCollectedPrincipalPln": 1371.66,
              "paymentPln": 1003.82,
              "paymentForeign": 432.59,
              "interestPln": 92.78,
              "interestForeign": 39.98,
              "principalPln": 911.04,
              "principalForeign": 392.6,
              "days": 31,
              "paymentDifferencePln": 507.52,
              "periodsTotal": 178,
              "simulationBalanceForeign": 46218.61,
              "simulationBalancePln": 107250.25,
              "bankBalanceForeign": 46219.03,
              "bankRate": 0.0101,
              "bankRateReversed": 0.010099,
              "controlSumForeign": 0.42
            },
            {
              "_calculationWarns": [
                "pKapitał 394.22"
              ],
              "index": 67,
              "date": "2014-07-01T00:00:00.000Z",
              "currencyDate": "2014-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 137,
                  "caseId": 1,
                  "position": 136,
                  "type": "Kapitał",
                  "date": "2014-07-01",
                  "transactionDate": "2014-07-01",
                  "installmentType": "equal",
                  "amountPln": 1389.07,
                  "amountForeign": 394.22,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 136,
                  "caseId": 1,
                  "position": 135,
                  "type": "Odsetki",
                  "date": "2014-07-01",
                  "transactionDate": "2014-07-01",
                  "installmentType": "equal",
                  "amountPln": 135.2,
                  "amountForeign": 38.37,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-07-01T00:00:00.000Z",
              "bankCollectedPln": 1524.27,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 135.2,
              "bankCollectedInterestForeign": 38.37,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 394.22,
              "bankCollectedPrincipalPln": 1389.07,
              "paymentPln": 1003.84,
              "paymentForeign": 432.6,
              "interestPln": 89.03,
              "interestForeign": 38.37,
              "principalPln": 914.81,
              "principalForeign": 394.23,
              "days": 30,
              "paymentDifferencePln": 520.43,
              "periodsTotal": 178,
              "simulationBalanceForeign": 45824.38,
              "simulationBalancePln": 106335.44,
              "bankBalanceForeign": 45824.81,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101005,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 393.28"
              ],
              "index": 68,
              "date": "2014-08-01T00:00:00.000Z",
              "currencyDate": "2014-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 138,
                  "caseId": 1,
                  "position": 137,
                  "type": "Odsetki",
                  "date": "2014-08-01",
                  "transactionDate": "2014-08-01",
                  "installmentType": "equal",
                  "amountPln": 139.06,
                  "amountForeign": 39.31,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 139,
                  "caseId": 1,
                  "position": 138,
                  "type": "Kapitał",
                  "date": "2014-08-01",
                  "transactionDate": "2014-08-01",
                  "installmentType": "equal",
                  "amountPln": 1391.27,
                  "amountForeign": 393.28,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-08-01T00:00:00.000Z",
              "bankCollectedPln": 1530.33,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 139.06,
              "bankCollectedInterestForeign": 39.31,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 393.28,
              "bankCollectedPrincipalPln": 1391.27,
              "paymentPln": 1003.83,
              "paymentForeign": 432.59,
              "interestPln": 91.21,
              "interestForeign": 39.31,
              "principalPln": 912.61,
              "principalForeign": 393.28,
              "days": 31,
              "paymentDifferencePln": 526.5,
              "periodsTotal": 178,
              "simulationBalanceForeign": 45431.1,
              "simulationBalancePln": 105422.83,
              "bankBalanceForeign": 45431.53,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101003,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 393.62"
              ],
              "index": 69,
              "date": "2014-09-01T00:00:00.000Z",
              "currencyDate": "2014-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 140,
                  "caseId": 1,
                  "position": 139,
                  "type": "Odsetki",
                  "date": "2014-09-01",
                  "transactionDate": "2014-09-01",
                  "installmentType": "equal",
                  "amountPln": 140.07,
                  "amountForeign": 38.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 141,
                  "caseId": 1,
                  "position": 140,
                  "type": "Kapitał",
                  "date": "2014-09-01",
                  "transactionDate": "2014-09-01",
                  "installmentType": "equal",
                  "amountPln": 1414.79,
                  "amountForeign": 393.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-09-01T00:00:00.000Z",
              "bankCollectedPln": 1554.86,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 140.07,
              "bankCollectedInterestForeign": 38.97,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 393.62,
              "bankCollectedPrincipalPln": 1414.79,
              "paymentPln": 1003.84,
              "paymentForeign": 432.6,
              "interestPln": 90.43,
              "interestForeign": 38.97,
              "principalPln": 913.41,
              "principalForeign": 393.63,
              "days": 31,
              "paymentDifferencePln": 551.02,
              "periodsTotal": 178,
              "simulationBalanceForeign": 45037.47,
              "simulationBalancePln": 104509.42,
              "bankBalanceForeign": 45037.91,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100996,
              "controlSumForeign": 0.44
            },
            {
              "_calculationWarns": [
                "pKapitał 395.2"
              ],
              "index": 70,
              "date": "2014-10-01T00:00:00.000Z",
              "currencyDate": "2014-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 142,
                  "caseId": 1,
                  "position": 141,
                  "type": "Odsetki",
                  "date": "2014-10-01",
                  "transactionDate": "2014-10-01",
                  "installmentType": "equal",
                  "amountPln": 133.52,
                  "amountForeign": 37.39,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 143,
                  "caseId": 1,
                  "position": 142,
                  "type": "Kapitał",
                  "date": "2014-10-01",
                  "transactionDate": "2014-10-01",
                  "installmentType": "equal",
                  "amountPln": 1411.22,
                  "amountForeign": 395.2,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-10-01T00:00:00.000Z",
              "bankCollectedPln": 1544.74,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 133.52,
              "bankCollectedInterestForeign": 37.39,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 395.2,
              "bankCollectedPrincipalPln": 1411.22,
              "paymentPln": 1003.86,
              "paymentForeign": 432.6,
              "interestPln": 86.76,
              "interestForeign": 37.39,
              "principalPln": 917.1,
              "principalForeign": 395.22,
              "days": 30,
              "paymentDifferencePln": 540.88,
              "periodsTotal": 178,
              "simulationBalanceForeign": 44642.25,
              "simulationBalancePln": 103592.32,
              "bankBalanceForeign": 44642.71,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101006,
              "controlSumForeign": 0.46
            },
            {
              "_calculationWarns": [
                "pKapitał 394.3"
              ],
              "index": 71,
              "date": "2014-11-01T00:00:00.000Z",
              "currencyDate": "2014-11-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 145,
                  "caseId": 1,
                  "position": 144,
                  "type": "Kapitał",
                  "date": "2014-11-03",
                  "transactionDate": "2014-11-03",
                  "installmentType": "equal",
                  "amountPln": 1422.56,
                  "amountForeign": 394.3,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 144,
                  "caseId": 1,
                  "position": 143,
                  "type": "Odsetki",
                  "date": "2014-11-03",
                  "transactionDate": "2014-11-03",
                  "installmentType": "equal",
                  "amountPln": 138.14,
                  "amountForeign": 38.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-11-03T00:00:00.000Z",
              "bankCollectedPln": 1560.7,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 138.14,
              "bankCollectedInterestForeign": 38.29,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 394.3,
              "bankCollectedPrincipalPln": 1422.56,
              "paymentPln": 1003.85,
              "paymentForeign": 432.6,
              "interestPln": 88.86,
              "interestForeign": 38.29,
              "principalPln": 914.99,
              "principalForeign": 394.31,
              "days": 31,
              "paymentDifferencePln": 556.85,
              "periodsTotal": 178,
              "simulationBalanceForeign": 44247.94,
              "simulationBalancePln": 102677.33,
              "bankBalanceForeign": 44248.41,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100987,
              "controlSumForeign": 0.47
            },
            {
              "_calculationWarns": [
                "pKapitał 395.86"
              ],
              "index": 72,
              "date": "2014-12-01T00:00:00.000Z",
              "currencyDate": "2014-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 146,
                  "caseId": 1,
                  "position": 145,
                  "type": "Odsetki",
                  "date": "2014-12-01",
                  "transactionDate": "2014-12-01",
                  "installmentType": "equal",
                  "amountPln": 131.48,
                  "amountForeign": 36.73,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 147,
                  "caseId": 1,
                  "position": 146,
                  "type": "Kapitał",
                  "date": "2014-12-01",
                  "transactionDate": "2014-12-01",
                  "installmentType": "equal",
                  "amountPln": 1417.02,
                  "amountForeign": 395.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2014-12-01T00:00:00.000Z",
              "bankCollectedPln": 1548.5,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 131.48,
              "bankCollectedInterestForeign": 36.73,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 395.86,
              "bankCollectedPrincipalPln": 1417.02,
              "paymentPln": 1003.86,
              "paymentForeign": 432.61,
              "interestPln": 85.24,
              "interestForeign": 36.73,
              "principalPln": 918.63,
              "principalForeign": 395.87,
              "days": 30,
              "paymentDifferencePln": 544.64,
              "periodsTotal": 178,
              "simulationBalanceForeign": 43852.07,
              "simulationBalancePln": 101758.7,
              "bankBalanceForeign": 43852.55,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100994,
              "controlSumForeign": 0.48
            },
            {
              "_calculationWarns": [
                "pKapitał 394.97"
              ],
              "index": 73,
              "date": "2015-01-01T00:00:00.000Z",
              "currencyDate": "2015-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 148,
                  "caseId": 1,
                  "position": 147,
                  "type": "Odsetki",
                  "date": "2015-01-02",
                  "transactionDate": "2015-01-02",
                  "installmentType": "equal",
                  "amountPln": 138.13,
                  "amountForeign": 37.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 149,
                  "caseId": 1,
                  "position": 148,
                  "type": "Kapitał",
                  "date": "2015-01-02",
                  "transactionDate": "2015-01-02",
                  "installmentType": "equal",
                  "amountPln": 1450.17,
                  "amountForeign": 394.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-01-02T00:00:00.000Z",
              "bankCollectedPln": 1588.3,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 138.13,
              "bankCollectedInterestForeign": 37.62,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 394.97,
              "bankCollectedPrincipalPln": 1450.17,
              "paymentPln": 1003.85,
              "paymentForeign": 432.6,
              "interestPln": 87.29,
              "interestForeign": 37.62,
              "principalPln": 916.56,
              "principalForeign": 394.99,
              "days": 31,
              "paymentDifferencePln": 584.45,
              "periodsTotal": 178,
              "simulationBalanceForeign": 43457.08,
              "simulationBalancePln": 100842.14,
              "bankBalanceForeign": 43457.58,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101008,
              "controlSumForeign": 0.5
            },
            {
              "_calculationWarns": [
                "pKapitał 395.31"
              ],
              "index": 74,
              "date": "2015-02-01T00:00:00.000Z",
              "currencyDate": "2015-02-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 150,
                  "caseId": 1,
                  "position": 149,
                  "type": "Odsetki",
                  "date": "2015-02-02",
                  "transactionDate": "2015-02-02",
                  "installmentType": "equal",
                  "amountPln": 150.79,
                  "amountForeign": 37.28,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 151,
                  "caseId": 1,
                  "position": 150,
                  "type": "Kapitał",
                  "date": "2015-02-02",
                  "transactionDate": "2015-02-02",
                  "installmentType": "equal",
                  "amountPln": 1598.95,
                  "amountForeign": 395.31,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-02-02T00:00:00.000Z",
              "bankCollectedPln": 1749.74,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 150.79,
              "bankCollectedInterestForeign": 37.28,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 395.31,
              "bankCollectedPrincipalPln": 1598.95,
              "paymentPln": 1003.87,
              "paymentForeign": 432.61,
              "interestPln": 86.5,
              "interestForeign": 37.28,
              "principalPln": 917.37,
              "principalForeign": 395.33,
              "days": 31,
              "paymentDifferencePln": 745.87,
              "periodsTotal": 178,
              "simulationBalanceForeign": 43061.75,
              "simulationBalancePln": 99924.77,
              "bankBalanceForeign": 43062.27,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0101005,
              "controlSumForeign": 0.52
            },
            {
              "_calculationWarns": [
                "pKapitał 6.19",
                "pKapitał 393.04"
              ],
              "index": 75,
              "date": "2015-03-01T00:00:00.000Z",
              "currencyDate": "2015-03-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 153,
                  "caseId": 1,
                  "position": 152,
                  "type": "Kapitał",
                  "date": "2015-03-02",
                  "transactionDate": "2015-03-02",
                  "installmentType": "equal",
                  "amountPln": 24.42,
                  "amountForeign": 6.19,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 152,
                  "caseId": 1,
                  "position": 151,
                  "type": "Odsetki",
                  "date": "2015-03-02",
                  "transactionDate": "2015-03-02",
                  "installmentType": "equal",
                  "amountPln": 131.6,
                  "amountForeign": 33.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 154,
                  "caseId": 1,
                  "position": 153,
                  "type": "Kapitał",
                  "date": "2015-03-03",
                  "transactionDate": "2015-03-03",
                  "installmentType": "equal",
                  "amountPln": 1546.53,
                  "amountForeign": 393.04,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-03-02T00:00:00.000Z",
              "bankCollectedPln": 1702.55,
              "bankCollectedForeign": 432.59,
              "bankCollectedInterestPln": 131.6,
              "bankCollectedInterestForeign": 33.36,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 399.23,
              "bankCollectedPrincipalPln": 1570.95,
              "paymentPln": 1003.88,
              "paymentForeign": 432.62,
              "interestPln": 77.42,
              "interestForeign": 33.36,
              "principalPln": 926.46,
              "principalForeign": 399.25,
              "days": 28,
              "paymentDifferencePln": 698.67,
              "periodsTotal": 178,
              "simulationBalanceForeign": 42662.5,
              "simulationBalancePln": 98998.31,
              "bankBalanceForeign": 42663.04,
              "bankRate": 0.0101,
              "bankRateReversed": 0.0100987,
              "controlSumForeign": 0.54
            },
            {
              "_calculationWarns": [
                "pKapitał 399.5",
                "pKapitał 12.15"
              ],
              "index": 76,
              "date": "2015-04-01T00:00:00.000Z",
              "currencyDate": "2015-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 155,
                  "caseId": 1,
                  "position": 154,
                  "type": "Odsetki",
                  "date": "2015-04-01",
                  "transactionDate": "2015-04-01",
                  "installmentType": "equal",
                  "amountPln": 20.06,
                  "amountForeign": 5.07,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 156,
                  "caseId": 1,
                  "position": 155,
                  "type": "Kapitał",
                  "date": "2015-04-01",
                  "transactionDate": "2015-04-01",
                  "installmentType": "equal",
                  "amountPln": 1580.9,
                  "amountForeign": 399.5,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 157,
                  "caseId": 1,
                  "position": 156,
                  "type": "Kapitał",
                  "date": "2015-04-02",
                  "transactionDate": "2015-04-02",
                  "installmentType": "equal",
                  "amountPln": 48.13,
                  "amountForeign": 12.15,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-04-01T00:00:00.000Z",
              "bankCollectedPln": 1649.09,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 20.06,
              "bankCollectedInterestForeign": 5.07,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.65,
              "bankCollectedPrincipalPln": 1629.03,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.77,
              "interestForeign": 5.07,
              "principalPln": 955.22,
              "principalForeign": 411.65,
              "days": 31,
              "paymentDifferencePln": 682.1,
              "periodsTotal": 178,
              "simulationBalanceForeign": 42250.85,
              "simulationBalancePln": 98043.09,
              "bankBalanceForeign": 42251.39,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0013992,
              "controlSumForeign": 0.54
            },
            {
              "_calculationWarns": [
                "pKapitał 411.86"
              ],
              "index": 77,
              "date": "2015-05-01T00:00:00.000Z",
              "currencyDate": "2015-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 158,
                  "caseId": 1,
                  "position": 157,
                  "type": "Odsetki",
                  "date": "2015-05-04",
                  "transactionDate": "2015-05-04",
                  "installmentType": "equal",
                  "amountPln": 19.1,
                  "amountForeign": 4.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 159,
                  "caseId": 1,
                  "position": 158,
                  "type": "Kapitał",
                  "date": "2015-05-04",
                  "transactionDate": "2015-05-04",
                  "installmentType": "equal",
                  "amountPln": 1618.82,
                  "amountForeign": 411.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-05-04T00:00:00.000Z",
              "bankCollectedPln": 1637.92,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 19.1,
              "bankCollectedInterestForeign": 4.86,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.86,
              "bankCollectedPrincipalPln": 1618.82,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.28,
              "interestForeign": 4.86,
              "principalPln": 955.71,
              "principalForeign": 411.85,
              "days": 30,
              "paymentDifferencePln": 670.93,
              "periodsTotal": 178,
              "simulationBalanceForeign": 41839,
              "simulationBalancePln": 97087.38,
              "bankBalanceForeign": 41839.53,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0013995,
              "controlSumForeign": 0.53
            },
            {
              "_calculationWarns": [
                "pKapitał 53.14",
                "pKapitał 358.61"
              ],
              "index": 78,
              "date": "2015-06-01T00:00:00.000Z",
              "currencyDate": "2015-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 160,
                  "caseId": 1,
                  "position": 159,
                  "type": "Odsetki",
                  "date": "2015-06-01",
                  "transactionDate": "2015-06-01",
                  "installmentType": "equal",
                  "amountPln": 20.07,
                  "amountForeign": 4.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 161,
                  "caseId": 1,
                  "position": 160,
                  "type": "Kapitał",
                  "date": "2015-06-01",
                  "transactionDate": "2015-06-01",
                  "installmentType": "equal",
                  "amountPln": 214.63,
                  "amountForeign": 53.14,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 162,
                  "caseId": 1,
                  "position": 161,
                  "type": "Kapitał",
                  "date": "2015-06-02",
                  "transactionDate": "2015-06-02",
                  "installmentType": "equal",
                  "amountPln": 1449.65,
                  "amountForeign": 358.61,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-06-01T00:00:00.000Z",
              "bankCollectedPln": 1684.35,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 20.07,
              "bankCollectedInterestForeign": 4.97,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.75,
              "bankCollectedPrincipalPln": 1664.28,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.54,
              "interestForeign": 4.98,
              "principalPln": 955.45,
              "principalForeign": 411.74,
              "days": 31,
              "paymentDifferencePln": 717.36,
              "periodsTotal": 178,
              "simulationBalanceForeign": 41427.26,
              "simulationBalancePln": 96131.93,
              "bankBalanceForeign": 41427.78,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0013986,
              "controlSumForeign": 0.52
            },
            {
              "_calculationWarns": [
                "pKapitał 411.95"
              ],
              "index": 79,
              "date": "2015-07-01T00:00:00.000Z",
              "currencyDate": "2015-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 163,
                  "caseId": 1,
                  "position": 162,
                  "type": "Odsetki",
                  "date": "2015-07-01",
                  "transactionDate": "2015-07-01",
                  "installmentType": "equal",
                  "amountPln": 19.47,
                  "amountForeign": 4.77,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 164,
                  "caseId": 1,
                  "position": 163,
                  "type": "Kapitał",
                  "date": "2015-07-01",
                  "transactionDate": "2015-07-01",
                  "installmentType": "equal",
                  "amountPln": 1681.66,
                  "amountForeign": 411.95,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-07-01T00:00:00.000Z",
              "bankCollectedPln": 1701.13,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 19.47,
              "bankCollectedInterestForeign": 4.77,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.95,
              "bankCollectedPrincipalPln": 1681.66,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.06,
              "interestForeign": 4.77,
              "principalPln": 955.93,
              "principalForeign": 411.95,
              "days": 30,
              "paymentDifferencePln": 734.14,
              "periodsTotal": 178,
              "simulationBalanceForeign": 41015.31,
              "simulationBalancePln": 95176,
              "bankBalanceForeign": 41015.83,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0014009,
              "controlSumForeign": 0.52
            },
            {
              "_calculationWarns": [
                "pKapitał 411.84"
              ],
              "index": 80,
              "date": "2015-08-01T00:00:00.000Z",
              "currencyDate": "2015-08-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 165,
                  "caseId": 1,
                  "position": 164,
                  "type": "Odsetki",
                  "date": "2015-08-03",
                  "transactionDate": "2015-08-03",
                  "installmentType": "equal",
                  "amountPln": 19.35,
                  "amountForeign": 4.88,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 166,
                  "caseId": 1,
                  "position": 165,
                  "type": "Kapitał",
                  "date": "2015-08-03",
                  "transactionDate": "2015-08-03",
                  "installmentType": "equal",
                  "amountPln": 1633.4,
                  "amountForeign": 411.84,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-08-03T00:00:00.000Z",
              "bankCollectedPln": 1652.75,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 19.35,
              "bankCollectedInterestForeign": 4.88,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.84,
              "bankCollectedPrincipalPln": 1633.4,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.32,
              "interestForeign": 4.88,
              "principalPln": 955.67,
              "principalForeign": 411.84,
              "days": 31,
              "paymentDifferencePln": 685.76,
              "periodsTotal": 178,
              "simulationBalanceForeign": 40603.47,
              "simulationBalancePln": 94220.33,
              "bankBalanceForeign": 40603.99,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0014009,
              "controlSumForeign": 0.52
            },
            {
              "_calculationWarns": [
                "pKapitał 411.89"
              ],
              "index": 81,
              "date": "2015-09-01T00:00:00.000Z",
              "currencyDate": "2015-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 167,
                  "caseId": 1,
                  "position": 166,
                  "type": "Odsetki",
                  "date": "2015-09-01",
                  "transactionDate": "2015-09-01",
                  "installmentType": "equal",
                  "amountPln": 19.15,
                  "amountForeign": 4.83,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 168,
                  "caseId": 1,
                  "position": 167,
                  "type": "Kapitał",
                  "date": "2015-09-01",
                  "transactionDate": "2015-09-01",
                  "installmentType": "equal",
                  "amountPln": 1633.19,
                  "amountForeign": 411.89,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-09-01T00:00:00.000Z",
              "bankCollectedPln": 1652.34,
              "bankCollectedForeign": 416.72,
              "bankCollectedInterestPln": 19.15,
              "bankCollectedInterestForeign": 4.83,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.89,
              "bankCollectedPrincipalPln": 1633.19,
              "paymentPln": 966.99,
              "paymentForeign": 416.72,
              "interestPln": 11.2,
              "interestForeign": 4.83,
              "principalPln": 955.79,
              "principalForeign": 411.89,
              "days": 31,
              "paymentDifferencePln": 685.35,
              "periodsTotal": 178,
              "simulationBalanceForeign": 40191.58,
              "simulationBalancePln": 93264.54,
              "bankBalanceForeign": 40192.1,
              "bankRate": 0.0014,
              "bankRateReversed": 0.0014006,
              "controlSumForeign": 0.52
            },
            {
              "_calculationWarns": [
                "pKapitał 410.18"
              ],
              "index": 82,
              "date": "2015-10-01T00:00:00.000Z",
              "currencyDate": "2015-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 169,
                  "caseId": 1,
                  "position": 168,
                  "type": "Odsetki",
                  "date": "2015-10-01",
                  "transactionDate": "2015-10-01",
                  "installmentType": "equal",
                  "amountPln": 34,
                  "amountForeign": 8.59,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 170,
                  "caseId": 1,
                  "position": 169,
                  "type": "Kapitał",
                  "date": "2015-10-01",
                  "transactionDate": "2015-10-01",
                  "installmentType": "equal",
                  "amountPln": 1623.58,
                  "amountForeign": 410.18,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-10-01T00:00:00.000Z",
              "bankCollectedPln": 1657.58,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 34,
              "bankCollectedInterestForeign": 8.59,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.18,
              "bankCollectedPrincipalPln": 1623.58,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 19.93,
              "interestForeign": 8.59,
              "principalPln": 951.8,
              "principalForeign": 410.17,
              "days": 30,
              "paymentDifferencePln": 685.85,
              "periodsTotal": 178,
              "simulationBalanceForeign": 39781.41,
              "simulationBalancePln": 92312.74,
              "bankBalanceForeign": 39781.92,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026003,
              "controlSumForeign": 0.51
            },
            {
              "_calculationWarns": [
                "pKapitał 409.99"
              ],
              "index": 83,
              "date": "2015-11-01T00:00:00.000Z",
              "currencyDate": "2015-11-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 172,
                  "caseId": 1,
                  "position": 171,
                  "type": "Kapitał",
                  "date": "2015-11-02",
                  "transactionDate": "2015-11-02",
                  "installmentType": "equal",
                  "amountPln": 1627.21,
                  "amountForeign": 409.99,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 171,
                  "caseId": 1,
                  "position": 170,
                  "type": "Odsetki",
                  "date": "2015-11-02",
                  "transactionDate": "2015-11-02",
                  "installmentType": "equal",
                  "amountPln": 34.85,
                  "amountForeign": 8.78,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-11-02T00:00:00.000Z",
              "bankCollectedPln": 1662.06,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 34.85,
              "bankCollectedInterestForeign": 8.78,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 409.99,
              "bankCollectedPrincipalPln": 1627.21,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 20.39,
              "interestForeign": 8.79,
              "principalPln": 951.34,
              "principalForeign": 409.97,
              "days": 31,
              "paymentDifferencePln": 690.33,
              "periodsTotal": 178,
              "simulationBalanceForeign": 39371.44,
              "simulationBalancePln": 91361.4,
              "bankBalanceForeign": 39371.93,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025986,
              "controlSumForeign": 0.49
            },
            {
              "_calculationWarns": [
                "pKapitał 410.36"
              ],
              "index": 84,
              "date": "2015-12-01T00:00:00.000Z",
              "currencyDate": "2015-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 173,
                  "caseId": 1,
                  "position": 172,
                  "type": "Odsetki",
                  "date": "2015-12-01",
                  "transactionDate": "2015-12-01",
                  "installmentType": "equal",
                  "amountPln": 33.54,
                  "amountForeign": 8.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 174,
                  "caseId": 1,
                  "position": 173,
                  "type": "Kapitał",
                  "date": "2015-12-01",
                  "transactionDate": "2015-12-01",
                  "installmentType": "equal",
                  "amountPln": 1636.47,
                  "amountForeign": 410.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2015-12-01T00:00:00.000Z",
              "bankCollectedPln": 1670.01,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 33.54,
              "bankCollectedInterestForeign": 8.41,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.36,
              "bankCollectedPrincipalPln": 1636.47,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 19.52,
              "interestForeign": 8.42,
              "principalPln": 952.21,
              "principalForeign": 410.35,
              "days": 30,
              "paymentDifferencePln": 698.28,
              "periodsTotal": 178,
              "simulationBalanceForeign": 38961.09,
              "simulationBalancePln": 90409.19,
              "bankBalanceForeign": 38961.57,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025988,
              "controlSumForeign": 0.48
            },
            {
              "_calculationWarns": [
                "pKapitał 410.17"
              ],
              "index": 85,
              "date": "2016-01-01T00:00:00.000Z",
              "currencyDate": "2016-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 175,
                  "caseId": 1,
                  "position": 174,
                  "type": "Odsetki",
                  "date": "2016-01-04",
                  "transactionDate": "2016-01-04",
                  "installmentType": "equal",
                  "amountPln": 34.47,
                  "amountForeign": 8.6,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 176,
                  "caseId": 1,
                  "position": 175,
                  "type": "Kapitał",
                  "date": "2016-01-04",
                  "transactionDate": "2016-01-04",
                  "installmentType": "equal",
                  "amountPln": 1644,
                  "amountForeign": 410.17,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-01-04T00:00:00.000Z",
              "bankCollectedPln": 1678.47,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 34.47,
              "bankCollectedInterestForeign": 8.6,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.17,
              "bankCollectedPrincipalPln": 1644,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 19.96,
              "interestForeign": 8.6,
              "principalPln": 951.77,
              "principalForeign": 410.16,
              "days": 31,
              "paymentDifferencePln": 706.74,
              "periodsTotal": 178,
              "simulationBalanceForeign": 38550.93,
              "simulationBalancePln": 89457.42,
              "bankBalanceForeign": 38551.4,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025989,
              "controlSumForeign": 0.47
            },
            {
              "_calculationWarns": [
                "pKapitał 410.26"
              ],
              "index": 86,
              "date": "2016-02-01T00:00:00.000Z",
              "currencyDate": "2016-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 177,
                  "caseId": 1,
                  "position": 176,
                  "type": "Odsetki",
                  "date": "2016-02-01",
                  "transactionDate": "2016-02-01",
                  "installmentType": "equal",
                  "amountPln": 34.44,
                  "amountForeign": 8.51,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 178,
                  "caseId": 1,
                  "position": 177,
                  "type": "Kapitał",
                  "date": "2016-02-01",
                  "transactionDate": "2016-02-01",
                  "installmentType": "equal",
                  "amountPln": 1660.49,
                  "amountForeign": 410.26,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-02-01T00:00:00.000Z",
              "bankCollectedPln": 1694.93,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 34.44,
              "bankCollectedInterestForeign": 8.51,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.26,
              "bankCollectedPrincipalPln": 1660.49,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 19.75,
              "interestForeign": 8.51,
              "principalPln": 951.98,
              "principalForeign": 410.25,
              "days": 31,
              "paymentDifferencePln": 723.19,
              "periodsTotal": 178,
              "simulationBalanceForeign": 38140.68,
              "simulationBalancePln": 88505.44,
              "bankBalanceForeign": 38141.14,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025991,
              "controlSumForeign": 0.46
            },
            {
              "_calculationWarns": [
                "pKapitał 410.89"
              ],
              "index": 87,
              "date": "2016-03-01T00:00:00.000Z",
              "currencyDate": "2016-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 180,
                  "caseId": 1,
                  "position": 179,
                  "type": "Kapitał",
                  "date": "2016-03-01",
                  "transactionDate": "2016-03-01",
                  "installmentType": "equal",
                  "amountPln": 1670.14,
                  "amountForeign": 410.89,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 179,
                  "caseId": 1,
                  "position": 178,
                  "type": "Odsetki",
                  "date": "2016-03-01",
                  "transactionDate": "2016-03-01",
                  "installmentType": "equal",
                  "amountPln": 32.03,
                  "amountForeign": 7.88,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-03-01T00:00:00.000Z",
              "bankCollectedPln": 1702.17,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.03,
              "bankCollectedInterestForeign": 7.88,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.89,
              "bankCollectedPrincipalPln": 1670.14,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 18.28,
              "interestForeign": 7.88,
              "principalPln": 953.46,
              "principalForeign": 410.88,
              "days": 29,
              "paymentDifferencePln": 730.43,
              "periodsTotal": 178,
              "simulationBalanceForeign": 37729.8,
              "simulationBalancePln": 87551.98,
              "bankBalanceForeign": 37730.25,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026003,
              "controlSumForeign": 0.45
            },
            {
              "_calculationWarns": [
                "pKapitał 410.44"
              ],
              "index": 88,
              "date": "2016-04-01T00:00:00.000Z",
              "currencyDate": "2016-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 181,
                  "caseId": 1,
                  "position": 180,
                  "type": "Odsetki",
                  "date": "2016-04-01",
                  "transactionDate": "2016-04-01",
                  "installmentType": "equal",
                  "amountPln": 32.82,
                  "amountForeign": 8.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 182,
                  "caseId": 1,
                  "position": 181,
                  "type": "Kapitał",
                  "date": "2016-04-01",
                  "transactionDate": "2016-04-01",
                  "installmentType": "equal",
                  "amountPln": 1616.97,
                  "amountForeign": 410.44,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-04-01T00:00:00.000Z",
              "bankCollectedPln": 1649.79,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.82,
              "bankCollectedInterestForeign": 8.33,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.44,
              "bankCollectedPrincipalPln": 1616.97,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 19.33,
              "interestForeign": 8.33,
              "principalPln": 952.4,
              "principalForeign": 410.43,
              "days": 31,
              "paymentDifferencePln": 678.06,
              "periodsTotal": 178,
              "simulationBalanceForeign": 37319.37,
              "simulationBalancePln": 86599.58,
              "bankBalanceForeign": 37319.81,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025995,
              "controlSumForeign": 0.44
            },
            {
              "_calculationWarns": [
                "pKapitał 410.79"
              ],
              "index": 89,
              "date": "2016-05-01T00:00:00.000Z",
              "currencyDate": "2016-05-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 183,
                  "caseId": 1,
                  "position": 182,
                  "type": "Odsetki",
                  "date": "2016-05-02",
                  "transactionDate": "2016-05-02",
                  "installmentType": "equal",
                  "amountPln": 32.18,
                  "amountForeign": 7.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 184,
                  "caseId": 1,
                  "position": 183,
                  "type": "Kapitał",
                  "date": "2016-05-02",
                  "transactionDate": "2016-05-02",
                  "installmentType": "equal",
                  "amountPln": 1656.55,
                  "amountForeign": 410.79,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-05-02T00:00:00.000Z",
              "bankCollectedPln": 1688.73,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.18,
              "bankCollectedInterestForeign": 7.98,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.79,
              "bankCollectedPrincipalPln": 1656.55,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 18.51,
              "interestForeign": 7.97,
              "principalPln": 953.23,
              "principalForeign": 410.79,
              "days": 30,
              "paymentDifferencePln": 717,
              "periodsTotal": 178,
              "simulationBalanceForeign": 36908.58,
              "simulationBalancePln": 85646.35,
              "bankBalanceForeign": 36909.02,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026016,
              "controlSumForeign": 0.44
            },
            {
              "_calculationWarns": [
                "pKapitał 410.62"
              ],
              "index": 90,
              "date": "2016-06-01T00:00:00.000Z",
              "currencyDate": "2016-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 186,
                  "caseId": 1,
                  "position": 185,
                  "type": "Kapitał",
                  "date": "2016-06-01",
                  "transactionDate": "2016-06-01",
                  "installmentType": "equal",
                  "amountPln": 1654.72,
                  "amountForeign": 410.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 185,
                  "caseId": 1,
                  "position": 184,
                  "type": "Odsetki",
                  "date": "2016-06-01",
                  "transactionDate": "2016-06-01",
                  "installmentType": "equal",
                  "amountPln": 32.84,
                  "amountForeign": 8.15,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-06-01T00:00:00.000Z",
              "bankCollectedPln": 1687.56,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.84,
              "bankCollectedInterestForeign": 8.15,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.62,
              "bankCollectedPrincipalPln": 1654.72,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 18.91,
              "interestForeign": 8.15,
              "principalPln": 952.82,
              "principalForeign": 410.61,
              "days": 31,
              "paymentDifferencePln": 715.83,
              "periodsTotal": 178,
              "simulationBalanceForeign": 36497.97,
              "simulationBalancePln": 84693.53,
              "bankBalanceForeign": 36498.4,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025999,
              "controlSumForeign": 0.43
            },
            {
              "_calculationWarns": [
                "pKapitał 410.97"
              ],
              "index": 91,
              "date": "2016-07-01T00:00:00.000Z",
              "currencyDate": "2016-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 187,
                  "caseId": 1,
                  "position": 186,
                  "type": "Odsetki",
                  "date": "2016-07-01",
                  "transactionDate": "2016-07-01",
                  "installmentType": "equal",
                  "amountPln": 32.05,
                  "amountForeign": 7.8,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 188,
                  "caseId": 1,
                  "position": 187,
                  "type": "Kapitał",
                  "date": "2016-07-01",
                  "transactionDate": "2016-07-01",
                  "installmentType": "equal",
                  "amountPln": 1688.8,
                  "amountForeign": 410.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-07-01T00:00:00.000Z",
              "bankCollectedPln": 1720.85,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.05,
              "bankCollectedInterestForeign": 7.8,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.97,
              "bankCollectedPrincipalPln": 1688.8,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 18.1,
              "interestForeign": 7.8,
              "principalPln": 953.64,
              "principalForeign": 410.96,
              "days": 30,
              "paymentDifferencePln": 749.11,
              "periodsTotal": 178,
              "simulationBalanceForeign": 36087.01,
              "simulationBalancePln": 83739.89,
              "bankBalanceForeign": 36087.43,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026001,
              "controlSumForeign": 0.42
            },
            {
              "_calculationWarns": [
                "pKapitał 410.8"
              ],
              "index": 92,
              "date": "2016-08-01T00:00:00.000Z",
              "currencyDate": "2016-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 190,
                  "caseId": 1,
                  "position": 189,
                  "type": "Kapitał",
                  "date": "2016-08-01",
                  "transactionDate": "2016-08-01",
                  "installmentType": "equal",
                  "amountPln": 1677.71,
                  "amountForeign": 410.8,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 189,
                  "caseId": 1,
                  "position": 188,
                  "type": "Odsetki",
                  "date": "2016-08-01",
                  "transactionDate": "2016-08-01",
                  "installmentType": "equal",
                  "amountPln": 32.55,
                  "amountForeign": 7.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-08-01T00:00:00.000Z",
              "bankCollectedPln": 1710.26,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 32.55,
              "bankCollectedInterestForeign": 7.97,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.8,
              "bankCollectedPrincipalPln": 1677.71,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 18.49,
              "interestForeign": 7.97,
              "principalPln": 953.24,
              "principalForeign": 410.79,
              "days": 31,
              "paymentDifferencePln": 738.53,
              "periodsTotal": 178,
              "simulationBalanceForeign": 35676.22,
              "simulationBalancePln": 82786.65,
              "bankBalanceForeign": 35676.63,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026004,
              "controlSumForeign": 0.41
            },
            {
              "_calculationWarns": [
                "pKapitał 410.89"
              ],
              "index": 93,
              "date": "2016-09-01T00:00:00.000Z",
              "currencyDate": "2016-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 191,
                  "caseId": 1,
                  "position": 190,
                  "type": "Odsetki",
                  "date": "2016-09-01",
                  "transactionDate": "2016-09-01",
                  "installmentType": "equal",
                  "amountPln": 31.8,
                  "amountForeign": 7.88,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 192,
                  "caseId": 1,
                  "position": 191,
                  "type": "Kapitał",
                  "date": "2016-09-01",
                  "transactionDate": "2016-09-01",
                  "installmentType": "equal",
                  "amountPln": 1658.15,
                  "amountForeign": 410.89,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-09-01T00:00:00.000Z",
              "bankCollectedPln": 1689.95,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 31.8,
              "bankCollectedInterestForeign": 7.88,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 410.89,
              "bankCollectedPrincipalPln": 1658.15,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 18.28,
              "interestForeign": 7.88,
              "principalPln": 953.46,
              "principalForeign": 410.88,
              "days": 31,
              "paymentDifferencePln": 718.21,
              "periodsTotal": 178,
              "simulationBalanceForeign": 35265.34,
              "simulationBalancePln": 81833.19,
              "bankBalanceForeign": 35265.74,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026006,
              "controlSumForeign": 0.4
            },
            {
              "_calculationWarns": [
                "pKapitał 130.73",
                "pKapitał 280.5"
              ],
              "index": 94,
              "date": "2016-10-01T00:00:00.000Z",
              "currencyDate": "2016-10-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 193,
                  "caseId": 1,
                  "position": 192,
                  "type": "Odsetki",
                  "date": "2016-10-03",
                  "transactionDate": "2016-10-03",
                  "installmentType": "equal",
                  "amountPln": 30.11,
                  "amountForeign": 7.54,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 194,
                  "caseId": 1,
                  "position": 193,
                  "type": "Kapitał",
                  "date": "2016-10-03",
                  "transactionDate": "2016-10-03",
                  "installmentType": "equal",
                  "amountPln": 522.02,
                  "amountForeign": 130.73,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 195,
                  "caseId": 1,
                  "position": 194,
                  "type": "Kapitał",
                  "date": "2016-10-04",
                  "transactionDate": "2016-10-04",
                  "installmentType": "equal",
                  "amountPln": 1120.6,
                  "amountForeign": 280.5,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-10-03T00:00:00.000Z",
              "bankCollectedPln": 1672.73,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 30.11,
              "bankCollectedInterestForeign": 7.54,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.23,
              "bankCollectedPrincipalPln": 1642.62,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 17.49,
              "interestForeign": 7.54,
              "principalPln": 954.25,
              "principalForeign": 411.23,
              "days": 30,
              "paymentDifferencePln": 700.99,
              "periodsTotal": 178,
              "simulationBalanceForeign": 34854.11,
              "simulationBalancePln": 80878.94,
              "bankBalanceForeign": 34854.51,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026013,
              "controlSumForeign": 0.4
            },
            {
              "_calculationWarns": [
                "pKapitał 212.21",
                "pKapitał 198.86"
              ],
              "index": 95,
              "date": "2016-11-01T00:00:00.000Z",
              "currencyDate": "2016-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 196,
                  "caseId": 1,
                  "position": 195,
                  "type": "Odsetki",
                  "date": "2016-11-02",
                  "transactionDate": "2016-11-02",
                  "installmentType": "equal",
                  "amountPln": 31.23,
                  "amountForeign": 7.7,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 197,
                  "caseId": 1,
                  "position": 196,
                  "type": "Kapitał",
                  "date": "2016-11-02",
                  "transactionDate": "2016-11-02",
                  "installmentType": "equal",
                  "amountPln": 860.63,
                  "amountForeign": 212.21,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 198,
                  "caseId": 1,
                  "position": 197,
                  "type": "Kapitał",
                  "date": "2016-11-04",
                  "transactionDate": "2016-11-04",
                  "installmentType": "equal",
                  "amountPln": 804.81,
                  "amountForeign": 198.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-11-02T00:00:00.000Z",
              "bankCollectedPln": 1696.67,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 31.23,
              "bankCollectedInterestForeign": 7.7,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.07,
              "bankCollectedPrincipalPln": 1665.44,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 17.86,
              "interestForeign": 7.7,
              "principalPln": 953.88,
              "principalForeign": 411.06,
              "days": 31,
              "paymentDifferencePln": 724.93,
              "periodsTotal": 178,
              "simulationBalanceForeign": 34443.05,
              "simulationBalancePln": 79925.06,
              "bankBalanceForeign": 34443.44,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026011,
              "controlSumForeign": 0.39
            },
            {
              "_calculationWarns": [
                "pKapitał 411.41"
              ],
              "index": 96,
              "date": "2016-12-01T00:00:00.000Z",
              "currencyDate": "2016-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 199,
                  "caseId": 1,
                  "position": 198,
                  "type": "Odsetki",
                  "date": "2016-12-01",
                  "transactionDate": "2016-12-01",
                  "installmentType": "equal",
                  "amountPln": 30.86,
                  "amountForeign": 7.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 200,
                  "caseId": 1,
                  "position": 199,
                  "type": "Kapitał",
                  "date": "2016-12-01",
                  "transactionDate": "2016-12-01",
                  "installmentType": "equal",
                  "amountPln": 1725.25,
                  "amountForeign": 411.41,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2016-12-01T00:00:00.000Z",
              "bankCollectedPln": 1756.11,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 30.86,
              "bankCollectedInterestForeign": 7.36,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.41,
              "bankCollectedPrincipalPln": 1725.25,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 17.08,
              "interestForeign": 7.36,
              "principalPln": 954.66,
              "principalForeign": 411.4,
              "days": 30,
              "paymentDifferencePln": 784.37,
              "periodsTotal": 178,
              "simulationBalanceForeign": 34031.65,
              "simulationBalancePln": 78970.4,
              "bankBalanceForeign": 34032.03,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025998,
              "controlSumForeign": 0.38
            },
            {
              "_calculationWarns": [
                "pKapitał 411.25"
              ],
              "index": 97,
              "date": "2017-01-01T00:00:00.000Z",
              "currencyDate": "2017-01-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 201,
                  "caseId": 1,
                  "position": 200,
                  "type": "Odsetki",
                  "date": "2017-01-03",
                  "transactionDate": "2017-01-03",
                  "installmentType": "equal",
                  "amountPln": 31.43,
                  "amountForeign": 7.52,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 202,
                  "caseId": 1,
                  "position": 201,
                  "type": "Kapitał",
                  "date": "2017-01-03",
                  "transactionDate": "2017-01-03",
                  "installmentType": "equal",
                  "amountPln": 1719.07,
                  "amountForeign": 411.25,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-01-03T00:00:00.000Z",
              "bankCollectedPln": 1750.5,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 31.43,
              "bankCollectedInterestForeign": 7.52,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.25,
              "bankCollectedPrincipalPln": 1719.07,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 17.44,
              "interestForeign": 7.51,
              "principalPln": 954.3,
              "principalForeign": 411.25,
              "days": 31,
              "paymentDifferencePln": 778.76,
              "periodsTotal": 178,
              "simulationBalanceForeign": 33620.4,
              "simulationBalancePln": 78016.1,
              "bankBalanceForeign": 33620.78,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026017,
              "controlSumForeign": 0.38
            },
            {
              "_calculationWarns": [
                "pKapitał 12.95",
                "pKapitał 398.4"
              ],
              "index": 98,
              "date": "2017-02-01T00:00:00.000Z",
              "currencyDate": "2017-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 203,
                  "caseId": 1,
                  "position": 202,
                  "type": "Odsetki",
                  "date": "2017-02-01",
                  "transactionDate": "2017-02-01",
                  "installmentType": "equal",
                  "amountPln": 30.46,
                  "amountForeign": 7.42,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 204,
                  "caseId": 1,
                  "position": 203,
                  "type": "Kapitał",
                  "date": "2017-02-01",
                  "transactionDate": "2017-02-01",
                  "installmentType": "equal",
                  "amountPln": 53.15,
                  "amountForeign": 12.95,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 205,
                  "caseId": 1,
                  "position": 204,
                  "type": "Kapitał",
                  "date": "2017-02-03",
                  "transactionDate": "2017-02-03",
                  "installmentType": "equal",
                  "amountPln": 1633.92,
                  "amountForeign": 398.4,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-02-01T00:00:00.000Z",
              "bankCollectedPln": 1717.53,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 30.46,
              "bankCollectedInterestForeign": 7.42,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.35,
              "bankCollectedPrincipalPln": 1687.07,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 17.23,
              "interestForeign": 7.42,
              "principalPln": 954.52,
              "principalForeign": 411.34,
              "days": 31,
              "paymentDifferencePln": 745.79,
              "periodsTotal": 178,
              "simulationBalanceForeign": 33209.06,
              "simulationBalancePln": 77061.58,
              "bankBalanceForeign": 33209.43,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025985,
              "controlSumForeign": 0.37
            },
            {
              "_calculationWarns": [
                "pKapitał 412.15"
              ],
              "index": 99,
              "date": "2017-03-01T00:00:00.000Z",
              "currencyDate": "2017-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 207,
                  "caseId": 1,
                  "position": 206,
                  "type": "Kapitał",
                  "date": "2017-03-01",
                  "transactionDate": "2017-03-01",
                  "installmentType": "equal",
                  "amountPln": 1692.7,
                  "amountForeign": 412.15,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 206,
                  "caseId": 1,
                  "position": 205,
                  "type": "Odsetki",
                  "date": "2017-03-01",
                  "transactionDate": "2017-03-01",
                  "installmentType": "equal",
                  "amountPln": 27.19,
                  "amountForeign": 6.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-03-01T00:00:00.000Z",
              "bankCollectedPln": 1719.89,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 27.19,
              "bankCollectedInterestForeign": 6.62,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.15,
              "bankCollectedPrincipalPln": 1692.7,
              "paymentPln": 971.75,
              "paymentForeign": 418.77,
              "interestPln": 15.37,
              "interestForeign": 6.62,
              "principalPln": 956.38,
              "principalForeign": 412.14,
              "days": 28,
              "paymentDifferencePln": 748.14,
              "periodsTotal": 178,
              "simulationBalanceForeign": 32796.92,
              "simulationBalancePln": 76105.2,
              "bankBalanceForeign": 32797.28,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025986,
              "controlSumForeign": 0.36
            },
            {
              "_calculationWarns": [
                "pKapitał 23.29",
                "pKapitał 388.24"
              ],
              "index": 100,
              "date": "2017-04-01T00:00:00.000Z",
              "currencyDate": "2017-04-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 208,
                  "caseId": 1,
                  "position": 207,
                  "type": "Odsetki",
                  "date": "2017-04-03",
                  "transactionDate": "2017-04-03",
                  "installmentType": "equal",
                  "amountPln": 29.09,
                  "amountForeign": 7.24,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 209,
                  "caseId": 1,
                  "position": 208,
                  "type": "Kapitał",
                  "date": "2017-04-03",
                  "transactionDate": "2017-04-03",
                  "installmentType": "equal",
                  "amountPln": 93.56,
                  "amountForeign": 23.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 210,
                  "caseId": 1,
                  "position": 209,
                  "type": "Kapitał",
                  "date": "2017-04-05",
                  "transactionDate": "2017-04-05",
                  "installmentType": "equal",
                  "amountPln": 1564.91,
                  "amountForeign": 388.24,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-04-03T00:00:00.000Z",
              "bankCollectedPln": 1687.56,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 29.09,
              "bankCollectedInterestForeign": 7.24,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.53,
              "bankCollectedPrincipalPln": 1658.47,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 16.81,
              "interestForeign": 7.24,
              "principalPln": 954.92,
              "principalForeign": 411.52,
              "days": 31,
              "paymentDifferencePln": 715.83,
              "periodsTotal": 178,
              "simulationBalanceForeign": 32385.4,
              "simulationBalancePln": 75150.28,
              "bankBalanceForeign": 32385.75,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025992,
              "controlSumForeign": 0.35
            },
            {
              "_calculationWarns": [
                "pKapitał 18.93",
                "pKapitał 392.92"
              ],
              "index": 101,
              "date": "2017-05-01T00:00:00.000Z",
              "currencyDate": "2017-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 211,
                  "caseId": 1,
                  "position": 210,
                  "type": "Odsetki",
                  "date": "2017-05-02",
                  "transactionDate": "2017-05-02",
                  "installmentType": "equal",
                  "amountPln": 27.32,
                  "amountForeign": 6.92,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 212,
                  "caseId": 1,
                  "position": 211,
                  "type": "Kapitał",
                  "date": "2017-05-02",
                  "transactionDate": "2017-05-02",
                  "installmentType": "equal",
                  "amountPln": 74.72,
                  "amountForeign": 18.93,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 213,
                  "caseId": 1,
                  "position": 212,
                  "type": "Kapitał",
                  "date": "2017-05-04",
                  "transactionDate": "2017-05-04",
                  "installmentType": "equal",
                  "amountPln": 1546.42,
                  "amountForeign": 392.92,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-05-02T00:00:00.000Z",
              "bankCollectedPln": 1648.46,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 27.32,
              "bankCollectedInterestForeign": 6.92,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.85,
              "bankCollectedPrincipalPln": 1621.14,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 16.06,
              "interestForeign": 6.92,
              "principalPln": 955.67,
              "principalForeign": 411.84,
              "days": 30,
              "paymentDifferencePln": 676.73,
              "periodsTotal": 178,
              "simulationBalanceForeign": 31973.56,
              "simulationBalancePln": 74194.61,
              "bankBalanceForeign": 31973.9,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025997,
              "controlSumForeign": 0.34
            },
            {
              "_calculationWarns": [
                "pKapitał 38.54",
                "pKapitał 373.17"
              ],
              "index": 102,
              "date": "2017-06-01T00:00:00.000Z",
              "currencyDate": "2017-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 214,
                  "caseId": 1,
                  "position": 213,
                  "type": "Odsetki",
                  "date": "2017-06-01",
                  "transactionDate": "2017-06-01",
                  "installmentType": "equal",
                  "amountPln": 27.51,
                  "amountForeign": 7.06,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 215,
                  "caseId": 1,
                  "position": 214,
                  "type": "Kapitał",
                  "date": "2017-06-01",
                  "transactionDate": "2017-06-01",
                  "installmentType": "equal",
                  "amountPln": 150.17,
                  "amountForeign": 38.54,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 216,
                  "caseId": 1,
                  "position": 215,
                  "type": "Kapitał",
                  "date": "2017-06-02",
                  "transactionDate": "2017-06-02",
                  "installmentType": "equal",
                  "amountPln": 1457.75,
                  "amountForeign": 373.17,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-06-01T00:00:00.000Z",
              "bankCollectedPln": 1635.43,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 27.51,
              "bankCollectedInterestForeign": 7.06,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.71,
              "bankCollectedPrincipalPln": 1607.92,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 16.38,
              "interestForeign": 7.06,
              "principalPln": 955.35,
              "principalForeign": 411.7,
              "days": 31,
              "paymentDifferencePln": 663.7,
              "periodsTotal": 178,
              "simulationBalanceForeign": 31561.86,
              "simulationBalancePln": 73239.26,
              "bankBalanceForeign": 31562.19,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025998,
              "controlSumForeign": 0.33
            },
            {
              "_calculationWarns": [
                "pKapitał 412.03"
              ],
              "index": 103,
              "date": "2017-07-01T00:00:00.000Z",
              "currencyDate": "2017-07-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 217,
                  "caseId": 1,
                  "position": 216,
                  "type": "Odsetki",
                  "date": "2017-07-03",
                  "transactionDate": "2017-07-03",
                  "installmentType": "equal",
                  "amountPln": 26.43,
                  "amountForeign": 6.74,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 218,
                  "caseId": 1,
                  "position": 217,
                  "type": "Kapitał",
                  "date": "2017-07-03",
                  "transactionDate": "2017-07-03",
                  "installmentType": "equal",
                  "amountPln": 1615.82,
                  "amountForeign": 412.03,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-07-03T00:00:00.000Z",
              "bankCollectedPln": 1642.25,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 26.43,
              "bankCollectedInterestForeign": 6.74,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.03,
              "bankCollectedPrincipalPln": 1615.82,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 15.65,
              "interestForeign": 6.74,
              "principalPln": 956.08,
              "principalForeign": 412.02,
              "days": 30,
              "paymentDifferencePln": 670.52,
              "periodsTotal": 178,
              "simulationBalanceForeign": 31149.84,
              "simulationBalancePln": 72283.18,
              "bankBalanceForeign": 31150.16,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025982,
              "controlSumForeign": 0.32
            },
            {
              "_calculationWarns": [
                "pKapitał 76.66",
                "pKapitał 335.23"
              ],
              "index": 104,
              "date": "2017-08-01T00:00:00.000Z",
              "currencyDate": "2017-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 219,
                  "caseId": 1,
                  "position": 218,
                  "type": "Odsetki",
                  "date": "2017-08-01",
                  "transactionDate": "2017-08-01",
                  "installmentType": "equal",
                  "amountPln": 26.01,
                  "amountForeign": 6.88,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 220,
                  "caseId": 1,
                  "position": 219,
                  "type": "Kapitał",
                  "date": "2017-08-01",
                  "transactionDate": "2017-08-01",
                  "installmentType": "equal",
                  "amountPln": 289.78,
                  "amountForeign": 76.66,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 221,
                  "caseId": 1,
                  "position": 220,
                  "type": "Kapitał",
                  "date": "2017-08-02",
                  "transactionDate": "2017-08-02",
                  "installmentType": "equal",
                  "amountPln": 1267.44,
                  "amountForeign": 335.23,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-08-01T00:00:00.000Z",
              "bankCollectedPln": 1583.23,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 26.01,
              "bankCollectedInterestForeign": 6.88,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.89,
              "bankCollectedPrincipalPln": 1557.22,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 15.96,
              "interestForeign": 6.88,
              "principalPln": 955.77,
              "principalForeign": 411.88,
              "days": 31,
              "paymentDifferencePln": 611.5,
              "periodsTotal": 178,
              "simulationBalanceForeign": 30737.96,
              "simulationBalancePln": 71327.41,
              "bankBalanceForeign": 30738.27,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026005,
              "controlSumForeign": 0.31
            },
            {
              "_calculationWarns": [
                "pKapitał 411.98"
              ],
              "index": 105,
              "date": "2017-09-01T00:00:00.000Z",
              "currencyDate": "2017-09-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 223,
                  "caseId": 1,
                  "position": 222,
                  "type": "Kapitał",
                  "date": "2017-09-01",
                  "transactionDate": "2017-09-01",
                  "installmentType": "equal",
                  "amountPln": 1554.61,
                  "amountForeign": 411.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 222,
                  "caseId": 1,
                  "position": 221,
                  "type": "Odsetki",
                  "date": "2017-09-01",
                  "transactionDate": "2017-09-01",
                  "installmentType": "equal",
                  "amountPln": 25.62,
                  "amountForeign": 6.79,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-09-01T00:00:00.000Z",
              "bankCollectedPln": 1580.23,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 25.62,
              "bankCollectedInterestForeign": 6.79,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 411.98,
              "bankCollectedPrincipalPln": 1554.61,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 15.75,
              "interestForeign": 6.79,
              "principalPln": 955.99,
              "principalForeign": 411.97,
              "days": 31,
              "paymentDifferencePln": 608.49,
              "periodsTotal": 178,
              "simulationBalanceForeign": 30325.99,
              "simulationBalancePln": 70371.42,
              "bankBalanceForeign": 30326.29,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026009,
              "controlSumForeign": 0.3
            },
            {
              "_calculationWarns": [
                "pKapitał 412.29"
              ],
              "index": 106,
              "date": "2017-10-01T00:00:00.000Z",
              "currencyDate": "2017-10-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 224,
                  "caseId": 1,
                  "position": 223,
                  "type": "Odsetki",
                  "date": "2017-10-02",
                  "transactionDate": "2017-10-02",
                  "installmentType": "equal",
                  "amountPln": 24.81,
                  "amountForeign": 6.48,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 225,
                  "caseId": 1,
                  "position": 224,
                  "type": "Kapitał",
                  "date": "2017-10-02",
                  "transactionDate": "2017-10-02",
                  "installmentType": "equal",
                  "amountPln": 1578.28,
                  "amountForeign": 412.29,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-10-02T00:00:00.000Z",
              "bankCollectedPln": 1603.09,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 24.81,
              "bankCollectedInterestForeign": 6.48,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.29,
              "bankCollectedPrincipalPln": 1578.28,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 15.04,
              "interestForeign": 6.48,
              "principalPln": 956.7,
              "principalForeign": 412.28,
              "days": 30,
              "paymentDifferencePln": 631.35,
              "periodsTotal": 178,
              "simulationBalanceForeign": 29913.71,
              "simulationBalancePln": 69414.72,
              "bankBalanceForeign": 29914,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025997,
              "controlSumForeign": 0.29
            },
            {
              "_calculationWarns": [
                "pKapitał 164.1",
                "pKapitał 248.06"
              ],
              "index": 107,
              "date": "2017-11-01T00:00:00.000Z",
              "currencyDate": "2017-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 227,
                  "caseId": 1,
                  "position": 226,
                  "type": "Kapitał",
                  "date": "2017-11-02",
                  "transactionDate": "2017-11-02",
                  "installmentType": "equal",
                  "amountPln": 605.99,
                  "amountForeign": 164.1,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 226,
                  "caseId": 1,
                  "position": 225,
                  "type": "Odsetki",
                  "date": "2017-11-02",
                  "transactionDate": "2017-11-02",
                  "installmentType": "equal",
                  "amountPln": 24.41,
                  "amountForeign": 6.61,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 228,
                  "caseId": 1,
                  "position": 227,
                  "type": "Kapitał",
                  "date": "2017-11-06",
                  "transactionDate": "2017-11-06",
                  "installmentType": "equal",
                  "amountPln": 918.74,
                  "amountForeign": 248.06,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-11-02T00:00:00.000Z",
              "bankCollectedPln": 1549.14,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 24.41,
              "bankCollectedInterestForeign": 6.61,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.16,
              "bankCollectedPrincipalPln": 1524.73,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 15.33,
              "interestForeign": 6.61,
              "principalPln": 956.41,
              "principalForeign": 412.16,
              "days": 31,
              "paymentDifferencePln": 577.4,
              "periodsTotal": 178,
              "simulationBalanceForeign": 29501.55,
              "simulationBalancePln": 68458.31,
              "bankBalanceForeign": 29501.84,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026017,
              "controlSumForeign": 0.29
            },
            {
              "_calculationWarns": [
                "pKapitał 289.43",
                "pKapitał 123.04"
              ],
              "index": 108,
              "date": "2017-12-01T00:00:00.000Z",
              "currencyDate": "2017-12-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 229,
                  "caseId": 1,
                  "position": 228,
                  "type": "Odsetki",
                  "date": "2017-12-01",
                  "transactionDate": "2017-12-01",
                  "installmentType": "equal",
                  "amountPln": 22.93,
                  "amountForeign": 6.3,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 230,
                  "caseId": 1,
                  "position": 229,
                  "type": "Kapitał",
                  "date": "2017-12-01",
                  "transactionDate": "2017-12-01",
                  "installmentType": "equal",
                  "amountPln": 1053.34,
                  "amountForeign": 289.43,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 231,
                  "caseId": 1,
                  "position": 230,
                  "type": "Kapitał",
                  "date": "2017-12-04",
                  "transactionDate": "2017-12-04",
                  "installmentType": "equal",
                  "amountPln": 450.24,
                  "amountForeign": 123.04,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2017-12-01T00:00:00.000Z",
              "bankCollectedPln": 1526.51,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 22.93,
              "bankCollectedInterestForeign": 6.3,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.47,
              "bankCollectedPrincipalPln": 1503.58,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 14.63,
              "interestForeign": 6.3,
              "principalPln": 957.11,
              "principalForeign": 412.46,
              "days": 30,
              "paymentDifferencePln": 554.77,
              "periodsTotal": 178,
              "simulationBalanceForeign": 29089.09,
              "simulationBalancePln": 67501.2,
              "bankBalanceForeign": 29089.37,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025981,
              "controlSumForeign": 0.28
            },
            {
              "_calculationWarns": [
                "pKapitał 292.86",
                "pKapitał 119.49"
              ],
              "index": 109,
              "date": "2018-01-01T00:00:00.000Z",
              "currencyDate": "2018-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 232,
                  "caseId": 1,
                  "position": 231,
                  "type": "Odsetki",
                  "date": "2018-01-02",
                  "transactionDate": "2018-01-02",
                  "installmentType": "equal",
                  "amountPln": 23.25,
                  "amountForeign": 6.42,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 233,
                  "caseId": 1,
                  "position": 232,
                  "type": "Kapitał",
                  "date": "2018-01-02",
                  "transactionDate": "2018-01-02",
                  "installmentType": "equal",
                  "amountPln": 1060.53,
                  "amountForeign": 292.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 234,
                  "caseId": 1,
                  "position": 233,
                  "type": "Kapitał",
                  "date": "2018-01-04",
                  "transactionDate": "2018-01-04",
                  "installmentType": "equal",
                  "amountPln": 428.23,
                  "amountForeign": 119.49,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-01-02T00:00:00.000Z",
              "bankCollectedPln": 1512.01,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 23.25,
              "bankCollectedInterestForeign": 6.42,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.35,
              "bankCollectedPrincipalPln": 1488.76,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 14.9,
              "interestForeign": 6.42,
              "principalPln": 956.83,
              "principalForeign": 412.34,
              "days": 31,
              "paymentDifferencePln": 540.27,
              "periodsTotal": 178,
              "simulationBalanceForeign": 28676.75,
              "simulationBalancePln": 66544.37,
              "bankBalanceForeign": 28677.02,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025986,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 412.44"
              ],
              "index": 110,
              "date": "2018-02-01T00:00:00.000Z",
              "currencyDate": "2018-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 236,
                  "caseId": 1,
                  "position": 235,
                  "type": "Kapitał",
                  "date": "2018-02-01",
                  "transactionDate": "2018-02-01",
                  "installmentType": "equal",
                  "amountPln": 1502.15,
                  "amountForeign": 412.44,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 235,
                  "caseId": 1,
                  "position": 234,
                  "type": "Odsetki",
                  "date": "2018-02-01",
                  "transactionDate": "2018-02-01",
                  "installmentType": "equal",
                  "amountPln": 23.05,
                  "amountForeign": 6.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-02-01T00:00:00.000Z",
              "bankCollectedPln": 1525.2,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 23.05,
              "bankCollectedInterestForeign": 6.33,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.44,
              "bankCollectedPrincipalPln": 1502.15,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 14.69,
              "interestForeign": 6.33,
              "principalPln": 957.05,
              "principalForeign": 412.43,
              "days": 31,
              "paymentDifferencePln": 553.46,
              "periodsTotal": 178,
              "simulationBalanceForeign": 28264.32,
              "simulationBalancePln": 65587.32,
              "bankBalanceForeign": 28264.58,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002599,
              "controlSumForeign": 0.26
            },
            {
              "_calculationWarns": [
                "pKapitał 413.13"
              ],
              "index": 111,
              "date": "2018-03-01T00:00:00.000Z",
              "currencyDate": "2018-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 237,
                  "caseId": 1,
                  "position": 236,
                  "type": "Odsetki",
                  "date": "2018-03-01",
                  "transactionDate": "2018-03-01",
                  "installmentType": "equal",
                  "amountPln": 20.75,
                  "amountForeign": 5.64,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 238,
                  "caseId": 1,
                  "position": 237,
                  "type": "Kapitał",
                  "date": "2018-03-01",
                  "transactionDate": "2018-03-01",
                  "installmentType": "equal",
                  "amountPln": 1519.74,
                  "amountForeign": 413.13,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-03-01T00:00:00.000Z",
              "bankCollectedPln": 1540.49,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 20.75,
              "bankCollectedInterestForeign": 5.64,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.13,
              "bankCollectedPrincipalPln": 1519.74,
              "paymentPln": 971.75,
              "paymentForeign": 418.77,
              "interestPln": 13.08,
              "interestForeign": 5.64,
              "principalPln": 958.67,
              "principalForeign": 413.13,
              "days": 28,
              "paymentDifferencePln": 568.74,
              "periodsTotal": 178,
              "simulationBalanceForeign": 27851.19,
              "simulationBalancePln": 64628.65,
              "bankBalanceForeign": 27851.45,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026012,
              "controlSumForeign": 0.26
            },
            {
              "_calculationWarns": [
                "pKapitał 412.62"
              ],
              "index": 112,
              "date": "2018-04-01T00:00:00.000Z",
              "currencyDate": "2018-04-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 239,
                  "caseId": 1,
                  "position": 238,
                  "type": "Odsetki",
                  "date": "2018-04-03",
                  "transactionDate": "2018-04-03",
                  "installmentType": "equal",
                  "amountPln": 22.36,
                  "amountForeign": 6.15,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 240,
                  "caseId": 1,
                  "position": 239,
                  "type": "Kapitał",
                  "date": "2018-04-03",
                  "transactionDate": "2018-04-03",
                  "installmentType": "equal",
                  "amountPln": 1499.87,
                  "amountForeign": 412.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-04-03T00:00:00.000Z",
              "bankCollectedPln": 1522.23,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 22.36,
              "bankCollectedInterestForeign": 6.15,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.62,
              "bankCollectedPrincipalPln": 1499.87,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 14.27,
              "interestForeign": 6.15,
              "principalPln": 957.46,
              "principalForeign": 412.61,
              "days": 31,
              "paymentDifferencePln": 550.5,
              "periodsTotal": 178,
              "simulationBalanceForeign": 27438.58,
              "simulationBalancePln": 63671.19,
              "bankBalanceForeign": 27438.83,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025999,
              "controlSumForeign": 0.25
            },
            {
              "_calculationWarns": [
                "pKapitał 88.28",
                "pKapitał 324.63"
              ],
              "index": 113,
              "date": "2018-05-01T00:00:00.000Z",
              "currencyDate": "2018-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 241,
                  "caseId": 1,
                  "position": 240,
                  "type": "Odsetki",
                  "date": "2018-05-02",
                  "transactionDate": "2018-05-02",
                  "installmentType": "equal",
                  "amountPln": 21.24,
                  "amountForeign": 5.86,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 242,
                  "caseId": 1,
                  "position": 241,
                  "type": "Kapitał",
                  "date": "2018-05-02",
                  "transactionDate": "2018-05-02",
                  "installmentType": "equal",
                  "amountPln": 320.01,
                  "amountForeign": 88.28,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 243,
                  "caseId": 1,
                  "position": 242,
                  "type": "Kapitał",
                  "date": "2018-05-04",
                  "transactionDate": "2018-05-04",
                  "installmentType": "equal",
                  "amountPln": 1174.87,
                  "amountForeign": 324.63,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-05-02T00:00:00.000Z",
              "bankCollectedPln": 1516.12,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 21.24,
              "bankCollectedInterestForeign": 5.86,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.91,
              "bankCollectedPrincipalPln": 1494.88,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 13.61,
              "interestForeign": 5.87,
              "principalPln": 958.13,
              "principalForeign": 412.9,
              "days": 30,
              "paymentDifferencePln": 544.39,
              "periodsTotal": 178,
              "simulationBalanceForeign": 27025.68,
              "simulationBalancePln": 62713.06,
              "bankBalanceForeign": 27025.92,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025984,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 412.79"
              ],
              "index": 114,
              "date": "2018-06-01T00:00:00.000Z",
              "currencyDate": "2018-06-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 245,
                  "caseId": 1,
                  "position": 244,
                  "type": "Kapitał",
                  "date": "2018-06-02",
                  "transactionDate": "2018-06-02",
                  "installmentType": "equal",
                  "amountPln": 1587.05,
                  "amountForeign": 412.79,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 244,
                  "caseId": 1,
                  "position": 243,
                  "type": "Odsetki",
                  "date": "2018-06-02",
                  "transactionDate": "2018-06-02",
                  "installmentType": "equal",
                  "amountPln": 22.95,
                  "amountForeign": 5.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-06-02T00:00:00.000Z",
              "bankCollectedPln": 1610,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 22.95,
              "bankCollectedInterestForeign": 5.97,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.79,
              "bankCollectedPrincipalPln": 1587.05,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 13.85,
              "interestForeign": 5.97,
              "principalPln": 957.88,
              "principalForeign": 412.79,
              "days": 31,
              "paymentDifferencePln": 638.27,
              "periodsTotal": 178,
              "simulationBalanceForeign": 26612.89,
              "simulationBalancePln": 61755.18,
              "bankBalanceForeign": 26613.13,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026009,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 413.07"
              ],
              "index": 115,
              "date": "2018-07-01T00:00:00.000Z",
              "currencyDate": "2018-07-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 246,
                  "caseId": 1,
                  "position": 245,
                  "type": "Odsetki",
                  "date": "2018-07-02",
                  "transactionDate": "2018-07-02",
                  "installmentType": "equal",
                  "amountPln": 21.84,
                  "amountForeign": 5.6899999999999995,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 247,
                  "caseId": 1,
                  "position": 246,
                  "type": "Kapitał",
                  "date": "2018-07-02",
                  "transactionDate": "2018-07-02",
                  "installmentType": "equal",
                  "amountPln": 1585.82,
                  "amountForeign": 413.07,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-07-02T00:00:00.000Z",
              "bankCollectedPln": 1607.66,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 21.84,
              "bankCollectedInterestForeign": 5.69,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.07,
              "bankCollectedPrincipalPln": 1585.82,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 13.2,
              "interestForeign": 5.69,
              "principalPln": 958.54,
              "principalForeign": 413.07,
              "days": 30,
              "paymentDifferencePln": 635.93,
              "periodsTotal": 178,
              "simulationBalanceForeign": 26199.82,
              "simulationBalancePln": 60796.64,
              "bankBalanceForeign": 26200.06,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026013,
              "controlSumForeign": 0.24
            },
            {
              "_calculationWarns": [
                "pKapitał 412.97"
              ],
              "index": 116,
              "date": "2018-08-01T00:00:00.000Z",
              "currencyDate": "2018-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 248,
                  "caseId": 1,
                  "position": 247,
                  "type": "Odsetki",
                  "date": "2018-08-02",
                  "transactionDate": "2018-08-02",
                  "installmentType": "equal",
                  "amountPln": 21.69,
                  "amountForeign": 5.79,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 249,
                  "caseId": 1,
                  "position": 248,
                  "type": "Kapitał",
                  "date": "2018-08-02",
                  "transactionDate": "2018-08-02",
                  "installmentType": "equal",
                  "amountPln": 1547.32,
                  "amountForeign": 412.97,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-08-02T00:00:00.000Z",
              "bankCollectedPln": 1569.01,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 21.69,
              "bankCollectedInterestForeign": 5.79,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 412.97,
              "bankCollectedPrincipalPln": 1547.32,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 13.43,
              "interestForeign": 5.78,
              "principalPln": 958.31,
              "principalForeign": 412.98,
              "days": 31,
              "paymentDifferencePln": 597.28,
              "periodsTotal": 178,
              "simulationBalanceForeign": 25786.84,
              "simulationBalancePln": 59838.33,
              "bankBalanceForeign": 25787.09,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002602,
              "controlSumForeign": 0.25
            },
            {
              "_calculationWarns": [
                "pKapitał 413.07"
              ],
              "index": 117,
              "date": "2018-09-01T00:00:00.000Z",
              "currencyDate": "2018-09-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 250,
                  "caseId": 1,
                  "position": 249,
                  "type": "Odsetki",
                  "date": "2018-09-02",
                  "transactionDate": "2018-09-02",
                  "installmentType": "equal",
                  "amountPln": 22.04,
                  "amountForeign": 5.6899999999999995,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 251,
                  "caseId": 1,
                  "position": 250,
                  "type": "Kapitał",
                  "date": "2018-09-02",
                  "transactionDate": "2018-09-02",
                  "installmentType": "equal",
                  "amountPln": 1599.96,
                  "amountForeign": 413.07,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-09-02T00:00:00.000Z",
              "bankCollectedPln": 1622,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 22.04,
              "bankCollectedInterestForeign": 5.69,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.07,
              "bankCollectedPrincipalPln": 1599.96,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 13.21,
              "interestForeign": 5.69,
              "principalPln": 958.52,
              "principalForeign": 413.07,
              "days": 31,
              "paymentDifferencePln": 650.26,
              "periodsTotal": 178,
              "simulationBalanceForeign": 25373.77,
              "simulationBalancePln": 58879.81,
              "bankBalanceForeign": 25374.02,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002598,
              "controlSumForeign": 0.25
            },
            {
              "_calculationWarns": [
                "pKapitał 413.34"
              ],
              "index": 118,
              "date": "2018-10-01T00:00:00.000Z",
              "currencyDate": "2018-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 253,
                  "caseId": 1,
                  "position": 252,
                  "type": "Kapitał",
                  "date": "2018-10-02",
                  "transactionDate": "2018-10-02",
                  "installmentType": "equal",
                  "amountPln": 1581.93,
                  "amountForeign": 413.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 252,
                  "caseId": 1,
                  "position": 251,
                  "type": "Odsetki",
                  "date": "2018-10-02",
                  "transactionDate": "2018-10-02",
                  "installmentType": "equal",
                  "amountPln": 20.75,
                  "amountForeign": 5.42,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-10-02T00:00:00.000Z",
              "bankCollectedPln": 1602.68,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 20.75,
              "bankCollectedInterestForeign": 5.42,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.34,
              "bankCollectedPrincipalPln": 1581.93,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 12.58,
              "interestForeign": 5.42,
              "principalPln": 959.16,
              "principalForeign": 413.34,
              "days": 30,
              "paymentDifferencePln": 630.94,
              "periodsTotal": 178,
              "simulationBalanceForeign": 24960.43,
              "simulationBalancePln": 57920.65,
              "bankBalanceForeign": 24960.68,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025989,
              "controlSumForeign": 0.25
            },
            {
              "_calculationWarns": [
                "pKapitał 413.25"
              ],
              "index": 119,
              "date": "2018-11-01T00:00:00.000Z",
              "currencyDate": "2018-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 254,
                  "caseId": 1,
                  "position": 253,
                  "type": "Odsetki",
                  "date": "2018-11-02",
                  "transactionDate": "2018-11-02",
                  "installmentType": "equal",
                  "amountPln": 21.11,
                  "amountForeign": 5.51,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 255,
                  "caseId": 1,
                  "position": 254,
                  "type": "Kapitał",
                  "date": "2018-11-02",
                  "transactionDate": "2018-11-02",
                  "installmentType": "equal",
                  "amountPln": 1583.24,
                  "amountForeign": 413.25,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-11-02T00:00:00.000Z",
              "bankCollectedPln": 1604.35,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 21.11,
              "bankCollectedInterestForeign": 5.51,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.25,
              "bankCollectedPrincipalPln": 1583.24,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 12.79,
              "interestForeign": 5.51,
              "principalPln": 958.95,
              "principalForeign": 413.25,
              "days": 31,
              "paymentDifferencePln": 632.61,
              "periodsTotal": 178,
              "simulationBalanceForeign": 24547.18,
              "simulationBalancePln": 56961.7,
              "bankBalanceForeign": 24547.43,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025991,
              "controlSumForeign": 0.25
            },
            {
              "_calculationWarns": [
                "pKapitał 413.51"
              ],
              "index": 120,
              "date": "2018-12-01T00:00:00.000Z",
              "currencyDate": "2018-12-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 256,
                  "caseId": 1,
                  "position": 255,
                  "type": "Odsetki",
                  "date": "2018-12-02",
                  "transactionDate": "2018-12-02",
                  "installmentType": "equal",
                  "amountPln": 20.15,
                  "amountForeign": 5.25,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 257,
                  "caseId": 1,
                  "position": 256,
                  "type": "Kapitał",
                  "date": "2018-12-02",
                  "transactionDate": "2018-12-02",
                  "installmentType": "equal",
                  "amountPln": 1587.3,
                  "amountForeign": 413.51,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2018-12-02T00:00:00.000Z",
              "bankCollectedPln": 1607.45,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 20.15,
              "bankCollectedInterestForeign": 5.25,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.51,
              "bankCollectedPrincipalPln": 1587.3,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 12.17,
              "interestForeign": 5.25,
              "principalPln": 959.57,
              "principalForeign": 413.52,
              "days": 30,
              "paymentDifferencePln": 635.71,
              "periodsTotal": 178,
              "simulationBalanceForeign": 24133.66,
              "simulationBalancePln": 56002.13,
              "bankBalanceForeign": 24133.92,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026021,
              "controlSumForeign": 0.26
            },
            {
              "_calculationWarns": [
                "pKapitał 413.43"
              ],
              "index": 121,
              "date": "2019-01-01T00:00:00.000Z",
              "currencyDate": "2019-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 259,
                  "caseId": 1,
                  "position": 258,
                  "type": "Kapitał",
                  "date": "2019-01-02",
                  "transactionDate": "2019-01-02",
                  "installmentType": "equal",
                  "amountPln": 1597.53,
                  "amountForeign": 413.43,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 258,
                  "caseId": 1,
                  "position": 257,
                  "type": "Odsetki",
                  "date": "2019-01-02",
                  "transactionDate": "2019-01-02",
                  "installmentType": "equal",
                  "amountPln": 20.6,
                  "amountForeign": 5.33,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-01-02T00:00:00.000Z",
              "bankCollectedPln": 1618.13,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 20.6,
              "bankCollectedInterestForeign": 5.33,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.43,
              "bankCollectedPrincipalPln": 1597.53,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 12.37,
              "interestForeign": 5.33,
              "principalPln": 959.37,
              "principalForeign": 413.43,
              "days": 31,
              "paymentDifferencePln": 646.39,
              "periodsTotal": 178,
              "simulationBalanceForeign": 23720.23,
              "simulationBalancePln": 55042.76,
              "bankBalanceForeign": 23720.49,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026003,
              "controlSumForeign": 0.26
            },
            {
              "_calculationWarns": [
                "pKapitał 413.52"
              ],
              "index": 122,
              "date": "2019-02-01T00:00:00.000Z",
              "currencyDate": "2019-02-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 260,
                  "caseId": 1,
                  "position": 259,
                  "type": "Odsetki",
                  "date": "2019-02-02",
                  "transactionDate": "2019-02-02",
                  "installmentType": "equal",
                  "amountPln": 19.91,
                  "amountForeign": 5.24,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 261,
                  "caseId": 1,
                  "position": 260,
                  "type": "Kapitał",
                  "date": "2019-02-02",
                  "transactionDate": "2019-02-02",
                  "installmentType": "equal",
                  "amountPln": 1571.37,
                  "amountForeign": 413.52,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-02-02T00:00:00.000Z",
              "bankCollectedPln": 1591.28,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 19.91,
              "bankCollectedInterestForeign": 5.24,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.52,
              "bankCollectedPrincipalPln": 1571.37,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 12.16,
              "interestForeign": 5.24,
              "principalPln": 959.59,
              "principalForeign": 413.53,
              "days": 31,
              "paymentDifferencePln": 619.54,
              "periodsTotal": 178,
              "simulationBalanceForeign": 23306.7,
              "simulationBalancePln": 54083.17,
              "bankBalanceForeign": 23306.97,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002601,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.12"
              ],
              "index": 123,
              "date": "2019-03-01T00:00:00.000Z",
              "currencyDate": "2019-03-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 263,
                  "caseId": 1,
                  "position": 262,
                  "type": "Kapitał",
                  "date": "2019-03-02",
                  "transactionDate": "2019-03-02",
                  "installmentType": "equal",
                  "amountPln": 1594.07,
                  "amountForeign": 414.12,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 262,
                  "caseId": 1,
                  "position": 261,
                  "type": "Odsetki",
                  "date": "2019-03-02",
                  "transactionDate": "2019-03-02",
                  "installmentType": "equal",
                  "amountPln": 17.9,
                  "amountForeign": 4.65,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-03-02T00:00:00.000Z",
              "bankCollectedPln": 1611.97,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 17.9,
              "bankCollectedInterestForeign": 4.65,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.12,
              "bankCollectedPrincipalPln": 1594.07,
              "paymentPln": 971.75,
              "paymentForeign": 418.77,
              "interestPln": 10.79,
              "interestForeign": 4.65,
              "principalPln": 960.96,
              "principalForeign": 414.12,
              "days": 28,
              "paymentDifferencePln": 640.22,
              "periodsTotal": 178,
              "simulationBalanceForeign": 22892.58,
              "simulationBalancePln": 53122.21,
              "bankBalanceForeign": 22892.85,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026008,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 413.7"
              ],
              "index": 124,
              "date": "2019-04-01T00:00:00.000Z",
              "currencyDate": "2019-04-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 264,
                  "caseId": 1,
                  "position": 263,
                  "type": "Odsetki",
                  "date": "2019-04-02",
                  "transactionDate": "2019-04-02",
                  "installmentType": "equal",
                  "amountPln": 19.75,
                  "amountForeign": 5.06,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 265,
                  "caseId": 1,
                  "position": 264,
                  "type": "Kapitał",
                  "date": "2019-04-02",
                  "transactionDate": "2019-04-02",
                  "installmentType": "equal",
                  "amountPln": 1614.5,
                  "amountForeign": 413.7,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-04-02T00:00:00.000Z",
              "bankCollectedPln": 1634.25,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 19.75,
              "bankCollectedInterestForeign": 5.06,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.7,
              "bankCollectedPrincipalPln": 1614.5,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 11.73,
              "interestForeign": 5.06,
              "principalPln": 960,
              "principalForeign": 413.7,
              "days": 31,
              "paymentDifferencePln": 662.52,
              "periodsTotal": 178,
              "simulationBalanceForeign": 22478.88,
              "simulationBalancePln": 52162.21,
              "bankBalanceForeign": 22479.15,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026024,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 413.96"
              ],
              "index": 125,
              "date": "2019-05-01T00:00:00.000Z",
              "currencyDate": "2019-05-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 266,
                  "caseId": 1,
                  "position": 265,
                  "type": "Odsetki",
                  "date": "2019-05-02",
                  "transactionDate": "2019-05-02",
                  "installmentType": "equal",
                  "amountPln": 18.31,
                  "amountForeign": 4.8,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 267,
                  "caseId": 1,
                  "position": 266,
                  "type": "Kapitał",
                  "date": "2019-05-02",
                  "transactionDate": "2019-05-02",
                  "installmentType": "equal",
                  "amountPln": 1579.05,
                  "amountForeign": 413.96,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-05-02T00:00:00.000Z",
              "bankCollectedPln": 1597.36,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 18.31,
              "bankCollectedInterestForeign": 4.8,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.96,
              "bankCollectedPrincipalPln": 1579.05,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 11.15,
              "interestForeign": 4.8,
              "principalPln": 960.59,
              "principalForeign": 413.96,
              "days": 30,
              "paymentDifferencePln": 625.63,
              "periodsTotal": 178,
              "simulationBalanceForeign": 22064.92,
              "simulationBalancePln": 51201.62,
              "bankBalanceForeign": 22065.19,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002598,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 413.89"
              ],
              "index": 126,
              "date": "2019-06-01T00:00:00.000Z",
              "currencyDate": "2019-06-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 268,
                  "caseId": 1,
                  "position": 267,
                  "type": "Odsetki",
                  "date": "2019-06-02",
                  "transactionDate": "2019-06-02",
                  "installmentType": "equal",
                  "amountPln": 18.97,
                  "amountForeign": 4.87,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 269,
                  "caseId": 1,
                  "position": 268,
                  "type": "Kapitał",
                  "date": "2019-06-02",
                  "transactionDate": "2019-06-02",
                  "installmentType": "equal",
                  "amountPln": 1612.31,
                  "amountForeign": 413.89,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-06-02T00:00:00.000Z",
              "bankCollectedPln": 1631.28,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 18.97,
              "bankCollectedInterestForeign": 4.87,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 413.89,
              "bankCollectedPrincipalPln": 1612.31,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 11.31,
              "interestForeign": 4.87,
              "principalPln": 960.42,
              "principalForeign": 413.89,
              "days": 31,
              "paymentDifferencePln": 659.55,
              "periodsTotal": 178,
              "simulationBalanceForeign": 21651.03,
              "simulationBalancePln": 50241.2,
              "bankBalanceForeign": 21651.3,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025987,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.13"
              ],
              "index": 127,
              "date": "2019-07-01T00:00:00.000Z",
              "currencyDate": "2019-07-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 271,
                  "caseId": 1,
                  "position": 270,
                  "type": "Kapitał",
                  "date": "2019-07-02",
                  "transactionDate": "2019-07-02",
                  "installmentType": "equal",
                  "amountPln": 1601.72,
                  "amountForeign": 414.13,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 270,
                  "caseId": 1,
                  "position": 269,
                  "type": "Odsetki",
                  "date": "2019-07-02",
                  "transactionDate": "2019-07-02",
                  "installmentType": "equal",
                  "amountPln": 17.91,
                  "amountForeign": 4.63,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-07-02T00:00:00.000Z",
              "bankCollectedPln": 1619.63,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 17.91,
              "bankCollectedInterestForeign": 4.63,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.13,
              "bankCollectedPrincipalPln": 1601.72,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 10.74,
              "interestForeign": 4.63,
              "principalPln": 961,
              "principalForeign": 414.13,
              "days": 30,
              "paymentDifferencePln": 647.9,
              "periodsTotal": 178,
              "simulationBalanceForeign": 21236.9,
              "simulationBalancePln": 49280.2,
              "bankBalanceForeign": 21237.17,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026018,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.07"
              ],
              "index": 128,
              "date": "2019-08-01T00:00:00.000Z",
              "currencyDate": "2019-08-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 272,
                  "caseId": 1,
                  "position": 271,
                  "type": "Odsetki",
                  "date": "2019-08-02",
                  "transactionDate": "2019-08-02",
                  "installmentType": "equal",
                  "amountPln": 18.54,
                  "amountForeign": 4.6899999999999995,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 273,
                  "caseId": 1,
                  "position": 272,
                  "type": "Kapitał",
                  "date": "2019-08-02",
                  "transactionDate": "2019-08-02",
                  "installmentType": "equal",
                  "amountPln": 1636.4,
                  "amountForeign": 414.07,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-08-02T00:00:00.000Z",
              "bankCollectedPln": 1654.94,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 18.54,
              "bankCollectedInterestForeign": 4.69,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.07,
              "bankCollectedPrincipalPln": 1636.4,
              "paymentPln": 971.73,
              "paymentForeign": 418.76,
              "interestPln": 10.88,
              "interestForeign": 4.69,
              "principalPln": 960.85,
              "principalForeign": 414.07,
              "days": 31,
              "paymentDifferencePln": 683.21,
              "periodsTotal": 178,
              "simulationBalanceForeign": 20822.83,
              "simulationBalancePln": 48319.35,
              "bankBalanceForeign": 20823.1,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026002,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.16"
              ],
              "index": 129,
              "date": "2019-09-01T00:00:00.000Z",
              "currencyDate": "2019-09-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 274,
                  "caseId": 1,
                  "position": 273,
                  "type": "Odsetki",
                  "date": "2019-09-02",
                  "transactionDate": "2019-09-02",
                  "installmentType": "equal",
                  "amountPln": 18.76,
                  "amountForeign": 4.6,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 275,
                  "caseId": 1,
                  "position": 274,
                  "type": "Kapitał",
                  "date": "2019-09-02",
                  "transactionDate": "2019-09-02",
                  "installmentType": "equal",
                  "amountPln": 1688.88,
                  "amountForeign": 414.16,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-09-02T00:00:00.000Z",
              "bankCollectedPln": 1707.64,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 18.76,
              "bankCollectedInterestForeign": 4.6,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.16,
              "bankCollectedPrincipalPln": 1688.88,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 10.67,
              "interestForeign": 4.6,
              "principalPln": 961.07,
              "principalForeign": 414.16,
              "days": 31,
              "paymentDifferencePln": 735.9,
              "periodsTotal": 178,
              "simulationBalanceForeign": 20408.67,
              "simulationBalancePln": 47358.28,
              "bankBalanceForeign": 20408.94,
              "bankRate": 0.0026,
              "bankRateReversed": 0.002601,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.4"
              ],
              "index": 130,
              "date": "2019-10-01T00:00:00.000Z",
              "currencyDate": "2019-10-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 276,
                  "caseId": 1,
                  "position": 275,
                  "type": "Odsetki",
                  "date": "2019-10-02",
                  "transactionDate": "2019-10-02",
                  "installmentType": "equal",
                  "amountPln": 17.78,
                  "amountForeign": 4.36,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 277,
                  "caseId": 1,
                  "position": 276,
                  "type": "Kapitał",
                  "date": "2019-10-02",
                  "transactionDate": "2019-10-02",
                  "installmentType": "equal",
                  "amountPln": 1690.17,
                  "amountForeign": 414.4,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-10-02T00:00:00.000Z",
              "bankCollectedPln": 1707.95,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 17.78,
              "bankCollectedInterestForeign": 4.36,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.4,
              "bankCollectedPrincipalPln": 1690.17,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 10.12,
              "interestForeign": 4.36,
              "principalPln": 961.62,
              "principalForeign": 414.4,
              "days": 30,
              "paymentDifferencePln": 736.21,
              "periodsTotal": 178,
              "simulationBalanceForeign": 19994.27,
              "simulationBalancePln": 46396.66,
              "bankBalanceForeign": 19994.54,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025992,
              "controlSumForeign": 0.27
            },
            {
              "_calculationWarns": [
                "pKapitał 414.34"
              ],
              "index": 131,
              "date": "2019-11-01T00:00:00.000Z",
              "currencyDate": "2019-11-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 279,
                  "caseId": 1,
                  "position": 278,
                  "type": "Kapitał",
                  "date": "2019-11-02",
                  "transactionDate": "2019-11-02",
                  "installmentType": "equal",
                  "amountPln": 1627.61,
                  "amountForeign": 414.34,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 278,
                  "caseId": 1,
                  "position": 277,
                  "type": "Odsetki",
                  "date": "2019-11-02",
                  "transactionDate": "2019-11-02",
                  "installmentType": "equal",
                  "amountPln": 17.36,
                  "amountForeign": 4.42,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-11-02T00:00:00.000Z",
              "bankCollectedPln": 1644.97,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 17.36,
              "bankCollectedInterestForeign": 4.42,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.34,
              "bankCollectedPrincipalPln": 1627.61,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 10.25,
              "interestForeign": 4.41,
              "principalPln": 961.49,
              "principalForeign": 414.35,
              "days": 31,
              "paymentDifferencePln": 673.23,
              "periodsTotal": 178,
              "simulationBalanceForeign": 19579.92,
              "simulationBalancePln": 45435.17,
              "bankBalanceForeign": 19580.2,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0026028,
              "controlSumForeign": 0.28
            },
            {
              "_calculationWarns": [
                "pKapitał 414.58"
              ],
              "index": 132,
              "date": "2019-12-01T00:00:00.000Z",
              "currencyDate": "2019-12-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 280,
                  "caseId": 1,
                  "position": 279,
                  "type": "Odsetki",
                  "date": "2019-12-02",
                  "transactionDate": "2019-12-02",
                  "installmentType": "equal",
                  "amountPln": 16.56,
                  "amountForeign": 4.18,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 281,
                  "caseId": 1,
                  "position": 280,
                  "type": "Kapitał",
                  "date": "2019-12-02",
                  "transactionDate": "2019-12-02",
                  "installmentType": "equal",
                  "amountPln": 1642.54,
                  "amountForeign": 414.58,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2019-12-02T00:00:00.000Z",
              "bankCollectedPln": 1659.1,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 16.56,
              "bankCollectedInterestForeign": 4.18,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.58,
              "bankCollectedPrincipalPln": 1642.54,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 9.71,
              "interestForeign": 4.18,
              "principalPln": 962.03,
              "principalForeign": 414.58,
              "days": 30,
              "paymentDifferencePln": 687.36,
              "periodsTotal": 178,
              "simulationBalanceForeign": 19165.34,
              "simulationBalancePln": 44473.14,
              "bankBalanceForeign": 19165.62,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025974,
              "controlSumForeign": 0.28
            },
            {
              "_calculationWarns": [
                "pKapitał 414.53"
              ],
              "index": 133,
              "date": "2020-01-01T00:00:00.000Z",
              "currencyDate": "2020-01-01T00:00:00.000Z",
              "_payments": [
                {
                  "id": 282,
                  "caseId": 1,
                  "position": 281,
                  "type": "Odsetki",
                  "date": "2020-01-02",
                  "transactionDate": "2020-01-02",
                  "installmentType": "equal",
                  "amountPln": 16.78,
                  "amountForeign": 4.23,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 283,
                  "caseId": 1,
                  "position": 282,
                  "type": "Kapitał",
                  "date": "2020-01-02",
                  "transactionDate": "2020-01-02",
                  "installmentType": "equal",
                  "amountPln": 1644.9,
                  "amountForeign": 414.53,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2020-01-02T00:00:00.000Z",
              "bankCollectedPln": 1661.68,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 16.78,
              "bankCollectedInterestForeign": 4.23,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.53,
              "bankCollectedPrincipalPln": 1644.9,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 9.82,
              "interestForeign": 4.23,
              "principalPln": 961.92,
              "principalForeign": 414.53,
              "days": 31,
              "paymentDifferencePln": 689.94,
              "periodsTotal": 178,
              "simulationBalanceForeign": 18750.81,
              "simulationBalancePln": 43511.22,
              "bankBalanceForeign": 18751.09,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025987,
              "controlSumForeign": 0.28
            },
            {
              "_calculationWarns": [
                "pKapitał 414.62"
              ],
              "index": 134,
              "date": "2020-02-01T00:00:00.000Z",
              "currencyDate": "2020-02-03T00:00:00.000Z",
              "_payments": [
                {
                  "id": 284,
                  "caseId": 1,
                  "position": 283,
                  "type": "Odsetki",
                  "date": "2020-02-02",
                  "transactionDate": "2020-02-02",
                  "installmentType": "equal",
                  "amountPln": 16.89,
                  "amountForeign": 4.14,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 285,
                  "caseId": 1,
                  "position": 284,
                  "type": "Kapitał",
                  "date": "2020-02-02",
                  "transactionDate": "2020-02-02",
                  "installmentType": "equal",
                  "amountPln": 1691.71,
                  "amountForeign": 414.62,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2020-02-02T00:00:00.000Z",
              "bankCollectedPln": 1708.6,
              "bankCollectedForeign": 418.76,
              "bankCollectedInterestPln": 16.89,
              "bankCollectedInterestForeign": 4.14,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.62,
              "bankCollectedPrincipalPln": 1691.71,
              "paymentPln": 971.74,
              "paymentForeign": 418.76,
              "interestPln": 9.61,
              "interestForeign": 4.14,
              "principalPln": 962.14,
              "principalForeign": 414.62,
              "days": 31,
              "paymentDifferencePln": 736.86,
              "periodsTotal": 178,
              "simulationBalanceForeign": 18336.19,
              "simulationBalancePln": 42549.08,
              "bankBalanceForeign": 18336.47,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0025996,
              "controlSumForeign": 0.28
            },
            {
              "_calculationWarns": [
                "pKapitał 414.98"
              ],
              "index": 135,
              "date": "2020-03-01T00:00:00.000Z",
              "currencyDate": "2020-03-02T00:00:00.000Z",
              "_payments": [
                {
                  "id": 286,
                  "caseId": 1,
                  "position": 285,
                  "type": "Odsetki",
                  "date": "2020-03-02",
                  "transactionDate": "2020-03-02",
                  "installmentType": "equal",
                  "amountPln": 15.55,
                  "amountForeign": 3.79,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                },
                {
                  "id": 287,
                  "caseId": 1,
                  "position": 286,
                  "type": "Kapitał",
                  "date": "2020-03-02",
                  "transactionDate": "2020-03-02",
                  "installmentType": "equal",
                  "amountPln": 1703.16,
                  "amountForeign": 414.98,
                  "vacationType": 0,
                  "createdAt": "2020-04-27T15:32:20.000Z",
                  "updatedAt": "2020-04-27T15:32:20.000Z"
                }
              ],
              "effectiveDate": "2020-03-02T00:00:00.000Z",
              "bankCollectedPln": 1718.71,
              "bankCollectedForeign": 418.77,
              "bankCollectedInterestPln": 15.55,
              "bankCollectedInterestForeign": 3.79,
              "bankCollectedOverpaymentForeign": 0,
              "bankCollectedOverpaymentPln": 0,
              "bankCollectedPrincipalForeign": 414.98,
              "bankCollectedPrincipalPln": 1703.16,
              "paymentPln": 971.75,
              "paymentForeign": 418.77,
              "interestPln": 9.38,
              "interestForeign": 4.04,
              "principalPln": 962.96,
              "principalForeign": 414.98,
              "days": 31,
              "paymentDifferencePln": 746.96,
              "periodsTotal": 178,
              "simulationBalanceForeign": 17921.21,
              "simulationBalancePln": 41586.12,
              "bankBalanceForeign": 17921.49,
              "bankRate": 0.0026,
              "bankRateReversed": 0.0024336,
              "controlSumForeign": 0.28
            }
          ]
        },
        "summary": {
          "basic": {
            "agreementNo": "00123456/2006",
            "bankName": "mB - mBank",
            "signatureDate": "2008-10-01T00:00:00.000Z",
            "preparationDate": "2008-10-01T00:00:00.000Z",
            "installmentAmount": 120,
            "bankMargin": 0.01,
            "temporaryIncrease": 10,
            "firstTrancheDate": "2008-11-04T00:00:00.000Z",
            "creditAmount": 210040.01,
            "totalCostsAmount": 8158.84
          },
          "ratesAndTranches": {
            "bankExchangeRatesForCalculationDay": {
              "bid": 3.9716,
              "ask": 4.0926,
              "spread": 0.121
            },
            "tranches": [
              {
                "id": 1,
                "caseId": 1,
                "position": 0,
                "amountPln": 210040.01,
                "amountForeign": 90514.98,
                "date": "2008-11-04",
                "description": "transza",
                "createdAt": "2020-04-27T15:32:20.000Z",
                "updatedAt": "2020-04-27T15:32:20.000Z"
              }
            ],
            "tranchesSum": {
              "pln": 210040.01,
              "foreign": 90514.98
            }
          },
          "byBank": {
            "principalSum": {
              "pln": 256348.74,
              "foreign": 72593.49
            },
            "overpaymentSum": {
              "pln": 0,
              "foreign": 0
            },
            "interestsSum": {
              "pln": 19380.38,
              "foreign": 5938.29
            },
            "penalInterestsSum": {
              "pln": 0,
              "foreign": 0
            },
            "costs": [
              {
                "pln": 0,
                "foreign": 0,
                "name": "Prowizja za udzielenie"
              },
              {
                "pln": 1471.4,
                "foreign": 634.09,
                "name": "Ubezp. NWW"
              },
              {
                "pln": 6267.36,
                "foreign": 2700.87,
                "name": "Składki ubezp. na życie"
              },
              {
                "pln": 420.08,
                "foreign": 181.03,
                "name": "Prowizja za ubezp."
              },
              {
                "pln": 0,
                "foreign": 0,
                "name": "składki ubezp. (na życie)"
              },
              {
                "pln": 0,
                "foreign": 0,
                "name": "składki ubezp. (Pakiet Bezpieczna Spłata)"
              }
            ],
            "bankExchangeRates": {
              "bid": 3.9716,
              "ask": 4.0926,
              "spread": 0.121
            },
            "exchangeRatesUrl": "https://www.mbank.pl/serwis-ekonomiczny/kursy-walut/ [2020-03-04]",
            "sumToRedeem": {
              "foreign": 17921.49,
              "pln": 73345.49
            },
            "totalPaymentsPln": 283887.96,
            "mortgageQuotePln": 210040.01,
            "sumToRedeemCustomerPln": -73847.95,
            "sumToRedeemPln": 73345.49,
            "quotesDifference": 147193.44
          },
          "bySimulator": {
            "principal": 168453.89,
            "overpayment": 0,
            "interests": 13780.3,
            "penalInterests": 0,
            "costs": [
              {
                "value": 0,
                "name": "Prowizja za udzielenie"
              },
              {
                "value": 1471.4,
                "name": "Ubezp. NWW"
              },
              {
                "value": 6267.36,
                "name": "Składki ubezp. na życie"
              },
              {
                "value": 420.08,
                "name": "Prowizja za ubezp."
              },
              {
                "value": 0,
                "name": "składki ubezp. (na życie)"
              },
              {
                "value": 0,
                "name": "składki ubezp. (Pakiet Bezpieczna Spłata)"
              }
            ],
            "remainingBalanceCustomer": 41586.12,
            "paymentsDiffFromExchangeRates": -93495.61,
            "remainingBalanceBank": 73345.49,
            "remainingBalancePlusOverpayment": -51909.49,
            "finalDifferenceInQuotes": 125254.98,
            "interestsDiff": 5600.08,
            "penalInterestsDiff": 0,
            "principalDiff": 87894.85,
            "overpaymentDiff": 0,
            "costDiffs": [
              {
                "value": 0,
                "name": "Prowizja za udzielenie"
              },
              {
                "value": 0,
                "name": "Ubezp. NWW"
              },
              {
                "value": 0,
                "name": "Składki ubezp. na życie"
              },
              {
                "value": 0,
                "name": "Prowizja za ubezp."
              },
              {
                "value": 0,
                "name": "składki ubezp. (na życie)"
              },
              {
                "value": 0,
                "name": "składki ubezp. (Pakiet Bezpieczna Spłata)"
              }
            ],
            "totalPaidPln": 283887.96,
            "totalSimulatedPln": 190393.03,
            "totalDifferencePln": 93494.93
          }
        }
      }
    ],*/
  },
};

export default initialState;