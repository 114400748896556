import React, { useEffect } from 'react';

import Table from 'components/basic/Table';

import styles from './CaseDetails.style';
import theme from 'theme/theme';
import {formatValue} from 'material-table';
import { fieldValues } from 'dict';
import clsx from 'clsx';

const columns = ({currency}) => [
  /* GENERAL */
  ...[
    { field: 'index',title: 'Lp.', cellStyle: { textAlign: 'center', width: 45, fontWeight: theme.typography.fontWeightBold} },
    { field: 'date', title: 'Data raty', type: 'date', width: 94, editWidth: 130 },
    { field: 'currencyDate', title: 'Data zdarzenia', type: 'date', width: 94, editWidth: 130 },
    { field: 'bankRate', title: 'Stopa proc. %', type: 'percentage', decimalDigits: 5},
    { field: 'days', title: '# dni dla odsetek', type: 'numeric', width: 75 },
    { field: 'periodsTotal', title: '# rat', type: 'numeric', width: 65 },
  ],
  /* PLN */
  ...[
    { field: 'tranchesSumPln', title: 'Bank Wypłaty/koszty', type: 'numeric', decimalDigits: 2, hideZeroes: true, width: 120},
    { field: 'bankCollectedPln', title: 'Bank Pobrał', type: 'numeric', decimalDigits: 2},
    { field: 'bankCollectedOverpaymentPln', title: 'Nadpłaty kredytu', type: 'numeric', decimalDigits: 2, hideZeroes: true},
    { field: 'paymentPln', title: 'Rata', type: 'numeric', decimalDigits: 2},
    { field: 'principalPln', title: 'Kapitał', type: 'numeric', decimalDigits: 2},
    { field: 'interestPln', title: 'Odsetki', type: 'numeric', decimalDigits: 2},
    { field: 'paymentDifferencePln', className: 'highlight-column', title: 'Nadpłata z tyt. różnic kursowych', type: 'numeric', decimalDigits: 2, width: 110},
    { field: 'simulationBalancePln', title: 'Saldo (PLN)', type: 'numeric', decimalDigits: 2},
  ].map(col => ({...col, colGroup: 'PLN', collapsed: true, className: clsx('groupPln', col.className)})),
  /* Foreign */
  ...[
    { field: 'tranchesSumForeign', title: `Bank Wypłaty/koszty`, type: 'numeric', decimalDigits: 2, hideZeroes: true, width: 120},
    { field: 'bankCollectedOverpaymentForeign', title: 'Bank Nadpłata kredytu', type: 'numeric', decimalDigits: 2, hideZeroes: true},
    { field: 'bankCollectedForeign', title: 'Bank pobrał Rata', type: 'numeric', decimalDigits: 2,
      render: rowData => <span>{formatValue((rowData.bankCollectedForeign - rowData.bankCollectedOverpaymentForeign), {type: 'numeric', decimalDigits: 2})}</span>},
    { field: 'paymentForeign', title: 'Rata', type: 'numeric', decimalDigits: 2},
    { field: 'bankCollectedPrincipalForeign', title: 'Bank Pobrał kapitał', type: 'numeric', decimalDigits: 2},
    { field: 'principalForeign', title: 'Kapitał', type: 'numeric', decimalDigits: 2},
    // { field: 'bankCollectedPrincipalForeign', title: 'Kapitał', type: 'numeric', decimalDigits: 2},
    { field: 'bankCollectedInterestForeign', title: 'Bank pobrał odsetki', type: 'numeric', decimalDigits: 2},
    { field: 'interestForeign', title: 'Odsetki', type: 'numeric', decimalDigits: 2},
  ].map(col => ({...col, colGroup: currency, className: clsx('groupForeign', col.className)})),
  /* Saldo CHF */
  ...[
    { field: 'bankBalanceForeign', title: `${currency} Bank`, type: 'numeric', decimalDigits: 2},
    { field: 'simulationBalanceForeign', title: `${currency} Symulacja`, type: 'numeric', decimalDigits: 2},
    { field: '', title: 'Różnica sald', type: 'numeric', decimalDigits: 2, 
      render: rowData => <strong>{formatValue(rowData.bankBalanceForeign - rowData.simulationBalanceForeign, {type: 'numeric', decimalDigits: 2})}</strong>},
  ].map(col => ({...col, colGroup: `Saldo ${currency}`, className: clsx('groupBalance', col.className)})),
  /* Oprocentowanie */
  ...[
    { field: 'bankRate', title: 'Oproc. użyte w kalkulacji', type: 'percentage', decimalDigits: 5},
    { field: 'bankRateReversed', title: `Oproc. na podstawie odsetek`, type: 'percentage', decimalDigits: 5},
    { field: '', title: 'Różnica oproc.', type: 'numeric', decimalDigits: 2, 
      render: rowData => <strong>{formatValue((rowData.bankRateReversed - rowData.bankRate)*100, {type: 'numeric', decimalDigits: 2}) + '%'}</strong>},
    ].map(col => ({...col, colGroup: 'Oprocentowanie', className: clsx('groupInterests', col.className)})),
  /* inne */  
  { field: 'installmentType', title: 'Rodzaj rat', width: 150, lookup: fieldValues.cases.installmentType},
  /* pozostałe pola z API */  
  // ...[
  //   { field: 'interestForeign', title: `Rata odsetki (${currency})`, type: 'numeric', decimalDigits: 2},
  //   { field: 'interestDate', title: 'interestDate', type: 'date', decimalDigits: 2, width: 150},
  //   { field: 'effectiveDate', title: 'effectiveDate', type: 'date', decimalDigits: 2, width: 150},
  //   { field: 'bankCollectedInterestPln', title: 'bankCollectedInterestPln', type: 'numeric', decimalDigits: 2, width: 190},
  //   { field: 'bankCollectedPrincipalPln', title: 'bankCollectedPrincipalPln', type: 'numeric', decimalDigits: 2, width: 190},
  //   { field: 'controlSumForeign', title: 'controlSumForeign', type: 'numeric', decimalDigits: 2, width: 150},  
  // ].map(col => ({...col, colGroup: 'Inne', className: clsx('groupNone', col.className)})),
];


const setFirstRowTop = (root) => () => {
  const thead = root.querySelector('thead');
  const firstRow = root.querySelectorAll('tbody > tr.mTable-body-row:first-of-type > td');
  for(let cell of firstRow){
    cell.style.top = `${thead.getBoundingClientRect().height - 1}px`;
  }

  const colGroupRow = thead.querySelector('thead tr:first-of-type');
  const tableHeaders = thead.querySelectorAll('thead tr:nth-of-type(2) > th');
  for(let cell of tableHeaders){
    cell.style.top = `${colGroupRow.getBoundingClientRect().height - 1}px`;
  }
};

export default function Cases({caseSimulation, currency, isLoading}) {
  const classes = styles();
  const rootRef = React.createRef();

  useEffect(() => {
    const callback = setFirstRowTop(rootRef.current);
    callback();
    window.addEventListener('resize', callback);
  }, [rootRef])

  return (
    <div className={classes.root} ref={rootRef}>
      <Table
        columns={columns({currency})}
        data={[caseSimulation.table.rowSummary, ...caseSimulation.table.rows]}
        isLoading={isLoading}
        options={{
          filtering: false,
          // pageSize: 20,
          // pageSizeOptions: [10, 20, 50, 100, 1000],
          paging: false,
          topSummaryRows: 1,
        }}
      />
    </div>
  );
}