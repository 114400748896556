import React from 'react';
import PropTypes from 'prop-types';

import styles from './UserAvatar.style';
import clsx from 'clsx';
import { Avatar } from '@material-ui/core';
import theme from 'theme/theme';

import red from '@material-ui/core/colors/red';
import pink from '@material-ui/core/colors/pink';
import deepPurple from '@material-ui/core/colors/deepPurple';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import cyan from '@material-ui/core/colors/cyan';
import teal from '@material-ui/core/colors/teal';
import green from '@material-ui/core/colors/green';
import lightGreen from '@material-ui/core/colors/lightGreen';
import lime from '@material-ui/core/colors/lime';
import amber from '@material-ui/core/colors/amber';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';

const colorShade = 500;

const palette = [
  red[colorShade],
  pink[colorShade],
  deepPurple[colorShade],
  indigo[colorShade],
  blue[colorShade],
  cyan[colorShade],
  teal[colorShade],
  green[colorShade],
  lightGreen[colorShade],
  lime[colorShade],
  amber[colorShade],
  orange[colorShade],
  deepOrange[colorShade],
];

const stringToColor = str => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    // const value = Math.floor(((hash >> (i * 8)) & 0xFF)/2)+128;
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }

  const paletteColor = palette[Math.floor(parseInt(color.substr(1), 16)/16777215*(palette.length-1))];

  // return color;
  return paletteColor;
}

export const UserAvatar = ({ className, variant, user: {avatarUrl, logoUrl, fullName, name}, ...props}) => {
  const classes = styles();

  const displayName = fullName || name;
  const displayAvatar = avatarUrl || logoUrl;
  
  if(displayAvatar){
    return (
      <Avatar
        className={clsx(classes.root, className, variant && `variant-${variant}`)} 
        alt={displayName} 
        src={displayAvatar}
        {...props}
      />
    );
  }

  if(!displayName || typeof displayName !== 'string'){
    return (
      <Avatar
        className={clsx(classes.root, className, variant && `variant-${variant}`)} 
        {...props}
      />
    );
  }

  const initials = displayName.split(' ').map(word => word.substr(0, 1)).join('').toUpperCase();
  const backgroundColor = stringToColor(displayName);
  //const textColor = theme.palette.common[isLight(bgColor) ? 'black' : 'white'];

  return (
    <Avatar 
      className={clsx(classes.root, className, variant && `variant-${variant}`)} 
      alt={displayName} 
      style={{backgroundColor, color: theme.palette.common.white/* theme.palette.getContrastText(backgroundColor) */}}
      {...props}
    >
      {initials}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  children: PropTypes.node,
};

export default UserAvatar;
