import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, TextField, MenuItem, Box, Modal, Typography, Tooltip, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { banksDuck, paymentTypesDuck } from 'redux/ducks/dictionaries';
import { useSelector } from 'react-redux';

import NewCaseContext from './NewCase.context';
import { stepsStyles } from './NewCase.style';
import { fieldValues } from 'dict';
import Table from 'components/basic/Table';
import * as  caseDetailsDucks from 'redux/ducks/caseDetails';
import {DateInput, format} from 'material-table';
import ModalWrapper from 'components/basic/ModalWrapper';
import Hint from 'components/basic/Hint';
import Form from 'components/basic/Form';
import { LibraryAddOutlined, InfoOutlined, DeleteSweepOutlined } from '@material-ui/icons';
import SmartSelect from 'components/basic/SmartSelect';
import { useSnackbar } from 'notistack';
import { companiesDuck } from 'redux/ducks/companies';
import config from 'config';

const unFormatRawValue = (rawValue = '', decimalDigits, percentage) => {
  let result = rawValue;
  if(typeof decimalDigits === 'undefined'){
    result = rawValue === '' ? null : rawValue
  } else {
    const unformatted = format.number.plToApi(rawValue.toString());
    result = isNaN(unformatted) ? null : unformatted;
  }
  if(percentage && !isNaN(result / 100)){
    result = result / 100;
  }
  return result;
};

const formatRawValue = (rawValue = '', decimalDigits, percentage) => {
  // console.log('format percentage:', percentage, [rawValue], !isNaN(rawValue) && rawValue * 100)
  if(percentage && !isNaN(rawValue)){
    rawValue = rawValue * 100;
  }
  if(typeof decimalDigits === 'undefined'){
    return rawValue;
  } else if(rawValue === null) {
    return '';
  } else {
    const formatted = format.number.apiToPl(rawValue.toString(), decimalDigits);
    return formatted.toString() === 'NaN' ? '' : formatted;
  }
};

const reFormatRawValue = (rawValue = '', decimalDigits, percentage) => {
  return formatRawValue(unFormatRawValue(rawValue, decimalDigits, percentage), decimalDigits)
}

const DateInputWrapper = ({date, onChange, onBlur, value, children, ...props}) => (
  (
    date ? (
      <DateInput
        value={value}
        {...props}
        render={(ref, {MuiProps, ...inputProps}) => (
          React.Children.map(children, (child, i) => React.cloneElement(child, {
            key: i,
            inputRef: ref, 
            inputProps: {...inputProps}, 
            ...MuiProps,
            style: null,
            ...props,
            onChange: (e) => {
              e.persist();
              onChange({target: {value: format.date.plToApi(e.target.value)}})
            },
            onBlur: (e) => {
              e.persist();
              onBlur({target: {value: format.date.plToApi(e.target.value)}})
            }
          }))
        )}
      />
    ) : (
      React.Children.map(children, (child, i) => React.cloneElement(child, {
        key: i, 
        onChange, 
        onBlur, 
        value, 
        ...props
      }))
    )
  )
)

const Row = ({name, data = {}, select, date, half = false, third = false, percentage, component: Component = TextField, decimalDigits, ...props}) => {
  const classes = stepsStyles();
  const opts = useContext(NewCaseContext);
  const [displayValue, setDisplayValue] = useState('');

  // window.addLabel(name, props.label);

  if(percentage && typeof decimalDigits === 'undefined'){
    decimalDigits = 7;
  }

  // name === 'margin' && console.log('percentage, decimalDigits', percentage, decimalDigits);

  useEffect(() => {  
    if(opts.data.case[name] && opts.data.case[name].value !== unFormatRawValue(displayValue, decimalDigits, percentage)){
      setDisplayValue(formatRawValue(opts.data.case[name].value, decimalDigits, percentage));
    }
  }, [decimalDigits, displayValue, name, opts.data.case, percentage]);

  const updateDisplayValue = value => setDisplayValue(reFormatRawValue(value, decimalDigits, percentage));
  
  const innerProps = {
    onChange: (e) => {
      setDisplayValue(e.target.value);
      const newVal = unFormatRawValue(e.target.value, decimalDigits, percentage);
      if(typeof opts.data.case[name] === 'undefined' || newVal !== opts.data.case[name].value){
        opts.data.case[name] && (opts.data.case[name].error = false);
        opts.update({name, value: newVal});
      }
    },
    onBlur: (e) => {
      updateDisplayValue(e.target.value);
      const newVal = unFormatRawValue(e.target.value, decimalDigits, percentage);
      opts.data.case[name] && (opts.data.case[name].error = false);
      opts.updateAndSend({name, value: newVal, savedValue: newVal, error: false});
    },
    value: displayValue,
  };

  if(select) Component = SmartSelect;

  return (
    <Grid item xs={12} lg={half ? 6 : third ? 4 : 12} className={classes.formRow}>
      <Hint variant='form' name={`Edycja umowy / ${props.label || name}`} />
      <DateInputWrapper
        date={date}
        error={opts.data.case[name] && !!opts.data.case[name].error}
        helperText={opts.data.case[name] && opts.data.case[name].error}
        wrapClassName={classes.dateInputWrapper}
        {...(select ? {onChange: innerProps.onBlur, value: innerProps.value} : innerProps)} 
        {...props}
      >
        <Component
          disabled={opts.editingDisabled}
          variant='outlined' 
          size='small'
          className={classes.input} 
          {...(typeof decimalDigits !== 'undefined' && {
            onKeyDown: e => {
              if(['ArrowUp', 'ArrowDown'].includes(e.key)){
                e.preventDefault();
              }
            },
          })}
          {...(select && {
            openOnEnter: true,
            options: Array.isArray(select) 
              ? select.map(item => ({value: item.id, label: item.name}))
              : Object.entries(select).map(([id, name]) => ({value: id, label: name})),
          })}
        >
        </Component>
      </DateInputWrapper>
    </Grid>
  );
};

const BulkAddModal = ({modal, setBulkModal, columns, opts, save}) => {
  const {enqueueSnackbar} = useSnackbar();

  return (
    <Modal open={modal}>
      <ModalWrapper
        maxWidth={900}
        variant='small'
        title='Dodawanie zbiorcze'
        description='Skopiuj komórki z arkusza kalkulacyjnego i wklej je poniżej.'
      >
        <Box style={{width: '100%'}}>
          <Box mt={-2} mb={2}>
              <Tooltip 
                PopperProps={{
                  style: {maxWidth: 500},
                }}
                title={
                  <ul>
                    <li>możesz skopiować poniższe nagłówki i wkleić je do arkusza,</li>
                    <li>możesz wkleić dane z wierszem nagłówków lub bez niego,</li>
                    <li>daty podajemy w formacie 'dd.mm.rrrr' lub 'rrrr-mm-dd', </li>
                    <li>liczby nie mogą mieć separatorów tysięcy, </li>
                    <li>wartości prawda/fałsz będą uznane za prawdziwe tylko dla wartości: tak, 1, yes, true, prawda, +, x, t,</li>
                    <li>jeśli którykolwiek wiersz zawiera błędy, zostanie wyświetlony komunikat informacyjny i żaden wiersz nie zostanie dodany.</li>
                  </ul>
                }
              >
                <Chip icon={<InfoOutlined/>} label='Wskazówki do importu danych' />
              </Tooltip>
          </Box>
          <Box mt={0} mb={0}>
            <Typography component='p' variant='body2'>Kolumny powinny być w następującej kolejności:</Typography>
          </Box>
          <Box width='100%' pr={3}>
            <TextField value={columns.map(col => col.title).join('\t')} onFocus={e => e.target.select()} onClick={e => e.target.select()} />
          </Box>
          <Form
            disabled={modal === 'sending'}
            variant='small'
            fields={[
              {label: 'Wklej tutaj', name: 'import', rows: 10, autoFocus: true}
            ]}
            buttons={[
              { label: 'Anuluj', color: 'primary', onClick: () => setBulkModal(false)},
              { label: 'Dodaj pozycje', color: 'secondary', icon: LibraryAddOutlined, type: 'submit'},
            ]}
            onSubmit={values => {
              let data = values.import.split('\n').map((row, rowIndex) => {
                // const values = row.replaceAll(/\s\s+/g, '\t').split('\t');
                const values = row.split('\t').map((cell) => cell.replaceAll(/\s\s+/g, '').trim().toLowerCase());
                // console.log(values);
                // console.log('rowIndex, row, values:', rowIndex, row, values)
                if(/^\s*$/.test(row) || (rowIndex === 0 && !/^\d{1,4}[/.-]\d{1,4}[/.-]\d{1,4}$/.test(values[0]))){
                  return false;
                }

                return Object.fromEntries(
                  values
                    .map((value, i) => {
                      if(i > columns.length - 1){
                        return false;
                      }
                      const col = columns[i];
                      let output = undefined;

                      // console.log(col.type, value, col);

                      if(typeof col.lookup !== 'undefined'){
                        output = Object.entries(col.lookup).reduce((out, [key, val]) => out || (val.toLowerCase() === value.toLowerCase() && key), false) || value;
                      } else if(col.type === 'date'){
                        if(/^\d{1,2}[/.-]\d{1,2}[/.-]\d{4}$/.test(value)){
                          const outputRaw = value.replace(/[/-]/g, '.');
                          const [day, month, year] = outputRaw.split('.');
                          
                          output = format.date.plToApi(
                            [
                              day.padStart(2, '0'),
                              month.padStart(2, '0'),
                              year,
                            ].join(".")
                          );

                          console.log(output);
                          // console.log('xx.xx.xxxx', output);
                        } else if(/^\d{4}[/.-]\d{1,2}[/.-]\d{1,2}$/.test(value)){
                          const outputRaw = value.replace(/^(\d{4})[/.-](\d{2})[/.-](\d{2})$/, '$3.$2.$1');
                          const [day, month, year] = outputRaw.split('.');

                          output = format.date.plToApi(
                            [
                              day.padStart(2, '0'),
                              month.padStart(2, '0'),
                              year,
                            ].join(".")
                          );

                          console.log(output);

                          // console.log('xxxx.xx.xx', output);
                        }
                      } else if(col.type === 'numeric'){
                        const outputValue = /,/.test(value) ? format.number.plToApi(value.replace(/\s/g, '')) : parseFloat(value.replace(/\s/g, ''));
                        output = !isNaN(outputValue) ? outputValue : value === '' ? undefined : value;
                      } else if(col.type === 'percentage'){
                        const outputValue = /,/.test(value) ? format.number.plToApi(value) : parseFloat(value);
                        output = !isNaN(outputValue / 100) ? outputValue / 100 : value === '' ? undefined : value;
                      } else if(col.type === 'boolean'){
                        output = ['tak', '1', 'yes', 'true', 'prawda', '+', 'x', 't'].includes(value.toLowerCase());
                      } else {
                        output = value;
                      }

                      if(typeof output === 'undefined'){
                        return false;
                      } else {
                        return [col.field, output];
                      }
                    })
                    .filter(Boolean)
                    // .concat([['caseId', opts.data.case.id.value]])
                );
              }).filter(Boolean);
              // console.log('data:', data);
              setBulkModal('sending');
              save({
                data, 
                callback: resData => {
                  setBulkModal(false);
                  enqueueSnackbar(`Zaimportowano ${resData.length} pozycje/i`, {variant: 'success', autoHideDuration: 1000})
                }, 
                onError: () => setBulkModal(true),
              });
            }}
          />
        </Box>
      </ModalWrapper>
    </Modal>
  );
};

const BulkDeleteModal = ({modal, setDeleteModal, clearSelected, selected, save}) => {
  const {enqueueSnackbar} = useSnackbar();

  return (
    <Modal open={modal}>
      {selected && selected.length ? (
        <ModalWrapper
          variant='small'
          disabled={modal === 'sending'}
          title='Usuwanie pozycji'
          description='Czy na pewno chcesz usunąć zaznaczone pozycje?'
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => setDeleteModal(false)},
            { label: 'Usuń pozycje', color: 'secondary', icon: DeleteSweepOutlined, 
              onClick: () => {
                // console.log('selected:', [...selected]);
                setDeleteModal('sending');
                save({
                  data: [...selected], 
                  callback: resData => {
                    setDeleteModal(false);
                    typeof clearSelected.run === 'function' && clearSelected.run();
                    enqueueSnackbar(`Usunięto ${selected.length} pozycje/i`, {variant: 'success', autoHideDuration: 1000});
                  }, 
                  onError: () => setDeleteModal(true),
                });
  
              },
            },
          ]}
        />
      ) : (
        <ModalWrapper
          variant='small'
          title='Usuwanie pozycji'
          description='Zaznacz pozycje w tabeli, aby usunąć wiele wierszy.'
          buttons={[
            { label: 'OK', color: 'primary', onClick: () => setDeleteModal(false)},
          ]}
        />
      )}
    </Modal>
  );
};

export const caseFields = [
  { step: 1,             required: true, name: 'ownerCompanyId',           label: 'Klient', selectFrom: 'companies', visibleTo: 'supermanager', draftOnly: true },
  { step: 1,             required: true, name: 'agreementNo',              label: 'Numer umowy' },
  { step: 1, half: true,                 name: 'name',                     label: 'Sygnatura' },
  { step: 1, half: true, required: true, name: 'person',                   label: 'Osoba' },
  { step: 1, half: true, required: true, name: 'bankId',                   label: 'Bank podpisania umowy', selectFrom: 'banks' },
  { step: 1, half: true,                 name: 'currentBankId',            label: 'Obecny bank', selectFrom: 'banks' },
  { step: 1, half: true, required: true, name: 'preparationDate',          label: 'Data sporządzenia umowy', date: true },
  { step: 1, half: true, required: true, name: 'signatureDate',            label: 'Data podpisania umowy', date: true },
  { step: 1, half: true, required: true, name: 'calculationDate',          label: 'Dzień, na jaki są wykonywane wyliczenia', date: true },
  { step: 1, half: true, required: true, name: 'paymentDayNo',             label: 'Dzień spłaty raty', select: fieldValues.cases.paymentDayNo },
  { step: 1,                             name: 'optionalNotes',            label: 'Opis umowy', multiline: true, rows: '4' },
  { step: 2, half: true, required: true, name: 'installmentAmount',        label: 'Liczba rat', decimalDigits: 0 },
  { step: 2, half: true, required: true, name: 'installmentType',          label: 'Raty równe/malejące', select: fieldValues.cases.installmentType },
  { step: 2, half: true, required: true, name: 'interestRate',             label: 'LIBOR + marża', percentage: true },
  { step: 2, half: true, required: true, name: 'margin',                   label: 'Marża', percentage: true },
  { step: 2, half: true,                 name: 'temporaryIncrease',        label: 'Podwyższenie do czasu wpisu do hipoteki', percentage: true },
  { step: 2, half: true,                 name: 'temporaryIncreasePeriods', label: 'Ilość rat z podwyższeniem', decimalDigits: 0 },
  // { step: 2, half: true, required: true, name: 'currency',                 label: 'Waluta', select: fieldValues.cases.currency },
  { step: 2, half: true, required: true, name: 'liborType',                label: 'Typ Liboru', select: fieldValues.cases.liborType },
  ...(config.feat.caseExchangePropsEnabled ? [
    { step: 2, half: true,               name: 'exchangeRateBid',          label: 'Kurs kupna na dzień sporządzenia umowy', decimalDigits: 7 },
    { step: 2, half: true,               name: 'exchangeRateAsk',          label: 'Kurs sprzedaży na dzień sporządzenia umowy', decimalDigits: 7 },
  ] : [])
];

export const steps = [
  {
    title: 'Dane umowy', 
    slug: 'info', 
    Step: () => {
      const opts = useContext(NewCaseContext);
      const libs = {
        banks: useSelector(banksDuck.get.lookupTable()), 
        companies: useSelector(companiesDuck.get.lookupTable()), 
      };
      return caseFields
        .filter(row => (
          row.step === 1
          && (!row.draftOnly || opts.data.isDraft)
          && (!row.visibleTo || opts.currentUserRole === row.visibleTo)
        ))
        .map(({selectFrom, ...props}, i) => (
          <Row key={`step-1/${i}`} select={selectFrom && libs[selectFrom]} {...props} />
        ));
    },
  },
  {
    title: 'Raty i opłaty', 
    slug: 'details', 
    Step: () => {
      const libs = {};
      return caseFields.filter(row => row.step === 2).map(({selectFrom, ...props}, i) => (
        <Row key={`step-2/${i}`} select={selectFrom && libs[selectFrom]} {...props} />
      ));
    },
  },
  {
    title: 'Transze', 
    slug: 'tranches', 
    onlyOnEdit: true,
    Step: () => {
      const opts = useContext(NewCaseContext);
      // const data = useSelector(caseDetailsDucks.caseTrancheDuck.get.byCaseId(opts.data.case.id ? opts.data.case.id.value : null));
      const data = opts.data.caseTranche;
      const [bulkModal, setBulkModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);
      const [selected, setSelected] = useState([]);
      const [clearSelected, setClearSelected] = useState({});
      const onSelectionChange = (rows, clicked, clearSelection) => {
        // console.log('rows:', rows.map(row => row.id));
        setSelected(rows.map(row => row.id));
        setClearSelected({run: clearSelection});
      };
      const columns=[
        { field: 'date', title: 'Data', type: 'date',
          filtering: true, searchable: true, required: true,
        },
        { field: 'amountPln', title: 'Kwota PLN', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true, required: true,
        },
        { field: 'amountForeign', title: 'Kwota w walucie', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true, required: true,
        },
        { field: 'description', title: 'Opis', 
          filtering: true, searchable: true,
        },
      ]
      return (
        <>
          <BulkAddModal
            modal={bulkModal}
            setBulkModal={setBulkModal}
            columns={columns}
            opts={opts}
            save={opts.saveTrancheBulk}
          />
          <BulkDeleteModal
            modal={deleteModal}
            setDeleteModal={setDeleteModal}
            clearSelected={clearSelected}
            selected={selected}
            save={opts.deleteTrancheBulk}
          />
          <Table
            data={data}
            columns={columns}
            editable={!opts.editingDisabled && {
              onRowAdd: ({tableData, ...newData}) => new Promise(resolve => {opts.saveTranche(newData); resolve()}),
              onRowUpdate: ({tableData, ...newData}) => new Promise(resolve => {opts.saveTranche(newData); resolve()}),
              onRowDelete: ({tableData, ...newData}) => new Promise((resolve, reject) => {opts.deleteTranche(newData, resolve, reject)}),
            }}
            options={{selection: true}}
            actions={[
              { icon: (props) => <DeleteSweepOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Usuń zaznaczone', onClick: () => setDeleteModal(true), position: 'toolbar-bottom' },
              { icon: (props) => <LibraryAddOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Wklej wiersze z arkusza', onClick: () => setBulkModal(true), position: 'toolbar-bottom' },
            ]}
            onSelectionChange={onSelectionChange}
          />
          <Hint 
            variant='text' 
            name={
              <>
                {'Jeżeli masz jakikolwiek problem z wypełnieniem tego formularza, zajrzyj do artykułu '}<Link to='/kb'>Jak wypełnić formularz umowy?</Link>
              </>
            }
          />
        </>
      );
    },
  },
  {
    title: 'Koszty', 
    slug: 'costs', 
    onlyOnEdit: true,
    Step: () => {
      const opts = useContext(NewCaseContext);
      // const data = useSelector(caseDetailsDucks.caseCostDuck.get.byCaseId(opts.data.case.id ? opts.data.case.id.value : null));
      const data = opts.data.caseCost;
      const [bulkModal, setBulkModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);
      const [selected, setSelected] = useState([]);
      const [clearSelected, setClearSelected] = useState({});
      const onSelectionChange = (rows, clicked, clearSelection) => {
        // console.log('rows:', rows.map(row => row.id));
        setSelected(rows.map(row => row.id));
        setClearSelected({run: clearSelection});
      };
      const columns=[
        { field: 'date', title: 'Data', type: 'date',
          filtering: true, searchable: true, required: true,
        },
        { field: 'amountPln', title: 'Kwota PLN', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true, // Walidacja dwóch fields. Albo jedno albo drugie albo oba wymagane.
        },
        { field: 'amountForeign', title: 'Kwota w walucie', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true,
        },
        { field: 'isBankLoaned', title: 'Kredytowany', type: 'boolean', width: 120,
          filtering: false, searchable: true,
        },
        { field: 'isAbusive', title: 'Nienależny', type: 'boolean', width: 120,
          filtering: false, searchable: true,
        },
        { field: 'description', title: 'Opis', 
          filtering: true, searchable: true,
        },
      ]
      return (
        <>
          <BulkAddModal
            modal={bulkModal}
            setBulkModal={setBulkModal}
            columns={columns}
            opts={opts}
            save={opts.saveCostBulk}
          />
          <BulkDeleteModal
            modal={deleteModal}
            setDeleteModal={setDeleteModal}
            clearSelected={clearSelected}
            selected={selected}
            save={opts.deleteCostBulk}
          />
          <Table
            data={data}
            columns={columns}
            editable={!opts.editingDisabled && {
              onRowAdd: ({tableData, ...newData}) => new Promise(resolve => {opts.saveCost(newData); resolve()}),
              onRowUpdate: ({tableData, ...newData}) => new Promise(resolve => {opts.saveCost(newData); resolve()}),
              onRowDelete: ({tableData, ...newData}) => new Promise((resolve, reject) => {opts.deleteCost(newData, resolve, reject)}),
            }}
            options={{selection: true}}
            actions={[
              { icon: (props) => <DeleteSweepOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Usuń zaznaczone', onClick: () => setDeleteModal(true), position: 'toolbar-bottom' },
              { icon: (props) => <LibraryAddOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Wklej wiersze z arkusza', onClick: () => setBulkModal(true), position: 'toolbar-bottom' },
            ]}
            onSelectionChange={onSelectionChange}
          />
          <Hint 
            variant='text' 
            name={
              <>
                {'Jeżeli masz jakikolwiek problem z wypełnieniem tego formularza, zajrzyj do artykułu '}<Link to='/kb'>Jak wypełnić formularz umowy?</Link>
              </>
            }
          />
        </>
      );
    },
  },
  {
    title: 'Zmiany oproc.', 
    slug: 'bank-rates', 
    onlyOnEdit: true,
    Step: () => {
      const opts = useContext(NewCaseContext);
      // const data = useSelector(caseDetailsDucks.caseBankRateDuck.get.byCaseId(opts.data.case.id ? opts.data.case.id.value : null));
      const data = opts.data.caseBankRate;
      const [bulkModal, setBulkModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);
      const [selected, setSelected] = useState([]);
      const [clearSelected, setClearSelected] = useState({});
      const onSelectionChange = (rows, clicked, clearSelection) => {
        // console.log('rows:', rows.map(row => row.id));
        setSelected(rows.map(row => row.id));
        setClearSelected({run: clearSelection});
      };
      const columns=[
        { field: 'date', title: 'Data', type: 'date',
          filtering: true, searchable: true, required: true,
        },
        { field: 'rate', title: 'Oprocentowanie', type: 'percentage', decimalDigits: 5, width: 160, 
          filtering: true, searchable: true, required: true,
        },
      ]
      return (
        <>
          <BulkAddModal
            modal={bulkModal}
            setBulkModal={setBulkModal}
            columns={columns}
            opts={opts}
            save={opts.saveBankRateBulk}
          />
          <BulkDeleteModal
            modal={deleteModal}
            setDeleteModal={setDeleteModal}
            clearSelected={clearSelected}
            selected={selected}
            save={opts.deleteBankRateBulk}
          />
          <Table
            data=/* {data}// */{[...data.map(row => ({...row}))]}
            columns={columns}
            editable={!opts.editingDisabled && {
              onRowAdd: ({tableData, ...newData}) => new Promise(resolve => {opts.saveBankRate(newData); resolve()}),
              onRowUpdate: ({tableData, ...newData}) => new Promise(resolve => {opts.saveBankRate(newData); resolve()}),
              onRowDelete: ({tableData, ...newData}) => new Promise((resolve, reject) => {opts.deleteBankRate(newData, resolve, reject)}),
            }}
            options={{selection: true}}
            actions={[
              { icon: (props) => <DeleteSweepOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Usuń zaznaczone', onClick: () => setDeleteModal(true), position: 'toolbar-bottom' },
              { icon: (props) => <LibraryAddOutlined {...props} />, iconProps: { style: { transform: "scale(1.3)" } }, tooltip: 'Wklej wiersze z arkusza', onClick: () => setBulkModal(true), position: 'toolbar-bottom' },
            ]}
            onSelectionChange={onSelectionChange}
          />
          <Hint 
            variant='text' 
            name={
              <>
                {'Jeżeli masz jakikolwiek problem z wypełnieniem tego formularza, zajrzyj do artykułu '}<Link to='/kb'>Jak wypełnić formularz umowy?</Link>
              </>
            }
          />
        </>
      );
    },
  },
  {
    title: 'Spłaty', 
    slug: 'payments', 
    onlyOnEdit: true,
    Step: () => {
      const opts = useContext(NewCaseContext);
      // const data = useSelector(caseDetailsDucks.casePaymentDuck.get.byCaseId(opts.data.case.id ? opts.data.case.id.value : null));
      const data = opts.data.casePayment;
      const paymentTypes = useSelector(paymentTypesDuck.get.lookupTable());
      const [bulkModal, setBulkModal] = useState(false);
      const [deleteModal, setDeleteModal] = useState(false);
      const [selected, setSelected] = useState([]);
      const [clearSelected, setClearSelected] = useState({});
      const onSelectionChange = (rows, clicked, clearSelection) => {
        // console.log('rows:', rows.map(row => row.id));
        setSelected(rows.map(row => row.id));
        setClearSelected({run: clearSelection});
      };
      const columns=[
        { field: 'date', title: 'Data', type: 'date',
          filtering: true, searchable: true, required: true,
        },
        // { field: 'transactionDate', title: 'Data transakcji', type: 'date', 
        //   filtering: true, searchable: true,
        // },
        { field: 'typeId', title: 'Typ spłaty', lookup: paymentTypes, ellipsis: true, width: 180, editWidth: 250,
          filtering: true, searchable: true, required: true,
        },
        // { field: 'installmentType', title: 'Typ raty', lookup: fieldValues.cases.installmentType,
        //   filtering: true, searchable: true,
        // },
        { field: 'amountPln', title: 'Kwota PLN', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true,
        },
        { field: 'amountForeign', title: 'Kwota w walucie', type: 'numeric', decimalDigits: 2,
          filtering: true, searchable: true, required: true,
        },
        // { field: 'vacationType', title: '(vacationType)', type: 'numeric', 
        //   filtering: true, searchable: true,
        // },
      ]
      return (
        <>
          <BulkAddModal
            modal={bulkModal}
            setBulkModal={setBulkModal}
            columns={columns}
            opts={opts}
            save={opts.savePaymentBulk}
          />
          <BulkDeleteModal
            modal={deleteModal}
            setDeleteModal={setDeleteModal}
            clearSelected={clearSelected}
            selected={selected}
            save={opts.deletePaymentBulk}
          />
          <Table
            data={data}
            columns={columns}
            editable={!opts.editingDisabled && {
              onRowAdd: ({tableData, ...newData}) => new Promise(resolve => {opts.savePayment(newData); resolve()}),
              onRowUpdate: ({tableData, ...newData}) => new Promise(resolve => {opts.savePayment(newData); resolve()}),
              onRowDelete: ({tableData, ...newData}) => new Promise((resolve, reject) => {opts.deletePayment(newData, resolve, reject)}),
            }}
            options={{selection: true}}
            actions={[
              { icon: (props) => <DeleteSweepOutlined {...props} />, iconProps: { transform: 'scale(1.35)' }, tooltip: 'Usuń zaznaczone', onClick: () => setDeleteModal(true), position: 'toolbar-bottom' },
              { icon: (props) => <LibraryAddOutlined {...props} />, iconProps: { transform: 'scale(1.35)' }, tooltip: 'Wklej wiersze z arkusza', onClick: () => setBulkModal(true), position: 'toolbar-bottom' },
            ]}
            onSelectionChange={onSelectionChange}
          />
          <Hint 
            variant='text' 
            name={
              <>
                {'Jeżeli masz jakikolwiek problem z wypełnieniem tego formularza, zajrzyj do artykułu '}<Link to='/kb'>Jak wypełnić formularz umowy?</Link>
              </>
            }
          />
        </>
      );
    },
  },
  {
    title: 'Modyfikacje obliczeń', 
    slug: 'overrides', 
    onlyOnEdit: true,
    Step: () => {
      const opts = useContext(NewCaseContext);
      const sections = [
        { title: 'Modyfikacja (zmiana) ilości rat', hintTitle: 'ilość rat', data: opts.data.caseInstallmentAmount, save: opts.saveInstallmentAmount, del: opts.deleteInstallmentAmount,
          columns: [
            { field: 'periodIndex', title: 'Numer okresu', type: 'numeric', width: 150, required: true},
            { field: 'change', title: 'Zmiana', type: 'numeric', width: 250, required: true},
          ],
        },
        { title: 'Modyfikacja (nadpisanie) metody naliczania spłaty rat', hintTitle: 'metoda naliczania spłaty', data: opts.data.caseInstallmentOverride, save: opts.saveInstallmentOverride, del: opts.deleteInstallmentOverride,
          columns: [
            { field: 'periodIndex', title: 'Numer okresu', type: 'text', width: 150, required: true},
            { field: 'amountOverrideType', title: 'Typ', width: 250, 
              initialEditValue: 'sameAsBank', lookup: fieldValues.installmentOverride.amountOverrideTypes, required: true,
            },
            { field: 'amount', title: 'Kwota', type: 'numeric', width: 250},
          ],
        },
        { title: 'Zmiana dnia spłaty rat', hintTitle: 'dzień spłaty', data: opts.data.caseInstallmentDay, save: opts.saveInstallmentDay, del: opts.deleteInstallmentDay,
          columns: [
            { field: 'periodIndex', title: 'Numer okresu', type: 'numeric', width: 150, required: true },
            { field: 'day', title: 'Dzień spłaty', type: 'numeric', width: 250, required: true },
          ],
        },
        { title: 'Zmiana rodzaju rat (równe/malejące)', hintTitle: 'rodzaj rat', data: opts.data.caseInstallmentType, save: opts.saveInstallmentType, del: opts.deleteInstallmentType,
          columns: [
            { field: 'periodIndex', title: 'Numer okresu', type: 'numeric', width: 150, required: true},
            { field: 'type', title: 'Typ wpłaty', width: 250, lookup: fieldValues.cases.installmentType, required: true}, 
          ],
        },
        { title: 'Dodawanie okresów karencji i wakacji kredytowych', hintTitle: 'wakacje kredytowe', data: opts.data.casePaymentHolidays, save: opts.savePaymentHolidays, del: opts.deletePaymentHolidays,
          columns: [
            { field: 'periodStart', title: 'Numer okresu (początek)', type: 'numeric', width: 150, required: true},
            { field: 'periodEnd', title: 'Numer okresu (koniec)', type: 'numeric', width: 150, required: true},
            { field: 'type', title: 'Typ', lookup: fieldValues.paymentHolidays.types, width: 350, required: true}, 
          ],
        },
      ];

      return sections.map(({title, hintTitle, data, columns, save, del}) => (
        <Box pb={4} width='100%'>
          <Box mb={2}>
            <Typography component='h4' variant='h6' align='center'>{title} <Hint name={`Edycja umowy / Modyfikacje / ${hintTitle || title}`} /></Typography>
          </Box>
          <Table
            className='auto-height'
            data={data}
            columns={columns}
            editable={!opts.editingDisabled && {
              onRowAdd: ({tableData, ...newData}) => new Promise(resolve => {save(newData); resolve()}),
              onRowUpdate: ({tableData, ...newData}) => new Promise(resolve => {save(newData); resolve()}),
              onRowDelete: ({tableData, ...newData}) => new Promise((resolve, reject) => {del(newData, resolve, reject)}),
            }}
            options={{filtering: false, paging: false}}
          />
        </Box>
      ));
    },
  },
];
