import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import PropTypes from 'prop-types';

import {
  Grid,
} from '@material-ui/core';
import { 
  CallOutlined,
  RoomOutlined,
  MailOutlineOutlined,
  SendOutlined
} from '@material-ui/icons';

import ownerDuck from 'redux/ducks/owner';

import PageTitle from 'components/basic/PageTitle';
import Form from 'components/basic/Form';
import StatsCards from 'components/basic/StatsCards';

import styles from './Contact.style';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { phrasesDuck } from 'redux/ducks/dictionaries';
import { fieldValues, defaultPhrases } from 'dict';
import { useSnackbar } from 'notistack';

export default function Contact() {
  const classes = styles();
  const dispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const ownerDetails = useSelector(ownerDuck.get.all());
  const currentUser = useSelector(currentUserDuck.get.data());

  // const apiData = useSelector(phrasesDuck.get.filtered({type: 'contact-subject'}));
  // const subjects = Object.fromEntries([
  //   ...apiData,
  //   ...Object.entries(defaultPhrases['contact-subject']).map(([key, value]) => (!apiData.some(row => row.title === key) && {title: key, content: value, active: true})).filter(Boolean),
  // ].filter(row => row.active).map(row => ([row.content, row.content])));

  const subjects = useSelector(phrasesDuck.get.typeLookupTableWithDefaults('contact-subject'));
  const contactInfo = useSelector(phrasesDuck.get.typeLookupTableWithDefaults('contact-info'));

  const [contactFormSelectError, setContactFormSelectError] = useState(false);
  const [contactFormDisabled, setContactFormDisabled] = useState(false);
  const [contactFormClear, setContactFormClear] = useState(0);
  const [fetched, setFetched] = useState(false);

  // useEffect(() => {
  //   if(!apiData || !apiData.length){
  //     dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-subject'}}));
  //     // dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-subject', active: true}}));
  //   }
  // }, [apiData])
  useEffect(() => {
    if(!fetched){
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-subject', active: 'all'}}));
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-info', active: 'all'}}));
      setFetched(true);
    }
  }, [fetched])

  return (
    <div className={classes.root}>
      <PageTitle title='Kontakt'/>
      <Grid container justify='space-between' alignItems='stretch' spacing={4}>
        <Grid item xs={12} lg={4}>
          <StatsCards
            titleVariant='h5'
            titleComponent='h3'
            textComponent='p'
            textVariant='body2'
            cards={[
              { title: 'Dane firmy', value: contactInfo.address, icon: RoomOutlined, variant: 'vertical' },
              { title: 'Telefon', value: contactInfo.phone, icon: CallOutlined, variant: 'vertical' },
              { title: 'E-mail', value: contactInfo.email, icon: MailOutlineOutlined, variant: 'vertical' },
            ]}            
            /> 
        </Grid>
        <Grid item xs={12} lg={8}>
          <Form 
            title='Masz pytanie? Chętnie odpowiemy.'
            disabled={contactFormDisabled}
            clear={contactFormClear}
            fields={[
              { label: 'Imię i nazwisko', name: '', defaultValue: currentUser.fullName, disabled: true, half: true },
              { label: 'E-mail', name: '', defaultValue: currentUser.email, disabled: true, half: true },
              { label: 'W czym możemy Ci pomóc?', name: 'subject', required: true, defaultValue: '', options: subjects,
                error: contactFormSelectError && 'To pole jest wymagane', onChange: () => setContactFormSelectError(false),
              },
              { label: 'Wiadomość', name: 'message', defaultValue: '', required: true, multiline: true, rows: '4' },
            ]} 
            buttons={[
              { label: 'Wyślij wiadomość', icon: SendOutlined, type: 'submit' }
            ]}
            onSubmit={values => {
              if(!values.subject){
                setContactFormSelectError(true);
                return;
              }
              setContactFormDisabled(true);
              currentUserDuck.api.contact({
                data: Object.fromEntries(Object.entries(values).filter(([key, value]) => key !== '')), 
                onAdded: resData => {
                  setContactFormDisabled(false);
                  setContactFormClear(Date.now());
                  enqueueSnackbar('Wiadomość wysłana', {variant: 'success'});
                }, 
                onError: err => {
                  setContactFormDisabled(false);
                  enqueueSnackbar('Nie udało się wysłać wiadomości', {variant: 'warning'});
                },
              })
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
