// import Axios from 'axios';
import {SingleRecordDuck} from 'redux/lib/Duck';

export const ownerDuck = new SingleRecordDuck('owner', ({endpoint}) => `/owner/${endpoint}`);
const duck = ownerDuck;

duck.get.all = () => ({ owner }) => owner.data;
duck.get.name = () => ({ owner }) => owner.data.name;

duck.action.changeType = {
  reducer: (owner, payload) => ({
    ...owner,
    data: {
      ...owner.data,
      type: payload,
    },
  })
};

duck.action.loading = {
  reducer: (owner) => ({
    ...owner,
    meta: {
      ...owner.meta,
      loading: true,
      errors: [],
    }
  })
};

duck.action.loaded = {
  reducer: (owner) => ({
    ...owner,
    meta: {
      ...owner.meta,
      loading: false,
    }
  })
};

duck.action.error = {
  payload: arg => ({message: arg}),
  reducer: (owner, payload) => ({
    ...owner,
    meta: {
      ...owner.meta,
      loading: false,
      errors: [
        ...owner.meta.errors,
        payload.message,
      ],
    },
  })
};

duck.thunk.fetch = () => (
  (dispatch, getState) => {
    if (getState().owner.meta.loading) return; 

    dispatch(duck.action.loading());
    setTimeout(e => {
      dispatch(duck.action.update([]));
      dispatch(duck.action.loaded());
    }, 1500);


    // if (getState().user.data.length === 0) {
      // Axios
      //   .get(`${api.url}/${api.user}`)
      //   .then(res => {
      //     dispatch(dux.action.loaded(res.data));
      //   })
      //   .catch(err => {
      //     dispatch(dux.action.error(err.message || true));
      //   });
    // }
  }
);

duck.thunk.fetch = () => (
  (dispatch, getState) => {
    if (getState().owner.meta.loading) return; 

    dispatch(duck.action.loading());
    const content = localStorage.userType;
    if(content){
      dispatch(duck.action.changeType(content));
    }
    dispatch(duck.action.loaded());
  }
);

duck.thunk.changeType = (type) => (
  ((dispatch, getState) => {

    if (getState().owner.meta.loading) return; 

    dispatch(duck.action.loading());
    localStorage.userType = type;
    dispatch(duck.action.changeType(type));
    dispatch(duck.action.loaded());
  })
);

export default duck;