import React, { useReducer, useState } from 'react';

import {
  Grid, Modal, Box, Typography,
} from '@material-ui/core';
import {
  LibraryBooksOutlined,
  Filter1Outlined,
  RestoreOutlined,
} from '@material-ui/icons';

import Dropdown from 'components/basic/Dropdown';
import PageTitle from 'components/basic/PageTitle';
import TrashedCasesList from 'components/feature/TrashedCasesList';
import TrashedReportsList from 'components/feature/TrashedReportsList';
import TabsWithPanels from 'components/basic/TabsWithPanels';

import styles from './TrashManager.style';
import { reportsDuck } from 'redux/ducks/reports';
import ModalWrapper from 'components/basic/ModalWrapper';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { casesDuck } from 'redux/ducks/cases';

const tabs = [
  { id: 'cases', label: 'Umowy',
    icon: LibraryBooksOutlined,
    panel: TrashedCasesList,
  },
  { id: 'reports', label: 'Raporty',
    icon: Filter1Outlined,
    panel: TrashedReportsList,
  },
];

export default function TrashManager() {
  const classes = styles();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const [bulk, updateBulk] = useReducer((s,a)=>({...s,...a,rows:{...s.rows,...a.rows}}), {
    rows: {}, 
    activeTab: 0,
  });

  const [modal, setModal] = useState(false);

  return (
    <div className={classes.root}>
      <PageTitle title='Kosz'/> 
      <Grid container>
        <TabsWithPanels
          variant='small'
          tabs={tabs}
          panelProps={{
            onSelectionChange: rows =>  updateBulk({rows: {[bulk.activeTab]: rows}}),
          }}
          onChange={activeTab => updateBulk({activeTab})}
          actions={<BulkAction bulk={bulk} updateModal={setModal} />}
        />
      </Grid>  
      <BulkModal
        modal={modal}
        setModal={setModal}
        dispatch={dispatch}
        enqueueSnackbar={enqueueSnackbar}
      />
    </div>
  );
}

const BulkAction = ({bulk, updateModal}) => (
  <Dropdown
    variant='label'
    title='Działania masowe:'
    disabled={!bulk.rows[bulk.activeTab] || !bulk.rows[bulk.activeTab].length}
    value='label'
    handleChange={value => updateModal({mode: value, tab: tabs[bulk.activeTab].id, rows: bulk.rows[bulk.activeTab]})}
    options={[
      { label: !bulk.rows[bulk.activeTab] || !bulk.rows[bulk.activeTab].length ? 'Zaznacz wiersze' : 'Wybierz', value: 'label', disabled: true },
      { label: 'Przywróć', value: 'restore' },
      { label: 'Usuń na zawsze', value: 'delete' },
    ]}
  /> 
);

const BulkModal = ({modal, setModal, dispatch, enqueueSnackbar}) => modal && (
  <Modal
    open={modal}
    onClose={() => setModal(false)}
  >
    <ModalWrapper 
      title={`Czy chcesz ${modal.mode === 'restore' ? 'przywrócić' : 'usunąć'} poniższe ${modal.tab === 'cases' ? 'umowy' : 'raporty'}?`}
      disabled={modal.sending}
      buttons={[
        { label: 'Anuluj', color: 'primary', onClick: () => setModal(false) },
        { label: `${modal.mode === 'restore' ? 'Przywróć' : 'Usuń'} ${modal.tab === 'cases' ? 'umowy' : 'raporty'}`, color: 'secondary', icon: RestoreOutlined, 
          onClick: () => {
            setModal({...modal, sending: true})
            dispatch((modal.tab === 'cases' ? casesDuck : reportsDuck).thunk.fieldSaveSyncMulti({
              data: modal.rows.map((row, i) => ({id: row.id, caseId: row.caseId, status: modal.mode === 'restore' ? 'active' : 'erased'})), 
              validate: false, 
              callback: result => {
                // console.log('result:', result)
                const errors = result.map((rowCall, index) => {
                  if(rowCall.status === 'fulfilled'){
                    return false;
                  } else {
                    return {row: modal.rows[index], msg: rowCall.reason};
                  }
                }).filter(Boolean);

                if(errors.length === 0){
                  enqueueSnackbar(`Wybrane ${modal.tab === 'cases' ? 'umowy' : 'raporty'} zostały ${modal.mode === 'restore' ? 'przywrócone' : 'usunięte'}`, {variant: 'success'});
                  setModal(false);
                } else {
                  enqueueSnackbar((
                    <Box>
                      <Typography component='p' variant='body2'>
                        {`${modal.mode === 'restore' ? 'Przywrócone' : 'Usunięte'} ${modal.tab === 'cases' ? 'umowy' : 'raporty'}: ${modal.rows.length - errors.length}`}
                      </Typography>
                      <Typography component='p' variant='body2'>
                        <strong>{`Wystąpiły błędy w ${errors.length} pozycjach:`}</strong>
                      </Typography>
                      <Typography component='p' variant='body2'>
                        {modal.tab === 'cases' ? (
                          errors.map(err => err.row.name).join(', ')
                        ): (
                          errors.map(err => err.row.typeName+' dla '+err.row.caseName).join(', ')
                        )}
                      </Typography>
                    </Box>
                  ), {variant: 'warning', persist: true});
                  setModal({...modal, rows: errors.map(err => err.row), sending: false});
                }
              },
            })); 
          }, 
        },
      ]}  
    >
      <Box mb={2}>
        <Typography component='p' variant='body2'>
          {modal.tab === 'cases' ? (
            modal.rows.map(row => row.name).join(', ')
          ) : (
            modal.rows.map(row => row.typeName+' dla '+row.caseName).join(', ')
          )}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography component='p' variant='body2'>
          {`Przywrócone pozycje ponownie pojawią się na liście ${modal.tab === 'cases' ? 'umów' : 'raportów'}.`}
        </Typography>
      </Box>
    </ModalWrapper>
  </Modal>
);
