export const getIsCaseFromCalculationWidget = (maybeCase, strict = false) => {
  try {
    if (!maybeCase) {
      return false;
    }

    const c = maybeCase;

    if (!strict) {
      return c.agreementNo.includes("calculation-widget");
    }

    return (
      c.agreementNo.includes("calculation-widget") &&
      (c.ownerUser?.login.includes("@example.org") ||
        c.ownerUser?.login.includes("guest")) &&
      c.ownerUser?.firstName?.toLowerCase() === "gość" &&
      c.ownerUser?.lastName?.toLowerCase() === "użytkownik"
    );
  } catch {
    return false;
  }
};
