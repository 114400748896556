import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    '& .MuiSvgIcon-root': {
      width: '1.2rem',
      height: '1.2rem',
    },
    '&.variant-form': {
      marginTop: 11,
      marginRight: theme.spacing(1),
    },
  },
  hintText: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.gray,
    fontSize: '0.8rem',
  },
  icon: {
    color: theme.palette.common.lightGray,
  }
}), {
  name: 'Hint', 
  index: 1,
});
