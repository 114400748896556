import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiInputBase-inputMultiline': {
      resize: 'vertical',
    },
  }
}), {
  name: 'Contact', 
  index: 1,
});
