import { parseISO, isValid as fnsIsValid } from "date-fns";
import { unFormatValue } from "material-table";

export const isDateCorrect = (str) => {
  if (str) {
    const parsedStr = parseISO(unFormatValue(str, { type: "date" }));
    const parsedStrReversed = parseISO(
      unFormatValue(str.split(".").reverse().join("."), { type: "date" })
    );
    const isStrNotMasked = str.search("_") === -1;

    if (str.constructor.name === "Date" && str.toString() !== "Invalid Date") {
      return true;
    }

    if (
      str.constructor.name === "String" &&
      isStrNotMasked &&
      (fnsIsValid(parsedStr) || fnsIsValid(parsedStrReversed))
    ) {
      return true;
    }
  }

  return false;
};
