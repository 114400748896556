import React from 'react';
import {
  Box
} from '@material-ui/core';

import { NavLink } from 'react-router-dom';
import styles from './LargeLogo.style';
import logo from '../../../assets/images/logo-color.svg'

function LargeLogo() {
  const classes = styles();

  return (
    <Box className={classes.root} alignSelf="flex-start">
      <NavLink to="/" exact>
        <img src={logo} alt="ProJuste" />
      </NavLink>
    </Box>
  );
}

export default LargeLogo;