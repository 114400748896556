import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
  Grid,
  Box,
  Typography,
  Button,
  Tooltip
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import Hint from 'components/basic/Hint';
import ButtonWrapper from 'components/basic/ButtonWrapper';

import styles from './PageTitle.style';

function PageTitle({justifyContent = 'space-between', centered = false, title, children, info, variant, hintVariant, hintName, buttons = {}, ...props}) {
  const classes = styles();
  return (
    <Grid container justify={centered ? 'center' : 'flex-start'}>
      <Grid item xs={12} lg={centered ? 8 : 12}>
        <Box display='flex' className={clsx(classes.root, variant && `variant-${variant}`)} justifyContent={justifyContent} {...props}>
          <Box className={classes.titleWrapper}>
            <Typography component='h2' variant='h5'>{title} {hintName && <Hint variant={hintVariant} name={hintName}/>}</Typography>
            <Box className={classes.titleInfo}>{info}</Box>
          </Box>
          <Box className={classes.mainTitleActions}>
            <ButtonWrapper>
              {!!buttons.length && buttons.map(button => button.tooltip ? (
                <Tooltip title={button.tooltip}>
                  <div>
                    <Button
                      key={button.label}
                      disableElevation
                      disabled={button.disabled}
                      variant={button.variant ? button.variant : 'contained'}
                      color={button.color ? button.color : 'secondary'}
                      className={classes.button}
                      startIcon={<button.icon />}
                      component={button.target ? Link : 'button'}
                      target={button.newTab ? '_blank' : ''}
                      to={button.target || null}
                      onClick={button.onClick}
                    >
                      {button.hideLabel ? null : button.label}
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  key={button.label}
                  disableElevation
                  disabled={button.disabled}
                  variant={button.variant ? button.variant : 'contained'}
                  color={button.color ? button.color : 'secondary'}
                  className={classes.button}
                  startIcon={<button.icon />}
                  component={button.target ? Link : 'button'}
                  target={button.newTab ? '_blank' : ''}
                  to={button.target || null}
                  onClick={button.onClick}
                >
                  {button.hideLabel ? null : button.label}
                </Button>
              ))}
            </ButtonWrapper>
            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

PageTitle.propTypes = {
  children: PropTypes.node,
};

export default PageTitle;
