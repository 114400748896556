import React from 'react';
import PropTypes from 'prop-types';

import styles from './NotFound.style';

import Typography from '@material-ui/core/Typography';

const NotFound = ({children}) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Typography className={classes.pageTitle} variant='h2'>Nie znaleziono strony o takim adresie.</Typography>
      <Typography variant='h4' component='p'>Skorzystaj z menu, aby przejść na inną podstronę.</Typography>
    </div>
  );
};

NotFound.propTypes = {
  children: PropTypes.node,
};

export default NotFound;