import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  tabs: {
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start",
    },
    '& .MuiTabs-flexContainer': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    '& .MuiTabs-root': {
      minHeight: 40,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    '& .MuiTab-wrapper': {
      flexDirection: "row",
      fontSize: "0.85rem",
    },
    '& .MuiSvgIcon-root': {
      width: "1.2rem",
      height: "1.2rem",
      marginRight: theme.spacing(1),
    },
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent",
    },
    '& .MuiTab-labelIcon': {
      position: "relative",
      minHeight: 40,
    },
  },
  tabsContent: {
    '& .MuiBox-root': {
      padding: 0,
    },
  },
}), {
  name: 'Companies', 
  index: 1,
});


