// export const reportTypes = {
//     r1: 'raport wstępny',
//     r2: 'raport szacunkowy',
//     r3: 'raport pełny',
// };

import {
  set as dateSet,
  subMonths,
  startOfMonth, 
  startOfQuarter, 
  startOfYear, 
  endOfMonth, 
  endOfQuarter, 
  endOfYear,
} from 'date-fns';

// export const statusTypes = {
//   active: 'Aktywny',
//   pending_activation: 'Oczekujący',
//   pending_admin_approval: 'Oczekujący',
//   deactivated: 'Nieaktywny',
//   erased: 'Usunięty',
// };

// export const issueStatusTypes = {
//   issued: 'Wystawiono',
//   unissued: 'Nie wystawiono',
// };

// export const paymentStatusTypes = {
//   paid: 'Zapłacono',
//   unpaid: 'Nie zapłacono',
// };

// export const roleTypes = { // ordered in priority order desc
//   admin: 'Admin',
//   supermanager: 'Super Manager',
//   manager: 'Manager',
//   regular: 'Pracownik',
//   finance: 'Finanse',
// };

// export const companyNames = {
//   1: 'Pro Bono Consultancy',
//   2: 'Sullivan & Rothman',
//   3: 'Smith Corporate Law',
// };

const now = new Date();

export const dates = {
  values: {
    now: now,
    thisYear: now.getFullYear(),
    thisMonth: now.getMonth(),
  },
  relative: {
    anytime: {},
    lastMonth: {start: startOfMonth(subMonths(now, 1)), end: endOfMonth(subMonths(now, 1))},
    currentMonth: {start: startOfMonth(now), end: endOfMonth(now)},
    currentQuarter: {start: startOfQuarter(now), end: endOfQuarter(now)},
    currentYear: {start: startOfYear(now), end: endOfYear(now)},
    givenYear: year => {
      if(isNaN(year)) return {}; 
      const yearDate = dateSet(now, {year, month: 0, date: 1}); 
      return {start: startOfYear(yearDate), end: endOfYear(yearDate)};
    },
    givenMonth: ({month, year}) => {
      if(isNaN(year)) return {}; 
      if(isNaN(month)) return dates.relative.givenYear(year); 
      const monthDate = dateSet(now, {year, month, date: 1}); 
      return {start: startOfMonth(monthDate), end: endOfMonth(monthDate)};
    },
  },
};

export const fieldValues = {
  users: {
    roles: { // ordered in priority order desc
      admin: 'Admin',
      supermanager: 'Super Manager',
      manager: 'Manager',
      regular: 'Pracownik',
      // finance: 'Finanse',
    },
    status: {
      active: 'Aktywny',
      pending_activation: 'Nowy',
      pending_admin_approval: 'Oczekujący',
      deactivated: 'Nieaktywny',
      erased: 'Usunięty',
    },
  },
  companies: {
    paymentMethods: {
      transfer: 'Przelew',
      cash: 'Gotówka',
    }
  },
  cases: {
    currency: {
      CHF: 'CHF',
      PLN: 'PLN',
      USD: 'USD',
      EUR: 'EUR',
      JPY: 'JPY',  
    },
    liborType: {
      '1M': '1M',
      '3M': '3M',
      '6M': '6M',
      '12M': '12M',  
    },
    status: {
      active: 'Aktywny',
      pending_activation: 'Oczekujący',
      pending_admin_approval: 'Oczekujący',
      deactivated: 'Nieaktywny',
      erased: 'Usunięty',
    },
    installmentType: {
      equal: 'równe',
      decreasing: 'malejące',
    },  
    paymentType: {
      'Kapitał': 'Kapitał', // Principal
      'Odsetki': 'Odsetki', // Interest
      'Nadpłata': 'Nadpłata', // Overpayment
      'Odsetki karne': 'Odsetki karne', // PenaltyInterest
      'Kapitalizacja odsetek': 'Kapitalizacja odsetek', // CapitalizationOfInterest
    },
    paymentDayNo: {
      '1': '1',
      '2': '2',
      '3': '3',
      '4': '4',
      '5': '5',
      '6': '6',
      '7': '7',
      '8': '8',
      '9': '9',
      '10': '10',
      '11': '11',
      '12': '12',
      '13': '13',
      '14': '14',
      '15': '15',
      '16': '16',
      '17': '17',
      '18': '18',
      '19': '19',
      '20': '20',
      '21': '21',
      '22': '22',
      '23': '23',
      '24': '24',
      '25': '25',
      '26': '26',
      '27': '27',
      '28': '28',
      '29': '29',
      '30': '30',
      '31': '31',
      '-1': 'ostatni dzień miesiąca',
    },
  },
  installmentOverride: {
    amountOverrideTypes: {
      sameAsBank: 'Jak bank',
      fixedPayment: 'Stała wpłata',
      fixedPrincipal: 'Stały kapitał',
    },
  },
  paymentHolidays: {
    types: {
      NoPaymentCapitalize: 'odsetki doliczane do salda',
      NoPaymentAccInterest: 'odsetki ściągane po wakacjach',
      InterestOnly: 'spłata tylko odsetek',
    },
  },
  reports: {
    versions: {
      r1: 'raport r1',
      r2: 'raport r2',
      cases: 'raport pełny',
      estimation: 'raport szacunkowy'
    },
  },
  receipts: {
    issueStatus: {
      issued: 'Wystawiono',
      unissued: 'Nie wystawiono',
    },
    paymentStatus: {
      paid: 'Zapłacono',
      unpaid: 'Nie zapłacono',
    },
  },
  exchangeRates: {
    source: {
      nbp: 'NBP',
      bank: 'Bank',
    },
    type: {
      avg: 'AVG',
      bid: 'BID',
      ask: 'ASK',  
    },
  },
  holidays: {
    type: {
      public: 'państwowe',
      bank: 'bankowe',
      school: 'szkolne',
      optional: 'opcjonalne',
      observance: 'obchodzone',  
    },
  },
  ibors: {
    type: {
      CHF_LIBOR1M: 'CHF_LIBOR1M',
      CHF_LIBOR3M: 'CHF_LIBOR3M',
      CHF_LIBOR6M: 'CHF_LIBOR6M',
      CHF_LIBOR12M: 'CHF_LIBOR12M',
      EUR_LIBOR1M: 'EUR_LIBOR1M',
      EUR_LIBOR3M: 'EUR_LIBOR3M',
      EUR_LIBOR6M: 'EUR_LIBOR6M',
      EUR_LIBOR12M: 'EUR_LIBOR12M',
      GBP_LIBOR1M: 'GBP_LIBOR1M',
      GBP_LIBOR3M: 'GBP_LIBOR3M',
      GBP_LIBOR6M: 'GBP_LIBOR6M',
      GBP_LIBOR12M: 'GBP_LIBOR12M',
      JPY_LIBOR1M: 'JPY_LIBOR1M',
      JPY_LIBOR3M: 'JPY_LIBOR3M',
      JPY_LIBOR6M: 'JPY_LIBOR6M',
      JPY_LIBOR12M: 'JPY_LIBOR12M',
      USD_LIBOR1M: 'USD_LIBOR1M',
      USD_LIBOR3M: 'USD_LIBOR3M',
      USD_LIBOR6M: 'USD_LIBOR6M',
      USD_LIBOR12M: 'USD_LIBOR12M',
      WIBOR3M: 'WIBOR3M',
      WIBOR6M: 'WIBOR6M',
      EURIBOR3M: 'EURIBOR3M',
      EURIBOR6M: 'EURIBOR6M',
    },
  },
  contact: {
    subjects: {
      userSupport: 'Pomoc w obsłudze panelu', 
      bugReport: 'Zgłoszenie błędu', 
      others: 'Inne',
    },
  },
};

export const defaultPhrases = {
  'contact-subject': {
    userSupport: 'Pomoc w obsłudze panelu', 
    bugReport: 'Zgłoszenie błędu', 
    others: 'Inne',
  },
  'contact-info': {
    address: 'Okopowa 125\n00-000 Wrocław',
    phone: '123-456-789',
    email: 'contact@pmbt.com',
  },
  'tooltip': {
    'Edycja umowy / Numer umowy': 'Wpisz numer umowy',
    'Edycja umowy / Sygnatura': 'Wpisz sygnaturę',
    'Edycja umowy / Osoba': 'Wpisz osobę',
    'Edycja umowy / Bank podpisania umowy': 'Wybierz bank podpisania umowy',
    'Edycja umowy / Obecny bank': 'Wybierz obecny bank',
    'Edycja umowy / Data podpisania umowy': 'Wpisz datę podpisania umowy w formacie dd.mm.rrrr',
    'Edycja umowy / Data sporządzenia umowy': 'Wpisz datę sporządzenia umowy w formacie dd.mm.rrrr',
    'Edycja umowy / Dzień, na jaki są wykonywane wyliczenia': 'Wpisz datę wykonywania wyliczeń w formacie dd.mm.rrrr',
    'Edycja umowy / Dzień spłaty raty': 'Wybierz dzień spłaty raty',
    'Edycja umowy / Opis umowy': 'Wpisz opis umowy',
    'Edycja umowy / Liczba rat': 'Wpisz liczbę rat',
    'Edycja umowy / Raty równe/malejące': 'Wybierz rodzaj rat',
    'Edycja umowy / Całkowite startowe oproc.': 'Wpisz całkowite startowe oproc.',
    'Edycja umowy / Marża': 'Wpisz marżę',
    'Edycja umowy / Podwyższenie do czasu wpisu do hipoteki': 'Wpisz podwyższenie do czasu wpisu do hipoteki',
    'Edycja umowy / Waluta': 'Wybierz walutę',
    'Edycja umowy / Typ Liboru': 'Wybierz typ Liboru',
    'Edycja umowy / Modyfikacje / ilość rat':  `Pozwala na zmianę ilości rat na inną niż wynikającą z umowy. Wartości zmiany ilości rat mogą być dodatnie i ujemne.
                                                Zmiany należy wprowadzać poprzez określenie, od którego okresu rozliczeniowego o ile ma się zmienić ilość rat. 
                                                
                                                Przykładowo wpis: Numer okresu = 7, Zmiana = -2 oznacza, że od siódmego okresu rozliczeniowego ilość rat dla kredytu zmieni się np. z 360 na 358.
                                                Natomiast kolejny wpis: Numer okresu = 15, Zmiana = 22 oznacza, że ilość rat od okresu piętnastego zmieni się z 358 na 380 (ponieważ wcześniej została zmodyfikowana o -2).
                                                `,
    'Edycja umowy / Modyfikacje / metoda naliczania spłaty': `Pozwala zmodyfikować sposób naliczania rat dla wybranego okresu rozliczeniowego. Wartości należy podawać w kwocie dla waluty kredytu.
                                                              
                                                              **Dostępne opcje**:
                                                              “Jak bank” - system przyjmuje wartość wpłat z zaświadczenia bankowego 1 do 1.
                                                              “Stała wpłata” - system przyjmie określoną wartość spłaty całej raty w CHF (pole Kwota)
                                                              “Stały kapitał” - system przyjmie określoną wartość części kapitałowej w CHF(pole Kwota)
                                                              `,
    'Edycja umowy / Modyfikacje / dzień spłaty': `Pozwala na modyfikację dnia w którym jest spłacana rata. Zmiana obowiązuje od okresu dla którego została wpisana.
                                                  
                                                  Przykładowo wpisanie: <Numer okresu = 15, Dzień spłaty = 12> oznacza, że od 15 raty dniem spłaty kredytu będzie 12 dzień miesiąca.
                                                  `,
    'Edycja umowy / Modyfikacje / rodzaj rat': `Pozwala na zmianę rodzaju naliczania rat na malejące lub równe. Zmiana obowiązuje od wskazanego okresu rozliczeniowego.`,
    'Edycja umowy / Modyfikacje / wakacje kredytowe':  `Pozwala określić zakres dla jakich okresów rozliczeniowych obowiązywały jakie wakacje kredytowe. Można określić zakres od którego do którego okresu rozliczeniowego obowiązuje wprowadzona karencja lub wakacje kredytowe.
                                                        
                                                        Są dostępne 3 rodzaje karencji/wakacji:
                                                        **“odsetki doliczane do salda”** - cała rata wynosi zero, odsetki za okres karencji są doliczane do salda kredytu
                                                        **“ odsetki ściągane po wakacjach”** - cała rata wynosi zero, odsetki są pobierane z wpłat następujących po okresie karencji
                                                        **“spłata tylko odsetek”** - kapitał wynosi zero, są pobierane same odsetki
                                                        `,
  },
};

export const fieldLabels = {
  "accountsNumber": "Liczba dostępów",
  "active": "Aktywny",
  "addressBuildingNo": "Numer domu/lokalu",
  "addressCity": "Miasto",
  "addressPostalCode": "Kod pocztowy",
  "addressStreetName": "Ulica",
  "company.addressBuildingNo": "Numer domu/lokalu",
  "company.addressCity": "Miasto",
  "company.addressPostalCode": "Kod pocztowy",
  "company.addressStreetName": "Ulica",
  "agreementNo": "Numer umowy", // Nr umowy || Numer i data
  "alertsEmail": "E-mail do monitów",
  "allAssistancePrice": "Cena przekazanych umów",
  "allReportsPrice": "Cena za raporty",
  "amount": "Kwota", // Suma za miesiąc
  "amountForeign": "Kwota w walucie",
  "amountOverrideType": "Typ",
  "amountPln": "Kwota PLN",
  "assistanceNumber": "Liczba przekazanych umów",
  "assistancePrice": "Cena za przekazanie umowy",
  "bankAccountNumber": "Numer konta",
  "bankBalanceForeign": "CHF Bank",
  "bankCollectedInterestForeign": "Odsetki",
  "interestForeign": "Odsetki",
  "bankCollectedOverpaymentForeign": "Bank Nadpłacanie kredytu",
  "bankCollectedOverpaymentPln": "Nadpłacanie kredytu",
  "bankCollectedPln": "Bank Pobrał",
  "bankCollectedPrincipalForeign": "Kapitał",
  "principalForeign": "Kapitał",
  "bankId": "Bank", // Bank podpisania umowy
  "bankName": "Bank",
  "bankRate": "Stopa proc. %", // Oproc. użyte w kalkulacji
  "bankRateReversed": "Oproc. na podstawie odsetek",
  "calculationDate": "Dzień, na jaki są wykonywane wyliczenia",
  "caseName": "Sygnatura", // Sygnatura umowy
  "casePerson": "Klient",
  "casesCount": "Liczba spraw",
  "caseSettlementSpreadRefund": "Kurs średni NBP",
  "caseSettlementWiborInterestRate": "WIBOR + PLN",
  "knf": "KNF",
  "category": "Kategoria",
  "categoryId": "Kategoria",
  "change": "Zmiana",
  "ckkRrso": "CKK + RRSO",
  "companyName": "Firma",
  "contactEmail": "E-mail firmowy",
  "content": "Treść", // Treść artykułu  || Treść podpowiedzi || Treść tytułu
  "createdAt": "Data utworzenia", // Timestamp || Data utworzenia || Data dołączenia || Data wprowadz.
  "currency": "Waluta",
  "currencyDate": "Data zdarzenia",
  "currentBankId": "Obecny bank",
  "currentMonthAmount": "Suma za bież. miesiąc",
  "customText": "Wiadomość dla odbiorcy",
  "date": "Data", // Data raty
  "day": "Dzień spłaty",
  "days": "# dni dla odsetek",
  "defaultContent": "Treść domyślna",
  "deletedAt": "Data usunięcia",
  "description": "Opis", // Opis własny || Opcjonalny opis
  "email": "E-mail", // E-mail managera
  "user.email": "E-mail",
  "firstName": "Imię", // Imię managera
  "user.firstName": "Imię", // Imię managera
  "fullName": "Imię i nazwisko",
  "iconUrl": "Podgląd ikony", // Adres ikony
  "index": "Lp.",
  "installmentAmount": "Liczba rat",
  "installmentType": "Rodzaj rat", // Raty równe/malejące
  "interestPln": "Odsetki",
  "interestRate": "LIBOR + marża",
  "invalidCase": "Nieważność umowy",
  "isAbusive": "Nienależny",
  "isBankLoaned": "Kredytowany",
  "issueStatus": "Status faktury",
  "key": "Klucz",
  "lastMonthAmount": "Suma za poprz. miesiąc",
  "lastName": "Nazwisko", // Nazwisko managera
  "user.lastName": "Nazwisko", // Nazwisko managera
  "liborType": "Typ Liboru",
  "login": "Twój e-mail",
  "margin": "Marża",
  "maxAccounts": "Liczba dostępów",
  "message": "Wiadomość",
  "month": "Miesiąc", // Okres rozliczeniowy
  "name": "Sygnatura/Nazwa", // Nazwa firmy || Nazwa || Nr faktury
  "company.name": "Nazwa firmy",
  "newPassword": "Nowe hasło",
  "oldPassword": "Stare hasło",
  "optionalNotes": "Opis umowy", // 
  "ownerCompany.name": "Klient",
  "ownerUser.firstName": "Użytkownik",
  "ownerUserId": "Użytkownik", // Kto usunął
  "password": "Hasło",
  "paymentDayNo": "Dzień spłaty raty",
  "paymentDifferencePln": "Nadpłata z tyt. różnic kursowych",
  "bankCollectedForeign": "Bank pobrał Rata",
  "paymentForeign": "Rata",
  "paymentMethod": "Sposób płatności",
  "paymentPln": "Rata",
  "paymentStatus": "Status płatności",
  "periodEnd": "Numer okresu (koniec)",
  "periodIndex": "Numer okresu",
  "periodStart": "Numer okresu (początek)",
  "periodsTotal": "# rat",
  "person": "Klient", // Osoba
  "phone": "Telefon",
  "plnLiborLoan": "LIBOR + PLN",
  "preparationDate": "Data sporządzenia", // Data sporządz.
  "priceForAccess": "Opłaty za dostęp", // Opłaty za dostępy
  "principalPln": "Kapitał",
  "rate": "Oprocentowanie",
  "repeatNewPassword": "Potwórz nowe hasło",
  "reportPrice": "Cena za raport", // Cena raportu
  "reportsCount": "Liczba raportów",
  "reportsNumber": "Liczba raportów",
  "roleName": "Rola",
  "selectAllNone": "Zaznacz/odznacz wszystkie",
  "signatureDate": "Data podpisania umowy",
  "simulationBalanceForeign": "CHF Symulacja",
  "simulationBalancePln": "Saldo (PLN)",
  "stats.reportsCurrentMonth.total": "Liczba raportów w bież. miesiącu",
  "stats.reportsCurrentYear.total": "Liczba raportów w bież. roku",
  "stats.reportsPrevMonth.total": "Liczba raportów w poprz. miesiącu",
  "stats.reportsTotal.total": "Liczba raportów ogółem",
  "status": "Status", // Status
  "statusName": "Status",
  "subject": "W czym możemy Ci pomóc?",
  "supermanagerUserId": "Super-manager",
  "target": "Przekaż do",
  "taxNumber": "NIP", // Numer NIP
  "company.taxNumber": "NIP",
  "temporaryIncrease": "Podwyższenie do czasu wpisu do hipoteki",
  "temporaryIncreasePeriods": "Ilość rat z podwyższeniem",
  "title": "Tytuł", // Lokalizacja || Identyfikator
  "tranchesSumForeign": "Bank Wypłaty/koszty",
  "tranchesSumPln": "Bank Wypłaty/koszty",
  "type": "Typ", // Typ wpłaty
  "typeId": "Typ",
  "typeName": "Rodzaj raportu", // Rodzaj
  "value": "Wartość",
};
