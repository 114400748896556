import React, { useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  EditOutlined,
  AccountBalanceWalletOutlined,
  PeopleAltOutlined,
} from '@material-ui/icons';

import { companiesDuck } from 'redux/ducks/companies';

import Table from 'components/basic/Table';
import InfoChip from 'components/basic/InfoChip';
import UserAvatar from 'components/basic/UserAvatar';

import styles from './CompaniesList.style';
import { statsDuck } from 'redux/ducks/stats';

const columns = [
  { field: 'name', title: 'Nazwa', width: 200, ellipsis: true,
    render: rowData => (
      <>
        <UserAvatar user={rowData} variant='horizontal' />
        <span>{rowData.name}</span>
      </>
    ),
  },
  { field: 'addressCity', title: 'Miasto', width: 100, ellipsis: true,
  },
  { field: 'statusName', title: 'Status', width: 100,
    render: rowData => <InfoChip label={rowData.statusName} variant={
      rowData.status === 'active' ? 'active' :
      rowData.status === 'pending_activation' || rowData.status === 'pending_admin_approval' ? 'pending' :
      rowData.status === 'deactivated' ? 'inactive' :
      rowData.status === 'erased' ? 'erased' :
      'error'
    } /> 
  },
  { field: 'maxAccounts', title: 'Liczba dostępów', type: 'numeric',
  },
  { field: 'stats.reportsPrevMonth.total', title: 'Liczba raportów w poprz. miesiącu', width: 120,
    render: rowData => <>
      <dl className='mTable-report-type'>
        <dt>RW:</dt>
        <dd>{rowData.stats && rowData.stats.reportsPrevMonth.rw}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RS:</dt>
        <dd>{rowData.stats && rowData.stats.reportsPrevMonth.rs}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RP:</dt>
        <dd>{rowData.stats && rowData.stats.reportsPrevMonth.rp}</dd>
      </dl>
    </>
  },
  { field: 'lastMonthAmount', title: 'Suma za poprz. miesiąc', type: 'numeric', 
  },
  { field: 'stats.reportsCurrentMonth.total', title: 'Liczba raportów w bież. miesiącu', width: 120,
    render: rowData => <>
      <dl className='mTable-report-type'>
        <dt>RW:</dt>
        <dd>{rowData.stats && rowData.stats.reportsCurrentMonth.rw}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RS:</dt>
        <dd>{rowData.stats && rowData.stats.reportsCurrentMonth.rs}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RP:</dt>
        <dd>{rowData.stats && rowData.stats.reportsCurrentMonth.rp}</dd>
      </dl>
    </>
  },
  { field: 'currentMonthAmount', title: 'Suma za bież. miesiąc', type: 'numeric', 
  },
  { field: 'stats.reportsCurrentYear.total', title: 'Liczba raportów w bież. roku', width: 120,
    render: rowData => <>
      <dl className='mTable-report-type'>
        <dt>RW:</dt>
        <dd>{rowData.stats && (rowData.stats.reportsCurrentYear || 0) && rowData.stats.reportsCurrentYear.rw}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RS:</dt>
        <dd>{rowData.stats && (rowData.stats.reportsCurrentYear || 0) && rowData.stats.reportsCurrentYear.rs}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RP:</dt>
        <dd>{rowData.stats && (rowData.stats.reportsCurrentYear || 0) && rowData.stats.reportsCurrentYear.rp}</dd>
      </dl>
    </>
  },
  { field: 'stats.reportsTotal.total', title: 'Liczba raportów ogółem', width: 120,
    render: rowData => <>
      <dl className='mTable-report-type'>
        <dt>RW:</dt>
        <dd>{rowData.stats && rowData.stats.reportsTotal.rw}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RS:</dt>
        <dd>{rowData.stats && rowData.stats.reportsTotal.rs}</dd>
      </dl>
      <dl className='mTable-report-type'>
        <dt>RP:</dt>
        <dd>{rowData.stats && rowData.stats.reportsTotal.rp}</dd>
      </dl>
    </>
  },
  { field: 'createdAt', title: 'Data dołączenia', type: 'date',
  },
];

const actions = (history) => [
  {
    onClick: (e, rowData) => history.push(`/companies/${rowData.id}/edit`),
    icon: EditOutlined,
    tooltip: 'Edytuj firmę',
  },
  {
    onClick: (e, rowData) => history.push(`/companies/${rowData.id}/payments`),
    icon: AccountBalanceWalletOutlined,
    tooltip: 'Płatności',
  },
  {
    onClick: (e, rowData) => history.push(`/companies/${rowData.id}/users`),
    icon: PeopleAltOutlined,
    tooltip: 'Pracownicy',
  },  
];

export default function CompaniesList() {
  const classes = styles();
  const history = useHistory();
  // const dispatch = useDispatch();

  const [stats, updateStats] = useReducer((s,a)=>({...s,...a}), {});
  const [fetched, setFetched] = useState(false);

  const companies = useSelector(companiesDuck.get.allWithStats());
  companies.forEach(row => {row.stats = stats[row.id]});

  useEffect(() => {
    if(!fetched && Object.keys(stats).length === 0 && companies && companies.length){
      setFetched(true);
      statsDuck.api.get({
        urlParams: {companyIds: companies.map(row => row.id).join(',')},
        callback: data => updateStats(Object.fromEntries(data.map(row => [row.companyId, row]))),
        ephemeral: true,
      })
    }
  }, [companies, fetched]);

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={companies}
        actions={actions(history)}
      />
    </div>
  );
}