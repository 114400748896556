import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {holidaysDuck} from 'redux/ducks/dictionaries';

import Table from 'components/basic/Table';
import styles from './DictionaryHolidays.style';
import { dates } from 'dict';
import shortid from 'shortid';

const columns = [
  { field: 'active', title: 'Aktywne', type: 'boolean', width: 80 },
  { field: 'date', title: 'Data', type: 'date' },
  { field: 'name', title: 'Nazwa' },
];

export default function DictionaryHolidays({active, year}) {
  const classes = styles();
  const dispatch = useDispatch();

  const filters = {
    date: dates.relative.givenYear(year),
  };

  const dictionaryData = useSelector(holidaysDuck.get.filtered(filters)).sort((a, b) => a.date === b.date ? 0 : a.date > b.date ? 1 : -1);

  useEffect(() => {
    active && year && dispatch(holidaysDuck.thunk.getAll({
      urlParams: {
        year, 
      }
    }));
  }, [active, year]);

  return (
    <div className={classes.root}>
      <Table
        columns={columns}
        data={dictionaryData}
        editable={{
          onRowAdd: newData => new Promise(resolve => {
            dispatch(holidaysDuck.thunk.fieldSave({id: `new-${shortid.generate()}`, data: newData}));
            resolve();
          }),
          onRowUpdate: newData => new Promise(resolve => {
            dispatch(holidaysDuck.thunk.fieldSave({data: newData}));
            resolve();
          }),
        }}
      />
    </div>
  );
}