import flagsmith from "flagsmith";
import { Duck } from "redux/lib/Duck";

export const featuresDuck = new Duck("features", () => []);
const duck = featuresDuck;

duck.action.setEnabledFeatures = {
  reducer: (s, payload) => ({
    ...s,
    data: payload,
  }),
};

duck.get.enabledFeatures =
  () =>
  ({ [duck.name]: features }) =>
    features.data;

duck.thunk.init = function () {
  return (dispatch, getState) => {
    try {
      if (flagsmith.initialised) {
        return;
      }

      flagsmith.init({
        environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
        cacheFlags: true,
        enableAnalytics: true,
        onChange: () => {
          const features = Object.entries(flagsmith.getAllFlags())
            .map(([flag, { enabled }]) => {
              if (!enabled) {
                return;
              }

              return flag;
            })
            .filter(Boolean);

          dispatch(duck.action.setEnabledFeatures(features));
        },
      });
    } catch (e) {
      console.log("flagsmith error");
      console.log(e);

      dispatch(duck.action.setEnabledFeatures([]));
    }
  };
};

duck.thunk.identify = function ({
  userId,
  email,
  companyId,
  companyName,
  companyContactEmail,
}) {
  return (dispatch, getState) => {
    try {
      flagsmith.identify(userId, {
        email,
        companyId,
        companyName,
        companyContactEmail:
          companyContactEmail || "Company contact email not set",
        guest: false,
      });
    } catch (error) {
      console.log("flagsmith identity error");
      console.log(error);
    }
  };
};
