import React from "react";

import { Box, Link } from "@material-ui/core";

import styles from "./CalculationWidgetAlertEmailWarning.style";
import { useSelector } from "react-redux";
import { currentUserDuck } from "redux/ducks/currentUser";
import Button from "components/basic/Button";

export default function CalculationWidgetAlertEmailWarning() {
  const classes = styles();
  const currentUser = useSelector(currentUserDuck.get.data());

  if (currentUser.company.alertsEmail) {
    return null;
  }

  return (
    <div className={classes.root}>
      {!currentUser.company.alertsEmail && (
        <Box py={0.5} px={3} mb={4} bgcolor="error.main" borderRadius={4}>
          <h1 className={classes.warningHeaderText}>
            Uwaga, adres email do monitów nie został skonfigurowany.
          </h1>

          <p className={classes.warningSubheadingText}>
            Należy wypełnić adres email do monitów. Na ten adres przychodzą
            informacje o raportach wygenerowanych w kalkulatorze.
          </p>

          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
            my={1}
          >
            <Button>
              <Link href="/company">
                Kliknij tutaj aby przejść do konfiguracji adresu email do
                monitów
              </Link>
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
}
