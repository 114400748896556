import React, { useEffect } from 'react';
import {
  Grid,
  Modal,
} from '@material-ui/core';
import { 
  Add,
  SaveOutlined,
 } from '@material-ui/icons';
 
import PageTitle from 'components/basic/PageTitle';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

import UsersList from 'components/feature/UsersList';

import styles from './Users.style';
import { fieldValues } from 'dict';
import { usersDuck } from 'redux/ducks/users';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { companiesDuck } from 'redux/ducks/companies';
import { useParams } from 'react-router-dom';

const handleAddition = ({values, newUserOptions, callback, onError, dispatch}) => {
  const data = {
    id: 'new-'+shortid.generate(), 
    login: values.email,
    email: values.email,
    firstName: values.firstName,
    lastName: values.lastName,
    companyId: newUserOptions.companyId,
    privileges: [values.roleName],
  };
  
  dispatch(usersDuck.thunk.fieldSaveSync({
    data, 
    callback,
    onError,
  })); 
};

const NewUserModal = ({newUserOptions, updateNewUserOptions, dispatch}) => {
  const currentUser = useSelector(currentUserDuck.get.data());

  return (
    <ModalWrapper 
      variant='small'
      title='Dodaj nowego użytkownika'
    >
      <Form 
        disabled={newUserOptions.sending}
        variant='small'
        fields={[
          { label: 'E-mail', name: 'email', defaultValue: '', required: true},
          { label: 'Imię', name: 'firstName', defaultValue: '', required: true},
          { label: 'Nazwisko', name: 'lastName', defaultValue: '', required: true},
          { label: 'Rola', name: 'roleName', defaultValue: 'regular', required: true, 
            options: Object.fromEntries(
              Object.entries(fieldValues.users.roles).reduce(
                (result, [name, label]) => (Object.keys(result).length === 0 && name !== currentUser.roleName ? result : [[name, label], ...result]), []
              ).filter(([name]) => name !== 'admin')
            ),
          },
          // { label: 'Wiadomość', name: 'message', defaultValue: '', multiline: true, rows: '4' },
        ]} 
        buttons={[
          { label: 'Anuluj', color: 'primary', onClick: () => updateNewUserOptions({modalOpen: false})},
          { label: 'Zapisz użytkownika', color: 'secondary', icon: SaveOutlined, type: 'submit'},
        ]}
        onSubmit={values => {
          updateNewUserOptions({sending: true});
          handleAddition({
            values, 
            newUserOptions, 
            dispatch,
            callback: () => updateNewUserOptions({modalOpen: false, sending: false}),
            onError: () => updateNewUserOptions({sending: false}),
          }); 
        }}
      />
    </ModalWrapper>
  );
};

export default function Users({noShowCasesAction}) {
  const classes = styles();
  const dispatch = useDispatch();
  const {companyId} = useParams();
  
  const currentCompany = useSelector(companyId ? companiesDuck.get.byId(companyId) : currentUserDuck.get.company());

  const [newUserOptions, updateNewUserOptions] = React.useReducer((s,a)=>({...s,...a}), {
    modalOpen: false, 
    sending: false,
    // companyId: currentCompany.id,
  });

  useEffect(() => {
    if(currentCompany && currentCompany.id){
      updateNewUserOptions({companyId: currentCompany.id})
    }
  }, [currentCompany && currentCompany.id]);

  return (
    <div className={classes.root}>
      <Modal
        open={newUserOptions.modalOpen}
        onClose={() => updateNewUserOptions({modalOpen: false})}
      >
        <NewUserModal newUserOptions={newUserOptions} updateNewUserOptions={updateNewUserOptions} dispatch={dispatch}/>
      </Modal>
      <Grid container>
        <Grid item xs={12}>
          <PageTitle 
            title='Użytkownicy'
            buttons={[
              { label: 'Dodaj użytkownika', color: 'secondary', icon: Add, onClick: () => updateNewUserOptions({modalOpen: true}) },
            ]}             
          />  
        </Grid>
        <Grid item xs={12}>
          <UsersList noShowCasesAction={noShowCasesAction}/>
        </Grid>
      </Grid>  
    </div>
  );
}
