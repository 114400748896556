import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {

  },
}), {
  name: 'AdminsList', 
  index: 1,
});
