import React, { useState } from 'react';

import {
  ArrowBackOutlined
} from '@material-ui/icons';

import AuthForm from 'components/basic/AuthForm';
import Form from 'components/basic/Form';

import styles from './ForgotPassword.style';
import { usersDuck } from 'redux/ducks/users';

export default function ForgotPassword() {
  const classes = styles();
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <div className={classes.root}>
      <AuthForm
        title='Reset hasła'
        description={success 
          ? 'Wysłaliśmy na ten adres link, dzięki któremu ustawisz nowe hasło.'
          : 'Jeżeli nie pamiętasz swojego hasła, wpisz poniżej adres e-mail, którego używasz do logowania. Otrzymasz od nas na ten adres link, dzięki któremu ustawisz nowe hasło.'
        }
        buttons={[
          { label: 'Powrót do strony logowania', target: '/login', icon: ArrowBackOutlined},
        ]}
      >
        {!success && (
          <Form 
            variant='inner'
            disabled={disabled}
            fields={[
              { label: 'Twój e-mail', name: 'login', defaultValue: '', required: true},
            ]} 
            buttons={[
              { label: 'Zresetuj hasło', color: 'secondary', type: 'submit'},
            ]}
            onSubmit={values => {
              setDisabled(true);
              usersDuck.api.resetPassword({data: values, callback: () => {
                setSuccess(true);
              }})
            }}
          />
        )}
      </AuthForm>
    </div>
  );
}
