import React from 'react';
import PropTypes from 'prop-types';

import PageMeta from 'components/basic/PageMeta';

import styles from './MainLayout.style';

import Sidebar from '../Sidebar';
import Content from '../Content';


const MainLayout = ({children, pageTitle}) => {

  const classes = styles();

  return (
    <div className={classes.root}>
      <PageMeta pageTitle={pageTitle} />
      <Sidebar/>
      <Content>
        {children}
      </Content>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;