import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';

import {
  Grid,
  Modal
} from '@material-ui/core';
import { 
  Add,
  SaveOutlined,
  // BusinessOutlined,
  // EcoOutlined,
  // ScheduleOutlined
 } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
// import TabsWithPanels from 'components/basic/TabsWithPanels';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

import CompaniesList from 'components/feature/CompaniesList';
// import PendingCompaniesList from 'components/feature/PendingCompaniesList';
import {usersDuck} from 'redux/ducks/users'
import {companiesDuck, registrationsDuck} from 'redux/ducks/companies'

import styles from './Companies.style';
import shortid from 'shortid';
import { useDispatch } from 'react-redux';

const handleAddition = ({values, callback, onError, dispatch}) => {
  const data = {
    id: 'new-'+shortid.generate(), 
    company: {
      name: values.name,
      taxNumber: values.taxNumber,
      addressStreetName: values.addressStreetName,
      addressBuildingNo: values.addressBuildingNo,
      addressPostalCode: values.addressPostalCode,
      addressCity: values.addressCity,
      priceForAccess: 0,
      // reportPrice: values.reportPrice,
      // caseTransferPrice: values.caseTransferPrice,
      // accountPrice: values.accountPrice,
      // accountsNumber: values.accountsNumber,
    },
    user: {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    }
  };
  
  registrationsDuck.api.addOne({
    data, 
    onAdded: resData => {
      if(resData.registration){
        companiesDuck.api.getOne({
          urlParams: {id: resData.registration.companyId},
        })
        usersDuck.api.getOne({
          urlParams: {id: resData.registration.userId},
        })
        typeof callback === 'function' && callback(resData);
      } else {
        typeof onError === 'function' && onError(resData);
      }
    }, 
    onError, 
  });
};

const NewClientModalWrapper = ({modal, setModal, disabled}) => {
  const dispatch = useDispatch();
  const [clear, setClear] = useState('');

  useEffect(() => {
    if(modal === false){
      setClear(Date.now);
    }
  }, [modal])

  return (
    <ModalWrapper 
      variant='small'
      title='Dodaj nową firmę'
      maxWidth='600'
    >
      <Form 
        disabled={disabled}
        variant='small'
        clear={clear}
        fields={[
          { label: 'E-mail managera', name: 'email', defaultValue: '', required: true},
          { half: true, label: 'Imię managera', name: 'firstName', defaultValue: '', required: true},
          { half: true, label: 'Nazwisko managera', name: 'lastName', defaultValue: '', required: true},
          { half: false, label: 'Nazwa firmy', name: 'name', defaultValue: '', required: true},
          { half: false, label: 'Numer NIP', name: 'taxNumber', defaultValue: '', required: true},
          { half: true, label: 'Ulica', name: 'addressStreetName', required: true, defaultValue: ''},
          { half: true, label: 'Numer domu/lokalu', name: 'addressBuildingNo', required: true, defaultValue: ''},
          { half: true, label: 'Kod pocztowy', name: 'addressPostalCode', required: true, defaultValue: ''},
          { half: true, label: 'Miasto', name: 'addressCity', required: true, defaultValue: ''},
          // { half: true, label: 'Cena za raport', name: 'reportPrice', defaultValue: ''},
          // { half: true, label: 'Cena za przekazanie umowy', name: 'caseTransferPrice', defaultValue: ''},
          // { half: true, label: 'Opłaty za dostęp', name: 'accountPrice', defaultValue: ''},
          // { half: true, label: 'Liczba dostępów', name: 'accountsNumber', defaultValue: ''},
        ]} 
        buttons={[
          { label: 'Anuluj', color: 'primary', onClick: () => setModal(false)},
          { label: 'Zapisz firmę', color: 'secondary', icon: SaveOutlined, type: 'submit'},
        ]}
        onSubmit={values => {
          if(modal === 'sending') return;
          setModal('sending');
          handleAddition({
            values, 
            dispatch,
            callback: () => setModal(false),
            onError: () => setModal(true),
          }); 
        }}
      />
    </ModalWrapper>
  );
};

export default function Clients() {
  const classes = styles();

  // New client modal

  const [newClientModal, setNewClientModal] = React.useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={newClientModal}
        onClose={() => setNewClientModal(false)}
      >
        <NewClientModalWrapper
          disabled={newClientModal === 'sending'}
          modal={newClientModal}
          setModal={setNewClientModal}
        />
      </Modal>
      <PageTitle 
        title='Lista klientów'
        buttons={[
          { label: 'Dodaj nową firmę', color: 'secondary', icon: Add, onClick: () => setNewClientModal(true)},
        ]} 
      />
      <Grid container>
        <Grid item xs={12}>
          <CompaniesList />
        </Grid>      
        {/* <TabsWithPanels
          variant='right'
          urlParam={false}
          tabs={[
            { id: 'all', label: 'Wszystkie',
              icon: BusinessOutlined,
              panel: CompaniesList,
            },
            // { id: 'pending', label: 'Oczekujące',
            //   icon: ScheduleOutlined,
            //   panel: PendingCompaniesList,
            // },
          ]}
        /> */}
      </Grid>   
    </div>
  );
}
