import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    width: '100%',
    '& .MuiTabs-root': {
      minHeight: 40,
      flexShrink: 0,
    },
    '& .MuiTab-root': {
      minWidth: 120,
    },
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      fontSize: '0.85rem',
    },
    '& .MuiSvgIcon-root': {
      width: '1.2rem',
      height: '1.2rem',
      marginRight: theme.spacing(1),
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& .MuiTab-labelIcon': {
      position: 'relative',
      minHeight: 40,
    },
    '&.variant-right': {
      justifyContent: 'flex-end',
    },
    '&.variant-small': {
      width: 'auto',
    },
    '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    }
  },
  tabsContent: {
    '& .MuiBox-root': {
      padding: 0,
    },
  }
}), {
  name: 'TabsWithPanels', 
  index: 1,
});
