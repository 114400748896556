import React from 'react';
import PropTypes from 'prop-types';

import styles from './PopupDialog.style';
import { Dialog } from '@material-ui/core';

export const PopupDialog = (props) => {
  const classes = styles();
  return (
    <Dialog className={classes.root} {...props}/>
  );
}

PopupDialog.propTypes = {
  children: PropTypes.node,
};

export default PopupDialog;
