import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '&.variant-dense': {
      marginBottom: theme.spacing(2),
    },
    '&.variant-denser': {
      marginBottom: theme.spacing(1),
    },
    '&.variant-full': {
      '& $titleWrapper': {
        [theme.breakpoints.up('lg')]: {
          maxWidth: '100%',
        },
      },
    },
    '& p': {  
      opacity: 0.5,
    },
    '& .MuiBreadcrumbs-separator': {
      opacity: 0.5,    
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    '& .MuiBreadcrumbs-li svg': {
      color: theme.palette.secondary.main,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  titleInfo: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
  },
  mainTitleActions: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(2),
    },
  },
}), {
  name: 'PageTitle', 
  index: 1,
});
