import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  outerWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 'calc(100vh - 64px)',
    maxWidth: '400px',
    margin: '0 auto'
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  description: {
    marginBottom: theme.spacing(4),
  },
  form: {
    width: '100%',
    marginBottom: theme.spacing(2),
  }
}), {
  name: 'AuthForm', 
  index: 1,
});
