import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./style/global.css";
import { Provider as StoreProvider } from "react-redux";
import store from "./redux";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./utils/overrides";
import { ErrorBoundary } from "ErrorBoundary";

Sentry.init({
  dsn: "https://be4e16fd8571403aaf23e7ebce0332bb@o1082279.ingest.sentry.io/6090481",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.55,
});

ReactDOM.render(
  <ErrorBoundary>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
