import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  'variant-small': {
    '& $avatar': {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  avatar: {
    position: 'relative',
    width: theme.spacing(12),
    height: theme.spacing(12),
    fontSize: '1rem',
    cursor: 'pointer',
  },
  avatarWrapper: {
    width: 120,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarActions: {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -45,
    top: 15,
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
    }
  },
  avatarButton: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    boxShadow: theme.shadows[1],
    '& .MuiSvgIcon-root': {
      width: '1.2rem',
      height: '1.2rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    }
  },
  usernameBox: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(0),
      textAlign: "center",
    },
  },
  userPosition: {
    marginTop: theme.spacing(1),
  },
  userStatus: {
    marginTop: theme.spacing(1),
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    '& a': {
      color: "inherit",
    },
  },
}), {
  name: 'UserCard', 
  index: 1,
});
