import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import {Pie} from 'react-chartjs-2';
import {
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import { 
  Filter1Outlined,
  CalendarTodayOutlined,
  Filter2Outlined,
  Filter3Outlined,
  LibraryBooksOutlined,
} from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import Dropdown from 'components/basic/Dropdown';
import ComplexStatsCard from 'components/basic/ComplexStatsCard';
import StatsCards from 'components/basic/StatsCards';

import styles from './PaymentsManager.style';

import PaymentsListManager from 'components/feature/PaymentsListManager';
import { useSelector } from 'react-redux';
import { statsDuck } from 'redux/ducks/stats';
import { dates } from 'dict';

const chartData = values => ({
	labels: [
		'Raporty wstępne',
		'Raporty szacunkowe',
		'Raporty pełne'
	],
	datasets: [{
		data: values,
		backgroundColor: [
		'#ff4d4d',
		'#ff0000',
		'#cc0000'
		],
		hoverBackgroundColor: [
		'#ff3333',
		'#e60000',
		'#b30000'
		]
	}]
});

export default function PaymentsManager() {
 const classes = styles();
 const [period, setPeriod] = useState('anytime');
 const [year, setYear] = useState(dates.values.thisYear.toString());

 // const currentCompany = useSelector(currentUserDuck.get.company());
 const stats = useSelector(statsDuck.get.casesAndReports({createdAt: dates.relative[period]/* , ownerCompanyId: currentCompany.id */}));

  return (
    <div className={classes.root}>
      <PageTitle title="Płatności">
        <Dropdown
          variant="right"
          icon={CalendarTodayOutlined}
          value={period}
          handleChange={(value) => setPeriod(value)}
          options={[
            { label: "Kiedykolwiek", value: "anytime" },
            { label: "Bieżący miesiąc", value: "currentMonth" },
            { label: "Poprzedni miesiąc", value: "lastMonth" },
          ]}
        />
      </PageTitle>
      <Grid container>
        <StatsCards
          cardSizeMd="6"
          cardSizeLg="3"
          cards={[
            {
              title: "Wszystkie raporty",
              value: stats.reports.all,
              icon: LibraryBooksOutlined,
            },
            {
              title: "Umowy",
              value: stats.cases.all,
              icon: LibraryBooksOutlined,
            },
            // { title: 'Raporty wstępne', value: stats.reports['r1'], icon: Filter1Outlined, variant: 'second' },
            {
              title: "Raporty szacunkowe",
              value: stats.reports["estimation"],
              icon: Filter2Outlined,
              variant: "third",
            },
            {
              title: "Raporty pełne",
              value: stats.reports["cases"],
              icon: Filter3Outlined,
              variant: "last",
            },
          ]}
        />
      </Grid>
      <Grid item container alignItems="stretch" spacing={6}>
        {/* <Grid item xs={12} lg={6}>
            <ComplexStatsCard
              icon={Filter1Outlined}
              title='Wszystkie raporty'
              totalValue={stats.all}
              cards={[
                { title: 'Raporty szacunkowe', value: stats['estimation']},
                { title: 'Raporty pełne', value: stats['cases']},
              ]}  
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardContent>
                <Pie data={chartData([stats['cases'], stats['estimation']])} />
              </CardContent>
            </Card>
          </Grid> */}
      </Grid>
      <Grid item xs={12}>
        <PageTitle title="Faktury" mt={8}>
          <Dropdown
            variant="right"
            icon={CalendarTodayOutlined}
            value={year}
            handleChange={(value) => setYear(value)}
            options={[
              { label: "kiedykolwiek", value: "any" },
              {
                label: dates.values.thisYear - 0,
                value: (dates.values.thisYear - 0).toString(),
              },
              {
                label: dates.values.thisYear - 1,
                value: (dates.values.thisYear - 1).toString(),
              },
              {
                label: dates.values.thisYear - 2,
                value: (dates.values.thisYear - 2).toString(),
              },
            ]}
          />
        </PageTitle>
      </Grid>
      <Grid item xs={12}>
        <PaymentsListManager year={year} />
      </Grid>
    </div>
  );
}

