import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import { 
  // Add,
  SaveOutlined
 } from '@material-ui/icons';
import PageTitle from 'components/basic/PageTitle';
import ButtonBack from 'components/basic/ButtonBack';
// import ButtonWrapper from 'components/basic/ButtonWrapper';
// import ModalWrapper from 'components/basic/ModalWrapper';

import styles from './KnowledgeBaseArticleNew.style';
import Form from 'components/basic/Form';
import { useSelector, useDispatch } from 'react-redux';
import { phrasesDuck } from 'redux/ducks/dictionaries';
import shortid from 'shortid';
import { useParams, Link, useHistory } from 'react-router-dom';

export default function KnowledgeBaseArticleNew() {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {articleId} = useParams();

  const categories = useSelector(phrasesDuck.get.kbCategories());
  const article = useSelector(phrasesDuck.get.byId(articleId));

  const [saving, setSaving] = useState(false);

  return (
    <div className={classes.root}>
      <ButtonBack text='Powrót do bazy wiedzy' />
      {(articleId === 'new' || article) && (<>
        <PageTitle centered mt={2} title={article ? 'Edycja artykułu' : 'Nowy artykuł'}/> 
        <Grid container justify='center'>
          <Grid item xs={12} md={8}>
            <Form
              disabled={saving}
              fields={[
                {label: 'Tytuł', name: 'title', defaultValue: article ? article.title : '' },
                ...(categories.length ? [{
                  label: 'Kategoria', name: 'categoryId', defaultValue: article ? article.categoryId : '',
                  options: Object.fromEntries(categories.map(cat => [cat.id, cat.title]))
                }] : [{
                  label: 'Kategoria', name: 'categoryId', disabled: true, 
                  error: <>Brak zdefiniowanych kategorii – przejdź do <Link to="/settings/knowledge-base-categories">ustawień</Link> aby dodać kategorie</>,
                }]),
                {label: 'Treść artykułu', name: 'content', defaultValue: article ? article.content : '', rows: 25 },
              ]}
              buttons={[
                {label: 'Zapisz artykuł', icon: SaveOutlined, type: 'submit'}
              ]}
              onSubmit={newData => {
                setSaving(true);
                dispatch(phrasesDuck.thunk.fieldSave({
                  data: phrasesDuck.prepareData({
                    id: article ? article.id : `new-${shortid.generate()}`, 
                    type: 'kb', 
                    ...newData
                  }), 
                  onAdded: (resData) => {
                    history.push(`/kb/${resData.id}`)
                    setSaving(false);
                  },
                  onUpdated: (resData) => {
                    history.push(`/kb/${resData.id}`)
                    setSaving(false);
                  },
                  onError: () => {
                    setSaving(false);
                  },
                }));
              }}
            />          
          </Grid>
        </Grid>
      </>)}
    </div>
  );
}
