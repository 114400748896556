import { makeStyles, withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export const GlobalCss = withStyles(theme => ({
  '@global':{
    '.mTable-cell-ellipsis-paper': {
      boxSizing: 'content-box',
      fontSize: '0.8rem',
      padding: '0 10px',
      borderRight: theme.utils.border,
      borderBottom: theme.utils.border,
      borderRadius: 0,
      overflowY: 'hidden',
      boxShadow: theme.shadows[2],
      '& .MuiSvgIcon-root': {
        width: '1rem', 
        height: '1rem', 
      },
      '& .icon-lock': {
        position: 'relative',
        top: theme.spacing(0.4),
        marginRight: theme.spacing(0.5),
      },
    },
  },
}))(() => null);

export default makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderCollapse: 'collapse',
    '& .mTable-table': {
      borderCollapse: 'separate',
    },
    /* Max table height and scrollbars width */
    '& .mTable-wrapper': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 300px)',
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(2),
      },
      '&::-webkit-scrollbar:vertical': {
        width: 14,
      },
      '&::-webkit-scrollbar:horizontal': {
        height: 14,
      },
    },
    /* Cells padding and font size */
    '& .mTable-header-cell > span[role="button"]': {
      minHeight: '35px',
    },
    '& .mTable-header-cell, & .MuiTableCell-root': {
      padding: '0 10px',
      fontSize: '0.8rem',
    },
    /* Icon size */
    '& .MuiSvgIcon-root': {
      width: '1rem!important',
      height: '1rem',
    },
    /* Remove arrows from numeric inputs */
    '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button': {
      WebkitAppearance: 'none', 
      margin: 0,
    },
    /* Cell ellipsis */
    '& .mTable-cell-ellipsis': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      backgroundColor: 'inherit',
    },
    /* Collapsible columns */
    '& .mTable-colgroup': {
      // backgroundColor: theme.palette.common.white,
    },
    '& .mTable-colgroup-filler': {
      background: theme.palette.common.white,
    },
    '& .mTable-colgroup-header': {
      border: theme.utils.border, 
      borderTop: 'none',
      textAlign: 'center',
      fontWeight: 700,
      paddingTop: '3px !important',
      paddingBottom: '3px !important',
      fontSize: '14px !important',
      lineHeight: '20px !important',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:focus': {
        outline: 'none',
      },
      // '&::before': {
      //   fontFamily: "'Material Icons'",
      //   content: "'remove'",
      //   fontSize: '1rem'
      // },
      '&.mTable-colgroup-collapsed': {
        padding: 0,
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
        // '&::before': {
        //   fontFamily: "'Material Icons'",
        //   content: "'add'",
        //   fontSize: '1rem'
        // },
      },
    },
    '& .mTable-collapsed': {
      '& + .mTable-collapsed:not(.mTable-colgroup-first)': {
        padding: '0 !important',
        width: '0 !important',
        minWidth: '0 !important',
        maxWidth: '0 !important',
        border: 'none !important',
      },
      '&.mTable-colgroup-first': {
        width: '60px !important',
        minWidth: '60px !important',
        maxWidth: '60px !important',
      },
    },
    /* New row button in toolbar */
    '& .mTable-toolbar-top': {
      position: 'absolute',
      height: 40,
      width: 40,
      right: 30,
      zIndex: 90,
      '& .mTable-addRow-button': {
        top: -15,
        left: 0,
      },
    },
    /* Table header with sorting */
    '& .mTable-header-cell:not(.mTable-internalAction-header):not(.mTable-action-header):not(.optional-notes-column):not(.mTable-locked)': {
      fontWeight: theme.typography.fontWeightBold,
      textAlign: 'center',
      fontSize: '0.7rem',
      lineHeight: 1.5,
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      '& > .MuiTableSortLabel-root': {
        position: 'relative',
        display: 'flex',
        flexFlow: 'nowrap',
        width: '80%',
        '&::before': {
          fontFamily: "'Material Icons'",
          position: 'absolute',
          content: "'expand_less'",
          transform: 'translate(14px, -10px)',
          top: '50%',
          right: '-20%',
          opacity: 0.5,
        },
        '&::after': {
          fontFamily: "'Material Icons'",
          position: 'absolute',
          content: "'expand_more'",
          transform: 'translate(14px, -4px)',
          top: '50%',
          right: '-20%',
          opacity: 0.5,
        },
      },
      '&[data-sorting="asc"]': {
        '& > .MuiTableSortLabel-root': {
          '&::after': {
            opacity: 1,        
          },
        },
      },
      '&[data-sorting="desc"]': {
        '& > .MuiTableSortLabel-root': {
          '&::before': {
            opacity: 1,        
          },
        },
      },
    },
    /* Filter cells */
    '& .mTable-filter-cell:not(.optional-notes-column):not(.mTable-locked)': {
      borderRight: theme.utils.border,
    },
    '& .filter-range': {
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      border: theme.utils.border,
      borderRadius: theme.shape.borderRadius,
      padding: '6px 0 7px 7px',
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
    },
    /* Action cells */
    '& .mTable-action-wrapper': {
      alignItems: 'center',
    },
    '& .mTable-action-cell': {
      padding: '0!important',
      paddingLeft: '16px!important',
    },
    /* Bulk selection */
    '& .mTable-selection-header': {
      textAlign: 'center!important',
    },
    '& .mTable-selection-cell': {
      borderRight: theme.utils.border,
    },
    /* Inputs */
    '& .MuiInputBase-root': {
      border: theme.utils.border,
      borderRadius: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
      '&::before, &::after': {
        display: 'none',
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 8px)',
      },
    },
    '& .MuiInputBase-input': {
      fontSize: '0.8rem',
    },
    '& .mTable-column-cell, & .mTable-filter-cell': {
      '& .MuiFormControl-root': {
        float: 'left!important',
        width: '100%',
      },
    },
    /* Clear input icon */
    '& .filter-clear': {
      width: 10,
      height: 10,
      padding: 10,
      marginLeft: theme.spacing(1),
      '& .MuiIconButton-label': {
        marginRight: -5,
        marginTop: 2,
      },
      '& .MuiSvgIcon-root': {
        width: '0.8rem!important',
        height: '0.8rem!important',
        marginTop: theme.spacing(-1),
      }
    },
    /* Checkboxes */
    '& .MuiCheckbox-root': {
      width: '1rem',
      height: '1rem',
      '& .MuiIconButton-label': {
        width: '1rem',
        height: '1rem',
      },
    },
    '&  .mTable-column-cell:not(.mTable-filter-cell)': {
      '& .MuiCheckbox-root': {
        transform: 'translateX(5px)',
      },
    },
    /* Row coloring (highlight & dim) */
    '& .MuiTableCell-body:focus': {
      outline: 'none',
    },
    '& .mTable-table .mTable-body-row': {
      backgroundColor: theme.palette.common.white,
      '&:nth-child(2n):not(.highlight)': {
        backgroundColor: theme.palette.grey[100],
      },
      '& .mTable-column-cell:not(.optional-notes-column), & .mTable-action-cell, & .mTable-internalAction-cell': {
        '&.mTable-focused-row, &.mTable-focused-column': {
          backgroundColor: blue[50],
        },
        '&.mTable-focused-row.mTable-focused-column': {
          backgroundColor: blue[100],
        },
      },
    },
    '& .mTable-body-row:hover': {
      '& .mTable-column-cell, & .mTable-action-cell, & .mTable-internalAction-cell': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    '& .mTable-dimmed': {
      opacity: '0.5',
    },
    /* User avatar */
    '& .MuiAvatar-root': {
      width: '30px',
      height: '30px',
      fontSize: '0.7rem',
    },
    /* User name */
    '& .userName': {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        paddingLeft: theme.spacing(1),
      },
      '& img': {
        maxHeight: 50,
        width: 'auto',
        marginRight: theme.spacing(1),
      }
    },
    /* Status chips */
    '& .MuiChip-root': {
      fontSize: '0.8rem',
    },
    /* Lock icon */
    '& .mTable-icon-locked': {
      position: 'relative',
      top: theme.spacing(0.4),
      left: -4,
    },
    /* Action buttons */
    '& .mTable-body-row': {
      '& .MuiButtonBase-root': {
        width: 40,
        height: 40,
        opacity: 0.3,
        transition: 'opacity 250ms',
        '& .MuiIconButton-label': {
          width: 16,
          height: 16,
        }
      },
      '&:hover': {
        '& .MuiButtonBase-root': {
          opacity: 1,
        },
      },
      '& .mTable-column-cell': {
        padding: 10,
        borderRight: theme.utils.border,
        borderBottom: theme.utils.border,
        '&.optional-notes-column': {
          width: '40px!important',
          maxWidth: '40px!important',
          borderRight: 0,
          padding: '0!important',
          paddingLeft: '16px!important',
        },
        '&.mTable-locked': {
          borderRight: 0,
        }
      },
    },
  },
}), {
  name: 'Table', 
  index: 1,
});