import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale'

import {
  Tooltip,
  Modal,
  IconButton,
  Box
} from '@material-ui/core';
import {
  EditOutlined,
  ArrowForwardOutlined,
  ChatOutlined,
  DeleteOutlineOutlined,
  Filter1Outlined,
  LockOutlined,
  SearchOutlined
} from '@material-ui/icons';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';

import {casesDuck} from 'redux/ducks/cases';
import { currentUserDuck } from 'redux/ducks/currentUser';

import styles from './CasesList.style';
import { usersDuck } from 'redux/ducks/users';
import { companiesDuck } from 'redux/ducks/companies';
import Form from 'components/basic/Form';
import { casesWithFlags } from 'utils/casesWithFlags';
import { formatValue } from 'material-table';
import { getFormattedCaseAgreementNo } from 'utils/getFormattedCaseAgreementNo';

const columns = ({userRole, someLocked, usersLookup, supermanagersLookup, displayEmailFromOptionalNotes, isFromCalculationWidgetView}) => [
  ...(!someLocked ? [] : [{ 
    field: 'status', title: '', width: 30,
    className: 'mTable-locked',
    sorting: false, filtering: false, searchable: false,
    render: rowData => {
      let locked = false;
      const reasons = [];

      if(rowData.flags.isLockedBecauseShared){
        locked = true;
        reasons.push('przekazana do wsparcia klienta');
      }
      if(rowData.flags.isLockedBecauseAssigned){
        locked = true;
        reasons.push('przypisana do innego użytkownika');
      }
      if(rowData.flags.isLockedBecauseUnassigned){
        locked = true;
        reasons.push('w puli umów nieprzypisanych');
      }
      
      return (locked) && (
        <Box className='mTable-icon-locked' display='inline-block'>
          <Tooltip title={`Nie możesz edytować tej umowy – jest ona ${reasons.join(', oraz ')}.`}>
            <LockOutlined color='secondary' />
          </Tooltip>
        </Box>
      );
    },
  }]),
  ...(isFromCalculationWidgetView ? [
    { 
      field: 'reportsCount', title: 'Wygenerowanie raportu', ellipsis: true, width: 130,
      render: rowData => {
        return rowData.reportsCount === 0 ? 'Nie' : `Tak, ${rowData.reportsCount} raz${rowData.reportsCount > 1 ? 'y' : ''}`;
      }
    },
  ] : []),
  ...(displayEmailFromOptionalNotes ? [{ 
    field: 'optionalNotes', title: 'Email', ellipsis: true, width: 300,
    render: rowData => {
      try {
        return JSON.parse(rowData.guestData)?.email
          || JSON.parse(rowData.ownerUser.guestData)?.email
          || JSON.parse(rowData.optionalNotes)?.email;
      } catch {
        return '';
      }
    }
  }] : [{ 
    field: 'person', title: 'Klient', ellipsis: true, width: 180, 
  }]),
  { 
    field: 'bankName', title: 'Bank', ellipsis: true, width: isFromCalculationWidgetView ? 150 : 180, 
  },
  { 
    field: 'agreementNo', title: 'Nr\u00a0umowy', ellipsis: true, width: isFromCalculationWidgetView ? 230 : 180, 
  },
  ...(isFromCalculationWidgetView ? [
    { 
      field: 'marketingAgreementDate', title: 'Zgoda marketingowa', ellipsis: true, width: 250,
      render: rowData => {
        try {
          const guestData = JSON.parse(rowData.guestData) || JSON.parse(rowData.ownerUser.guestData) || {};
          
          if (!guestData.marketingAgreement) {
            return 'Nie wyrażono zgody';
          }
          
          return format(new Date(guestData.marketingAgreementDate), 'k:mm:ss, dd.MM.u (cccc BBBB)', { locale: pl });
        } catch {
          return 'Brak danych';
        }
      }
    },
    { 
      field: 'phone', title: 'Nr telefonu', ellipsis: true, width: 250,
      render: rowData => {
        try {
          const guestData = JSON.parse(rowData.guestData) || JSON.parse(rowData.ownerUser.guestData) || {};

          return guestData.phone;
        } catch {
          return 'Brak danych';
        }
      }
    }
  ] : []),
  { 
    field: 'preparationDate', title: 'Data sporządz.', type: 'date',
  },
  { 
    field: 'amountPln', title: 'Kwota PLN', type: 'numeric', decimalDigits: 2,
    render: (rowData) =>
      formatValue(
        rowData.tranches
          .map(({ amountPln }) => amountPln)
          .reduce((a, b) => a + b, 0),
        { type: "numeric" }
      ),
  },
  ...(displayEmailFromOptionalNotes ? [] : [{ 
    field: 'name', title: 'Sygnatura', width: 180, ellipsis: true,
  }]),
  ...(userRole !== 'manager' || displayEmailFromOptionalNotes ? [] : [{ 
    field: 'ownerUserId', title: 'Użytkownik', ellipsis: true, width: 120,
    lookup: usersLookup,
  }]),
  { 
    field: 'createdAt', title: 'Data wprowadz.', type: 'date', sorting: true, defaultSort: 'desc'
  },
  ...(userRole !== 'supermanager' ? [] : [
    { 
      field: 'ownerUser.firstName', title: 'Użytkownik', ellipsis: true, width: 120, 
      render: rowData => usersLookup[rowData.ownerUserId] || (rowData.ownerUser && `${rowData.ownerUser.firstName} ${rowData.ownerUser.lastName}`)
    },
    { 
      field: 'ownerCompany.name', title: 'Klient', ellipsis: true, width: 120, 
    },
    { 
      field: 'supermanagerUserId', title: 'Super-manager', ellipsis: true, width: 120, 
      lookup: supermanagersLookup,
    },
  ]),
  { 
    className: 'optional-notes-column',
    field: 'optionalNotes', title: '', 
    width: 36, headerStyle: { padding: '0 5px'}, cellStyle: { padding: '0 5px'}, 
    filtering: false, searchable: true,
    render: rowData => {
      try {
        if (!rowData?.optionalNotes && !rowData?.guestData && !rowData?.ownerUser?.guestData) {
          return null;
        }
  
        let notesFormatted = '';
        
        const notesObj = JSON.parse(rowData.guestData) || JSON.parse(rowData.ownerUser.guestData) || JSON.parse(rowData.optionalNotes);
        notesFormatted = `Email: ${notesObj.email || JSON.parse(rowData.optionalNotes)?.data}`;

        return (
          <Tooltip title={notesFormatted} className='icon-description'>
            <IconButton>
              <ChatOutlined />
            </IconButton>
          </Tooltip>
        );
      } catch {
        return null
      }
    },
  },
];

const actions = ({history, openModal, userData, isFromCalculationWidgetView = false}) => [
  {
    icon: (props, rowData) => rowData.flags.isLocked ? <SearchOutlined {...props}/> : <EditOutlined {...props}/>,
    onClick: (e, rowData) => history.push(`/cases/${rowData.id}`),
    tooltip: rowData => rowData.flags.isLocked ? 'Zobacz szczegóły umowy' : 'Edytuj umowę',
  },
  {
    icon: Filter1Outlined,
    onClick: (e, rowData) => history.push(`/cases/${rowData.id}/reports`),
    tooltip: 'Raporty',
  },
  {
    icon: ArrowForwardOutlined,
    onClick: (e, rowData) => openModal.transfer(rowData),
    tooltip: rowData => rowData.flags.isLockedBecauseShared ? 'Nie możesz przekazać umowy udostępnionej wsparciu klienta' : 'Przekaż umowę',
    disabled: rowData => rowData.flags.isLockedBecauseShared,
  },  
  {
    icon: DeleteOutlineOutlined,
    onClick: (e, rowData) => openModal.delete(rowData),
    tooltip: rowData => rowData.flags.isLockedBecauseShared ? 'Nie możesz usunąć umowy udostępnionej wsparciu klienta' : rowData.flags.isLockedBecauseNotMine ? 'Możesz usunąć tylko umowę przypisaną do siebie' : 'Usuń umowę',
    disabled: rowData => rowData.flags.isLocked,
  },
];

const handleTransfer = ({dispatch, caseId, options, userData, callback, onError}) => {
  let data = {id: caseId};

  if(options.target === 'shared' || options.target === 'active'){
    data = {
      id: caseId, 
      status: options.target,
      ...(options.target === 'active' && {
        supermanagerUserId: null,
      })
    };
  } else {
    const [typeOfAssignation, userId] = options.target.split('/');
    data = {
      id: caseId, 
      ...(typeOfAssignation !== 'supermanager' ? {
        ownerUserId: userId === 'nobody' ? null : userId,
      } : userId === 'nobody' ? {
        supermanagerUserId: null,
        ownerUserId: null,
      } : {
        supermanagerUserId: userId,
      }),
    };
  }
  if(options.customText){
    data.customText = options.customText;
  }
  casesDuck.api.updateOne({
    urlParams: {
      id: data.id, 
      transferPrice: options.priceType === 'custom' ? options.priceValue : options.priceType,
    },
    data, 
    immediate: true,
    onUpdated: (resData) => {
      if(options.target === 'active' && userData.roleName === 'supermanager'){
        dispatch(casesDuck.action.delete({id: resData.id}));
      } else {
        dispatch(casesDuck.thunk.fieldUpdate({data: resData}));
      }
      callback();
    },
    onError,
  }); 
};

const handleDeletion = ({dispatch, caseId, callback}) => {
  const data = {id: caseId, status: 'deactivated'};
  casesDuck.api.updateOne({
    data, 
    immediate: true,
    onUpdated: (resData) => {
      dispatch(casesDuck.thunk.fieldUpdate({data: resData}));
      callback();
    },
  }); 
};

export default function Cases({cases: propsCases, tableOptions, displayEmailFromOptionalNotes = false, isFromCalculationWidgetView = false}) {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {userId: urlUserId} = useParams();
  const [reportsCounts, setReportsCounts] = useState([]);

  const userData = useSelector(currentUserDuck.get.data());
  const users = useSelector(usersDuck.get.all());
  // const usersLookup = useSelector(usersDuck.get.negFilteredLookupTable({privileges: 'supermanager'/* companyId: userData.companyId */}, 'id', 'fullName'));
  const usersLookup = useSelector(usersDuck.get.lookupTable('id', 'fullName'));
  const supermanagers = useSelector(usersDuck.get.filtered({privileges: 'supermanager', status: 'active'/* companyId: userData.companyId */}, 'id', 'fullName'));
  const supermanagersLookup = useSelector(usersDuck.get.filteredLookupTable({privileges: 'supermanager'/* companyId: userData.companyId */}, 'id', 'fullName'));
  // const clientsLookup = useSelector(companiesDuck.get.lookupTable('id', 'name'));

  const userId = userData.roleName === 'regular' ? userData.id : (urlUserId || null);
  const companyUsers = useSelector(usersDuck.get.allFiltered({companyId: userData.companyId, status: 'active'}));
  const companyUsersNoSMs = companyUsers.filter(user => user.roleName !== 'supermanager');

  const filters = userId ? {ownerUserId: userId} : {};
  userData.roleName === 'supermanager' && (filters.status = 'shared');

  const selectorCases = useSelector(casesDuck.get.allFiltered(filters));

  const cases = casesWithFlags({
    reportsCounts,
    cases: (propsCases || selectorCases).map((c) => getFormattedCaseAgreementNo(c)), 
    users: users,
    userId: userData.id,
    IAmSupermanager: userData.roleName === 'supermanager',
  });

  const [transferOptions, setTransferOptions] = React.useState({});
  const [transferModalOpen, setTransferModalOpen] = React.useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  useEffect(() => {
    if (!isFromCalculationWidgetView || propsCases.length === 0) {
      return;
    }

    casesDuck.api.getAllReportsCount(propsCases.map((p) => p.id), (res) => {
      setReportsCounts(res?.data?.reportsCount || []);
    });
  }, [isFromCalculationWidgetView, propsCases.length]);
  
  return (
    <div className={classes.root}>
      <Modal
        open={transferModalOpen}
        onClose={() => setTransferModalOpen(false)}
      >
        <ModalWrapper 
          title='Przekaż umowę'
          description={`Wybierz pracownika, któremu chcesz przekazać tę umowę. Umowę możesz przekazać do osoby z Twojej kancelarii lub do pracownika firmy ProJuste.`}
        >
          <Form
            variant='small'
            disabled={transferOptions.sending}
            fields={[
              { label: 'Przekaż do...', name: 'target', 
                defaultValue: transferOptions.target || '', 
                options: transferModalOpen && (
                  userData.roleName !== 'supermanager' ? ({
                    subheaderCompany: userData.companyName,
                    ...Object.fromEntries(
                      companyUsersNoSMs.map(({id, fullName}) => (
                        [`user/${id}`, {label: fullName, disabled: id === transferModalOpen.ownerUserId,}]
                      ))
                    ),
                    subheaderProJuste: 'ProJuste',
                    ...(transferModalOpen.flags.IAmCaseOwner && transferModalOpen.flags.isInSupportEdit ? {
                      'user/nobody': {
                        label: 'Przekaż do Super-managera',
                      },
                    } : {
                      shared: {
                        label: 'Wsparcie klienta',
                        disabled: transferModalOpen.status === 'shared',
                      },
                    }),
                  }) : ({
                    subheaderUnassigned: 'Nieprzydzielone',
                    'supermanager/nobody': 'Zwróć do puli umów nieprzydzielonych',
                    ...(supermanagers.length && {
                      subheaderSMs: 'Super-managerowie',
                      ...(Object.fromEntries(
                        supermanagers.map(({id, fullName}) => (
                          [`supermanager/${id}`, {label: fullName, disabled: id === transferModalOpen.supermanagerUserId}]
                        ))
                      )),
                    }),
                    ...(companyUsersNoSMs.length && {
                      subheaderUsers: 'Pracownicy',
                      ...(transferModalOpen.flags.isInSupportEdit && {
                        'user/nobody': {
                          label: 'Odbierz pracownikowi',
                        },
                      }),
                      ...(Object.fromEntries(
                        companyUsersNoSMs.map(({id, fullName}) => (
                          [`user/${id}`, {label: fullName, disabled: id === transferModalOpen.ownerUserId}]
                        ))
                      )),
                    }),
                    subheaderReturn: 'Oddanie sprawy',
                    ...(
                      transferModalOpen.status !== 'shared' ? {
                        shared: 'Przejmij sprawę',
                      } : transferModalOpen.flags.isInSupportEdit ? {
                        active: {label: 'Niemożliwe – umowa jest przypisana do pracownika', disabled: true},
                      } : {
                        active: 'Przekaż z powrotem klientowi',
                      }
                    ),
                  })
                ),
              },
              ...(userData.roleName !== 'supermanager' ? [] : [
                {
                  label: 'Opłata', name: 'priceType', 
                  visible: values => values.target === 'active', 
                  defaultValue: 'standard', required: true, 
                  options: {
                    standard: 'Standardowa',
                    free: 'Bez opłaty',
                    custom: 'Inna'
                  },
                },
                {
                  label: 'Kwota opłaty', name: 'priceValue',
                  visible: values => values.target === 'active' && values.priceType === 'custom', 
                  defaultValue: '', required: true,
                },
              ]),
              ...(userData.id !== transferModalOpen.ownerUserId ? [] : [{
                label: 'Wiadomość dla odbiorcy', name: 'customText', 
                defaultValue: '',
                multiline: true, rows: '4',
              }]),
            ]}
            buttons={[
              { 
                label: 'Anuluj', 
                color: 'primary', 
                onClick: () => setTransferModalOpen(false)
              },
              { 
                label: 'Przekaż umowę', 
                color: 'secondary', 
                icon: ArrowForwardOutlined, 
                type: 'submit',
              },
            ]}
            onSubmit={values => {
              setTransferOptions({...transferOptions, sending: true})
              handleTransfer({
                options: {...transferOptions, ...values},
                caseId: transferModalOpen.id,
                userData, 
                callback: () => {setTransferModalOpen(false); setTransferOptions({})},
                onError: () => setTransferOptions({...transferOptions, sending: false}),
                dispatch,
              });
            }}
          />
        </ModalWrapper>
      </Modal>
      <Modal
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
      >
        <ModalWrapper 
          title='Czy chcesz skasować tę umowę?'
          description={`Potwierdź, że chcesz przenieść umowę o sygnaturze ${modalDeleteOpen.name} do kosza.`}
          buttons={[
            { 
              label: 'Anuluj', color: 'primary', 
              onClick: () => setModalDeleteOpen(false)
            },
            { 
              label: 'Skasuj umowę', color: 'secondary', icon: DeleteOutlineOutlined, 
              onClick: () => handleDeletion({
                caseId: modalDeleteOpen.id,
                callback: () => setModalDeleteOpen(false),
                dispatch,
              })
            },
          ]}            
          >
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns({
          userRole: userData.roleName,
          someLocked: cases.some(rowData => rowData.flags.isLocked),
          usersLookup, 
          supermanagersLookup,
          displayEmailFromOptionalNotes,
          isFromCalculationWidgetView,
        })}
        data={cases}
        actions={actions({
          history, 
          isFromCalculationWidgetView,
          openModal: {
            transfer: (rowData) => setTransferModalOpen(rowData), 
            delete: (id) => setModalDeleteOpen(id)
          },
        })}
        options={{
          onEnterKey: rowData => history.push(`/cases/${rowData.id}`),
          ...tableOptions
        }}
      />
    </div>
  );
}