import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

//import styles from './PageMeta.style';

export const PageMeta = ({pageTitle}) => {
 // const classes = styles();
  return (
    <Helmet>
      <title>{pageTitle ? `ProJuste – ${pageTitle}` : `ProJuste`}</title>
    </Helmet>
  );
}

PageMeta.propTypes = {
  children: PropTypes.node,
};

export default PageMeta;
