import {CaseDetailsDuck, SingleRecordDuck} from 'redux/lib/Duck';

export const caseBankRateDuck = new CaseDetailsDuck('caseBankRate', ({id, caseId}) => `/cases/${caseId}/bank-rates/${id}`);
export const caseCostDuck = new CaseDetailsDuck('caseCost', ({id, caseId}) => `/cases/${caseId}/costs/${id}`);
export const casePaymentDuck = new CaseDetailsDuck('casePayment', ({id, caseId}) => `/cases/${caseId}/payments/${id}`);
export const caseTrancheDuck = new CaseDetailsDuck('caseTranche', ({id, caseId}) => `/cases/${caseId}/tranches/${id}`);

export const caseInstallmentAmountDuck = new CaseDetailsDuck('caseInstallmentAmount', ({id, caseId}) => `/cases/${caseId}/installment-amounts/${id}`);
export const caseInstallmentOverrideDuck = new CaseDetailsDuck('caseInstallmentOverride', ({id, caseId}) => `/cases/${caseId}/installment-overrides/${id}`);
export const caseInstallmentDayDuck = new CaseDetailsDuck('caseInstallmentDay', ({id, caseId}) => `/cases/${caseId}/installment-payment-days/${id}`);
export const caseInstallmentTypeDuck = new CaseDetailsDuck('caseInstallmentType', ({id, caseId}) => `/cases/${caseId}/installment-types/${id}`);
export const casePaymentHolidaysDuck = new CaseDetailsDuck('casePaymentHolidays', ({id, caseId}) => `/cases/${caseId}/payment-holidays/${id}`);

export const caseSimulationDuck = new SingleRecordDuck('caseSimulation', ({id, caseId}) => `/cases/${caseId}/payments-simulation/${id}`);

// caseSimulationDuck.apiDataTransform = (data, urlParams) => ({caseId: urlParams.caseId, ...data});

caseInstallmentOverrideDuck.thunk.fieldSave = function({data, ...rest}){
  if(data.amountOverrideType === 'sameAsBank'){
    data.amount = null;
  }
  return CaseDetailsDuck.prototype._defaultThunks.fieldSave.call(this, {data, ...rest});
};

caseInstallmentAmountDuck.api.addOne = 
caseInstallmentOverrideDuck.api.addOne = 
caseInstallmentDayDuck.api.addOne = 
caseInstallmentTypeDuck.api.addOne = 
casePaymentHolidaysDuck.api.addOne = function({data, ...rest}){
  data = {...Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'caseId'))}
  return CaseDetailsDuck.prototype._defaultApis.addOne.call(this, {data, ...rest});
};
