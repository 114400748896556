import React, { useEffect } from 'react';
import flagsmith from "flagsmith";

import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';

import theme from 'theme/theme';
import { currentUserDuck } from 'redux/ducks/currentUser';
import { casesDuck } from 'redux/ducks/cases';
import { banksDuck, paymentTypesDuck, phrasesDuck } from 'redux/ducks/dictionaries';

import MainLayout from './components/layout/MainLayout';
import SimpleLayout from './components/layout/SimpleLayout';
import Contact from './components/view/Contact';
import Cases from './components/view/Cases';
import NewCase from './components/view/NewCase';
import KnowledgeBase from './components/view/KnowledgeBase';
import KnowledgeBaseArticle from './components/view/KnowledgeBaseArticle';
import KnowledgeBaseArticleNew from './components/view/KnowledgeBaseArticleNew';
import Login from './components/view/Login';
import Register from './components/view/Register';
import ForgotPassword from './components/view/ForgotPassword';
import SetPassword from './components/view/SetPassword';
import Companies from './components/view/Companies';
import Users from './components/view/Users';
import Admins from './components/view/Admins';
import TrashManager from './components/view/TrashManager';
import HomepageManager from './components/view/HomepageManager';
import HomepageAdmin from './components/view/HomepageAdmin';
import UserEdit from './components/view/UserEdit';
import CompanyEdit from './components/view/CompanyEdit';
import PaymentsManager from './components/view/PaymentsManager';
import PaymentsAdmin from './components/view/PaymentsAdmin';
import Reports from './components/view/Reports';
import FullView from './components/view/FullView';
import Dictionaries from './components/view/Dictionaries';
import Settings from './components/view/Settings';
import NotFound from './components/view/NotFound';
import { usersDuck } from 'redux/ducks/users';
import { postOfficeDuck } from 'redux/ducks/postOffice';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { reportsDuck } from 'redux/ducks/reports';
import { companiesDuck } from 'redux/ducks/companies';
import { receiptsDuck } from 'redux/ducks/receipts';
import { statsDuck } from 'redux/ducks/stats';
import CalculationWidget from 'components/view/CalculationWidget/CalculationWidget';
import CalculationWidgetEmbed from 'components/view/CalculationWidgetEmbed/CalculationWidgetEmbed';
import { featuresDuck } from 'redux/ducks/features';

const AppRoute = ({ component: Component, layout: Layout = MainLayout, children, pageTitle, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const newConnectionErrors = useSelector(postOfficeDuck.get.newErrors());
  const newConnectionMessages = useSelector(postOfficeDuck.get.newMessages());

  useEffect(() => {
    closeSnackbar();
  }, [location.pathname]);

  useEffect(() => {
    if(newConnectionErrors.length){
      const item = newConnectionErrors[0];
      console.log('hello', newConnectionErrors);
      enqueueSnackbar(
        <div style={{ overflow: "auto", maxHeight: "50vh" }}>
          {item.text} <span style={{ fontSize: '10px' }}>({item.details.length})</span>
          <br />
          {item.details && (
            <ul>
              {item.details.map((msg) => (
                <li>{msg}</li>
              ))}
            </ul>
          )}
        </div>,
        {
          variant: "error",
          persist: true,
          onExit: () => dispatch(postOfficeDuck.action.setErrorClosed(item.id)),
        }
      );
      dispatch(postOfficeDuck.action.setErrorSeen(item.id));
    }
  }, [newConnectionErrors]);
  
  useEffect(() => {
    if(newConnectionMessages.length){
      const item = newConnectionMessages[0];
      enqueueSnackbar(
        <div style={{ overflow: "auto", maxHeight: "50vh" }}>
          {item.text} {item.details && <span style={{ fontSize: '10px' }}>({item.details.length})</span>}
          <br />
          {item.details && (
            <ul>
              {item.details.map((msg) => (
                <li>{msg}</li>
              ))}
            </ul>
          )}
        </div>,
        {
          variant: "info",
          persist: true,
          onExit: () =>
            dispatch(postOfficeDuck.action.setMessageClosed(item.id)),
        }
      );
      dispatch(postOfficeDuck.action.setMessageSeen(item.id));
    }
  }, [newConnectionMessages]);

  return (
    <Route {...rest} render={({staticContext, ...props}) => (
      <Layout pageTitle={pageTitle}>
        {Component ? <Component {...props}>{children}</Component> : <div {...props}>{children}</div>}
      </Layout>
    )} />
  );
}

const roleShortcuts = {
  r: 'regular', 
  m: 'manager', 
  s: 'supermanager', 
  a: 'admin',
};

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(currentUserDuck.get.loggedIn());
  const currentUser = useSelector(currentUserDuck.get.data());

  useEffect(() => {
    dispatch(currentUserDuck.thunk.get());
    dispatch(featuresDuck.thunk.init());
  }, []);

  useEffect(() => {
    if(loggedIn) {
      dispatch(casesDuck.thunk.getAll());
      dispatch(banksDuck.thunk.getAll());
      dispatch(usersDuck.thunk.getAll());
      dispatch(paymentTypesDuck.thunk.getAll());
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'kb-cat'}}));
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'kb'}}));
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'contact-info'}}));
      dispatch(phrasesDuck.thunk.getAll({urlParams: {type: 'tooltip', active: 'all'}}));

      if (currentUser.company) {
        dispatch(reportsDuck.thunk.getAll({urlParams: {companyId: currentUser.company.id}}));
      }

      if(currentUser.roleName === 'admin'){
        dispatch(companiesDuck.thunk.getAll());
        dispatch(receiptsDuck.thunk.getAll());
        statsDuck.api.get();
      } else if(currentUser.roleName === 'supermanager'){
        dispatch(companiesDuck.thunk.getAll());
      }
    }
  }, [loggedIn])

  useEffect(() => {
    if (!loggedIn || !currentUser.company) {
      return;
    }

    dispatch(featuresDuck.thunk.identify({
      userId: currentUser.id,
      email: currentUser.email,
      companyId: currentUser.companyId,
      companyName: currentUser.company.name,
      companyContactEmail: currentUser.company.contactEmail,
    }));
  }, [loggedIn])

  const roles = (def) => {
    return def.split('').some(r => currentUser.roleName === roleShortcuts[r]);
  };

  const notistackRef = React.createRef();

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider 
          ref={notistackRef}
          maxSnack={5}
          anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
          autoHideDuration={null}
          action={key => <IconButton size="small" aria-label="close" color="inherit" onClick={() => notistackRef.current.closeSnackbar(key)}><Close fontSize="small" /></IconButton>}
        >
          <BrowserRouter>
            {loggedIn === null && (
              <Switch>
                <AppRoute path='*' layout={SimpleLayout}>
                  <h2>Trwa wczytywanie...</h2>
                </AppRoute> {/* this will be a preloader */}
              </Switch>
            )}
            {loggedIn === false && (
              <Switch>
                <AppRoute path='/login' layout={SimpleLayout} component={Login} pageTitle='Logowanie'/>
                <AppRoute path='/user-activation/:token' layout={SimpleLayout} component={SetPassword}/>
                <AppRoute path='/forgot-password' layout={SimpleLayout} component={ForgotPassword} pageTitle='Przypomnienie hasła'/>
                {/* <AppRoute path='/register' layout={SimpleLayout} component={Register} pageTitle='Rejestracja'/> */}

                <AppRoute path={['/', '/logout', '*']} exact layout={SimpleLayout} pageTitle='Logowanie'>
                  <Redirect to='/login'/>
                </AppRoute>
                <AppRoute path='*' layout={SimpleLayout} component={NotFound} pageTitle='Nie znaleziono strony'/>
              </Switch>
            )}
            {loggedIn === true && (
              <Switch>
                <AppRoute path='/logout' layout={SimpleLayout} component={Login}/>
                <AppRoute path='/user-activation/:token' layout={SimpleLayout} component={SetPassword}/>

                <AppRoute path={['/login', '/forgot-password', '/register']} exact layout={SimpleLayout}>
                  <Redirect to='/'/>
                </AppRoute>

                <AppRoute path='/' exact pageTitle='Dashboard'>
                  {roles('r') && <Cases/>}
                  {roles('ms') && <HomepageManager/>}
                  {roles('a') && <HomepageAdmin/>}
                </AppRoute>

                <AppRoute path='/cases' exact component={Cases} pageTitle='Lista spraw' />
                <AppRoute path='/cases/:id/full' exact layout={SimpleLayout} component={FullView} pageTitle='Widok rozszerzony'/>
                <AppRoute path='/cases/:id/reports' exact component={Reports} pageTitle='Raporty'/>
                <AppRoute path='/cases/:id/:step?' exact component={NewCase} pageTitle='Dodaj umowę'/>

                <AppRoute path='/trash/:tab?' pageTitle='Kosz'>{roles('ms') && <TrashManager/>}</AppRoute>

                <AppRoute path='/profile' exact pageTitle='Edytuj profil'><UserEdit/></AppRoute>
                <AppRoute path='/users' exact pageTitle='Użytkownicy'>{roles('ms') && <Users/>}</AppRoute>
                <AppRoute path='/users/:userId/cases' exact>{roles('ms') && <Cases/>}</AppRoute>
                <AppRoute path='/users/:userId/edit'>{roles('msa') && <UserEdit/>}</AppRoute>
                <AppRoute path='/admins' exact>{roles('a') && <Admins/>}</AppRoute>

                <AppRoute path='/company' exact pageTitle='Edytuj firmę'>{roles('msa') && <CompanyEdit/>}</AppRoute>
                <AppRoute path='/companies' exact pageTitle='Lista klientów'>{roles('a') && <Companies/>}</AppRoute>
                <AppRoute path='/companies/:companyId/users' exact pageTitle='Użytkownicy'>{roles('a') && <Users noShowCasesAction={true} />}</AppRoute>
                <AppRoute path='/companies/:companyId/payments' exact pageTitle='Płatności'>{roles('a') && <PaymentsAdmin/>}</AppRoute>
                <AppRoute path='/companies/:companyId/edit' pageTitle='Edytuj firmę'>{roles('a') && <CompanyEdit/>}</AppRoute>

                <AppRoute path='/payments' exact pageTitle='Płatności'>{roles('ms') && <PaymentsManager/>}{roles('a') && <PaymentsAdmin/>}</AppRoute>

                <AppRoute path='/calculation-widget' exact pageTitle='Lista umów wygenerowanych przez kalkulator'>{roles('msa') && <CalculationWidget/>}</AppRoute>
                <AppRoute path='/calculation-widget/embed' exact pageTitle='Instrukcje dołączania kalkulatora na stronę'>{roles('msa') && <CalculationWidgetEmbed/>}</AppRoute>

                <AppRoute path='/kb' exact pageTitle='Baza wiedzy'><KnowledgeBase/></AppRoute>

                <AppRoute path='/kb/:articleId' exact pageTitle='Baza wiedzy'><KnowledgeBaseArticle/></AppRoute>
                <AppRoute path='/kb/:articleId/edit' exact pageTitle='Baza wiedzy'>{roles('a') && <KnowledgeBaseArticleNew/>}</AppRoute>

                <AppRoute path='/dictionaries/:tab?' exact pageTitle='Edytuj słowniki'>{roles('a') && <Dictionaries/>}</AppRoute>

                <AppRoute path='/settings/:tab?' exact pageTitle='Ustawienia'>{roles('a') && <Settings/>}</AppRoute>

                <AppRoute path='/contact' component={Contact} pageTitle='Kontakt'/>
                
                <AppRoute path='*' component={NotFound} pageTitle='Nie znaleziono strony'/>
              </Switch>
            )}          
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
