import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Modal } from '@material-ui/core';
import {
  CheckOutlined,
  BusinessOutlined
} from '@material-ui/icons';
import { formatValue } from 'material-table';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';
import UserAvatar from 'components/basic/UserAvatar';

import { receiptsDuck } from 'redux/ducks/receipts';

import styles from './UnpaidInvoicesList.style';

const columns = [
  { field: 'companyName', title: 'Firma', 
    width: 300,
    filtering: true, searchable: true,
    render: rowData => (
      <div className='userName'>
        <UserAvatar user={{name: rowData.companyName, logo: rowData.companyLogo}}/>
        <span>{rowData.companyName}</span>
      </div>
    ),
  },
  { field: 'month', title: 'Okres rozliczeniowy', 
    width: 170,
    filtering: true, searchable: true,
  },
  { field: 'discount', title: 'Rabat', type: 'numeric', decimalDigits: 2,
    width: 170,
    filtering: true, searchable: true,
  },
  { field: 'amount', title: 'Kwota', type: 'numeric', decimalDigits: 2,
    width: 170,
    filtering: true, searchable: true,
    // currencySetting: { currencyCode: 'PLN', locale: 'pl' },
      render: (rowData) =>
        formatValue(rowData.amount - rowData.discount, {
          type: "numeric",
          decimalDigits: 2,
        }),
    // currencySetting: { currencyCode: 'PLN', locale: 'pl' },
  },
];

const actions = ({history, setModalOptions}) => [ 
  {
    onClick: (e, rowData) => history.push(`/companies/${rowData.companyId}/edit`),
    icon: BusinessOutlined,
    tooltip: 'Zobacz profil firmy',
  },
  {
    onClick: (e, rowData) => setModalOptions({...rowData, open: true}),
    icon: CheckOutlined,
    tooltip: 'Oznacz jako opłacone',
  },  
];

export default function UnpaidInvoicesList() {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();

  const invoices = useSelector(receiptsDuck.get.unpaid());

  const [modalOptions, setModalOptions] = useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={modalOptions}
        onClose={() => setModalOptions(false)}
      >
        <ModalWrapper 
          disabled={modalOptions.sending}
          title={`Faktura za ${modalOptions.month} dla ${modalOptions.companyName}`}
          description='Potwierdź, że ta faktura została opłacona przez klienta i że chcesz ją usunąć z tej listy.'
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => setModalOptions(false)},
            { label: 'Oznacz jako opłaconą', color: 'secondary', icon: CheckOutlined, 
              onClick: values => {
                setModalOptions({...modalOptions, sending: true});
                  const data = {id: modalOptions.id, paymentStatus: 'paid'};
                  receiptsDuck.api.updateOne({
                    data, 
                    immediate: true,
                    onUpdated: (resData) => {
                      dispatch(receiptsDuck.thunk.fieldUpdate({data: resData}));
                      setModalOptions(false);
                    },
                  });             
                }},
          ]}
        >
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns}
        data={invoices}
        actions={actions({history, setModalOptions})}
      />
    </div>
  );
}