export const singleCaseWithFlags = ({thisCase, users, userId, IAmSupermanager, reportsCount = 0}) => {
  const caseOwnerUnassigned = thisCase.ownerUserId === null;
  const caseSupermanagerUnassigned = thisCase.supermanagerUserId === null;
  const IAmCaseOwner = !caseOwnerUnassigned && thisCase.ownerUserId === userId;
  const IAmCaseSupermanager = !caseSupermanagerUnassigned && thisCase.supermanagerUserId === userId;
  const statusIsShared = thisCase.status === 'shared';
  const ownerAndSupermanagerAreCoworkers = thisCase.supermanagerUserId && usersFromSameCompany(users, thisCase.supermanagerUserId, thisCase.ownerUserId);

  // thisCase.id === 6 && console.log(IAmSupermanager, thisCase.status, {
  //   caseOwnerUnassigned,
  //   caseSupermanagerUnassigned,
  //   IAmCaseOwner,
  //   IAmCaseSupermanager,
  //   statusIsShared,
  //   ownerAndSupermanagerAreCoworkers,
  //   supermanagerUserId: thisCase.supermanagerUserId,
  // })

  const isLockedBecauseShared = statusIsShared && !IAmSupermanager && (!IAmCaseOwner || !ownerAndSupermanagerAreCoworkers);
  const isLockedBecauseUnassigned = IAmSupermanager ? caseSupermanagerUnassigned : caseOwnerUnassigned;
  const isLockedBecauseAssigned = !isLockedBecauseUnassigned && !IAmCaseOwner && !(IAmCaseSupermanager && !ownerAndSupermanagerAreCoworkers);

  return {
    ...thisCase,
    reportsCount,
    flags: {
      isLocked: isLockedBecauseShared || isLockedBecauseAssigned || isLockedBecauseUnassigned,
      isLockedBecauseShared,
      isLockedBecauseAssigned,
      isLockedBecauseUnassigned,
      isLockedBecauseNotMine: isLockedBecauseAssigned || isLockedBecauseUnassigned,
      isInSupportEdit: ownerAndSupermanagerAreCoworkers,
      IAmCaseOwner, 
    },
  };
};

export const casesWithFlags = ({cases, users, userId, IAmSupermanager, reportsCounts = []}) => {
  return cases.map(thisCase => {
    const reportsCount = reportsCounts.find((c) => c.caseId === thisCase.id)?.num || 0;
    return singleCaseWithFlags({thisCase, users, userId, IAmSupermanager, reportsCount});
  })
};

const usersFromSameCompany = (users, userId1, userId2) => {
  const user1 = users.find(user => user.id === userId1);
  const user2 = users.find(user => user.id === userId2);
  return user1 && user2 && user1.companyId === user2.companyId;
};
