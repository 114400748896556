import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    '&.variant-full': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      '& > *:not(:first-child)': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    '& > *': {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      }
    },
    '& > *:not(:first-child)': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      }
    },
  },
}), {
  name: 'ButtonWrapper', 
  index: 1,
});
