import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  cardWrapper: {
    display: 'flex',
    height: '100%',
    '&.variant-second': {
      justifyContent: 'center',
      marginLeft: '-5%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-end',
        marginLeft: 0,
      },
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        marginLeft: 0,
      },
    },
    '&.variant-third': {
      justifyContent: 'center',
      marginRight: '-5%',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        marginRight: 0,
      },
    },
    '&.variant-last': {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    '&.variant-vertical': {
      height: 'auto',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(2),
      },
      '& cardTitle': {
        marginBottom: theme.spacing(4),
      },
    },
  },
  card: {
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardTitle: {
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.common.black,
    '&.variant-vertical': {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(1),
    },
  },
  cardBadge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    //background: 'linear-gradient(45deg, #CC0000 30%, #F23A1F 90%)',
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.primary.main,
    },
  },
  cardContent: {
    flex: 1,
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
}), {
  name: 'StatsCard', 
  index: 1,
});
