import store from 'redux/store';
import {Duck} from 'redux/lib/Duck';
import backend from 'redux/lib/backend';
import { PostOffice } from 'redux/lib/PostOffice';
import shortid from 'shortid';

export class RecordLibraryDuck extends Duck {
  apiGeneralUrlCreator(urlParams = {}){
    return this.apiUrlCreator({...urlParams, id: ''});
  }
};

RecordLibraryDuck.prototype.deletedStatuses = ['erased'];

const filterRow = (row, filters, isNewId) => (!isNewId || typeof filters.id !== 'undefined') && Object.entries(filters).findIndex(([key, condition]) => {
  let result = x => !x;
  if(/^!/.test(key)){
    key = key.substring(1);  
    result = x => !!x;
  }
  if(!condition){
    return result(true);
  }
  if(Array.isArray(condition)){
    return result(condition.includes(row[key]));
  }
  if(condition.constructor.name === 'Object' && (condition.start || condition.end)){
    const rowDate = new Date(row[key]);
    return result(
      (
        !condition.start ? true : rowDate >= condition.start
      ) && (
        !condition.end ? true : rowDate <= condition.end
      )
    );
  }
  if(condition.constructor.name === 'Object' && Object.keys(condition).length === 0){
    return result(true);
  }
  if(['String', 'Number'].includes(condition.constructor.name) && Array.isArray(row[key])){
    return result(row[key].includes(condition));
  }
  return result(row[key] == condition); // eslint-disable-line eqeqeq
}) === -1;

RecordLibraryDuck.prototype._defaultGets = {
  ...Duck.prototype._defaultGets,
  all: function(){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => this.deletedStatuses.indexOf(row.status) === -1)
        .filter(row => !this.isNewId(row.id))
        .map(row => this.normalize(row, rest))
    );
  },
  allWithNew: function(){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => this.deletedStatuses.indexOf(row.status) === -1)
        .map(row => this.normalize(row, rest))
    );
  },
  allDeleted: function(){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => this.deletedStatuses.indexOf(row.status) !== -1)
        .map(row => this.normalize(row, rest))
    );
  },
  byId: function(requestedId){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => row.id == requestedId) // eslint-disable-line eqeqeq
        .map(row => this.normalize(row, rest))
        [0]
    )
  },
  byStatus: function(requestedStatus){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => row.status == requestedStatus) // eslint-disable-line eqeqeq
        .map(row => this.normalize(row, rest))
    )
  },
  allFiltered: function(filters){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => this.deletedStatuses.indexOf(row.status) === -1)
        .filter(row => !this.isNewId(row.id))
        .filter(row => filterRow(row, filters, this.isNewId(row.id)))
        .map(row => this.normalize(row, rest))
    )
  },
  filtered: function(filters){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => filterRow(row, filters, this.isNewId(row.id)))
        .map(row => this.normalize(row, rest))
    )
  },
  negfiltered: function(filters){
    return ({ [this.name]: segment, ...rest }) => (
      segment.data
        .filter(row => !filterRow(row, filters, this.isNewId(row.id)))
        .map(row => this.normalize(row, rest))
    )
  },
  lookupTable: function(valueKey = 'id', labelKey = 'name'){
    return ({ [this.name]: segment, ...rest }) => Object.fromEntries(
      segment.data
        .filter(row => !this.deletedStatuses.some(row => row.status))
        .map(row => this.normalize(row, rest))
        .map(row => [row[valueKey], row[labelKey]])
    );
  },
  filteredLookupTable: function(filters, valueKey = 'id', labelKey = 'name'){
    return ({ [this.name]: segment, ...rest }) => Object.fromEntries(
      segment.data
        .filter(row => filterRow(row, filters, this.isNewId(row.id)))
        .map(row => this.normalize(row, rest))
        .map(row => [row[valueKey], row[labelKey]])
    )
  },
  negFilteredLookupTable: function(filters, valueKey = 'id', labelKey = 'name'){
    return ({ [this.name]: segment, ...rest }) => Object.fromEntries(
      segment.data
        .filter(row => !filterRow(row, filters, this.isNewId(row.id)))
        .map(row => this.normalize(row, rest))
        .map(row => [row[valueKey], row[labelKey]])
    )
  },
};

RecordLibraryDuck.prototype._defaultActions = {
  ...Duck.prototype._defaultActions,
  addOne: {
    reducer: (segment, payload) => ({...segment,
      data: [...segment.data, payload],
    }),
  },
  updateOne: {
    reducer: (segment, payload) => ({...segment,
      data: [
        ...segment.data.map(row => (row.id === payload.id ? {...row, ...payload} : row)),
        ...(segment.data.some(row => row.id === payload.id) ? [] : [payload])
      ],
    }),
  },
  updateMany: {
    reducer: (segment, payload) => ({...segment,
      data: [
        ...segment.data.map(row => payload.find(item => item.id === row.id) || row),
        ...payload.filter(item => !segment.data.some(row => row.id === item.id)),
      ],
    }),
  },
  updateId: {
    reducer: (segment, payload) => ({...segment,
      data: [
        // ...segment.data.map(row => row.id === payload.oldId ? {...row, id: payload.newId} : row),
        ...segment.data.map(row => row.id === payload.oldId ? {...row, ...payload.newData} : row),
      ],
    }),
  },
  delete: {
    reducer: (segment, payload) => ({...segment,
      data: segment.data.filter(row => row.id !== payload.id),
    }),
  },
  deleteMany: {
    reducer: (segment, payload) => ({...segment,
      data: segment.data.filter(row => !payload.includes(row.id)),
    }),
  },
};

RecordLibraryDuck.prototype._defaultThunks = {
  ...Duck.prototype._defaultThunks,
  getAll: function({urlParams, callback} = {}){
    return (dispatch, getState) => {
      this.api.getAll({
        urlParams: urlParams || {},
        success: res => {
          store.dispatch(this.action.updateMany(res.data));
          typeof callback === 'function' && callback(res.data);
        },
      });
    }
  },
  fieldUpdate: function({urlParams, data}){
    return (dispatch, getState) => {
      dispatch(this.action.updateOne({
        id: (urlParams || data).id, 
        ...data, //Object.fromEntries(Object.entries(data).filter(([key, val]) => val !== null/*  || key === 'temporaryIncrease' */)),
      }));
    }
  },
  fieldSave: function({urlParams, data, validate = true, onAdded, onUpdated}){
    urlParams || (urlParams = data);
    typeof urlParams.id === 'undefined' && typeof data.id === 'undefined' && (urlParams.id = data.id = 'new-'+shortid.generate());
    
    return (dispatch, getState) => {
      dispatch(this.action.updateOne({
        id: urlParams.id, 
        ...data,
      }));
      const rowData = {...getState()[this.name].data.find(row => row.id === urlParams.id), ...data};

      if(!validate || !this.validationErrors(rowData)){
        if(this.isNewId(urlParams.id)){
          this.api.addOne({
            urlParams, 
            data: Object.fromEntries(Object.entries(rowData).filter(([key, val]) => val !== null/*  || key === 'temporaryIncrease' */)),
            onAdded 
          });
        } else {
          this.api.updateOne({
            urlParams, 
            data: Object.fromEntries(Object.entries(data).filter(([key, val]) => val !== null/*  || key === 'temporaryIncrease' */)),
            onUpdated 
          });
        }
      }
    }
  },
  fieldSaveWithNulls: function({urlParams, data, validate = true, onAdded, onUpdated}){
    urlParams || (urlParams = data);
    typeof urlParams.id === 'undefined' && typeof data.id === 'undefined' && (urlParams.id = data.id = 'new-'+shortid.generate());
    
    return (dispatch, getState) => {
      dispatch(this.action.updateOne({
        id: urlParams.id, 
        ...data,
      }));
      const rowData = {...getState()[this.name].data.find(row => row.id === urlParams.id), ...data};

      if(!validate || !this.validationErrors(rowData)){
        if(this.isNewId(urlParams.id)){
          this.api.addOne({
            urlParams, 
            data: rowData,
            onAdded 
          });
        } else {
          this.api.updateOne({
            urlParams, 
            data: data,
            onUpdated 
          });
        }
      }
    }
  },
  delete: function({urlParams, data, callback, onError}){
    urlParams || (urlParams = data);

    return (dispatch, getState) => {
      const id = urlParams.id;

      this.api.deleteOne({
        immediate: true, 
        urlParams, 
        data: {id: urlParams.id},
        onDeleted: resData => {
          dispatch(this.action.delete({id}));
          typeof callback === 'function' && callback(resData);
        },
        onError: err => {
          typeof onError === 'function' && onError(err);
        },
      })
    };
  },
  fieldSaveSync: function({urlParams, data, validate = true, callback, onError}){
    urlParams || (urlParams = data);
    typeof urlParams.id === 'undefined' && typeof data.id === 'undefined' && (urlParams.id = data.id = 'new-'+shortid.generate());
    
    return (dispatch, getState) => {
      const rowData = {...getState()[this.name].data.find(row => row.id === urlParams.id), ...data};
      const invalid = validate && this.validationErrors(rowData);

      if(!invalid){
        if(this.isNewId(urlParams.id)){
          this.api.addOne({ 
            immediate: true, 
            urlParams, 
            data: Object.fromEntries(Object.entries(rowData).filter(([key, val]) => val !== null/*  || key === 'temporaryIncrease' */)), 
            onError, 
            onAdded: resData => {
              dispatch(this.action.updateOne(resData));
              typeof callback === 'function' && callback(resData);
            } 
          });
        } else {
          this.api.updateOne({ 
            immediate: true, 
            urlParams, 
            data: Object.fromEntries(Object.entries(data).filter(([key, val]) => val !== null/*  || key === 'temporaryIncrease' */)), 
            onError, 
            onUpdated: resData => {
              dispatch(this.action.updateOne({id: urlParams.id, ...resData}));
              typeof callback === 'function' && callback(resData);
            } 
          });
        }
      } else {
        typeof onError === 'function' && onError(invalid);
      }
    }
  },
  fieldSaveSyncMulti: function({data, callback, ...opts}){
    return (dispatch, getState) => {
      if(!Array.isArray(data)) return;
      Promise.allSettled(data.map((singleData, i) => new Promise((resolve, reject) => {
        dispatch(this.thunk.fieldSaveSync({
          data: singleData, 
          callback: resolve, 
          onError: reject,
          ...opts,
        }))
      }))).then(result => typeof callback === 'function' && callback(result));
    };
  },

};

RecordLibraryDuck.prototype._defaultApis = {
  ...Duck.prototype._defaultApis,
  getAll: function({urlParams, ...options} = {urlParams: {id: ''}}){ 
    backend({
      url: this.apiGeneralUrlCreator(urlParams),
      ...options,
    });
  },
  getOne: function({urlParams, callback, ...options} = {urlParams: {id: ''}}){ 
    if(!this.isNewId(urlParams.id)){
      backend({
        url: this.apiUrlCreator(urlParams),
        success: res => {
          store.dispatch(this.action.updateOne(res.data));
          typeof callback === 'function' && callback(res.data);
        },
        error: {
          // 404: err => store.dispatch(this.action.loaded([])),
          default: err => console.log('ERROR', err),//dispatch(this.action.error({message: err.message, response: err.response})),
        },
      });
    }
  },
  addOne: function({urlParams, data, onAdded, ...options} = {urlParams: {id: ''}}){ 
    urlParams || (urlParams = data);
    PostOffice.add({
      url: this.apiGeneralUrlCreator(urlParams),
      model: this.name, 
      data,
      onAdded: function(resData){
        // store.dispatch(this.action.updateId({oldId: urlParams.id, newId: data.id}));
        store.dispatch(this.action.updateId({oldId: urlParams.id, newData: resData}));
        typeof onAdded === 'function' && onAdded(resData);
      }.bind(this),
      ...options,
    });
  },
  updateOne: function({urlParams, data, onUpdated, ...options} = {urlParams: {id: ''}}){ 
    urlParams || (urlParams = data);
    PostOffice.update({
      url: this.apiUrlCreator(urlParams),
      model: this.name, 
      data,
      onUpdated: function(resData){
        // store.dispatch(this.action.updateId({oldId: urlParams.id, newId: data.id}));
        // store.dispatch(this.action.updateOne(resData));
        typeof onUpdated === 'function' && onUpdated(resData);
      }.bind(this),
      ...options,
    });
  },
  // addMany: 0,
  deleteOne: function({urlParams, data, ...options} = {urlParams: {id: ''}}){ 
    urlParams || (urlParams = data);
    PostOffice.delete({
      url: this.apiUrlCreator(urlParams),
      model: this.name, 
      data,
      ...options,
    });
  },
  /* getAll2: async function({urlParams}){ 
    try {
      const response = await backend({url: this.apiGeneralUrlCreator(urlParams)});
      store.dispatch(this.action.updateMany(response.data));
      return {response};
    } catch(error) {
      console.log('ERROR', error);
      return {error};
    } // finally {}
  }, */
};
