import React from 'react';
//import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Grid,
  Typography,
  Card,
  CardContent
} from '@material-ui/core';
import { 
  EditOutlined 
} from '@material-ui/icons';
import PageTitle from 'components/basic/PageTitle';
import ButtonBack from 'components/basic/ButtonBack';
import Text from 'components/basic/Text';

import styles from './KnowledgeBaseArticle.style';

import { currentUserDuck } from 'redux/ducks/currentUser';
import { useParams } from 'react-router-dom';
import { phrasesDuck } from 'redux/ducks/dictionaries';

export default function Clients() {
  const classes = styles();
  const {articleId} = useParams();

  const currentUser = useSelector(currentUserDuck.get.data());
  const article = useSelector(phrasesDuck.get.byId(articleId)) || {};

  return (
    <div className={classes.root}>
      <ButtonBack target='/kb' text='Powrót do bazy wiedzy' />
      <PageTitle 
        centered 
        variant='full' 
        mt={2} 
        title={article.title}
        buttons={currentUser.roleName === 'admin' && [
          { label: 'Edytuj artykuł', color: 'secondary', target: `/kb/${articleId}/edit`, icon: EditOutlined },
        ]}  
      />   
      <Grid container justify='center'>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Text>
                {article.content && article.content.split('\n\n').map(par => <Typography component='p' variant='body2'>{par}</Typography>)}
              </Text>
            </CardContent>
          </Card>
        </Grid>
      </Grid>   
    </div>
  );
}
