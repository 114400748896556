import React, { useMemo } from "react";

import { Grid } from "@material-ui/core";
import { Info } from "@material-ui/icons";

import PageTitle from "components/basic/PageTitle";
import CasesList from "components/feature/CasesList";

import styles from "./CalculationWidget.style";
import { useSelector } from "react-redux";
import { currentUserDuck } from "redux/ducks/currentUser";
import { casesDuck } from "redux/ducks/cases";
import { getIsCaseFromCalculationWidget } from "utils/getIsCaseFromCalculationWidget";
import { getFormattedCaseAgreementNo } from "utils/getFormattedCaseAgreementNo";
import CalculationWidgetAlertEmailWarning from "components/feature/CalculationWidgetAlertEmailWarning";

export default function CalculationWidget() {
  const classes = styles();
  const currentUser = useSelector(currentUserDuck.get.data());
  const cases = useSelector(casesDuck.get.all());

  const casesFromWidget = useMemo(
    () =>
      cases
        .filter((c) => getIsCaseFromCalculationWidget(c, true))
        .map((c) => getFormattedCaseAgreementNo(c, true))
        .sort((a, b) =>
          a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? -1 : 1
        ),
    [cases]
  );

  return (
    <div className={classes.root}>
      {currentUser.company?.isCalculatorOn ? (
        <>
          <CalculationWidgetAlertEmailWarning />

          <PageTitle
            title="Lista umów wygenerowanych przez kalkulator"
            hintName="W tym widoku znajdziesz informacje dot. spraw wygenerowanych przez kalkulator oraz dołączanie kalkulatora na stronę."
            buttons={[
              {
                label: "Instrukcja dołączania kalkulatora na stronę",
                color: "secondary",
                target: "/calculation-widget/embed",
                icon: Info,
              },
            ]}
          />

          <Grid container>
            <Grid item xs={12}>
              <CasesList
                displayEmailFromOptionalNotes
                isFromCalculationWidgetView
                cases={casesFromWidget}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div style={{ padding: "0 1rem" }}>
            <h2>Kalkulator roszczeń frankowych</h2>

            <h3 style={{ fontWeight: "normal" }}>
              Dotrzyj do większej liczby klientów. Dołącz kalkulator roszczeń
              frankowych na swoją stronę www.
              <br />
              Od dziś każdy klient, który odwiedzi twoją stronę, będzie mógł,
              całkowicie za darmo sprawdzić swój kredyt CHF, a ty w łatwy i
              szybki sposób będziesz mógł skontaktować się z potencjalnym
              klientem. Kalkulator jest połączony z systemem Projuste i na
              bieżąco możesz śledzić kto potrzebuje pomocy przy sprawie
              frankowej. 
            </h3>

            <h3>
              Aby korzystać z kalkulatora roszczeń frankowych, który możesz
              dołączyć na swoją stronę, skontaktuj się z nami{" "}
              <a href="mailto:biuro@projuste.pl">biuro@projuste.pl</a> lub
              zadzwoń na numer <a href="tel:+48690806585">+48 690 806 585</a>
            </h3>
          </div>

          <img
            width="100%"
            src="/calculation-widget-off.png"
            alt="Wizualizacja dołączania kalkulatora do swojej strony"
          />
        </>
      )}
    </div>
  );
}
