import React from 'react';
//import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Modal,
} from '@material-ui/core';
import { 
  Add,
  SaveOutlined,
 } from '@material-ui/icons';

import PageTitle from 'components/basic/PageTitle';
import ModalWrapper from 'components/basic/ModalWrapper';
import Form from 'components/basic/Form';

import AdminsList from 'components/feature/AdminsList';

import styles from './Admins.style';
import shortid from 'shortid';
import { usersDuck } from 'redux/ducks/users';
import { useDispatch } from 'react-redux';

const handleAddition = ({values, callback, onError, dispatch}) => {
  const data = {
    id: 'new-'+shortid.generate(), 
    ...values,
    privileges: ['admin'],
  };
  
  usersDuck.api.addOne({
    data, 
    onAdded: resData => {
      if(resData.id){
        dispatch(usersDuck.thunk.fieldUpdate({data: resData}))
        typeof callback === 'function' && callback(resData);
      } else {
        typeof onError === 'function' && onError(resData);
      }
    }, 
    onError, 
  });
};

const NewUserModal = ({newUserOptions, updateNewUserOptions}) => {
  const dispatch = useDispatch();

  return (
    <ModalWrapper 
      variant='small'
      title='Dodaj nowego administratora'
      disabled={newUserOptions.sending}
    >
      <Form 
        variant='small'
        fields={[
          { label: 'E-mail', name: 'email', defaultValue: '', required: true},
          { label: 'Imię', name: 'firstName', defaultValue: '', required: true},
          { label: 'Nazwisko', name: 'lastName', defaultValue: '', required: true},
        ]} 
        buttons={[
          { label: 'Anuluj', color: 'primary', onClick: () => updateNewUserOptions({modalOpen: false})},
          { label: 'Zapisz administratora', color: 'secondary', icon: SaveOutlined, type: 'submit'},
        ]}
        onSubmit={values => {
          updateNewUserOptions({sending: true});
          handleAddition({
            values, 
            newUserOptions, 
            dispatch,
            callback: () => updateNewUserOptions({modalOpen: false, sending: false}),
            onError: () => updateNewUserOptions({sending: false}),
          }); 
        }}
      />
    </ModalWrapper>
  );
};

export default function Admins() {
  const classes = styles();

  // New admin modal

  const [newUserOptions, updateNewUserOptions] = React.useReducer((s,a)=>({...s,...a}), {
    modalOpen: false, 
    sending: false,
  });

  return (
    <div className={classes.root}>
      <Modal
        open={newUserOptions.modalOpen}
        onClose={() => updateNewUserOptions({modalOpen: false})}
      >
        <NewUserModal newUserOptions={newUserOptions} updateNewUserOptions={updateNewUserOptions}/>
      </Modal>
      <PageTitle 
        title='Administratorzy'
        buttons={[
          { label: 'Dodaj administratora', color: 'secondary', icon: Add, onClick: () => updateNewUserOptions({modalOpen: true})},
        ]} 
      /> 
      <Grid container>
        <Grid item xs={12}>
          <AdminsList/>
        </Grid>
      </Grid>  
    </div>
  );
}
