import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
// import DateFnsUtils from '@date-io/date-fns';
// import plLocale from 'date-fns/locale/pl';
// import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MaskedInput from 'react-text-mask';
// import { Hidden } from '@material-ui/core';
// import { EventOutlined } from '@material-ui/icons';
import classnames from 'classnames';
import { formatValue, unFormatValue } from 'material-table';
import { isDateCorrect } from './isDateCorrect';

const DatePickerWrap = ({ children, value, onChange, open, onClose, variantStatic, className }) => {
    return (
        <div className={classnames('mTable-date-input-component', className)}>
            {children}
        </div>
    );
};

export const DateInput = ({
    Icon,
    onChange: propsOnChange,
    onBlur: propsOnBlur,
    value: propsValue,
    filterPlaceholder = 'dd.mm.rrrr',
    hideFilterIcons,
    hideFilterIcon,
    filterTooltip,
    filterIconClassName,
    className,
    wrapClassName,
    variantStatic,
    ...props
}) => {
    const [value, setValue] = useState(formatValue(propsValue, { type: 'date' }));
    const [prevValue, setPrevValue] = useState(isDateCorrect(value) ? value : '');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (propsValue !== '' && propsValue !== null && propsValue !== value) {
            setValue(formatValue(propsValue, { type: 'date' }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsValue]);


    return (
        <DatePickerWrap className={wrapClassName}>
            <MaskedInput
                mask={[/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                keepCharPositions={true}
                placeholder={filterPlaceholder || null}
                onBlur={e => {
                    e.persist();

                    if (isDateCorrect(e.target.value)) {
                        propsOnChange && propsOnChange(unFormatValue(e.target.value, { type: 'date' }))
                        setValue(e.target.value);
                    } else {
                        setValue(prevValue);
                        setError(false);
                    }
                }}
                onKeyUp={e => {
                    e.persist();

                    setError(!isDateCorrect(e.target.value));
                }}
                value={value}
                onChange={e => {
                    e.persist();

                    if (isDateCorrect(e.target.value)) {
                        setPrevValue(e.target.value);
                    }

                    setValue(e.target.value);
                }}
                MuiProps={{
                    error,
                    className,
                    focused: (value || value === 0).toString(),
                    helperText: error && 'Nieprawidłowa data',
                    InputProps: {
                        startAdornment: hideFilterIcons || hideFilterIcon || !Icon ? undefined : (
                            <InputAdornment className={classnames(filterIconClassName, 'mTable-date-input-adornment-start')} position="start">
                                <Tooltip title={filterTooltip}>
                                    <Icon className={classnames(filterIconClassName && `${filterIconClassName}-icon`, 'mTable-date-input-adornment-start-icon')} />
                                </Tooltip>
                            </InputAdornment>
                        ),
                    }
                }}
                render={(ref, { MuiProps, ...props }) => (
                    <TextField
                        inputRef={ref}
                        inputProps={props}
                        {...MuiProps}
                    />
                )}
                {...props}
            />
        </DatePickerWrap>
    );
};