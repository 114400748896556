import store from 'redux/store';
import {Duck} from 'redux/lib/Duck';
import backend from 'redux/lib/backend';
import { PostOffice } from 'redux/lib/PostOffice';

export class SingleRecordDuck extends Duck {};

SingleRecordDuck.prototype._defaultGets = {
  ...Duck.prototype._defaultGets,
  data: function(){
    return ({ [this.name]: segment, ...rest }) => (
      this.normalize(segment.data, rest)
    );
  },
};

SingleRecordDuck.prototype._defaultActions = {
  ...Duck.prototype._defaultActions,
  replace: {
    reducer: (segment, payload) => ({...segment,
      data: {
        ...payload
      },
    }),
  },
  update: {
    reducer: (segment, payload) => ({...segment,
      data: {
        ...segment.data, 
        ...payload
      },
    }),
  },
};

SingleRecordDuck.prototype._defaultThunks = {
  ...Duck.prototype._defaultThunks,
  fieldUpdate: function({urlParams, data}){
    return (dispatch, getState) => {
      dispatch(this.action.update(data));
    }
  },
  fieldSave: function({urlParams, data, onAdded}){
    return (dispatch, getState) => {
      dispatch(this.action.update(data));

      const rowData = {...getState()[this.name].data, ...data};

      if(!this.validationErrors(rowData)){
        this.api.update({urlParams, data});
      }
    }
  },
};

SingleRecordDuck.prototype._defaultApis = {
  ...Duck.prototype._defaultApis,
  get: function({urlParams, callback, onError, ephemeral, ...options} = {urlParams: {}}){ 
    backend({
      url: this.apiUrlCreator(urlParams),
      success: res => {
        !ephemeral && store.dispatch(this.action.update(res.data));
        typeof callback === 'function' && callback(res.data);
      },
      error: onError,
      ...(typeof this.apiDataTransform === 'function' ? {transform: data => this.apiDataTransform(data, urlParams)} : {}),
      ...options,
    });
  },
  add: function({urlParams, row, onAdded, ...options} = {urlParams: {}}){ 
    PostOffice.add({
      url: this.apiUrlCreator(urlParams),
      model: this.name, 
      data: row,
      onAdded: onAdded || true,
      ...options,
    });
  },
  update: function({urlParams, data, ...options} = {urlParams: {}}){ 
    // console.log('urlParams:', urlParams, this.apiUrlCreator(urlParams))
    PostOffice.update({
      url: this.apiUrlCreator(urlParams),
      model: this.name, 
      data,
      ...options,
    });
  },
};
