import React from 'react';
import clsx from 'clsx';

import {
  Typography,
  Box,
  Link
} from '@material-ui/core';

import styles from './Copyright.style';

function Copyright({text = 'Copyright © ', variant, ...props}) {
  const classes = styles();
  
  return (
    <Box className={clsx(classes.root, variant && (classes[`variant-${variant}`] || `variant-${variant}`))} {...props}>
      <Typography variant='body2' color='textSecondary' align='center'>
        {text}
        <Link color='inherit' href='https://projuste.pl'>
          ProJuste.pl
        </Link>{' '}
        {/* {new Date().getFullYear()}
        {'.'} */}
      </Typography>
    </Box>
  );
}

export default Copyright;