import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&.variant-last': {
      marginTop: theme.spacing(4),
    }
  },
  'variant-small': {
    boxShadow: theme.shadows[0],
    width: '100%',
    '& $formWrapper': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
  },
  'variant-inner': {
    boxShadow: theme.shadows[0],
    backgroundColor: 'transparent',
    width: '100%',
    '& $formWrapper': {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
  },
  formWrapper: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formTitle: {
    paddingRight: theme.spacing(2),
  },
  sectionTitle: {
    paddingRight: theme.spacing(2),
  },
  sectionDescription: {
    marginTop: theme.spacing(4),
  },
  formButton: {
    marginTop: theme.spacing(2),
  },
}), {
  name: 'Form', 
  index: 1,
});
