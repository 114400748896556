import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(3),
    '&.variant-indented': {
      '& $cardContent': {
        paddingLeft: theme.spacing(11),
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(3),
        },
      },
    },
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  cardTitle: {
    paddingLeft: theme.spacing(2),
  },
  cardBadge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.primary.main,
    },
  },
  cardContent: {
    padding: theme.spacing(3),
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
    '& .MuiList-root': {
      width: "100%",
      paddingLeft: theme.spacing(8),
      marginTop: theme.spacing(-3),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(3),
      },
      '& li': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .MuiListItem-root': {
      display: "block",
      position: "relative",
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.palette.common.black,
      textDecoration: "underline",
      '&::before': {
        position: "absolute",
        content: "' '",
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        backgroundColor: theme.palette.secondary.main,
        left: 0,
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
  },
}), {
  name: 'ListCard', 
  index: 1,
});
