import { RecordLibraryDuck } from 'redux/lib/Duck';
import { defaultPhrases } from 'dict';
import backend from 'redux/lib/backend';

export const banksDuck = new RecordLibraryDuck('banks', ({id}) => `/dictionaries/banks/${id}`);
export const exchangeRatesDuck = new RecordLibraryDuck('exchangeRates', ({id, from, to, currency}) => `/dictionaries/exchange-rates/${id}` + (!from && !to ? '' : '?'+[from && `from=${from}`, to && `to=${to}`, currency && `currency=${currency}`].filter(Boolean).join('&')));
export const holidaysDuck = new RecordLibraryDuck('holidays', ({id, year}) => `/dictionaries/holidays/${id}` + (!year ? '' : '?'+[year && `year=${year}`].filter(Boolean).join('&')));
export const iborsDuck = new RecordLibraryDuck('ibors', ({id, from, to}) => `/dictionaries/ibors/${id}` + (!from && !to ? '' : '?'+[from && `from=${from}`, to && `to=${to}`].filter(Boolean).join('&')));
export const paymentTypesDuck = new RecordLibraryDuck('paymentTypes', ({id}) => `/dictionaries/payment-types/${id}`)
export const settingsDuck = new RecordLibraryDuck('settings', ({id}) => `/settings/${id}`);

settingsDuck.api.reload = function({callback, error, ...options}){ 
  backend({
    url: '/settings/reload',
    method: 'post',
    success: res => callback(res.data),
    error: error,
  });
};

export const phrasesDuck = new RecordLibraryDuck('phrases', ({id, type, active}) => `/dictionaries/phrases/${id}` + (!type && !active ? '' : '?'+[type && `type=${type}`, active && `active=${active}`].filter(Boolean).join('&')));

phrasesDuck.separator = '|';

phrasesDuck.normalize = (data) => {
  if(data.type === 'kb-cat'){
    return {
      ...Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'content')), 
      iconUrl: data.content,
    };
  }
  if(data.type === 'kb'){
    const parts = data.title.split(phrasesDuck.separator);
    return {
      ...data, 
      categoryId: parts.shift(),
      title: parts.join(phrasesDuck.separator),
    };
  }
  return data;
}

phrasesDuck.prepareData = (data) => {
  if(data.type === 'kb-cat'){
    return {
      ...Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'iconUrl')), 
      content: data.iconUrl,
    };
  }
  if(data.type === 'kb'){
    return {
      ...Object.fromEntries(Object.entries(data).filter(([key]) => key !== 'categoryId')), 
      title: data.categoryId+phrasesDuck.separator+data.title,
    };
  }
  return data;
};

phrasesDuck.get.kbArticles = function(){
  return (state) => this.get.filtered({type: 'kb'})(state)
};

phrasesDuck.get.kbCategories = function(){
  return (state) => this.get.filtered({type: 'kb-cat'})(state)
};

phrasesDuck.get.kb = function(){
  return (state) => {
    const categories = this.get.kbCategories()(state).map(cat => ({...cat, articles: []}));
    this.get.kbArticles()(state)
      .forEach(art => {
        const cat = categories.find(c => c.id == art.categoryId); // eslint-disable-line eqeqeq
        if(cat && cat.articles){
          cat.articles.push(art)
        }
      });
    
    return categories;//.sort((a, b) => a.title === b.title ? 0 : a.title > b.title ? 1 : -1);
  }
};

phrasesDuck.get.typeLookupTableWithDefaults = function(requestedType){
  return (state) => {
    const stateRows = this.get.filtered({type: requestedType})(state);
    return {
      ...Object.fromEntries(
        Object.entries(defaultPhrases[requestedType]).filter(([key, value]) => !stateRows.some(row => row.title === key && row.content))
      ), 
      ...Object.fromEntries(
        stateRows.map(({title, content, active}) => active && content && [title, content]).filter(Boolean)
      ),
    };
  }
};

phrasesDuck.get.contentByTitleWD = function(requestedType, requestedTitle){
  return (state) => {
    const stateRows = this.get.filtered({type: requestedType, title: requestedTitle}, 'title', 'content')(state);
    return stateRows && stateRows.length 
      ? stateRows[0].active && stateRows[0].content 
      : defaultPhrases[requestedType][requestedTitle]
      || false; 
  }
};
