import parse from 'html-react-parser';

export const formatCurrency = (amount, currency) => {
  return parseInt(amount).toLocaleString('pl') + ' ' + currency;
};

export const simpleMarkDown = rawText => {
  let output = rawText;
  output = output.replace(/\n/g, '<br/>');
  output = output.replace(/(\W)\*\*([^*]+)\*\*(\W)/g, '$1<strong>$2</strong>$3');
  return parse(output);
};
