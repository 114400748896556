import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Modal,
} from '@material-ui/core';
import {
  EditOutlined,
  BlockOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons';

import { usersDuck } from 'redux/ducks/users';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';
import InfoChip from 'components/basic/InfoChip';
import UserAvatar from 'components/basic/UserAvatar';

import styles from './AdminsList.style';
import Form from 'components/basic/Form';
import { useSnackbar } from 'notistack';

const columns = [
  { 
    field: 'fullName', 
    title: 'Imię i nazwisko', 
    filtering: true,
    searchable: true,
    width: 350,
    render: rowData => (
      <div className='userName'>
        <UserAvatar user={rowData}/>
        <span>{rowData.fullName}</span>
      </div>
    ),
  },
  { 
    field: 'statusName', 
    title: 'Status',
    width: 350,
    headerStyle: { textAlign: 'left'},
    cellStyle: { textAlign: 'left'},
    filtering: true,
    searchable: true,
    render: rowData => <InfoChip label={rowData.statusName} variant={
      rowData.status === 'active' ? 'active' :
      rowData.status === 'pending_activation' || rowData.status === 'pending_admin_approval' ? 'pending' :
      rowData.status === 'deactivated' ? 'inactive' :
      rowData.status === 'erased' ? 'erased' :
      'error'
    } /> 
  },
];

const actions = (history, openModal) => [ 
  {
    onClick: (e, rowData) => history.push(`/users/${rowData.id}/edit`),
    icon: EditOutlined,
    tooltip: 'Edytuj użytkownika',
  },  
  {
    icon: DeleteOutlineOutlined,
    tooltip: 'Usuń użytkownika',
    onClick: (e, rowData) => openModal.delete(rowData),
  }
];

export default function Users() {
  const classes = styles();
  const users = useSelector(usersDuck.get.admins());
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [deleteOptions, updateDeleteOptions] = React.useReducer((s, a) => ({...s, ...a}), {open: false, disabled: false, rowData: {}});;

  return (
    <div className={classes.root}>
    <Modal
        open={deleteOptions.open}
        onClose={() => updateDeleteOptions({open: false})}
      >
      <ModalWrapper 
        title='Czy chcesz skasować administratora?' 
        description='Jeżeli skasujesz tego administratora, nie będzie on mógł się zalogować na swoje konto.'
      >
        <Form
          variant='small'
          disabled={deleteOptions.disabled}
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => updateDeleteOptions({open: false}) },
            { label: 'usuń administratora', color: 'secondary', icon: DeleteOutlineOutlined, type: 'submit' },  
          ]}
          onSubmit={values => {
            updateDeleteOptions({disabled: true});
            dispatch(usersDuck.thunk.fieldSaveSync({
              data: {id: deleteOptions.rowData.id, status: 'erased', ...(values.newUserIdForCases && {newUserIdForCases: values.newUserIdForCases})}, 
              callback: () => {
                updateDeleteOptions({open: false});
                enqueueSnackbar('Użytkownik został usunięty', {variant: 'success'});
              },
              onError: () => {
                updateDeleteOptions({disabled: false});
              },
            })); 
          }}
        />
      </ModalWrapper>
      </Modal>
      <Table
        columns={columns}
        data={users}
        actions={actions(history, {delete: (rowData) => updateDeleteOptions({open: true, rowData})})}
      />
    </div>
  );
}