import React, { useMemo, useState } from "react";
//import PropTypes from 'prop-types';

import { Grid } from "@material-ui/core";
import {
  Filter1Outlined,
  Filter2Outlined,
  Filter3Outlined,
  CalendarTodayOutlined,
  LibraryBooksOutlined,
} from "@material-ui/icons";

import PageTitle from "components/basic/PageTitle";
import TabsWithPanels from "components/basic/TabsWithPanels";
import Dropdown from "components/basic/Dropdown";
import StatsCards from "components/basic/StatsCards";

import CasesList from "components/feature/CasesList";
import ReportsList from "components/feature/ReportsList";

import styles from "./HomepageManager.style";
import { useSelector } from "react-redux";
import { statsDuck } from "redux/ducks/stats";
// import { currentUserDuck } from 'redux/ducks/currentUser';
import { dates } from "dict";
import { reportsDuck } from "redux/ducks/reports";
import { casesDuck } from "redux/ducks/cases";
import { getIsCaseFromCalculationWidget } from "utils/getIsCaseFromCalculationWidget";
import { getFormattedCaseAgreementNo } from "utils/getFormattedCaseAgreementNo";

export default function HomepageManager() {
  const classes = styles();
  const [period, setPeriod] = useState("currentMonth");
  // const [statsOptions, updateStatsOptions] = useReducer((state, action) => ({...state, ...action}), {period: 'currentMonth'});

  // const currentCompany = useSelector(currentUserDuck.get.company());
  const stats = useSelector(
    statsDuck.get.casesAndReports({
      createdAt:
        dates.relative[period] /* , ownerCompanyId: currentCompany.id */,
    })
  );

  const cases = useSelector(casesDuck.get.all());
  const casesNotFromGuests = useMemo(
    () =>
      cases
        .filter((c) => !getIsCaseFromCalculationWidget(c))
        .map((c) => getFormattedCaseAgreementNo(c))
        .sort((a, b) =>
          a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? -1 : 1
        ),
    [cases]
  );

  casesNotFromGuests.splice(10);

  const reports = useSelector(reportsDuck.get.allWithCases());
  reports.sort((a, b) =>
    a.createdAt === b.createdAt ? 0 : a.createdAt > b.createdAt ? -1 : 1
  );
  reports.splice(10);

  return (
    <div className={classes.root}>
      <PageTitle title="Przegląd spraw">
        <Dropdown
          variant="right"
          icon={CalendarTodayOutlined}
          value={period}
          handleChange={(value) => setPeriod(value)}
          options={[
            { label: "Bieżący miesiąc", value: "currentMonth" },
            { label: "Bieżący kwartał", value: "currentQuarter" },
            { label: "Bieżący rok", value: "currentYear" },
          ]}
        />
      </PageTitle>
      <Grid container>
        <StatsCards
          cardSizeMd="6"
          cardSizeLg="3"
          cards={[
            {
              title: "Wszystkie raporty",
              value: stats.reports.all,
              icon: LibraryBooksOutlined,
            },
            {
              title: "Umowy",
              value: stats.cases.all,
              icon: LibraryBooksOutlined,
            },
            // { title: 'Raporty wstępne', value: stats.reports['r1'], icon: Filter1Outlined, variant: 'second' },
            {
              title: "Raporty szacunkowe",
              value: stats.reports["estimation"],
              icon: Filter2Outlined,
              variant: "third",
            },
            {
              title: "Raporty pełne",
              value: stats.reports["cases"],
              icon: Filter3Outlined,
              variant: "last",
            },
          ]}
        />
        {/* <Grid item xs={12}>
          <PageTitle 
            title='Ostatnio utworzone' 
            variant='dense' 
            mt={8}
          />
        </Grid> */}
        <TabsWithPanels
          title={
            <PageTitle title="Ostatnio utworzone" variant="denser" mt={1} />
          }
          mt={8}
          variant="right"
          urlParam={false}
          tabs={[
            {
              id: "cases",
              label: "Umowy",
              icon: LibraryBooksOutlined,
              panel: () => (
                <CasesList
                  cases={casesNotFromGuests}
                  tableOptions={{ paging: false, filtering: false }}
                />
              ),
            },
            {
              id: "reports",
              label: "Raporty",
              icon: Filter1Outlined,
              panel: () => (
                <ReportsList
                  showCaseDetails
                  reports={reports}
                  tableOptions={{ paging: false, filtering: false }}
                />
              ),
            },
          ]}
        />
      </Grid>
    </div>
  );
}
