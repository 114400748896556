import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
} from '@material-ui/core';

import styles from './ComplexStatsCard.style';

export const ComplexStatsCard = ({icon: Icon, cards = {}, title, totalValue}) => {
  const classes = styles();
  return (
  <Card className={classes.root}>
    <CardContent className={classes.cardContent}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography component="h3" variant="body1">{title}</Typography>
        <Avatar className={classes.cardBadge}>
          <Icon/>
        </Avatar>
        <Typography component="h4" variant="h2">{totalValue}</Typography>
      </Box>
    </CardContent>
    <CardContent className={classes.cardSecondaryContent}>
      {cards.map((card) =>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography  component="h3" variant="body2" className={classes.cardTitle}>{card.title}</Typography>
              <Typography component="h4" variant="h2">{card.value}</Typography>
            </Box>
          </CardContent>
        </Card>
      )}      
    </CardContent>
  </Card>
  );
}

ComplexStatsCard.propTypes = {
  children: PropTypes.node,
};

export default ComplexStatsCard;
