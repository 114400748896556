import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal
} from '@material-ui/core';
import {
  RestoreOutlined,
  DeleteOutlineOutlined,
} from '@material-ui/icons';

import { reportsDuck } from 'redux/ducks/reports';

import Table from 'components/basic/Table';
import ModalWrapper from 'components/basic/ModalWrapper';

import styles from './TrashedReportsList.style';
import { useSnackbar } from 'notistack';
import { usersDuck } from 'redux/ducks/users';

const columns = ({usersLookup}) => [
  { field: 'caseName', title: 'Sygnatura', 
    width: 250,
    ellipsis: true,
    filtering: true, searchable: true,
  },
  { field: 'casePerson', title: 'Klient', 
    width: 200,
    ellipsis: true,
    filtering: true, searchable: true,
  },
  { field: 'typeName', title: 'Rodzaj', 
    filtering: true, searchable: true,
  },
  { field: 'createdAt', title: 'Data utworzenia', type: 'date',
    filtering: true,
  },
  { field: 'deletedAt', title: 'Data usunięcia', type: 'date',
    filtering: true,
  },
  { field: 'ownerUserId', title: 'Kto usunął', lookup: usersLookup,
    width: 200,
    ellipsis: true,
    filtering: true,
  },
];

const actions = (openModal) => [
  {
    icon: RestoreOutlined,
    onClick: (e, rowData) => openModal.restore(rowData),
    tooltip: 'Przywróć raport',
  },
  {
    icon: DeleteOutlineOutlined,
    onClick: (e, rowData) => openModal.delete(rowData),
    tooltip: 'Usuń na zawsze',
  },
];

export default function Cases({onSelectionChange}) {
  const classes = styles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const reports = useSelector(reportsDuck.get.filteredWithCases({status: 'deactivated'}));
  const usersLookup = useSelector(usersDuck.get.negFilteredLookupTable({privileges: 'supermanager'/* companyId: userData.companyId */}, 'id', 'fullName'));

  const [restoreModalOpen, setRestoreModalOpen] = React.useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <Modal
        open={restoreModalOpen}
        onClose={() => setRestoreModalOpen(false)}
      >
        <ModalWrapper 
          title='Czy chcesz przywrócić ten raport?' 
          description='Przywrócony raport ponownie pojawi się na liście raportów i będzie wliczany do ogólnej liczby raportów.'
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => setRestoreModalOpen(false) },
            { label: 'Przywróć raport', color: 'secondary', icon: RestoreOutlined, 
              onClick: () => {
                dispatch(reportsDuck.thunk.fieldSaveSync({
                  data: {id: restoreModalOpen.id, caseId: restoreModalOpen.caseId, status: 'active'}, 
                  callback: () => {
                    setRestoreModalOpen(false);
                    enqueueSnackbar('Raport został przywrócony', {variant: 'success'});
                  },
                })); 
              }, 
            },
          ]}  
          >
        </ModalWrapper>
      </Modal>
      <Modal
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
      >
        <ModalWrapper 
          title='Czy chcesz skasować ten raport?' 
          description='Ta opcja spowoduje, że raport zostanie usunięty na stałe i nie będzie możliwości przywrócenia go.'
          buttons={[
            { label: 'Anuluj', color: 'primary', onClick: () => setModalDeleteOpen(false)},
            { label: 'Skasuj raport', color: 'secondary', icon: DeleteOutlineOutlined, 
              onClick: () => {
                dispatch(reportsDuck.thunk.fieldSaveSync({
                  data: {id: modalDeleteOpen.id, caseId: modalDeleteOpen.caseId, status: 'erased'}, 
                  callback: () => {
                    setModalDeleteOpen(false);
                    enqueueSnackbar('Raport został usunięty', {variant: 'success'});
                  },
                })); 
              } 
            },
          ]}  
          >
        </ModalWrapper>
      </Modal>
      <Table
        columns={columns({usersLookup})}
        data={reports}
        actions={actions({restore: (rowData) => setRestoreModalOpen(rowData), delete: (rowData) => setModalDeleteOpen(rowData)})}
        options={{selection: true}}
        onSelectionChange={onSelectionChange}
     />
    </div>
  );
}