import React from 'react';
import { useSelector } from 'react-redux';
//import PropTypes from 'prop-types';

import { 
  AccessAlarmOutlined, 
  AccessAlarmsOutlined, 
  AccessibilityNewOutlined, 
  AccessibilityOutlined, 
  AccessibleForwardOutlined, 
  AccessibleOutlined, 
  AccessTimeOutlined,
  HelpOutlineOutlined, 
} from '@material-ui/icons';

import ListCard from 'components/basic/ListCard';

import styles from './KnowledgeBaseList.style';

import { phrasesDuck } from 'redux/ducks/dictionaries';

export default function KnowledgeBaseList() {
  const classes = styles();

  const categories = useSelector(phrasesDuck.get.kb());

  return (
    <div classNames={classes.root}>
      <ListCard   
        cards={categories.length ? (
          categories.map(({title, iconUrl, articles}) => ({
            title, 
            icon: iconUrl ? (() => <img src={iconUrl} alt=''/>) : null, 
            links: articles.map(({title: articleTitle, id: articleId}) => ({
              label: articleTitle, 
              target: '/kb/'+articleId,
            })),
          }))
        ) : (
          [{
            title: 'Brak artykułów w bazie wiedzy', 
          }]
        )}
      />   
    </div>
  );
}
