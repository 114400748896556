import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import PageMeta from 'components/basic/PageMeta';
import Content from '../Content';
import styles from './SimpleLayout.style';

const SimpleLayout = ({variant, pageTitle, children}) => {

  const classes = styles();
  return (
    <div className={clsx(classes.root, variant && `variant-${variant}`)}>
      <PageMeta pageTitle={pageTitle} />
      <Content>
        {children}
      </Content>
    </div>
  );
};

SimpleLayout.propTypes = {
  children: PropTypes.node,
};

export default SimpleLayout;