import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      width: "100%",
    },
    warningHeaderText: {
      fontSize: theme.typography.h2.fontSize,
      margin: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: 0,
      paddingRight: 0,
      color: theme.palette.error.contrastText,
    },
    warningSubheadingText: {
      fontSize: theme.typography.h5.fontSize,
      margin: 0,
      marginBottom: theme.spacing(2),
      padding: 0,
      color: theme.palette.error.contrastText,
    },
  }),
  {
    name: "CalculationWidgetAlertEmailWarning",
    index: 1,
  }
);
